import axios from "axios";
//import Cookies from "universal-cookie";
// ----------------------------------------------
// ------------------ USUARIOS ------------------
// ----------------------------------------------

export const crearUsuario = async (data) => {
    try {
        const response = await axios.post("/usuarios", data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: usuarios.js:44 ~ registerUser ~ error", error);
        throw error.response;
    }
};

export const getAllUsuarios = async () => {
    try {
        const response = await axios.get("/usuarios", { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: usuarios.js:54 ~ getAllUsuarios ~ error", error);
        throw error.response;
    }
};

export const getUsuario = async (id) => {
    try {
        const response = await axios.get(`/usuarios/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: usuarios.js:64 ~ getUsuario ~ error", error);
        throw error.response;
    }
};

export const updateUsuario = async (id, data) => {
    try {
        const response = await axios.patch(`/usuarios/${id}`, data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: usuarios.js:74 ~ updateUsuario ~ error", error);
        throw error.response;
    }
};

export const deleteUsuario = async (id) => {
    try {
        const response = await axios.delete(`/usuarios/${id}`, { retry: 3, retryDelay: 1000 });
        console.error("🚀 ~ file: usuarios.js:51 ~ deleteUsuario ~ response:", response.data)

        return response.data;
    } catch (error) {
        console.error("🚀 ~ file: usuarios.js:84 ~ deleteUsuario ~ error", error);
        return error.response;
    }
};
