import { Heading, Select, SimpleGrid, Stack, Text } from '@chakra-ui/react';

const ClasificacionEnergetica = ({ formData, handleChange }) => {

    let clasificacion_energetica;
    if (formData) {
        try {
            clasificacion_energetica = JSON.parse(formData);
        } catch (error) {
            clasificacion_energetica = JSON.parse(JSON.stringify(formData));
        }
    }
    
    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Clasificación energetica</Heading>

            <SimpleGrid columns={2}>
                <Text>Certificación</Text>
                <Select
                    name="clasificacion_energetica.certificacion"
                    value={clasificacion_energetica.certificacion}
                    onChange={handleChange} isDisabled={handleChange == null}
                    placeholder="-- Ninguno --">
                    <option value="Tramitacion">En tramite</option>
                    <option value="Exento">Exento</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="C">C</option>
                    <option value="D">D</option>
                    <option value="E">E</option>
                    <option value="F">F</option>
                    <option value="G">G</option>
                </Select>
            </SimpleGrid>

        </Stack>
    );
};

export default ClasificacionEnergetica;
