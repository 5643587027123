import axios from "axios";

export const getAllEncargos = async () => {
    try {
        const response = await axios.get("/encargos", { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        throw error.response;
    }
};

export const getEncargoById = async (id) => {
    try {
        const response = await axios.get(`/encargos/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        throw error.response;
    }
};

export const getEncargosByCliente = async (id) => {
    try {
        const response = await axios.get(`/encargos/cliente/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        throw error.response;
    }
};

export const getEncargosByInmueble = async (id) => {
    try {
        const response = await axios.get(`/encargos/inmueble/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: encargos.js:35 ~ getEncargosByInmueble ~ error:", error);
        throw error.response;
    }
};

export const getEncargoByNoticia = async (id) => {
    try {
        const response = await axios.get(`/encargos/noticia/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: encargos.js:35 ~ getEncargosByInmueble ~ error:", error);
        throw error.response;
    }
};

export const getEncargoPedidoById = async (id) => {
    try {
        const response = await axios.get(`/encargos/pedido/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: encargos.js:35 ~ getEncargosByInmueble ~ error:", error);
        throw error.response;
    }
};

export const getEncargoPedidoByActividad = async (id) => {
    try {
        const response = await axios.get(`/encargos/pedido/actividad/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: encargos.js:35 ~ getEncargosByInmueble ~ error:", error);
        throw error.response;
    }
};

export const crearEncargo = async (data) => {
    try {
        const response = await axios.post("/encargos", data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: encargos.js:65 ~ crearEncargo ~ error:", error);
        throw error.response;
    }
};

export const actualizarEncargoPedidos = async (id, data) => {
    try {
        const response = await axios.patch(`/encargos/pedido/${id}`, data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: encargos.js:74 ~ actualizarEncargoPedidos ~ error:", error);
        throw error.response;
    }
};

export const editarEncargo = async (id, data) => {
    try {
        const response = await axios.patch(`/encargos/${id}`, data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: encargos.js:85 ~ editarEncargo ~ error:", error);
        throw error.response;
    }
};

export const eliminarEncargo = async (id) => {
    try {
        const response = await axios.delete(`/encargos/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: encargos.js:95 ~ eliminarEncargo ~ error:", error);
        throw error.response;
    }
};

export const uploadEncargoFile = async (id, filename, data) => {
    try {
        const response = await axios.post(`/encargos/documentos/${id}`, {
            filename: filename,
            data: data,
        });

        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: encargos.js:116 ~ uploadEncargoFile ~ error:", error);
        throw error.response;
    }
};

export const getAllEncargoFiles = async (id) => {
    try {
        const response = await axios.get(`/encargos/documentos/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: encargos.js:126 ~ getAllEncargoFiles ~ error:", error);
        throw error.response;
    }
};

export const getEncargoFile = async (id, name) => {
    try {
        const response = await axios.get(`/encargos/documentos/${id}/${name}`, { retry: 3, retryDelay: 1000, responseType: 'blob' });
        return response.data;
    } catch (error) {
        console.error("🚀 ~ file: encargos.js:136 ~ getEncargoFile ~ error:", error);
        throw error.response;
    }
};
