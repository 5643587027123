import { Button, SimpleGrid, Stack, useDisclosure, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import DatosContacto from "./CRUD/DatosContacto";
import InfoGeneral from "./CRUD/InfoGeneral";
import Procedencia from "./CRUD/Procedencia";
import { createCliente, deleteCliente, getClienteSimilar, updateCliente } from "../../services/clientes";
import { errorNotification, okNotification } from "../../utils/Notificaciones";
import ReconciliacionModal from "../Modals/ReconciliacionModal";
import { useRef, useState } from "react";
import TablaPedidosCliente from "../Pedidos/TablaPedidosCliente";
import TablaInmueblesCliente from "../Inmuebles/TablaInmuebles/TablaInmueblesCliente";
import Fechas from "../Clientes/CRUD/Fechas";
import TablaActividadesCliente from "../Actividades/TablaActividadesCliente";
import Separador from "../Separador/Separador";
import { useSelector } from "react-redux";
import ConfirmationModal from "../Modals/ConfirmationModal";

const GridInfoCliente = ({ formData, handleChange, isEdit = false }) => {
    const navigate = useNavigate();
    const toast = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [clienteSimilar, setClienteSimilar] = useState([]);
    const { user } = useSelector((state) => state.auth);

    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
    const cancelRef = useRef();

    const handleSave = async () => {
        let cliente;
        try {
            if (isEdit) {
                cliente = await updateCliente(formData.uuid, formData);
                okNotification("Cliente actualizado correctamente", toast);
                navigate(`/clientes/ver/${cliente.uuid}`);
            } else {
                const similar = await getClienteSimilar(formData);
                if (similar.length > 0) {
                    setClienteSimilar(similar[0]);
                    onOpen();
                    errorNotification("Ya existe un cliente con los mismos datos", toast);
                } else {
                    cliente = await createCliente(formData);
                    okNotification("Cliente creado correctamente", toast);
                    navigate(`/clientes/ver/${cliente.uuid}`);
                }
            }
        } catch (error) {
            console.error("🚀 ~ file: GridInfoClientes.js:60 ~ handleSave ~ error:", error);
            errorNotification("Error al realizar la operación", toast);
        }
    };

    const handleDelete = async () => {
        try {
            await deleteCliente(formData.uuid);
            okNotification("Cliente eliminado correctamente", toast);
            navigate(-1);
        } catch (error) {
            console.error("🚀 ~ file: EditarCliente.js:21 ~ handleSave ~ error", error);
            errorNotification("Error al eliminar el cliente", toast);
        }
    };

    return (
        <>
            <Stack>
                <SimpleGrid columns={3} spacing={4}>
                    <InfoGeneral formData={formData} handleChange={handleChange} />
                    <DatosContacto formData={formData} handleChange={handleChange} />
                    <Stack direction="column">
                        <Procedencia formData={formData} handleChange={handleChange} />
                        {handleChange == null && <Fechas formData={formData} handleChange={handleChange} />}
                        {handleChange == null && (
                            <Stack spacing={0}>
                                <Stack direction={{ base: "column", md: "row" }} spacing={0} w="100%">
                                    <Button
                                        colorScheme="facebook"
                                        w="100%"
                                        rounded="none"
                                        onClick={() => navigate(`/clientes/editar/${formData.uuid}`)}>
                                        EDITAR
                                    </Button>
                                    <Button rounded="none" colorScheme="facebook" w="100%" onClick={() => navigate(-1)}>
                                        VOLVER
                                    </Button>
                                </Stack>
                                {user && user.rol === "Gerente" && (
                                    <Button rounded="none" colorScheme="red" size="sm" w="100%" onClick={onOpenDelete}>
                                        ELIMINAR
                                    </Button>
                                )}
                            </Stack>
                        )}
                        {handleChange != null && (
                            <>
                                <Stack direction={{ base: "column", md: "row" }} spacing={0} w="100%">
                                    <Button
                                        colorScheme="green"
                                        rounded="none"
                                        w="100%"
                                        onClick={handleSave}
                                        isDisabled={!formData.telefono || !formData.nombre || !formData.apellidos}>
                                        GUARDAR
                                    </Button>
                                    <Button colorScheme="facebook" rounded="none" w="100%" onClick={() => navigate(-1)}>
                                        VOLVER
                                    </Button>
                                </Stack>
                            </>
                        )}
                    </Stack>
                </SimpleGrid>
                <Separador />
                {handleChange == null && (
                    <Stack direction="column">
                        <TablaActividadesCliente cliente={formData.uuid} />
                        <Separador />
                        <TablaPedidosCliente cliente={formData.uuid} />
                        <Separador />
                        <TablaInmueblesCliente cliente={formData.uuid} />
                    </Stack>
                )}
            </Stack>
            {isOpen && (
                <ReconciliacionModal
                    isOpen={isOpen}
                    onClose={onClose}
                    clienteNuevo={formData}
                    clienteSimilar={clienteSimilar}
                />
            )}

            {isOpenDelete && (
                <ConfirmationModal
                    show={isOpenDelete}
                    cancelRef={cancelRef}
                    handleClose={onCloseDelete}
                    handleConfirm={handleDelete}
                    title="Eliminar cliente"
                    body="¿Estás seguro de que quieres eliminar este cliente?"
                />
            )}
        </>
    );
};

export default GridInfoCliente;
