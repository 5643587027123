import { Td, Tr } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const TableItem = ({ usuario }) => {
    const navigate = useNavigate();

    const handleVerCliente = () => {
        navigate(`/clientes/ver/${usuario.uuid}`);
    };

    let datosContacto;
    if (usuario.otros_datos_contacto) {
        try {
            datosContacto = JSON.parse(usuario.otros_datos_contacto);
        } catch (error) {
            datosContacto = JSON.parse(JSON.stringify(usuario.otros_datos_contacto));
        }
    }

    return (
        <Tr key={usuario.id} onClick={handleVerCliente} _hover={{ cursor: "pointer", bg: "gray.100" }}>
            <Td
                fontSize={!usuario.tipo_cliente && "12px"}
                fontStyle={!usuario.tipo_cliente && "italic"}>
                {usuario.tipo_cliente ? usuario.tipo_cliente : "- Sin datos -"}
            </Td>
            <Td>
                {usuario.nombre} {usuario.apellidos}
            </Td>
            <Td
                fontSize={!usuario.tipo_identificador && "12px"}
                fontStyle={!usuario.tipo_identificador && "italic"}>
                {usuario.tipo_identificador ? usuario.tipo_identificador : "- Sin datos -"}
            </Td>
            <Td
                fontSize={!usuario.identificador && "12px"}
                fontStyle={!usuario.identificador && "italic"}>
                {usuario.identificador ? usuario.identificador : "- Sin datos -"}
            </Td>
            <Td
                fontSize={!datosContacto?.direccion && "12px"}
                fontStyle={!datosContacto?.direccion && "italic"}>
                {datosContacto?.direccion ? datosContacto?.direccion : "- Sin datos -"}
            </Td>
            <Td>{usuario.telefono}</Td>
            <Td
                fontSize={!datosContacto?.email && "12px"}
                fontStyle={!datosContacto?.email && "italic"}>
                {datosContacto?.email ? datosContacto?.email : "- Sin datos -"}
            </Td>
        </Tr>
    );
};

export default TableItem;
