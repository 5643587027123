import { useEffect, useState } from "react";

import { Box, Heading, Table, TableContainer, Tbody, Td, Thead, Tr } from "@chakra-ui/react";

import { getAllPedidos } from "../../services/pedidos";
import PedidoTableItem from "../../components/Pedidos/PedidoTableItem";
import Layout from "../../components/Layout/Layout";
import FiltroPedidos from "../../components/Filtros/FiltroPedidos";

const ListarPedidos = () => {
    const [pedidos, setPedidos] = useState([]);
    const [pedidosFiltrados, setPedidosFiltrados] = useState(pedidos);
    const [needReload, setNeedReload] = useState(true);

    useEffect(() => {
        const getPedidos = async () => {
            const auxPedidos = await getAllPedidos();
            setPedidos(auxPedidos);
            setNeedReload(false);
        };

        needReload && getPedidos();
    }, [needReload]);

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center" color="facebook.800">PEDIDOS</Heading>
            </Box>

            <FiltroPedidos datos={pedidos} setDatosFiltrados={setPedidosFiltrados} />

            <Box mt={6}>
                <TableContainer w="100%" border="1px" borderColor="facebook.400" rounded="lg" shadow="dark-lg">
                    <Table size="lg" p={4}>
                        <Thead bgColor="facebook.100">
                            <Tr>
                                <Td>Nombre</Td>
                                <Td>Estado</Td>
                                <Td>Motivo</Td>
                                <Td>Tipologia Inmueble</Td>
                                <Td>Precio</Td>
                                <Td>Fecha de creación</Td>
                                <Td>Fecha de modificación</Td>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {pedidosFiltrados &&
                                pedidosFiltrados.map((pedido) => {
                                    return <PedidoTableItem key={pedido.uuid} pedido={pedido} />;
                                })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Layout>
    );
};

export default ListarPedidos;
