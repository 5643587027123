import axios from "axios";

export const crearNoticia = async (data) => {
    try {
        const response = await axios.post("/noticias", data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: noticias.js:8 ~ crearNoticia ~ error:", error);
        throw error.response;
    }
};

export const getNoticias = async () => {
    try {
        const response = await axios.get("/noticias", { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: noticias.js:18 ~ getNoticias ~ error:", error);
        throw error.response;
    }
};

export const getNoticiaById = async (id) => {
    try {
        const response = await axios.get(`/noticias/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: noticias.js:28 ~ getNoticiaById ~ error:", error);
        throw error.response.data.error;
    }
};

export const getNoticiaByInmueble = async (id) => {
    try {
        const response = await axios.get(`/noticias/inmueble/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: noticias.js:38 ~ getNoticiaByInmueble ~ error:", error);
        throw error.response;
    }
};

export const updateNoticia = async (id, data) => {
    try {
        const response = await axios.patch(`/noticias/${id}`, data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: noticias.js:38 ~ updateNoticia ~ error:", error);
        throw error.response;
    }
};

export const deleteNoticia = async (id) => {
    try {
        const response = await axios.delete(`/noticias/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: noticias.js:58 ~ deleteNoticia ~ error:", error)
        throw error.response;
    }
}