import { Heading, Select, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import EasyDatePicker from '../../EasyDatePicker/EasyDatePicker'

const Fechas = ({ encargo, handleChange }) => {
    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md" my={2}>
                Estado y fechas
            </Heading>
            <SimpleGrid columns={2}>
                <Text>Estado</Text>
                <Select
                    name="estado"
                    value={encargo.estado}
                    onChange={handleChange}
                    isDisabled={handleChange == null}>
                    <option value="Activo">Activo</option>
                    <option value="Cerrado negativamente">
                        Cerrado negativamente
                    </option>
                </Select>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Fecha de creación encargo</Text>
                <EasyDatePicker
                    name="createdAt"
                    value={encargo.createdAt}
                    needHour={true}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Última modificación encargo</Text>
                <EasyDatePicker
                    name="updatedAt"
                    value={encargo.updatedAt}
                    needHour={true}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Fecha de vencimiento</Text>
                <EasyDatePicker
                    name="fecha_vencimiento"
                    value={encargo.fecha_vencimiento}
                    needHour={true}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Fecha cierre encargo</Text>
                {encargo.fecha_cierre_encargo ? (
                    <EasyDatePicker
                        name="fecha_cierre_encargo"
                        value={encargo.fecha_cierre_encargo}
                        needHour={true}
                    />
                ) : (
                    <Text>Sin fecha.</Text>
                )}
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Fecha escritura</Text>
                {encargo.fecha_escritura ? (
                    <EasyDatePicker
                        name="fecha_escritura"
                        value={encargo.fecha_escritura}
                        needHour={true}
                    />
                ) : (
                    <Text>Sin fecha.</Text>
                )}
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Fecha perfeccionamiento compraventa</Text>
                {encargo.fecha_perfeccionamiento_compraventa ? (
                    <EasyDatePicker
                        name="fecha_perfeccionamiento_compraventa"
                        value={encargo.fecha_perfeccionamiento_compraventa}
                        needHour={true}
                    />
                ) : (
                    <Text>Sin fecha.</Text>
                )}
            </SimpleGrid>
        </Stack>
    )
}

export default Fechas
