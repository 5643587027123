import { Box, Heading, SimpleGrid, Stack } from "@chakra-ui/react";
import InfoBox from "./InfoBox";
import HeaderBox from "./HeaderBox";

const Agencias = ({ agencia }) => {

    const ObjectBox = ({ children }) => (
        <Stack shadow="lg" p={4} rounded="md" _hover={{ shadow: "2xl" }}>
            {children}
        </Stack>
    );

    return (
        <Box>
            <Box p={4} rounded="md" bgColor="blackAlpha.50">
                <Heading p={4} fontSize={"xl"}>
                    Estadísticas de la agencia: {agencia.nombre}
                </Heading>

                <Stack spacing={12}>
                    <ObjectBox>
                        <InfoBox nombre="Noticias" dato={agencia.noticias.total} />

                        {Object.keys(agencia.noticias.estados).length > 0 && (
                            <SimpleGrid minChildWidth="120px" spacing={4}>
                                <HeaderBox nombre="Estados" />
                                {agencia?.noticias?.estados &&
                                    Object.keys(agencia.noticias.estados).map((key) => (
                                        <InfoBox nombre={key} dato={agencia.noticias.estados[key]} boxStyle={1} />
                                    ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(agencia.noticias.motivacion).length > 0 && (
                            <SimpleGrid minChildWidth="120px" spacing={4}>
                                <HeaderBox nombre="Motivación" />
                                {agencia?.noticias?.motivacion &&
                                    Object.keys(agencia.noticias.motivacion).map((key) => (
                                        <InfoBox nombre={key} dato={agencia.noticias.motivacion[key]} boxStyle={1} />
                                    ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(agencia.noticias.procedencia).length > 0 && (
                            <SimpleGrid minChildWidth="120px" spacing={4}>
                                <HeaderBox nombre="Procedencia" />
                                {agencia?.noticias?.procedencia &&
                                    Object.keys(agencia.noticias.procedencia).map((key) => (
                                        <InfoBox nombre={key} dato={agencia.noticias.procedencia[key]} boxStyle={1} />
                                    ))}
                            </SimpleGrid>
                        )}
                    </ObjectBox>

                    <ObjectBox>
                        <InfoBox nombre="Pedidos" dato={agencia.pedidos.total} />

                        {Object.keys(agencia.pedidos.estados).length > 0 && (
                            <SimpleGrid minChildWidth="120px" spacing={4}>
                                <HeaderBox nombre="Estados" />
                                {agencia?.pedidos?.estados &&
                                    Object.keys(agencia.pedidos.estados).map((key) => (
                                        <InfoBox nombre={key} dato={agencia.pedidos.estados[key]} boxStyle={1} />
                                    ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(agencia.pedidos.estados).length > 0 && (
                            <SimpleGrid minChildWidth="120px" spacing={4}>
                                <HeaderBox nombre="Tipo de inmueble" />
                                {agencia?.pedidos?.tipo_inmueble &&
                                    Object.keys(agencia.pedidos.tipo_inmueble).map((key) => (
                                        <InfoBox nombre={key} dato={agencia.pedidos.tipo_inmueble[key]} boxStyle={1} />
                                    ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(agencia.pedidos.tipo_pedido).length > 0 && (
                            <SimpleGrid minChildWidth="120px" spacing={4}>
                                <HeaderBox nombre="Tipo de pedido" />
                                {agencia?.pedidos?.tipo_pedido &&
                                    Object.keys(agencia.pedidos.tipo_pedido).map((key) => (
                                        <InfoBox nombre={key} dato={agencia.pedidos.tipo_pedido[key]} boxStyle={1} />
                                    ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(agencia.pedidos.contrato).length > 0 && (
                            <SimpleGrid minChildWidth="120px" spacing={4}>
                                <HeaderBox nombre="Contrato" />
                                {agencia?.pedidos?.contrato &&
                                    Object.keys(agencia.pedidos.contrato).map((key) => (
                                        <InfoBox nombre={key} dato={agencia.pedidos.contrato[key]} boxStyle={1} />
                                    ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(agencia.pedidos.motivo_cierre).length > 0 && (
                            <SimpleGrid minChildWidth="120px" spacing={4}>
                                <HeaderBox nombre="Motivo de cierre" />
                                {agencia?.pedidos?.motivo_cierre &&
                                    Object.keys(agencia.pedidos.motivo_cierre).map((key) => (
                                        <InfoBox nombre={key} dato={agencia.pedidos.motivo_cierre[key]} boxStyle={1} />
                                    ))}
                            </SimpleGrid>
                        )}
                    </ObjectBox>

                    <ObjectBox>
                        <InfoBox nombre="Encargos" dato={agencia.encargos.total} />

                        {Object.keys(agencia.encargos.estados).length > 0 && (
                            <SimpleGrid minChildWidth="120px" spacing={4}>
                                <HeaderBox nombre="Estados" />
                                {agencia?.encargos?.estados &&
                                    Object.keys(agencia.encargos.estados).map((key) => (
                                        <InfoBox nombre={key} dato={agencia.encargos.estados[key]} boxStyle={1} />
                                    ))}
                            </SimpleGrid>
                        )}
                    </ObjectBox>

                    <ObjectBox>
                        <InfoBox nombre="Actividades" dato={agencia.actividades.total} />

                        {Object.keys(agencia.actividades.estados).length > 0 && (
                            <SimpleGrid minChildWidth="120px" spacing={4}>
                                <HeaderBox nombre="Estados" />
                                {agencia?.actividades?.estados &&
                                    Object.keys(agencia.actividades.estados).map((key) => (
                                        <InfoBox nombre={key} dato={agencia.actividades.estados[key]} boxStyle={1} />
                                    ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(agencia.actividades.entidades).length > 0 && (
                            <SimpleGrid minChildWidth="120px" spacing={4}>
                                <HeaderBox nombre="Entidades" />
                                {agencia?.actividades?.entidades &&
                                    Object.keys(agencia.actividades.entidades).map((key) => (
                                        <InfoBox nombre={key} dato={agencia.actividades.entidades[key]} boxStyle={1} />
                                    ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(agencia.actividades.modalidad_contacto).length > 0 && (
                            <SimpleGrid minChildWidth="120px" spacing={4}>
                                <HeaderBox nombre="Modalidad de contacto" />
                                {agencia?.actividades?.modalidad_contacto &&
                                    Object.keys(agencia.actividades.modalidad_contacto).map((key) => (
                                        <InfoBox nombre={key} dato={agencia.actividades.modalidad_contacto[key]} boxStyle={1} />
                                    ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(agencia.actividades.tipo_actividad).length > 0 && (
                            <SimpleGrid minChildWidth="120px" spacing={4}>
                                <HeaderBox nombre="Tipo de actividad" />
                                {agencia?.actividades?.tipo_actividad &&
                                    Object.keys(agencia.actividades.tipo_actividad).map((key) => (
                                        <InfoBox nombre={key} dato={agencia.actividades.tipo_actividad[key]} boxStyle={1} />
                                    ))}
                            </SimpleGrid>
                        )}
                    </ObjectBox>

                    <ObjectBox>
                        <InfoBox nombre="Citas" dato={agencia.citas.total} />

                        {Object.keys(agencia.citas.estado).length > 0 && (
                            <SimpleGrid minChildWidth="120px" spacing={4}>
                                <HeaderBox nombre="Estados" />
                                {agencia?.citas?.estado &&
                                    Object.keys(agencia.citas.estado).map((key) => (
                                        <InfoBox nombre={key} dato={agencia.citas.estado[key]} boxStyle={1} />
                                    ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(agencia.citas.tipos).length > 0 && (
                            <SimpleGrid minChildWidth="120px" spacing={4}>
                                <HeaderBox nombre="Tipos de citas" />
                                {agencia?.citas?.tipos &&
                                    Object.keys(agencia.citas.tipos).map((key) => (
                                        <InfoBox nombre={key} dato={agencia.citas.tipos[key]} boxStyle={1} />
                                    ))}
                            </SimpleGrid>
                        )}
                    </ObjectBox>
                </Stack>
            </Box>
        </Box>
    );
};

export default Agencias;
