import { useEffect, useState } from "react";

import { Box, Heading, Table, TableContainer, Tbody, Td, Thead, Tr } from "@chakra-ui/react";
import Layout from "../../components/Layout/Layout";
import { getNoticias } from "../../services/noticias";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import FiltroNoticias from "../../components/Filtros/FiltroNoticias";

const ListarNoticias = () => {
    const [noticias, setNoticias] = useState([]);
    const [noticiasFiltradas, setNoticiasFiltradas] = useState([]);
    const [needReload, setNeedReload] = useState(true);

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        const getInmuebles = async () => {
            setNeedReload(false);
            const auxInmuebles = await getNoticias();
            setNoticias(auxInmuebles);
        };

        needReload && user && getInmuebles();
    }, [needReload, user]);

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center" color="facebook.800">
                    NOTICIAS
                </Heading>
            </Box>

            <FiltroNoticias datos={noticias} setDatosFiltrados={setNoticiasFiltradas} />

            <Box mt={6}>
                <TableContainer w="100%" mt={2} border="1px" borderColor="facebook.400" rounded="lg" shadow="dark-lg">
                    <Table size="lg" p={4}>
                        <Thead bgColor="facebook.100">
                            <Tr>
                                <Td>Noticia</Td>
                                <Td>Inmueble</Td>
                                <Td>Motivación</Td>
                                <Td>Precio cliente</Td>
                                <Td>Responsable</Td>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {noticiasFiltradas &&
                                noticiasFiltradas.map((noticia) => {
                                    let direccion;
                                    if (noticia && noticia.inmueble && noticia.inmueble.ubicacion) {
                                        try {
                                            direccion = JSON.parse(noticia.inmueble.ubicacion).direccion;
                                        } catch (error) {
                                            direccion = JSON.parse(
                                                JSON.stringify(noticia.inmueble.ubicacion)
                                            ).direccion;
                                        }
                                    }
                                    return (
                                        <Tr key={noticia.uuid} _hover={{ cursor: "pointer", bg: "gray.100" }}>
                                            <TdLink noticia={noticia.uuid}>
                                                NT - {noticia.cliente?.nombre} {noticia.cliente?.apellidos} -{" "}
                                                {noticia.estado}
                                            </TdLink>
                                            <TdLink noticia={noticia.uuid}>
                                                <strong>
                                                    {noticia.inmueble?.tipo_inmueble}{" "}
                                                    {noticia.inmueble?.subtipo_inmueble}
                                                </strong>{" "}
                                                en <strong>{direccion}</strong>
                                            </TdLink>
                                            <TdLink noticia={noticia.uuid}>{noticia.motivacion}</TdLink>
                                            <TdLink noticia={noticia.uuid}>{noticia.precio_cliente}</TdLink>
                                            <TdLink noticia={noticia.uuid}>
                                                {noticia.responsable?.nombre} {noticia.responsable?.apellidos}
                                            </TdLink>
                                        </Tr>
                                    );
                                })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Layout>
    );
};

const TdLink = ({ children, noticia }) => {
    return (
        <Td>
            <Link to={`/noticias/ver/${noticia}`}>{children}</Link>
        </Td>
    );
};

export default ListarNoticias;

/*
<Stack direction={["column", "row"]} spacing={4} mb={2}>
                    <Button
                        w="100%"
                        onClick={() => setMostrarTodas(!mostrarTodas)}
                        colorScheme={mostrarTodas ? "blue" : "green"}>
                        {mostrarTodas ? "Mostrar solo las mías" : "Mostrar todas"}
                    </Button>
                    <Button
                        w="100%"
                        onClick={() => setMostrarAbiertas(!mostrarAbiertas)}
                        colorScheme={mostrarAbiertas ? "red" : "green"}>
                        {mostrarAbiertas ? "Mostrar solo las cerradas" : "Mostrar solo las abiertas"}
                    </Button>
                </Stack>
                */
