export const okNotification = (mensaje, toast) => {
    toast({
        title: '¡Completado!',
        description: mensaje,
        status: 'success',
        duration: 5000,
        isClosable: true,
    });
}

export const errorNotification = (mensaje, toast) => {
    toast({
        title: 'ERROR',
        description: mensaje,
        status: 'error',
        duration: 5000,
        isClosable: true,
    });
}