import {
    Box,
    Flex,
    Heading,
    IconButton,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { CloseIcon, ViewIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";


const ListaDocumentosPedidos = ({ pedidos }) => {
    const navigate = useNavigate();
    return (
        <Box w="100%">
            <Heading textAlign="center" fontSize="2xl" color="facebook.800" my={4}>
                PEDIDOS CON DOCUMENTACIÓN
            </Heading>
            {pedidos.length === 0 ? (
                <Heading textAlign="center" fontSize="xl" color="red.800">
                    No hay pedidos con documentación
                </Heading>
            ) : (
                <TableContainer maxH="40rem" overflowY="auto">
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Pedido</Th>
                                <Th>Cliente</Th>
                                <Th>Responsable</Th>
                                <Th>Acciones</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {pedidos.map((pedido) => (
                                <Tr key={pedido.uuid}>
                                    <Td>
                                        PE - {pedido.tipo_inmueble} {pedido.subtipo_inmueble && pedido.subtipo_inmueble}
                                    </Td>
                                    <Td>
                                        {pedido.cliente?.nombre} {pedido.cliente?.apellidos}
                                    </Td>
                                    <Td>
                                        {pedido.responsable?.nombre} {pedido.responsable?.apellidos}
                                    </Td>
                                    <Td>
                                        <Flex gap={1}>
                                            <IconButton
                                                colorScheme="facebook"
                                                icon={<ViewIcon />}
                                                onClick={() => navigate(`/pedidos/ver/${pedido.uuid}`)}
                                            />
                                            <IconButton isDisabled colorScheme="red" icon={<CloseIcon />} />
                                        </Flex>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default ListaDocumentosPedidos;
