import { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { createEvent } from "../../utils/createEvent";
import styles from "./EasyDatePicker.module.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { Text } from "@chakra-ui/react";

const EasyDatePicker = ({ name, value, handleChange, needHour = false }) => {
    const defaultDate = value ? new Date(value) : null;
    const [fecha, setFecha] = useState(defaultDate);
    const [ultimaActualizacion, setUltimaActualizacion] = useState("");

    useEffect(() => {
        const generateEvent = (name, value) => {
            if (!handleChange) return;
            const event = createEvent(name, value);
            handleChange(event);
        };

        if (fecha !== ultimaActualizacion) {
            generateEvent(name, fecha);
            setUltimaActualizacion(fecha);
        }
    }, [handleChange, ultimaActualizacion, fecha, name]);

    const fechaPlazo = new Date(value);
    let formattedDate;
    if (needHour) {
        formattedDate = fechaPlazo.toLocaleDateString("es-ES", {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
        });
    } else {
        formattedDate = fechaPlazo.toLocaleDateString("es-ES", {
            day: "numeric",
            month: "short",
            year: "numeric",
        });
    }

    // To uppercase
    formattedDate = formattedDate.toUpperCase();

    return handleChange ? (
        <DateTimePicker
            format="dd/MM/yyyy"
            name={name}
            value={value}
            onChange={(datetime) => setFecha(datetime)}
            className={styles.datePickerRestyle}
        />
    ) : (
        <Text fontWeight="bold">{value === null ? "Sin fecha" : formattedDate}</Text>
    );
};

export default EasyDatePicker;
