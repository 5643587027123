import { Heading, Stack } from "@chakra-ui/react";
import AutoComplete from "../../AutoCompletarDireccion/AutoCompletarDireccion";

const UbicacionInmueble = ({ formData, handleChange }) => {
    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Ubicación</Heading>
            <AutoComplete
                inputId="address"
                handleChange={handleChange}
                formData={formData}
            />
        </Stack>
    );
};

export default UbicacionInmueble;
