export const API_ROUTE =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? "http://localhost:3333"
        : "https://api.vivepanacea.com";

export const S3_ROUTE = "https://cdn.vivepanacea.com";

export const TIPOS_CITAS = [
    {
        tipo: "Venta o alquiler",
        texto: "Venta/alquiler",
        color: "#FF0101",
        isHidden: true,
    },
    {
        tipo: "Curso",
        texto: "Reunión curso",
        color: "#000000",
        isWhite: true,
    },
    {
        tipo: "Personal",
        texto: "Personal",
        color: "#FFFFFF",
    },
    {
        tipo: "Valoracion",
        texto: "Valoración",
        color: "#B3B3B3",
        isHidden: true,
    },
    {
        tipo: "Adquisicion",
        texto: "Adquisición",
        color: "#F543CE",
        isHidden: true,
    },
    {
        tipo: "Gestion de encargo",
        texto: "Gestión encargo",
        color: "#27A3D9",
        isHidden: true,
    },
    {
        tipo: "Propuesta",
        texto: "Propuesta (reserva)",
        color: "#1FBC2F",
        isHidden: true,
    },
    {
        tipo: "Firma notaria",
        texto: "Firma notaria",
        color: "#D11BE1",
        isHidden: true,
    },
    {
        tipo: "Asesoramiento financiero",
        texto: "Asesoramiento financiero",
        color: "#573EEE",
        isWhite: true,
    },
];

export const Tipologia_inmueble = [
    {
        tipo: "Piso",
    },
    {
        tipo: "Casa",
    },
    {
        tipo: "Casa rural",
    },
    {
        tipo: "Plaza de garaje",
    },
    {
        tipo: "Terreno",
    },
    {
        tipo: "Local comercial",
    },
];

export const Subtipo_inmueble = [
    {
        tipo: "Piso",
        subtipo: [
            {
                tipo: "Ático",
            },
            {
                tipo: "Bajo",
            },
            {
                tipo: "Planta media",
            },
            {
                tipo: "Duplex",
            },
            {
                tipo: "Estudio",
            },
        ],
    },
    {
        tipo: "Casa",
        subtipo: [
            {
                tipo: "Duplex",
            },
            {
                tipo: "Independiente",
            },
            {
                tipo: "Pareada",
            },
            {
                tipo: "Planta baja",
            },
        ],
    },
    {
        tipo: "Casa rural",
        subtipo: [],
    },
    {
        tipo: "Plaza de garaje",
        subtipo: [],
    },
    {
        tipo: "Terreno",
        subtipo: [
            {
                tipo: "Agrícola",
            },
            {
                tipo: "Comercial",
            },
            {
                tipo: "Edificable",
            },
            {
                tipo: "Industrial",
            },
        ],
    },
    {
        tipo: "Local comercial",
        subtipo: [],
    },
];

export const Tipologia_comercial = [
    {
        tipo: "Hotel",
    },
    {
        tipo: "Producción alimentaria",
    },
    {
        tipo: "Alimentos",
    },
    {
        tipo: "Otro",
    },
    {
        tipo: "Ambulatorio",
    },
    {
        tipo: "Lavado de autos",
    },
    {
        tipo: "Bancos e instituciones de crédito",
    },
    {
        tipo: "Bar",
    },
    {
        tipo: "Cartografia",
    },
    {
        tipo: "Hojalatería Garajes",
    },
    {
        tipo: "Centros culturales y lugares de culto",
    },
    {
        tipo: "Comercio al por menor",
    },
    {
        tipo: "Centros de Estética",
    },
    {
        tipo: "Al por mayor",
    },
    {
        tipo: "Producción electrónica",
    },
    {
        tipo: "Alim descuento y supermercado",
    },
    {
        tipo: "Puesto de periódicos",
    },
    {
        tipo: "Ebanistería Muebles",
    },
    {
        tipo: "Quimica Farmacéutica",
    },
    {
        tipo: "Garaje",
    },
    {
        tipo: "Transporte Internacional",
    },
    {
        tipo: "Inversión",
    },
    {
        tipo: "Industria plástica",
    },
    {
        tipo: "Carpinteria",
    },
    {
        tipo: "Nacional de Transporte",
    },
    {
        tipo: "Gimnasios, entretimiento, deportes",
    },
    {
        tipo: "Panadería",
    },
    {
        tipo: "Industria mecanica",
    },
    {
        tipo: "Producción mecánica",
    },
    {
        tipo: "Estudios Profesionales",
    },
    {
        tipo: "Representante de las empresas comerciales y soc.",
    },
    {
        tipo: "Restaurante - Pizzeria",
    },
    {
        tipo: "Restaurantes, tiendas de sándwich, etc",
    },
    {
        tipo: "Ricevitoria/Juegos",
    },
    {
        tipo: "Escuelas, guarderías, etc.",
    },
    {
        tipo: "Autoservicio",
    },
    {
        tipo: "Las empresa de TI",
    },
    {
        tipo: "Estudios Médicos",
    },
    {
        tipo: "Tabacos",
    },
    {
        tipo: "Tientorería",
    },
    {
        tipo: "Taberna/Restaurante",
    },
    {
        tipo: "Peluqueria",
    },
];
