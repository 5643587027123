import {
    Center,
    Checkbox,
    Divider,
    Heading,
    NumberInput,
    NumberInputField,
    SimpleGrid,
    Stack,
    Text,
} from "@chakra-ui/react";

const Otros = ({ formData, handleChange }) => {
    let info;
    try {
        info = JSON.parse(formData);
    } catch (error) {
        info = JSON.parse(JSON.stringify(formData));
    }
    
    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Otros detalles</Heading>

            <SimpleGrid columns={2}>
                <SimpleGrid columns={2}>
                    <Text>V.P.O</Text>
                    <Checkbox
                        size="lg"
                        name="otros.vpo"
                        isChecked={info.vpo}
                        onChange={handleChange}
                        isDisabled={!handleChange}
                    />
                </SimpleGrid>
                {info.vpo && (
                    <SimpleGrid columns={2}>
                        <Text>Año V.P.O</Text>
                        <NumberInput value={info.fecha_vpo} isDisabled={!handleChange}>
                            <NumberInputField
                                borderLeft="3px solid red"
                                name="otros.fecha_vpo"
                                onChange={handleChange}
                            />
                        </NumberInput>
                    </SimpleGrid>
                )}
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <SimpleGrid columns={2}>
                    <Text>Ascensor</Text>
                    <Checkbox
                        size="lg"
                        name="otros.ascensor"
                        isChecked={info.ascensor}
                        onChange={handleChange}
                        isDisabled={!handleChange}
                    />
                </SimpleGrid>

                <SimpleGrid columns={2}>
                    <Text>Garaje</Text>
                    <Checkbox
                        size="lg"
                        name="otros.garaje"
                        isChecked={info.garaje}
                        onChange={handleChange}
                        isDisabled={!handleChange}
                    />
                </SimpleGrid>

                <SimpleGrid columns={2}>
                    <Text>Trastero</Text>
                    <Checkbox
                        size="lg"
                        name="otros.trastero"
                        isChecked={info.trastero}
                        onChange={handleChange}
                        isDisabled={!handleChange}
                    />
                </SimpleGrid>

                <SimpleGrid columns={2}>
                    <Text>Piscina</Text>
                    <Checkbox
                        size="lg"
                        name="otros.piscina"
                        isChecked={info.piscina}
                        onChange={handleChange}
                        isDisabled={!handleChange}
                    />
                </SimpleGrid>

                <SimpleGrid columns={2}>
                    <Text>Jardin</Text>
                    <Checkbox
                        size="lg"
                        name="otros.jardin"
                        isChecked={info.jardin}
                        onChange={handleChange}
                        isDisabled={!handleChange}
                    />
                </SimpleGrid>

                <SimpleGrid columns={2}>
                    <Text>Terraza</Text>
                    <Checkbox
                        size="lg"
                        name="otros.terraza"
                        isChecked={info.terraza}
                        onChange={handleChange}
                        isDisabled={!handleChange}
                    />
                </SimpleGrid>

                <SimpleGrid columns={2}>
                    <Text>Patio</Text>
                    <Checkbox
                        size="lg"
                        name="otros.patio"
                        isChecked={info.patio}
                        onChange={handleChange}
                        isDisabled={!handleChange}
                    />
                </SimpleGrid>

                <SimpleGrid columns={2}>
                    <Text>Amueblado</Text>
                    <Checkbox
                        size="lg"
                        name="otros.amueblado"
                        isChecked={info.amueblado}
                        onChange={handleChange}
                        isDisabled={!handleChange}
                    />
                </SimpleGrid>
            </SimpleGrid>
            <Divider py={2} />
            <SimpleGrid columns={2} w="100%">
                <Stack direction="column" w="100%">
                    <Text textAlign="center">Calefaccion</Text>
                    <Center>
                        <Checkbox
                            size="lg"
                            name="otros.calefaccion"
                            isChecked={info.calefaccion}
                            onChange={handleChange}
                            isDisabled={!handleChange}
                        />
                    </Center>
                </Stack>

                <Stack direction="column" w="100%">
                    <Text textAlign="center">Aire acondicionado</Text>
                    <Center>
                        <Checkbox
                            size="lg"
                            name="otros.aire_acondicionado"
                            isChecked={info.aire_acondicionado}
                            onChange={handleChange}
                            isDisabled={!handleChange}
                        />
                    </Center>
                </Stack>
            </SimpleGrid>
        </Stack>
    );
};

export default Otros;
