export const NAV_ITEMS = [
    {
        label: "Inicio",
        href: "/inicio",
        isDisabled: false,
    },
    {
        label: "Clientes",
        children: [
            {
                label: "Buscar cliente",
                subLabel: "Buscar entre los clientes actuales",
                href: "/clientes/",
            },
            {
                label: "Nuevo cliente",
                subLabel: "Añadir un nuevo cliente",
                href: "/clientes/crear",
            },
        ],
        href: "/clientes/",
        isDisabled: false,
    },
    {
        label: "Pedidos",
        href: "/pedidos/",
        isDisabled: false,
    },
    {
        label: "Inmuebles",
        href: "/inmuebles/",
        isDisabled: false,
    },
    {
        label: "Noticias",
        href: "/noticias",
        isDisabled: false,
    },
    {
        label: "Encargos",
        href: "/encargos",
        isDisabled: false,
    },
    {
        label: "Usuarios",
        children: [
            {
                label: "Buscar usuario",
                subLabel: "Buscar entre los usuarios actuales",
                href: "/usuarios/",
            },
            {
                label: "Nuevo usuario",
                subLabel: "Añadir un nuevo usuario",
                href: "/usuarios/crear",
            },
        ],
        href: "/usuarios/",
        isDisabled: false,
        rolesPermitidos: ["Gerente", "Responsable"],
    },
    {
        label: "Agencias",
        children: [
            {
                label: "Nueva agencia",
                subLabel: "Añadir una nueva agencia",
                href: "/agencias/crear",
            },
        ],
        href: "/agencias/",
        isDisabled: false,
        rolesPermitidos: ["Gerente"],
    },
    {
        label: "Estadísticas",
        href: "/estadisticas",
        isDisabled: false,
    },
    {
        label: "Documentación",
        href: "/documentacion",
        isDisabled: false,
        rolesPermitidos: ["Gerente", "Responsable"],
    },
    {
        label: "Zona",
        href: "#",
        isDisabled: true,
    },
    {
        label: "Contabilidad",
        href: "#",
        isDisabled: true,
    },
    {
        label: "Formación",
        href: "#",
        isDisabled: true,
    },
    {
        label: "Wolfin financiera",
        href: "#",
        isDisabled: true,
    },
];
