import { Box, Heading, useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { errorNotification, okNotification } from "../../utils/Notificaciones";
import { updatePedido } from "../../services/pedidos";
import GridInfoPedidos from "../../components/Pedidos/CRUD/GridInfoPedidos";
import { crearPedido, getPedidoById } from "../../services/pedidos";
import { defaultPedidoData } from "../../data/DefaultData/DefaultPedidoData";
import Layout from "../../components/Layout/Layout";
import { useSelector } from "react-redux";

const AddEditPedidos = ({ isEdit = false }) => {
    const copyDefaultPedidoData = _.cloneDeep(defaultPedidoData);
    const [formData, setFormData] = useState(copyDefaultPedidoData);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { id } = useParams();
    const { user } = useSelector((state) => state.auth);

    const navigate = useNavigate();
    const toast = useToast();

    const initialFormData = useMemo(() => {
        return isEdit
            ? {}
            : {
                  ...copyDefaultPedidoData,
                  clienteUuid: id,
                  responsableUuid: user.uuid,
                  agenciaUuid: user.agencia?.uuid,
                  estado_pedido: "En proceso",
              };
    }, [isEdit, copyDefaultPedidoData, id, user]);

    const fetchPedido = useCallback(async () => {
        try {
            const estePedido = await getPedidoById(id);
            setFormData(estePedido);
        } catch (error) {
            console.error(error);
        }
    }, [id, setFormData]);

    useEffect(() => {
        const check = async () => {
            if (isEdit) {
                await fetchPedido();
            } else {
                setFormData(initialFormData);
            }
            setDataLoaded(true);
        };
        !dataLoaded && check();
    }, [isEdit, initialFormData, fetchPedido, setFormData, dataLoaded]);

    const handleSave = async () => {
        let pedido;
        if (isEdit) {
            try {
                pedido = await updatePedido(formData.uuid, {
                    ...formData,
                    responsableUuid: formData.responsableUuid || user.uuid,
                });
                okNotification("Pedido actualizado correctamente", toast);
                navigate(`/pedidos/ver/${pedido.uuid}`);
            } catch (error) {
                errorNotification("Error al actualizar el pedido", toast);
            }
        } else {
            try {
                pedido = await crearPedido({
                    ...formData,
                    clienteUuid: id,
                    responsableUuid: formData.responsableUuid || user.uuid,
                });
                okNotification("Pedido creado correctamente", toast);
                navigate(`/pedidos/ver/${pedido.uuid}`);
            } catch (error) {
                errorNotification("Error al crear el pedido", toast);
            }
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(_.set({ ...formData }, name, type === "checkbox" ? checked : value));
    };

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center">{isEdit ? "Editar" : "Crear"} pedido</Heading>
            </Box>

            {formData && (
                <GridInfoPedidos
                    formData={formData}
                    handleChange={handleChange}
                    handleCancel={handleCancel}
                    handleSave={handleSave}
                    isEdit={isEdit}
                />
            )}
        </Layout>
    );
};

export default AddEditPedidos;
