import { Box, Heading } from "@chakra-ui/react";
import GridInfoUser from "../../components/Usuarios/CRUD/GridInfoUser";
import Layout from "../../components/Layout/Layout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUsuario } from "../../services/usuarios";

const VerUsuario = () => {
    const [usuario, setUsuario] = useState();
    const [needReload, setNeedReload] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const fetchUsuario = async () => {
            setNeedReload(false);
            const response = await getUsuario(id);
            setUsuario(response);
        };
        needReload && fetchUsuario();
    }, [id, needReload]);
    
    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center">Ver usuario</Heading>
            </Box>

            {usuario && <GridInfoUser formData={usuario} />}
        </Layout>
    );
};

export default VerUsuario;
