import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { useParams } from "react-router-dom";
import { getNoticiaById } from "../../services/noticias";
import { Box, Heading, Stack } from "@chakra-ui/react";
import TablaActividadesNoticia from "../../components/Actividades/TablaActividadesNoticia";
import GridInfoNoticias from "../../components/Noticias/CRUD/GridInfoNoticias";
import GridInfoInmuebles from "../../components/Inmuebles/CRUD/GridInfoInmuebles";
import Separador from "../../components/Separador/Separador";

const VerNoticia = () => {
    const { id } = useParams();
    const [noticia, setNoticia] = useState();
    const [haveError, setHaveError] = useState(false);
    const [needReload, setNeedReload] = useState(true);

    useEffect(() => {
        const fetchNoticia = async () => {
            try {
                setNeedReload(false);
                const response = await getNoticiaById(id);
                setNoticia(response);
                setHaveError(false);
            } catch (error) {
                setHaveError(true);
            }
        };
        needReload && fetchNoticia();
    }, [id, needReload]);

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center">Ver noticia</Heading>
            </Box>

            {haveError && (
                <Box mb={4}>
                    <Heading textAlign="center" fontSize="2xl" color="red">
                        La noticia no existe o no tienes permiso para verla.
                    </Heading>
                </Box>
            )}

            {noticia && (
                <>
                    <GridInfoNoticias noticia={noticia} />
                    <Separador />
                    <Heading textAlign="center" mb={2}>
                        Inmueble
                    </Heading>
                    <GridInfoInmuebles
                        formData={{ ...noticia.inmueble, cliente: noticia.cliente }}
                        hideActividades={true}
                    />
                    <Stack direction="column">
                        <TablaActividadesNoticia datos={noticia} />
                    </Stack>
                </>
            )}
        </Layout>
    );
};

export default VerNoticia;
