import { Box, Heading, useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import GridInfoUser from "../../components/Usuarios/CRUD/GridInfoUser";
import { crearUsuario, getUsuario, updateUsuario } from "../../services/usuarios";
import { errorNotification, okNotification } from "../../utils/Notificaciones";
import Layout from "../../components/Layout/Layout";
import { defaultUsuarioData } from "../../data/DefaultData/DefaultUsuarioData";

const AddEditUsuarios = ({ isEdit = false }) => {
    const copyDefaultUsuarioData = _.cloneDeep(defaultUsuarioData);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [formData, setFormData] = useState(copyDefaultUsuarioData);
    const { id } = useParams();

    const navigate = useNavigate();
    const toast = useToast();

    const fetchUsuario = useCallback(async () => {
        try {
            const esteUsuario = await getUsuario(id);
            setFormData(esteUsuario);
        } catch (error) {
            console.error("🚀 ~ file: AddEditUsuarios.js:22 ~ fetchUsuario ~ error:", error);
        }
    }, [id, setFormData]);

    useEffect(() => {
        const check = async () => {
            setDataLoaded(true);
            if (isEdit) {
                await fetchUsuario();
            } else {
                setFormData(copyDefaultUsuarioData);
            }
        };
        !dataLoaded && check();
    }, [isEdit, fetchUsuario, setFormData, dataLoaded, copyDefaultUsuarioData]);

    const handleSave = async () => {
        if (isEdit) {
            try {
                await updateUsuario(formData.uuid, formData);
                okNotification("Usuario actualizado correctamente", toast);
                navigate("/usuarios/");
            } catch (error) {
                console.error("🚀 ~ file: EditarUsuario.js:21 ~ handleSave ~ error", error);
                errorNotification("Error al actualizar usuario", toast);
            }
        } else {
            await crearUsuario(formData);
            okNotification("Usuario creado correctamente", toast);
            navigate("/usuarios/");
        }
    };

    const handleCancel = () => {
        navigate("/usuarios/");
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(_.set({ ...formData }, name, type === "checkbox" ? checked : value));
    };

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center">{isEdit ? "Editar" : "Crear"} usuario</Heading>
            </Box>

            {formData && (
                <GridInfoUser
                    formData={formData}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    handleChange={handleChange}
                />
            )}
        </Layout>
    );
};

export default AddEditUsuarios;
