export const defaultPedidoData = {
    uuid: undefined,
    responsableUuid: undefined,
    clienteUuid: undefined,
    fechas: {},
    estado_pedido: undefined,
    tipo_inmueble: undefined,
    subtipo_inmueble: undefined,
    contrato: undefined,
    precio: undefined,
    aportacion: undefined,
    contado: false,
    asesoramiento_financiero: undefined,
    observacion_financiacion: undefined,
    planta: undefined,
    habitaciones: undefined,
    observaciones_notas: undefined,
    tipo_pedido: undefined,
    estado_inmueble: undefined,
    bathrooms: undefined,
    otros: {},
    ubicacion: {},
    nota_privada: undefined,
    nota_compartida: undefined,
    no_interesante: undefined,
};