import { Heading, Stack, Text, Textarea } from "@chakra-ui/react";

const DatosPrivados = ({ formData, handleChange }) => {
    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Datos privados</Heading>

            <Text>Nota Privada</Text>
            <Textarea
                name="nota_privada"
                value={formData.nota_privada}
                onChange={handleChange}
                isDisabled={handleChange == null}
            />
            <Text>Nota compartida</Text>
            <Textarea
                name="nota_compartida"
                value={formData.nota_compartida}
                onChange={handleChange}
                isDisabled={handleChange == null}
            />
        </Stack>
    );
};

export default DatosPrivados;
