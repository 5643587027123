import { useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { useParams } from "react-router-dom";
import { getNoticiaById } from "../../services/noticias";
import { Box, Heading } from "@chakra-ui/react";
import _ from "lodash";
import GridInfoNoticias from "../../components/Noticias/CRUD/GridInfoNoticias";

const EditarNoticia = () => {
    const { id } = useParams();
    const [noticia, setNoticia] = useState();

    useEffect(() => {
        const fetchNoticia = async () => {
            const response = await getNoticiaById(id);
            setNoticia(response);
        };
        fetchNoticia();
    }, [id]);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setNoticia(_.set({ ...noticia }, name, type === "checkbox" ? checked : value));
    };

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center">Editar noticia</Heading>
            </Box>

            {noticia && <GridInfoNoticias noticia={noticia} handleChange={handleChange} />}
        </Layout>
    );
};

export default EditarNoticia;
