import { Button, SimpleGrid, Stack, Text, Box, StackDivider, Textarea, Flex } from "@chakra-ui/react";
import Fechas from "./Fechas";
import Clientes from "./Cliente";
import Responsables from "./Responsable";
import EstadoCita from "./EstadoCita";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const GridEvent = ({ formData, handleChange, handleSave, handleCancel }) => {
    const navigate = useNavigate();
    const esNuevaCita = formData.id === undefined;
    let direccionInmueble;
    if (formData.inmueble && formData.inmueble.ubicacion) {
        try {
            direccionInmueble = JSON.parse(formData.inmueble.ubicacion);
        } catch (error) {
            direccionInmueble = JSON.parse(JSON.stringify(formData.inmueble.ubicacion));
        }
    }

    const { user } = useSelector((state) => state.auth);

    return (
        <SimpleGrid columns={1}>
            <Stack direction="column" spacing={3} divider={<StackDivider borderColor="gray.200" />}>
                {formData.responsable && <Responsables formData={formData} />}
                {formData.cliente && <Clientes formData={formData} />}
                {formData.inmueble && (
                    <SimpleGrid columns={2}>
                        <Text>Inmueble</Text>
                        <Text
                            fontWeight="bold"
                            _hover={{ cursor: "pointer", textDecoration: "underline" }}
                            onClick={() => navigate(`/inmuebles/ver/${formData.inmueble.uuid}`)}>
                            {formData.inmueble.tipo_inmueble} - {direccionInmueble.direccion}
                        </Text>
                    </SimpleGrid>
                )}

                <EstadoCita formData={formData} handleChange={handleChange} />

                <Fechas formData={formData} handleChange={handleChange} />
                <Box>
                    <Text paddingBottom={2}>Descripcion</Text>
                    <Textarea name="descripcion" value={formData.descripcion} onChange={handleChange} />
                </Box>
                <Flex gap={4}>
                    {(user?.rol === "Gerente" ||
                        user?.rol === "Responsable" ||
                        (user?.rol === "Asesor" && user?.agenciaUuid === formData.agenciaUuid)) && (
                        <Button
                            w="100%"
                            colorScheme="blue"
                            size="lg"
                            mt={4}
                            onClick={handleSave}
                            isDisabled={formData.cliente === "" || formData.usuario === "" || !formData.estado}>
                            Guardar
                        </Button>
                    )}
                    {!esNuevaCita &&
                        (user?.rol === "Gerente" ||
                            user?.rol === "Responsable" ||
                            (user?.rol === "Asesor" && user?.uuid === formData.responsableUuid)) && (
                            <Button colorScheme="red" w="100%" size="lg" mt={4} onClick={handleCancel}>
                                Eliminar
                            </Button>
                        )}
                </Flex>
            </Stack>
        </SimpleGrid>
    );
};

export default GridEvent;
