import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    user: null,
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: '',
};

export const loginUser = createAsyncThunk(
    'user/loginUser',
    async (user, thunkAPI) => {
        try {
            const response = await axios.post('login', user);
            return response.data.data;
        } catch (error) {
            console.error("🚀 ~ file: authSlice.js:24 ~ error:", error)
            if (error.response) {
                return thunkAPI.rejectWithValue(error.response.data.message);
            }
        }
    }
);

export const getMe = createAsyncThunk(
    'user/getMe', 
    async (_, thunkAPI) => {
    try {
        const response = await axios.get('me');
        return response.data.data;
    } catch (error) {
        if (error.response) {
            return thunkAPI.rejectWithValue(error.response.data.message);
        }
    }
});

export const logout = createAsyncThunk(
    'user/logout', 
    async () => {
    await axios.delete('logout');
});

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {

        builder.addCase(loginUser.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(loginUser.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
        });
        builder.addCase(loginUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        });

        builder.addCase(getMe.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMe.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.user = action.payload;
        });
        builder.addCase(getMe.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        });
    },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
