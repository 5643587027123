import { Heading, Input, SimpleGrid, Stack, Text, Select, Checkbox, Textarea } from "@chakra-ui/react";

const Contrato = ({ formData, handleChange }) => {
    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Contrato</Heading>
            <SimpleGrid columns={2}>
                <Text>Motivo</Text>
                <Select
                    borderLeft="3px solid red"
                    name="contrato"
                    value={formData.contrato}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                    placeholder="-- Ninguno --"
                >
                    <option value="Compra">Compra</option>
                    <option value="Alquiler">Alquiler</option>
                </Select>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Precio</Text>
                <Input
                    name="precio"
                    value={formData.precio}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Aportación</Text>
                <Input
                    name="aportacion"
                    value={formData.aportacion}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Contado</Text>
                <Checkbox
                    size="lg"
                    name="contado"
                    isChecked={formData.contado}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Asesoramiento financiero</Text>
                <Select
                    name="asesoramiento_financiero"
                    value={formData.asesoramiento_financiero}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                    placeholder="-- Ninguno --"
                >
                    <option value="Presentacion general">Presentacion general</option>
                    <option value="Presentacion detallada">Presentacion detallada</option>
                    <option value="No presentado">No presentado</option>
                    <option value="Analisis financiero realizado">Analisis financiero realizado</option>
                </Select>
            </SimpleGrid>
            <Text>Comentario financiación</Text>
            <Textarea
                name="observacion_financiacion"
                value={formData.observacion_financiacion}
                onChange={handleChange}
                isDisabled={handleChange == null}
            />
        </Stack>
    );
};

export default Contrato;
