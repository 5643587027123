import { Box, SimpleGrid } from "@chakra-ui/react";
import InfoBox from "./InfoBox";

const Generales = ({ estadisticas }) => {
    return (
        <Box>
            <SimpleGrid minChildWidth="80px" spacing={4}>
                <InfoBox nombre="Agencias" dato={estadisticas.agencias} />
                <InfoBox nombre="Asesores" dato={estadisticas.asesores} />
                <InfoBox nombre="Responsables" dato={estadisticas.responsables} />
                <InfoBox nombre="Clientes" dato={estadisticas.clientes} />
                <InfoBox nombre="Inmuebles" dato={estadisticas.inmuebles} />
            </SimpleGrid>

            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                <InfoBox nombre="Total pedidos" dato={estadisticas.pedidos.total} />
                {estadisticas?.pedidos?.estados &&
                    Object.keys(estadisticas.pedidos.estados).map((key) => (
                        <InfoBox nombre={key} dato={estadisticas.pedidos.estados[key]} boxStyle={1} />
                    ))}
            </SimpleGrid>

            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                <InfoBox nombre="Encargos" dato={estadisticas.encargos.total} />
                {estadisticas?.encargos?.estados &&
                    Object.keys(estadisticas.encargos.estados).map((key) => (
                        <InfoBox nombre={key} dato={estadisticas.encargos.estados[key]} boxStyle={1} />
                    ))}
            </SimpleGrid>

            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                <InfoBox nombre="Noticias" dato={estadisticas.noticias.total} />
                {estadisticas?.noticias?.estados &&
                    Object.keys(estadisticas.noticias.estados).map((key) => (
                        <InfoBox nombre={key} dato={estadisticas.noticias.estados[key]} boxStyle={1} />
                    ))}
            </SimpleGrid>

            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                <InfoBox nombre="Actividades" dato={estadisticas.actividades} />
                <InfoBox nombre="Citas" dato={estadisticas.citas} />
            </SimpleGrid>
        </Box>
    );
};

export default Generales;
