import { Button, SimpleGrid, Stack, useDisclosure, useToast } from "@chakra-ui/react";
import Cliente from "./Cliente";
import Pedido from "./Pedido";
import IdentificacionInmueble from "./IdentificacionInmueble";
import Contrato from "./Contrato";
import DetalleInmueble from "./DetalleInmueble";
import OtrasInformaciones from "./OtrasInformaciones";
import Otro from "./Otro";
import DatosPrivados from "./DatosPrivados";
import { useNavigate } from "react-router-dom";
import ListaActividadesPedidos from "../../Actividades/ListaActividadesPedidos";
import Separador from "../../Separador/Separador";
import ListaDocumentosPedidos from "../../Documentos/ListaDocumentosPedidos";
import { useSelector } from "react-redux";
import { deletePedido } from "../../../services/pedidos";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import { useRef } from "react";
import ListaCistasFinanciacion from "../../Agenda/ListaCitasFinanciacion";

const GridInfoPedidos = ({ formData, handleChange, handleSave, handleCancel }) => {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.auth);
    const toast = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();

    const handleDelete = async () => {
        try {
            await deletePedido(formData.uuid);
            okNotification("Pedido eliminado correctamente", toast);
            navigate(-1);
        } catch (error) {
            console.error("🚀 ~ file: GridInfoPedidos.js:26 ~ handleDelete ~ error:", error);
            errorNotification("Error al eliminar el pedido", toast);
        }
    };

    return (
        <>
            <SimpleGrid columns={3} spacing={4}>
                <Stack direction="column">
                    <Cliente formData={formData} handleChange={handleChange} />
                    <Pedido formData={formData} handleChange={handleChange} />
                    <Contrato formData={formData} handleChange={handleChange} />
                </Stack>

                <Stack direction="column">
                    <IdentificacionInmueble in formData={formData} handleChange={handleChange} />
                    <DetalleInmueble formData={formData} handleChange={handleChange} />
                </Stack>

                <Stack direction="column">
                    <OtrasInformaciones formData={formData} handleChange={handleChange} />
                    <Otro formData={formData.otros} handleChange={handleChange} />
                    <DatosPrivados formData={formData} handleChange={handleChange} />
                    {handleChange == null && (
                        <Stack spacing={0}>
                            <Stack direction={{ base: "column", md: "row" }} spacing={0} w="100%">
                                {(user?.rol === "Gerente" ||
                                    user?.rol === "Responsable" ||
                                    (user?.rol === "Asesor" && user?.uuid === formData.responsableUuid)) && (
                                    <Button
                                        colorScheme="facebook"
                                        rounded="none"
                                        w="100%"
                                        onClick={() => navigate(`/pedidos/editar/${formData.uuid}`)}>
                                        EDITAR
                                    </Button>
                                )}
                                <Button colorScheme="facebook" rounded="none" w="100%" onClick={() => navigate(-1)}>
                                    VOLVER
                                </Button>
                            </Stack>
                            {user && user.rol === "Gerente" && (
                                <Button colorScheme="red" rounded="none" size="sm" onClick={onOpen}>
                                    ELIMINAR
                                </Button>
                            )}
                        </Stack>
                    )}
                    {handleChange != null && (
                        <>
                            <Stack direction={{ base: "column", md: "row" }} spacing={0} w="100%">
                                <Button
                                    colorScheme="green"
                                    w="100%"
                                    rounded="none"
                                    onClick={handleSave}
                                    isDisabled={
                                        !formData.contrato ||
                                        !formData.estado_pedido ||
                                        !formData.tipo_inmueble ||
                                        !formData.localidad ||
                                        !formData.provincia ||
                                        !formData.codigo_postal
                                    }>
                                    GUARDAR
                                </Button>
                                <Button colorScheme="facebook" rounded="none" w="100%" onClick={handleCancel}>
                                    VOLVER
                                </Button>
                            </Stack>
                        </>
                    )}
                </Stack>
            </SimpleGrid>
            <Separador />
            {handleChange == null && (
                <Stack direction="column" mt={2}>
                    <ListaActividadesPedidos
                        actividades={formData.actividades}
                        datos={formData}
                        pedidoAbierto={formData.estado_pedido === "En proceso"}
                    />
                    <Separador />
                    <ListaCistasFinanciacion datos={formData} />
                    <Separador />
                    <ListaDocumentosPedidos pedidoUuid={formData.uuid} />
                </Stack>
            )}

            {isOpen && (
                <ConfirmationModal
                    show={isOpen}
                    cancelRef={cancelRef}
                    handleClose={onClose}
                    handleConfirm={handleDelete}
                    title="Eliminar pedido"
                    body="¿Estás seguro de que quieres eliminar este pedido?"
                />
            )}
        </>
    );
};

export default GridInfoPedidos;
