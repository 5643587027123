import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Center,
    Heading,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from "@chakra-ui/react";
import { getActividadesByEncargo, getActividadesByInmueble, getActividadesByNoticia } from "../../services/actividades";
import EasyDatePicker from "../EasyDatePicker/EasyDatePicker";
import ValoracionModal from "../Modals/Actividades/ValoracionModal";
import GestionEncargoModal from "../Modals/Actividades/GestionEncargoModal";
import MultiActividad from "../MultiActividad/MultiActividad";
import { useNavigate } from "react-router-dom";
import PopoverDescripcion from "./PopoverDescripcion";

const TablaActividadesEncargo = ({ datos }) => {
    const [actividades, setActividades] = useState([]);
    const [datosRecibidos, setDatosRecibidos] = useState({});
    const [needReload, setNeedReload] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenNuevaGestion, onOpen: onOpenNuevaGestion, onClose: onCloseNuevaGestion } = useDisclosure();
    const {
        isOpen: isOpenMultiActividad,
        onOpen: onOpenMultiActividad,
        onClose: onCloseMultiActividad,
    } = useDisclosure();
    const { uuid } = datos;

    useEffect(() => {
        const getActividades = async () => {
            setNeedReload(false);
            const actividadesPromises = [
                getActividadesByEncargo(uuid),
                datos.inmueble?.uuid && getActividadesByInmueble(datos.inmueble.uuid),
                datos.noticia?.uuid && getActividadesByNoticia(datos.noticia.uuid),
            ];
            const actividadesResults = await Promise.all(actividadesPromises.filter(Boolean));

            // Quitar si alguna tiene el mismo uuid
            const actividades = actividadesResults
                .flat()
                .filter((actividad, index, self) => index === self.findIndex((t) => t.uuid === actividad.uuid));

            actividades.sort((a, b) => {
                if (a.createdAt < b.createdAt) {
                    return 1;
                }
                if (a.createdAt > b.createdAt) {
                    return -1;
                }
                return 0;
            });
            setActividades(actividades);
        };

        needReload && datos && getActividades();
    }, [uuid, datos, needReload]);

    const handleOpenActividad = (actividad) => {
        setIsEdit(true);
        setDatosRecibidos({
            ...actividad,
            cliente: datos.cliente,
            inmueble: datos.inmueble,
            precio_final: datos.precio_final,
            vencimiento_encargo: datos.vencimiento_encargo,
            encargoUuid: datos.uuid,
        });
        onOpenMultiActividad();
    };

    const handleNuevaValoracion = () => {
        setIsEdit(false);
        setDatosRecibidos(datos);
        onOpen();
    };

    const handleNuevaGestion = () => {
        setIsEdit(false);
        setDatosRecibidos(datos);
        onOpenNuevaGestion();
    };

    return (
        <>
            <Box p={8} border="1px" rounded="md" shadow="dark-lg">
                <Heading textAlign="center" mb={4}>
                    Actividades
                </Heading>
                <Center>
                    <Button rounded="none" colorScheme="facebook" w="100%" onClick={handleNuevaValoracion}>
                        Cita de valoración
                    </Button>
                    <Button rounded="none" colorScheme="yellow" w="100%" onClick={handleNuevaGestion}>
                        Gestión de encargo (seguimiento)
                    </Button>
                    <Button
                        rounded="none"
                        colorScheme="green"
                        w="100%"
                        onClick={() => navigate(`/cruce/encargo/promocion/${uuid}`)}>
                        Llamada de promoción
                    </Button>
                    <Button
                        rounded="none"
                        colorScheme="green"
                        w="100%"
                        onClick={() => navigate(`/cruce/encargo/cita/${uuid}`)}>
                        Cita de venta/alquiler
                    </Button>
                </Center>
                <TableContainer maxH="40rem" overflowY="auto">
                    <Table size='sm'>
                        <Thead>
                            <Tr bgColor="facebook.50">
                                <Th>Tipo de actividad</Th>
                                <Th>Entidad relacionada</Th>
                                <Th>Modalidad contacto</Th>
                                <Th>Estado actividad</Th>
                                <Th>Fecha actividad</Th>
                                <Th>Creación</Th>
                                <Th>Modificación</Th>
                                <Th>Vencimiento</Th>
                                <Th>Descripción</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {actividades && actividades.length === 0 && (
                                <Tr>
                                    <Td colSpan={7} textAlign="center" fontWeight="bolder">
                                        No hay actividades
                                    </Td>
                                </Tr>
                            )}
                            {actividades &&
                                actividades.length > 0 &&
                                actividades.map((actividad) => (
                                    <Tr
                                        key={actividad.uuid}
                                        _hover={{
                                            cursor: "pointer",
                                            bg: "gray.100",
                                        }}>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            ACT - {actividad.tipo_actividad}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.entidad_relacionada}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.modalidad_contacto}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.estado_actividad}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            <EasyDatePicker value={actividad.fecha_actividad} />
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            <EasyDatePicker value={actividad.createdAt} needHour={true} />
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            <EasyDatePicker value={actividad.updatedAt} needHour={true} />
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.fecha_vencimiento ? (
                                                <EasyDatePicker value={actividad.fecha_vencimiento} needHour={true} />
                                            ) : (
                                                <EasyDatePicker value={actividad.fecha_finalizacion} needHour={true} />
                                            )}
                                        </Td>
                                        <Td>
                                            <PopoverDescripcion descripcion={actividad.descripcion} />
                                        </Td>
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
            {isOpen && <ValoracionModal isOpen={isOpen} onClose={onClose} datos={datosRecibidos} isEdit={isEdit} />}
            {isOpenNuevaGestion && (
                <GestionEncargoModal
                    isOpen={isOpenNuevaGestion}
                    onClose={onCloseNuevaGestion}
                    datos={datosRecibidos}
                    isEdit={isEdit}
                />
            )}
            {isOpenMultiActividad && (
                <MultiActividad isOpen={isOpenMultiActividad} onClose={onCloseMultiActividad} datos={datosRecibidos} />
            )}
        </>
    );
};

export default TablaActividadesEncargo;
