import {
    Box,
    Button,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    SimpleGrid,
    Stack,
    Text,
    Textarea,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import EasyDatePicker from "../../EasyDatePicker/EasyDatePicker";
import { useEffect, useState } from "react";
import { getAllUsuarios } from "../../../services/usuarios";
import { crearActividad, updateActividad } from "../../../services/actividades";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";
import GestionEncargoProcesoGuiado from "./GestionEncargoProcesoGuiado";
import { useSelector } from "react-redux";
import EasyDatePickerWithTime from "../../EasyDatePicker/EasyDatePickerWithTime";
import { createEvent } from "../../../services/agenda";
import { TIPOS_CITAS } from "../../../data/constants";

const GestionEncargoModal = ({ isOpen, onClose, datos, isEdit }) => {
    const [responsables, setResponsables] = useState([]);
    const [needReload, setNeedReload] = useState(true);
    const [formData, setFormData] = useState(datos);
    const toast = useToast();

    const [datosEnviados, setDatosEnviados] = useState({});

    const { isOpen: isOpenProcesoGuiado, onOpen: onOpenProcesoGuiado, onClose: onCloseProcesoGuiado } = useDisclosure();
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        const getResponsables = async () => {
            const auxResponsables = await getAllUsuarios();
            setResponsables(auxResponsables);
            if (!isEdit) {
                const newDate = new Date();
                setFormData({
                    ...formData,
                    responsableUuid: user.uuid,
                    estado_actividad: "Programada",
                    modalidad_contacto: "Llamada",
                    fechaInicio: newDate,
                    fechaFin: new Date(newDate.getTime() + 30 * 60000),
                });
            }
            setNeedReload(false);
        };

        needReload && user && getResponsables();
    }, [formData, needReload, isEdit, user]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const getDataForSend = () => {
        return {
            entidad_relacionada: "Inmueble",
            tipo_actividad: "Gestion encargo",
            modalidad_contacto: formData.modalidad_contacto,
            fecha_vencimiento: formData.fecha_vencimiento,
            fecha_actividad: formData.fecha_actividad,
            fecha_finalizacion: formData.estado_actividad === "Realizada" ? new Date() : null,
            estado_actividad: formData.estado_actividad,
            descripcion: formData.descripcion,
            fechaInicio: formData.fechaInicio,
            fechaFin: formData.fechaFin,
            recordatorio: false,
            recordatorio_fecha: null,
            responsableUuid: formData.responsableUuid,
            clienteUuid: datos.cliente.uuid,
            inmuebleUuid: datos.inmueble.uuid,
            encargoUuid: datos.encargoUuid || datos.uuid,
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const notificationMessage = !isEdit ? "Actividad creada correctamente" : "Actividad actualizada correctamente";
        const errorMessage = "Error al crear la actividad";

        try {
            if (!isEdit) {
                const activididad = await crearActividad(getDataForSend());

                if (formData.modalidad_contacto === "Presencial") {
                    await createEvent({
                        entidad_relacionada: "Encargo",
                        fechaInicio: formData.fechaInicio || new Date(),
                        fechaFin: formData.fechaFin || new Date(new Date().getTime() + 30 * 60000),
                        estado: "Programada",
                        tipo: "Gestion de encargo",
                        descripcion: formData.descripcion,
                        color: TIPOS_CITAS.find((tipo) => tipo.tipo === "Gestion de encargo").color,
                        responsableUuid: formData.responsableUuid,
                        clienteUuid: datos.cliente.uuid,
                        inmuebleUuid: datos.inmueble.uuid,
                        actividadeUuid: activididad.uuid,
                    });
                }
            } else {
                await updateActividad(datos.uuid, {
                    ...formData,
                    fecha_finalizacion: formData.estado_actividad === "Realizada" ? new Date() : undefined,
                });
            }

            okNotification(notificationMessage, toast);
            onClose();
            window.location.reload();
        } catch (error) {
            errorNotification(errorMessage, toast);
            console.error("🚀 ~ file: ActualizacionModal.js:7 ~ ActualizacionModal ~ error", error);
        }
    };

    const handleProcesoGuiado = () => {
        const parseData = getDataForSend();
        setDatosEnviados({
            ...parseData,
            responsables,
            actividadUuid: isEdit ? datos.uuid : null,
            precio_final: datos.precio_final,
            vencimiento_encargo: datos.fecha_vencimiento,
        });
        onOpenProcesoGuiado();
    };

    useEffect(() => {
        const changeFechaInicio = () => {
            try {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    fechaFin: new Date(prevFormData.fechaInicio.getTime() + 30 * 60000),
                }));
            } catch (error) {
                console.error("🚀 ~ file: GestionEncargoModal.js:150 ~ changeFechaInicio ~ error:", error);
            }
        };

        if (formData?.fechaInicio) {
            changeFechaInicio();
        }
    }, [formData.fechaInicio]);

    const ubicacion = JSON.parse(datos.inmueble?.ubicacion);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
                <ModalOverlay bgColor="blackAlpha.800" />
                <ModalContent>
                    <ModalHeader>Gestión de encargo</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {!isEdit && (
                            <Text
                                textAlign="center"
                                mb={4}
                                fontWeight="bold"
                                color={formData.modalidad_contacto === "Llamada" ? "facebook.900" : "green"}>
                                {formData.modalidad_contacto === "Llamada"
                                    ? "NO SE CREARÁ UNA CITA. SOLO ACTIVIDAD"
                                    : "SE CREARÁ CITA Y ACTIVIDAD."}
                            </Text>
                        )}
                        <Stack direction="column" spacing={4}>
                            <SimpleGrid columns={2}>
                                <Text>Cliente</Text>
                                <Input
                                    name="cliente"
                                    value={`${datos.cliente?.nombre} ${datos.cliente?.apellidos}`}
                                    isReadOnly
                                    isDisabled
                                />
                            </SimpleGrid>

                            <SimpleGrid columns={2}>
                                <Text>Entidad relacionada</Text>
                                <Input
                                    name="entidad"
                                    value={`IN - ${datos.inmueble.tipo_inmueble} - ${ubicacion.direccion}`}
                                    isReadOnly
                                    isDisabled
                                />
                            </SimpleGrid>

                            <SimpleGrid columns={2}>
                                <Text>Modalidad contacto</Text>
                                <Select
                                    name="modalidad_contacto"
                                    value={formData.modalidad_contacto}
                                    onChange={handleChange}
                                    isDisabled={datos.fecha_finalizacion}>
                                    <option value="Llamada">Llamada</option>
                                    <option value="Presencial">Presencial</option>
                                </Select>
                            </SimpleGrid>

                            {!isEdit &&
                                !datos.fecha_finalizacion &&
                                formData.modalidad_contacto === "Presencial" && (
                                    <Box border="1px solid black" rounded="md" p={2}>
                                        <SimpleGrid columns={2}>
                                            <Text>Inicio cita</Text>
                                            <EasyDatePickerWithTime
                                                name="fechaInicio"
                                                value={formData.fechaInicio}
                                                handleChange={
                                                    datos.fecha_finalizacion
                                                        ? null
                                                        : (fecha) => setFormData({ ...formData, fechaInicio: fecha })
                                                }
                                            />
                                        </SimpleGrid>
                                        <SimpleGrid columns={2}>
                                            <Text>Fin cita</Text>
                                            <EasyDatePickerWithTime
                                                name="fechaFin"
                                                value={formData.fechaFin}
                                                handleChange={
                                                    datos.fecha_finalizacion
                                                        ? null
                                                        : (fecha) => setFormData({ ...formData, fechaFin: fecha })
                                                }
                                            />
                                        </SimpleGrid>
                                    </Box>
                                )}

                            <SimpleGrid columns={2}>
                                <Text>Responsable</Text>
                                <Select
                                    name="responsableUuid"
                                    value={formData.responsableUuid}
                                    onChange={handleChange}
                                    isDisabled={datos.fecha_finalizacion}>
                                    {responsables.map((responsable) => (
                                        <option key={responsable.uuid} value={responsable.uuid}>
                                            {responsable.nombre} {responsable.apellidos}
                                        </option>
                                    ))}
                                </Select>
                            </SimpleGrid>

                            <SimpleGrid columns={2}>
                                <Text>Fecha actividad</Text>
                                <EasyDatePicker
                                    name="fecha_actividad"
                                    value={formData.fecha_actividad || new Date()}
                                    handleChange={datos.fecha_finalizacion ? null : handleChange}
                                />
                            </SimpleGrid>

                            <SimpleGrid columns={2}>
                                <Text>Vencimiento</Text>
                                <EasyDatePicker
                                    name="fecha_vencimiento"
                                    value={formData.fecha_vencimiento || new Date()}
                                    handleChange={datos.fecha_finalizacion ? null : handleChange}
                                />
                            </SimpleGrid>

                            {datos.fecha_finalizacion && (
                                <SimpleGrid columns={2}>
                                    <Text>Finalización</Text>
                                    <EasyDatePicker name="fecha_finalizacion" value={formData.fecha_finalizacion} />
                                </SimpleGrid>
                            )}

                            <SimpleGrid columns={2}>
                                <Text>Estado</Text>
                                <Select
                                    name="estado_actividad"
                                    value={formData.estado_actividad}
                                    onChange={handleChange}
                                    isDisabled={datos.fecha_finalizacion}>
                                    <option value="Programada">Programada</option>
                                    <option value="Realizada">Realizada</option>
                                </Select>
                            </SimpleGrid>

                            <Text>Descripción</Text>
                            <Textarea
                                borderLeft="3px solid red"
                                name="descripcion"
                                value={formData.descripcion}
                                onChange={handleChange}
                                isDisabled={datos.fecha_finalizacion}
                            />

                            {datos.modalidad_contacto === "Llamada" && formData.modalidad_contacto === "Presencial" && (
                                <Text
                                    fontWeight="bold"
                                    textAlign="center"
                                    fontSize="sm"
                                    color="facebook.800"
                                    border="1px"
                                    rounded="lg"
                                    p={2}>
                                    NO se creará una nueva cita al cambiar la modalidad de contacto.
                                    <br />
                                    Si desea crear una cita, debe eliminar la actividad y crear una nueva.
                                </Text>
                            )}

                            {datos && !datos.fecha_finalizacion && (
                                <Flex gap={0}>
                                    <Button
                                        w="100%"
                                        rounded="none"
                                        onClick={handleSubmit}
                                        colorScheme="green"
                                        isDisabled={!formData.descripcion}>
                                        {isEdit ? "Editar" : "Crear"}
                                    </Button>
                                    {(formData.estado_actividad === "Realizada" ||
                                        datos.estado_actividad === "Realizada") && (
                                        <Button
                                            w="100%"
                                            rounded="none"
                                            onClick={handleProcesoGuiado}
                                            colorScheme="facebook"
                                            isDisabled={!formData.descripcion}>
                                            PROCESO GUIADO
                                        </Button>
                                    )}
                                </Flex>
                            )}
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {isOpenProcesoGuiado && (
                <GestionEncargoProcesoGuiado
                    isOpen={isOpenProcesoGuiado}
                    onClose={onCloseProcesoGuiado}
                    datos={datosEnviados}
                    onClosePadre={onClose}
                />
            )}
        </>
    );
};

export default GestionEncargoModal;
