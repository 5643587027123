import { Heading, Input, SimpleGrid, Stack, Text } from "@chakra-ui/react";

const Direccion = ({ direccion, handleChange }) => {
    return (
        <Stack border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md" my={2}>
                Dirección
            </Heading>
            <SimpleGrid columns={2}>
                <Text>Dirección</Text>
                <Input
                    name="direccion.direccion"
                    value={direccion.direccion}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Número</Text>
                <Input
                    name="direccion.numero"
                    value={direccion.numero}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Localidad</Text>
                <Input
                    name="direccion.localidad"
                    value={direccion.localidad}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Provincia</Text>
                <Input
                    name="direccion.provincia"
                    value={direccion.provincia}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
        </Stack>
    );
};

export default Direccion;
