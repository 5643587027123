import { Heading, SimpleGrid, Stack, Text, Select, Input } from "@chakra-ui/react";
import EasyDatePicker from "../../EasyDatePicker/EasyDatePicker";
import { useEffect, useState } from "react";
import { getAllUsuarios } from "../../../services/usuarios";
import { getAllAgencias } from "../../../services/agencias";
import { useSelector } from "react-redux";

const Pedido = ({ formData, handleChange }) => {
    const [usuarios, setUsuarios] = useState([]);
    const [agencias, setAgencias] = useState([]);
    const [needReload, setNeedReload] = useState(true);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        const getData = async () => {
            setNeedReload(false);
            const [auxUsuarios, auxAgencias] = await Promise.all([getAllUsuarios(), getAllAgencias()]);
            setAgencias(auxAgencias);
            setUsuarios(auxUsuarios);
        };
        needReload && getData();
    }, [needReload, handleChange]);

    let fechas;
    if (formData.fechas) {
        try {
            fechas = JSON.parse(formData.fechas);
        } catch (error) {
            fechas = JSON.parse(JSON.stringify(formData.fechas));
        }
    }

    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Pedido</Heading>

            <SimpleGrid columns={2}>
                <Text>Estado</Text>
                <Select
                    borderLeft="3px solid red"
                    name="estado_pedido"
                    value={formData.estado_pedido}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                    placeholder="-- Ninguno --">
                    <option value="En proceso">En proceso</option>
                    <option value="Cerrado">Cerrado</option>
                    <option value="Cancelado">Cancelado</option>
                </Select>
            </SimpleGrid>
            {formData.estado_pedido === "Cerrado" && (
                <SimpleGrid columns={2}>
                    <Text>Motivo de cierre</Text>
                    <Input name="motivo_cierre" border="3px solid red" value={formData.motivo_cierre} isDisabled readOnly />
                </SimpleGrid>
            )}
            <SimpleGrid columns={2}>
                <Text>Responsable</Text>
                <Select
                    name="responsableUuid"
                    value={formData.responsableUuid}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                    placeholder="-- Ninguno --">
                    {usuarios &&
                        usuarios.map((usuario) => {
                            const nombreCompleto = usuario.nombre + " " + usuario.apellidos;
                            return (
                                <option key={usuario.uuid} value={usuario.uuid}>
                                    {nombreCompleto}
                                </option>
                            );
                        })}
                </Select>
            </SimpleGrid>
            {user.rol === "Gerente" && (
                <SimpleGrid columns={2}>
                    <Text>Agencia</Text>
                    <Select
                        name="agenciaUuid"
                        value={formData.agenciaUuid}
                        onChange={handleChange}
                        isDisabled={handleChange == null || user?.rol !== "Gerente"}
                        placeholder="-- Ninguna --">
                        {agencias &&
                            agencias.map((agencia) => {
                                return (
                                    <option key={agencia.uuid} value={agencia.uuid}>
                                        {agencia.nombre}
                                    </option>
                                );
                            })}
                    </Select>
                </SimpleGrid>
            )}
            <SimpleGrid columns={2} py={1}>
                <Text>Encontrar solucion antes del</Text>
                <EasyDatePicker
                    name="fechas.fecha_plazo"
                    value={fechas?.fecha_plazo}
                    handleChange={handleChange}
                />
            </SimpleGrid>
            {handleChange == null && (
                <>
                    <SimpleGrid columns={2} py={1}>
                        <Text>Fecha de creación</Text>
                        <EasyDatePicker name="createdAt" value={formData.createdAt} needHour={true} />
                    </SimpleGrid>
                    <SimpleGrid columns={2} py={1}>
                        <Text>Fecha de modificación</Text>
                        <EasyDatePicker name="updatedAt" value={formData.updatedAt} needHour={true} />
                    </SimpleGrid>
                </>
            )}
        </Stack>
    );
};

export default Pedido;
