import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Button,
    Text,
    useToast,
    Stack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { errorNotification, okNotification } from "../../utils/Notificaciones";
import { createCliente } from "../../services/clientes";

const ReconciliacionModal = ({ isOpen, onClose, clienteNuevo, clienteSimilar }) => {
    const mismoIdentificador =
        clienteNuevo.identificador !== undefined && clienteNuevo.identificador === clienteSimilar.identificador;
    const navigate = useNavigate();
    const toast = useToast();

    const reconciliar_si = async () => {
        navigate(`/reconciliacion/`, {
            state: {
                clienteNuevo,
                clienteSimilar,
            },
        });
    };

    const reconciliar_no = async () => {
        try {
            const cliente = await createCliente(clienteNuevo);
            okNotification("Cliente creado correctamente", toast);
            navigate(`/clientes/ver/${cliente.uuid}`);
        } catch (error) {
            errorNotification("Error al crear el cliente", toast);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay bgColor="blackAlpha.800" />
            <ModalContent>
                <ModalHeader>RECONCILICIALICIÓN</ModalHeader>
                <ModalCloseButton />
                <ModalBody my={2}>
                    <Text>El usuario coincide con otro, ¿desea reconciliarlo?</Text>
                    {mismoIdentificador && (
                        <Text
                            textColor="red"
                            fontWeight="bold"
                            my={4}
                            border="1px solid red"
                            p={2}
                            textAlign="center"
                            rounded="md"
                        >
                            ES OBLIGATORIA LA RECONCILICIALICIÓN, COINCIDEN IDENTIFICADORES
                        </Text>
                    )}
                    <Stack direction="row" mt={2}>
                        <Button colorScheme="blue" size="lg" w="100%" onClick={reconciliar_si}>
                            SI
                        </Button>
                        {!mismoIdentificador && (
                            <Button colorScheme="red" size="lg" w="100%" onClick={reconciliar_no}>
                                NO
                            </Button>
                        )}
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ReconciliacionModal;
