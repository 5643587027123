import { useRef, useState, useEffect } from "react";

import {
    Box,
    Heading,
    Button,
    useDisclosure,
    useToast,
    Stack,
    Text,
    Flex,
    Divider,
    Checkbox,
    Spacer,
    SimpleGrid,
    Collapse,
    Tooltip,
} from "@chakra-ui/react";

import _ from "lodash";

import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";

import { defaultEventData } from "../../data/DefaultData/DefaultEventData";
import { errorNotification, okNotification } from "../../utils/Notificaciones";
import { createEvent, getAllEventsUser, updateEvent, deleteEvent } from "../../services/agenda";
import { getAllUsuarios } from "../../services/usuarios";
import CitasModal from "../Modals/CitasModal";
import Layout from "../Layout/Layout";
import NuevaCitaAdminModal from "../Modals/NuevaCitaGeneral";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import { updateActividad } from "../../services/actividades";
import { AddIcon, ArrowBackIcon, ArrowForwardIcon, InfoIcon, ViewIcon } from "@chakra-ui/icons";

const AgendaGeneral = () => {
    const defaultDataCopy = _.cloneDeep(defaultEventData);
    const [needReload, setNeedReload] = useState(true);
    const [formData, setFormData] = useState(defaultDataCopy);
    const [allUsers, setAllUsers] = useState([]);
    const [showAllHours, setShowAllHours] = useState(false);
    const toast = useToast();
    const calendarRef = useRef();
    const modal = useDisclosure();
    const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const [showUsers, setShowUsers] = useState(false);

    const { user } = useSelector((state) => state.auth);

    const aux = defaultDataCopy;
    const getCopia = () => {
        return _.cloneDeep(defaultDataCopy);
    };

    useEffect(() => {
        const getEvents = async () => {
            try {
                setNeedReload(false);
                setIsLoading(true);

                const usuarios = await getAllUsuarios();
                const listaUsuarios = usuarios.map((user) => ({
                    uuid: user.uuid,
                    nombre: `${user.nombre} ${user.apellidos}`,
                    isChecked: true,
                }));
                setAllUsers(listaUsuarios);

                const calendarApi = calendarRef.current.getApi();
                // Eliminar todo lo que haya en el calendario
                calendarApi.removeAllEvents();
                // Eliminar también los recursos
                calendarApi.getEventSources().forEach((source) => source.remove());
                const currentEvents = calendarApi.getEvents();
                const currentEventIds = new Set(currentEvents.map((event) => event.extendedProps.id));

                const requests = listaUsuarios.map(async (user) => {
                    const eventos = await getAllEventsUser(user.uuid);
                    const eventsToRender = eventos
                        .filter((event) => !currentEventIds.has(event.uuid))
                        .map((element) => ({
                            id: element.uuid,
                            title: element.tipo,
                            start: element.fechaInicio,
                            end: element.fechaFin,
                            resourceId: user.uuid,
                            resourceEditable: true,
                            color: element.color,
                            extendedProps: {
                                descripcion: element.descripcion,
                                id: element.uuid,
                                cliente: element.cliente,
                                clienteUuid: element.clienteUuid,
                                responsable: element.responsable,
                                responsableUuid: element.responsableUuid,
                                actividadUuid: element.actividadeUuid,
                                inmueble: element.inmueble,
                                inmuebleUuid: element.inmuebleUuid,
                                encargo: element.encargo,
                                encargoUuid: element.encargoUuid,
                                pedido: element.pedido,
                                pedidoUuid: element.pedidoUuid,
                                estado: element.estado,
                                tipo: element.tipo,
                            },
                        }));
                    const resource = {
                        id: user.uuid,
                        title: user.nombre,
                    };
                    return [eventsToRender, resource];
                });

                const responses = await Promise.allSettled(requests);
                const newEvents = responses.filter((response) => response.status === "fulfilled").map((response) => response.value[0]);
                const newResources = responses.filter((response) => response.status === "fulfilled").map((response) => response.value[1]);

                await calendarApi.batchRendering(async () => {
                    await Promise.all(newEvents.map((eventsToRender) => calendarApi.addEventSource(eventsToRender)));
                    await Promise.all(newResources.map((resource) => calendarApi.addResource(resource)));
                });

                setIsLoading(false);
            } catch (error) {
                console.error("🚀 ~ file: cargar.js:32 ~ handleSave ~ error", error);
                errorNotification("Error al listar evento", toast);
            }
        };

        needReload && toast && getEvents();
    }, [needReload, toast]);

    const handleFilter = async (event) => {
        event.preventDefault();
        const { name, checked } = event.target;

        const newUsers = allUsers.map((user) => {
            if (user.uuid === name) {
                return { ...user, isChecked: checked };
            } else {
                return user;
            }
        });

        setAllUsers(newUsers);

        const calendarApi = calendarRef.current.getApi();
        for (const user of newUsers) {
            if (user.isChecked) {
                calendarApi.addResource({
                    id: user.uuid,
                    title: user.nombre,
                });
            } else {
                const resourceStore = calendarApi.getResourceById(user.uuid);
                if (resourceStore) resourceStore.remove();
            }
        }
    };

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(_.set({ ...formData }, name, type === "checkbox" ? checked : value));
    };

    const handleCancel = () => {
        onModalClose();
    };

    const handleCancel2 = () => {
        setFormData(aux);
        modal.onClose();
    };

    const handleSave = async () => {
        try {
            const aux = await createEvent({
                ...formData,
                responsableUuid: formData.responsableUuid || user.uuid,
            });
            const calendarApi = calendarRef.current.getApi();
            const events = [
                {
                    id: aux.uuid,
                    title: formData.tipo,
                    resourceId: formData.usuario,
                    start: formData.fechaInicio,
                    end: formData.fechaFin,
                    color: formData.color,
                    extendedProps: {
                        descripcion: formData.descripcion,
                        uuid: aux.uuid,
                        clienteUuid: formData.clienteUuid,
                        responsableUuid: formData.responsableUuid || user.uuid,
                        estado: formData.estado,
                        tipo: formData.tipo,
                    },
                },
            ];
            calendarApi.addEventSource(events);
            okNotification("Evento creado correctamente", toast);
            setFormData(getCopia());
            modal.onClose();
            setNeedReload(true);
        } catch (error) {
            console.error("🚀 ~ file: AgendaAdmin.js:159 ~ handleSave ~ error:", error);
            errorNotification("Error al crear evento", toast);
        }
    };

    const handleSave2 = async () => {
        try {
            await updateEvent(formData.id, formData);

            if (formData.actividadUuid) {
                await updateActividad(formData.actividadUuid, {
                    estado_actividad: formData.estado,
                    fecha_finalizacion: formData.fechaFin,
                    descripcion: formData.descripcion,
                });
            }

            okNotification("Evento actualizado correctamente", toast);
            modal.onClose();
            setNeedReload(true);
        } catch (error) {
            console.error("🚀 ~ file: CrearEvento.js:32 ~ handleSave ~ error", error);
            errorNotification("Error al crear evento", toast);
        }
    };

    const handleDelete = async () => {
        try {
            const calendarApi = calendarRef.current.getApi();
            calendarApi.getEventById(formData.id).remove();
            await deleteEvent(formData.id);
            okNotification("Evento eliminado correctamente", toast);
        } catch (error) {
            console.error("🚀 ~ file: AgendaAdmin.js:205 ~ handleDelete ~ error:", error);
            errorNotification("Error al eliminar evento", toast);
        }

        // Limpiar formData
        setFormData(getCopia());
        modal.onClose();
    };

    //funcion para actualizar eventos
    const UploadEvent = async (eventInfo) => {
        const newOwner = allUsers.find((user) => user.uuid === eventInfo.event._def.resourceIds[0]).uuid;
        if (!newOwner) {
            errorNotification("Error al actualizar evento, no se encuentra al nuevo responsable.", toast);
            return;
        }
        const eventId = eventInfo.event.id || eventInfo.event.extendedProps.id;
        const eventData = {
            ...formData,
            fechaInicio: eventInfo.event.start,
            fechaFin: eventInfo.event.end,
            id: eventId,
            clienteUuid: eventInfo.event.extendedProps.clienteUuid,
            descripcion: eventInfo.event.extendedProps.descripcion,
            tipo: eventInfo.event.title,
            responsableUuid: newOwner || eventInfo.event.extendedProps.responsableUuid,
            estado: eventInfo.event.extendedProps.estado,
            color: eventInfo.event.backgroundColor,
        };
        try {
            await updateEvent(eventId, eventData);
            okNotification("Evento actualizado correctamente", toast);
        } catch (error) {
            console.error("🚀 ~ file: AgendaGeneral.js:275 ~ UploadEvent ~ error:", error);
            errorNotification("Error al actualizar evento", toast);
        }
    };

    const handleVistaPrincipal = () => {
        calendarRef.current.getApi().changeView("resourceTimeGridDay");
    };

    const handleNextDay = () => {
        const calendarApi = calendarRef.current.getApi();
        // Get actual date
        const currentDate = calendarApi.getDate();
        const nextDay = new Date(currentDate);
        nextDay.setDate(nextDay.getDate() + 1);
        calendarApi.gotoDate(nextDay);
    };

    const handlePreviousDay = () => {
        const calendarApi = calendarRef.current.getApi();
        // Get actual date
        const currentDate = calendarApi.getDate();
        const nextDay = new Date(currentDate);
        nextDay.setDate(nextDay.getDate() - 1);
        calendarApi.gotoDate(nextDay);
    };

    const handleToday = () => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.today();
    };

    const handleWeekView = () => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.changeView("resourceTimeGridWeek");
    };

    const handleDayView = () => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.changeView("timeGridDay");
    };

    const msg = <Stack>
        <Text fontWeight="bold">Color de los eventos:</Text>
        <Text fontWeight="bold" color="green.500">Verde: Realizada</Text>
        <Text fontWeight="bold" color="yellow">Amarillo: Programada</Text>
        <Text fontWeight="bold" color="red">Rojo: No realizada</Text>
    </Stack>

    return (
        <Layout>
            <Box mb={4} w="100%">
                <Heading textAlign="center" color="facebook.800">
                    AGENDA
                </Heading>
            </Box>

            {isLoading && <Loader />}

            <Box mt={6} display={isLoading ? "none" : "block"}>
                <Flex mt={-16}>
                    <Spacer />
                    <Button colorScheme="facebook" rounded="none" onClick={() => setShowUsers(!showUsers)}>
                        <ViewIcon />
                    </Button>
                    <Tooltip label={msg} placement="top" p={4}>
                        <Button colorScheme="facebook" rounded="none">
                            <InfoIcon />
                        </Button>
                    </Tooltip>
                </Flex>
                <Collapse in={showUsers} animateOpacity>
                    <SimpleGrid
                        minChildWidth="200px"
                        align="center"
                        justify="center"
                        border="1px solid black"
                        p={2}
                        my={2}
                        bgColor="facebook.600"
                        color="white"
                        fontWeight="bold"
                        shadow="md"
                        rounded="none">
                        {allUsers &&
                            allUsers.length > 0 &&
                            allUsers.map((user) => (
                                <Flex spacing={0} key={user.uuid} px={4} height="100%">
                                    <Checkbox
                                        key={user.uuid}
                                        name={user.uuid}
                                        isChecked={user.isChecked}
                                        onChange={handleFilter}
                                        size="lg"
                                    />
                                    <Text textAlign="center" noOfLines={2} minW="100%" height="100%">
                                        {user.nombre}
                                    </Text>
                                </Flex>
                            ))}
                    </SimpleGrid>
                </Collapse>
                <Box mt={6} border="2px solid black" rounded="lg" p={2} shadow="dark-lg">
                    <Stack direction="row" spacing={0}>
                        <Button colorScheme="facebook" onClick={onModalOpen} size="sm" fontWeight="bold" rounded="none">
                            <AddIcon w={"1.5rem"} />
                        </Button>
                        <Button
                            colorScheme="facebook"
                            onClick={() => setShowAllHours(!showAllHours)}
                            size="sm"
                            w={"7rem"}
                            fontWeight="bold"
                            rounded="none">
                            {showAllHours ? "LABORAL" : "24 HORAS"}
                        </Button>
                        <Spacer />
                        <Button
                            colorScheme="facebook"
                            onClick={handleVistaPrincipal}
                            minW="14rem"
                            size="sm"
                            rounded="none"
                            fontWeight="bold">
                            PRINCIPAL
                        </Button>
                    </Stack>
                    <Stack direction="row" spacing={0}>
                        <Button colorScheme="facebook" onClick={handlePreviousDay} size="sm" fontWeight="bold" rounded="none">
                            <ArrowBackIcon w={"1.5rem"} />
                        </Button>
                        <Button colorScheme="facebook" onClick={handleNextDay} size="sm" fontWeight="bold" rounded="none">
                            <ArrowForwardIcon w={"1.5rem"} />
                        </Button>
                        <Button colorScheme="facebook" onClick={handleToday} size="sm" w={"4rem"} fontWeight="bold" rounded="none">
                            HOY
                        </Button>
                        <Spacer />
                        <Button colorScheme="facebook" onClick={handleWeekView} minW="7rem" size="sm" rounded="none" fontWeight="bold">
                            SEMANA
                        </Button>
                        <Button colorScheme="facebook" onClick={handleDayView} minW="7rem" size="sm" rounded="none" fontWeight="bold">
                            DÍA
                        </Button>
                    </Stack>
                    <Box mt={-10}>
                        <FullCalendar
                            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                            locale={"es"}
                            ref={calendarRef}
                            plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin, resourceTimeGridPlugin]}
                            editable={true}
                            initialView="resourceTimeGridDay"
                            slotMinTime={showAllHours ? "00:00:00" : "07:00:00"}
                            slotMaxTime={showAllHours ? "23:59:59" : "23:00:00"}
                            slotDuration={showAllHours ? "00:30:00" : "00:15:00"}
                            headerToolbar={{
                                left: false,
                                center: "title",
                                right: false,
                            }}
                            views={{
                                timeGrid: {
                                    allDaySlot: false,
                                },
                            }}
                            height="auto"
                            aspectRatio={2}
                            eventDrop={(info) => {
                                UploadEvent(info);
                            }}
                            eventResize={(info) => {
                                UploadEvent(info);
                            }}
                            eventClick={(info) => {
                                setFormData({
                                    ...formData,
                                    fechaInicio: info.event.start,
                                    fechaFin: info.event.end,
                                    id: info.event.id || info.event.extendedProps.id,
                                    clienteUuid: info.event.extendedProps.clienteUuid,
                                    actividadUuid: info.event.extendedProps.actividadUuid,
                                    cliente: info.event.extendedProps.cliente,
                                    responsable: info.event.extendedProps.responsable,
                                    inmueble: info.event.extendedProps.inmueble,
                                    descripcion: info.event.extendedProps.descripcion,
                                    tipo: info.event.title,
                                    responsableUuid: info.event.extendedProps.responsableUuid,
                                    estado: info.event.extendedProps.estado,
                                    edit: true,
                                    color: info.event.backgroundColor,
                                });
                                modal.onOpen();
                            }}
                            eventContent={renderEventContent}
                            eventDisplay="block"
                            dateClick={(info) => {
                                const hora30 = new Date(info.date.getTime() + 30 * 60000);
                                setFormData({
                                    ...formData,
                                    fechaInicio: info.date,
                                    fechaFin: hora30,
                                    responsableUuid: info.resource._resource.id,
                                });
                                onModalOpen();
                            }}
                        />
                    </Box>
                </Box>
            </Box>

            {isModalOpen && (
                <NuevaCitaAdminModal
                    isOpen={isModalOpen}
                    handleCancel={handleCancel}
                    modal={modal}
                    formData={formData}
                    setFormData={setFormData}
                />
            )}
            {modal.isOpen && (
                <CitasModal
                    isOpen={modal.isOpen}
                    onClose={handleCancel2}
                    formData={formData}
                    handleChange={handleChange}
                    handleSave={formData.edit ? handleSave2 : handleSave}
                    handleCancel={formData.edit !== "" ? handleDelete : handleCancel2}
                />
            )}
        </Layout>
    );
};

// evento para rendderizar los eventos
function renderEventContent(eventInfo) {
    const estaRealizada = eventInfo.event.extendedProps.estado === "Realizada";
    const noRealizada = eventInfo.event.extendedProps.estado === "No realizada";
    const estaProgramada = eventInfo.event.extendedProps.estado === "Programada";

    const borderColor = noRealizada ? "4px solid red" : estaProgramada ? "4px solid yellow" : estaRealizada ? "4px solid green" : "unset";
    // Comprobar si el backgroundColor es negro
    const needWhiteText =
        eventInfo.backgroundColor === "#000000" || eventInfo.backgroundColor === "#573EEE" || eventInfo.backgroundColor === "#27A3D9";
    return (
        <Box border={borderColor} rounded="md" h="100%">
            <Stack
                direction={"column"}
                bgClip="border-box"
                border="2px solid black"
                bgColor={estaRealizada ? "whiteAlpha.600" : undefined}
                w="100%"
                h="100%"
                rounded="sm"
                spacing={0}
                overflow="auto"
                color={needWhiteText ? "white" : "black"}>
                <Flex gap={2}>
                    <Text fontWeight="bold">{eventInfo.timeText}</Text> <Text fontWeight="bold">{" | "}</Text>
                    <Text fontStyle="italic" fontWeight="black">
                        {eventInfo.event.title}
                    </Text>
                    <Text fontWeight="bold">{" | "}</Text>
                    <Text fontStyle="italic" fontWeight="bold">
                        {eventInfo.event.extendedProps?.cliente?.nombre} {eventInfo.event.extendedProps?.cliente?.apellidos}
                    </Text>
                </Flex>
                <Divider />
                <Text>{eventInfo.event.extendedProps.descripcion}</Text>
            </Stack>
        </Box>
    );
}

export default AgendaGeneral;
