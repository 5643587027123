import { useEffect, useState } from "react";
import { Button, Center, Image, Input, Text, Stack, Flex, Box } from "@chakra-ui/react";
// react-redux
import { useDispatch, useSelector } from "react-redux";
import { getMe, loginUser, reset } from "../features/auth/authSlice";

import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();

    const [inputUser, setInputUser] = useState("");
    const [inputPassword, setInputPassword] = useState("");
    const dispatch = useDispatch();

    const onChangeUser = (e) => setInputUser(e.target.value);
    const onChangePassword = (e) => setInputPassword(e.target.value);

    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);
    const noSesion = isError && message === "No hay sesion iniciada.";

    useEffect(() => {
        const checkAndRedirect = () => {
            navigate("/inicio");
            dispatch(reset());
        };

        if (isSuccess && user) {
            checkAndRedirect();
        } else {
            dispatch(getMe());
        }
    }, [isSuccess, user, navigate, dispatch]);

    const handleLogin = () => {
        dispatch(loginUser({ identificador: inputUser, password: inputPassword }));
    };

    return (
        <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
            <Flex p={8} flex={1} align={"center"} justify={"center"}>
                <Stack spacing={4} w={"full"} maxW={"md"}>
                    <Center mb={12}>
                        <Image src="https://panacloud-data.s3.eu-west-3.amazonaws.com/logo.png" alt="Logo" />
                    </Center>

                    <Box>
                        <Text fontWeight="bold" color="facebook.800">
                            Identificador
                        </Text>
                        <Input onChange={onChangeUser} isInvalid={!noSesion && isError} borderColor="facebook.800" />
                    </Box>

                    <Box>
                        <Text fontWeight="bold" color="facebook.800">
                            Contraseña
                        </Text>
                        <Input
                            type="password"
                            onChange={onChangePassword}
                            borderColor="facebook.800"
                            isInvalid={!noSesion && isError}
                        />
                    </Box>

                    <Stack spacing={6}>
                        <Button
                            colorScheme={"facebook"}
                            rounded="none"
                            variant={"solid"}
                            onClick={handleLogin}
                            isDisabled={isLoading}>
                            Iniciar sesión
                        </Button>
                    </Stack>

                    {!noSesion && isError && (
                        <Center mt={4}>
                            <Text fontWeight="bold">{message}</Text>
                        </Center>
                    )}
                </Stack>
            </Flex>
            <Flex flex={1}>
                <Image
                    alt={"Login Image"}
                    objectFit={"cover"}
                    src={
                        "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80"
                    }
                />
            </Flex>
        </Stack>
    );
};

export default Login;
