import { Heading, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import EasyDatePicker from '../../EasyDatePicker/EasyDatePicker';

const Fechas = ({ noticia, handleChange }) => {

    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md" my={2}>
                Información
            </Heading>
            <SimpleGrid columns={2}>
                <Text>Fecha de creación</Text>
                <EasyDatePicker name="createdAt" value={noticia.createdAt} needHour={true}/>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Última modificación</Text>
                <EasyDatePicker name="updatedAt" value={noticia.updatedAt} needHour={true}/>
            </SimpleGrid>
        </Stack>
    );
};

export default Fechas;
