import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllAgencias } from "../../services/agencias";
import Layout from "../../components/Layout/Layout";
import { Box, Button, Flex, Heading, Spacer, Table, TableContainer, Tbody, Td, Thead, Tr } from "@chakra-ui/react";
import TableItem from "../../components/Agencias/TableItem";
import { AddIcon } from "@chakra-ui/icons";

const BuscarAgencias = memo(() => {
    const [agencias, setAgencias] = useState([]);
    const [needReload, setNeedReload] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const getAgencias = async () => {
            const agencias = await getAllAgencias();
            setAgencias(agencias);
            setNeedReload(false);
        };

        needReload && getAgencias();
    }, [needReload]);

    return (
        <Layout>
            <Box>
                <Heading textAlign="center" color="facebook.800">
                    AGENCIAS
                </Heading>
            </Box>

            <Flex mt={-10}>
                <Spacer />
                <Button rounded="none" onClick={() => navigate("/agencias/crear")} colorScheme="facebook">
                    <AddIcon />
                </Button>
            </Flex>

            <Box mt={6}>
                <TableContainer w="100%" border="1px" rounded="lg" shadow="dark-lg">
                    <Table size="lg" p={4}>
                        <Thead bgColor="blackAlpha.200">
                            <Tr>
                                <Td>Nombre</Td>
                                <Td>Dirección</Td>
                                <Td>Activa</Td>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {agencias &&
                                agencias.map((agencia) => {
                                    return (
                                        <TableItem key={agencia.uuid} agencia={agencia} setNeedReload={setNeedReload} />
                                    );
                                })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Layout>
    );
});

export default BuscarAgencias;
