import axios from "axios";

export const crearPedido = async (data) => {
    try {
        const response = await axios.post("/pedidos", data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: pedidos.js:8 ~ crearPedido ~ error", error);
        throw error.response;
    }
};

export const getAllPedidos = async () => {
    try {
        const response = await axios.get("/pedidos", { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: pedidos.js:18 ~ getAllPedidos ~ error", error);
        throw error.response;
    }
};

export const getPedidoById = async (id) => {
    try {
        const response = await axios.get(`/pedidos/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: pedidos.js:28 ~ getPedidoById ~ error", error);
        throw error.response;
    }
};

export const getPedidoEncargoById = async (id) => {
    try {
        const response = await axios.get(`/pedidos/encargos/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: pedidos.js:38 ~ getPedidoEncargoById ~ error:", error);
        throw error.response;
    }
};

export const getPedidoEncargoByActividad = async (id) => {
    try {
        const response = await axios.get(`/pedidos/encargos/actividad/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: pedidos.js:38 ~ getPedidoEncargoByActividad ~ error:", error);
        throw error.response;
    }
};

export const actualizarPedidoEncargos = async (id, data) => {
    try {
        const response = await axios.patch(`/pedidos/encargos/${id}`, data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: pedidos.js:38 ~ actualizarPedidoEncargos ~ error:", error);
        throw error.response;
    }
};

export const updatePedido = async (id, data) => {
    try {
        const response = await axios.patch(`/pedidos/${id}`, data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: pedidos.js:38 ~ updatePedido ~ error", error);
        throw error.response;
    }
};

export const deletePedido = async (id) => {
    try {
        const response = await axios.delete(`/pedidos/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: pedidos.js:48 ~ deletePedido ~ error", error);
        throw error.response;
    }
};

export const getPedidosByCliente = async (id) => {
    try {
        const response = await axios.get(`/pedidos/cliente/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: pedidos.js:28 ~ getPedidoById ~ error", error);
        throw error.response;
    }
};

export const uploadPedidoFile = async (id, filename, data) => {
    try {
        const response = await axios.post(`/pedidos/documentos/${id}`, {
            filename: filename,
            data: data,
        });

        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: pedidos.js:103 ~ uploadPedidoFile ~ error:", error);
        throw error.response;
    }
};

export const getAllPedidoFiles = async (id) => {
    try {
        const response = await axios.get(`/pedidos/documentos/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: pedidos.js:113 ~ getAllEncargoFiles ~ error:", error);
        throw error.response;
    }
};

export const getPedidoFile = async (id, name) => {
    try {
        const response = await axios.get(`/pedidos/documentos/${id}/${name}`, {
            retry: 3,
            retryDelay: 1000,
            responseType: "blob",
        });
        return response.data;
    } catch (error) {
        console.error("🚀 ~ file: pedidos.js:124 ~ getEncargoFile ~ error:", error);
        throw error.response;
    }
};
