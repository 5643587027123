import { Heading, Select, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { Tipologia_inmueble, Subtipo_inmueble } from "../../../data/constants";

const IdentificacionInmueble = ({ formData, handleChange }) => {
    let auxSubtipo = [];
    
    if (formData.tipo_inmueble) {
        auxSubtipo = Subtipo_inmueble.find(function (e) {
            return e.tipo === formData.tipo_inmueble;
        }).subtipo;
    }

    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Identificación inmueble</Heading>

            <SimpleGrid columns={2}>
                <Text>Tipologia</Text>
                <Select
                    borderLeft="3px solid red"
                    name="tipo_inmueble"
                    value={formData.tipo_inmueble}
                    onChange={handleChange} isDisabled={handleChange == null}
                    placeholder="-- Ninguno --">
                    {Tipologia_inmueble.map((tipo, index) => {
                        return (
                            <option key={index} value={tipo.tipo}>
                                {tipo.tipo}
                            </option>
                        );
                    })}
                </Select>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Subtipologia</Text>
                <Select
                    borderLeft="3px solid red"
                    name="subtipo_inmueble"
                    value={formData.subtipo_inmueble}
                    onChange={handleChange} isDisabled={handleChange == null}
                    placeholder="-- Ninguno --">
                    {auxSubtipo.map((tipo, index) => {
                        return (
                            <option key={index} value={tipo.tipo}>
                                {tipo.tipo}
                            </option>
                        );
                    })}
                </Select>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Planta</Text>
                <Select
                    name="planta"
                    value={formData.planta}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                    placeholder="-- Ninguna --"
                >
                    <option value="Baja">Baja</option>
                    <option value="Media">Media</option>
                    <option value="Alta">Alta</option>
                </Select>
            </SimpleGrid>
        </Stack>
    );
};

export default IdentificacionInmueble;
