import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import _ from "lodash"
import { getAgencia } from "../../services/agencias"
import Layout from "../../components/Layout/Layout"
import { Box, Heading } from "@chakra-ui/react"
import GridInfoAgencia from "../../components/Agencias/GridInfoAgencia"
import { defaultAgenciaData } from "../../data/DefaultData/DefaultAgenciaData"

const AddEditAgencia = ({ isEdit = false }) => {

    const { id } = useParams()
    const copyDefaultAgenciaData = _.cloneDeep(defaultAgenciaData)
    const [agencia, setAgencia] = useState(copyDefaultAgenciaData)
    const [formData, setFormData] = useState(agencia)
    const [needReload, setNeedReload] = useState(true)

    useEffect(() => {
        const getAgenciaById = async () => {
            setNeedReload(false)
            const estaAgencia = await getAgencia(id)
            setAgencia(estaAgencia)
            setFormData(estaAgencia)
        }

        needReload && isEdit && getAgenciaById()
    } , [id, isEdit, needReload])

    const handleChange = event => {
        const { name, value, type, checked } = event.target
        setFormData(_.set({ ...formData }, name, type === 'checkbox' ? checked : value))
    }

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center">{isEdit ? "Editar" : "Crear"} agencia</Heading>
            </Box>

            {agencia && (
                <GridInfoAgencia
                    formData={formData}
                    handleChange={handleChange}
                    isEdit={isEdit}
                />
            )}
        </Layout>
    )
}

export default AddEditAgencia