import axios from 'axios';

export const createEvent = async (data) => {
    try {
        const response = await axios.post('/citas', data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: agenda.js:8 ~ crearEvent ~ error", error)
        throw error.response;
        //return error.response;
    }
}

export const getAllEvents = async () => {
    try {
        const response = await axios.get('/citas', { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: agenda.js:19 ~ getAllEvents ~ error", error)
        throw error.response;
    }
}

export const getCitasFinancierasByPedido = async (id) => {
    try {
        const response = await axios.get(`/citas/pedido/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: agenda.js:29 ~ getCitasFinancierasByPedido ~ error:", error)
        throw error.response;
    }
}

export const getEvent = async (id) => {
    try {
        const response = await axios.get(`/citas/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: agenda.js:29 ~ getEvent ~ error", error)
        throw error.response;
    }
}

export const updateEvent = async (id, data) => {
    try {
        const response = await axios.patch(`/citas/${id}`, data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: agenda.js:39 ~ updateEvent ~ error", error)
        throw error.response;
    }
}

export const deleteEvent = async (id) => {
    try {
        const response = await axios.delete(`/citas/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: agenda.js:49 ~ deleteEvent ~ error", error)
        return error.response;
    }
}
export const getAllEventsUser = async (id) => {
    try {
        const response = await axios.get(`/citas/usuario/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: agenda.js:49 ~ deleteEvent ~ error", error)
        return error.response;
    }
}