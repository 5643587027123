import { Box, Center, Spinner, Text } from "@chakra-ui/react";

const Loader = () => {
    return (
        <Center minW="30rem">
            <Box my={4} border="1px solid black" rounded="md" p={12} bgColor="facebook.600" shadow="dark-lg" minW="30rem">
                <Center>
                    <Text textAlign="center" fontWeight="black" color="white" mb={4} fontSize="xl">
                        Cargando...
                    </Text>
                </Center>
                <Center my={4}>
                    <Spinner size="xl" color="white" />
                </Center>
            </Box>
        </Center>
    );
};

export default Loader;
