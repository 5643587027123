import ActualizacionModal from "../Modals/Actividades/ActualizacionModal";
import AdquisicionModal from "../Modals/Actividades/AdquisicionModal";
import BusquedaModal from "../Modals/Actividades/BusquedaModal";
import GestionEncargoModal from "../Modals/Actividades/GestionEncargoModal";
import PromocionModal from "../Modals/Actividades/PromocionModal";
import ValoracionModal from "../Modals/Actividades/ValoracionModal";
import VentaAlquilerV2Modal from "../Modals/Actividades/VentaAlquilerV2Modal";

const MultiActividad = ({ isOpen, onClose, datos, esNoticia = false }) => {
    return (
        <>
            {datos.tipo_actividad === "Actualizacion" && (
                <ActualizacionModal isOpen={isOpen} onClose={onClose} datos={datos} isEdit={true} esNoticia={esNoticia} />
            )}
            {datos.tipo_actividad === "Adquisicion" && (
                <AdquisicionModal isOpen={isOpen} onClose={onClose} datos={datos} isEdit={true} />
            )}
            {datos.tipo_actividad === "Busqueda" && (
                <BusquedaModal isOpen={isOpen} onClose={onClose} datos={datos} isEdit={true} />
            )}
            {datos.tipo_actividad === "Valoracion" && (
                <ValoracionModal isOpen={isOpen} onClose={onClose} datos={datos} isEdit={true} />
            )}
            {datos.tipo_actividad === "Venta o alquiler" && (
                <VentaAlquilerV2Modal isOpen={isOpen} onClose={onClose} datos={datos} isEdit={true} />
            )}
            {datos.tipo_actividad === "Promocion" && (
                <PromocionModal isOpen={isOpen} onClose={onClose} datos={datos} isEdit={true} />
            )}
            {datos.tipo_actividad === "Gestion encargo" && (
                <GestionEncargoModal isOpen={isOpen} onClose={onClose} datos={datos} isEdit={true} />
            )}
        </>
    );
};

export default MultiActividad;
