import { Box, Button, Flex, Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import ActualizacionModal from "../Modals/Actividades/ActualizacionModal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MultiActividad from "../MultiActividad/MultiActividad";
import PopoverDescripcion from "./PopoverDescripcion";
import EasyDatePicker from "../EasyDatePicker/EasyDatePicker";

const ListaActividadesPedidos = ({ actividades, datos, pedidoAbierto = false }) => {
    const [datosRecibidos, setDatosRecibidos] = useState(datos);
    const [isEdit, setIsEdit] = useState(false);
    const navigate = useNavigate();

    const { isOpen: isOpenActualizacion, onOpen: onOpenActualizacion, onClose: onCloseActualizacion } = useDisclosure();
    const { isOpen: isOpenMultiActividad, onOpen: onOpenMultiActividad, onClose: onCloseMultiActividad } = useDisclosure();

    const handleOpenActividad = (actividad) => {
        setIsEdit(true);
        setDatosRecibidos({
            ...actividad,
            tipo_inmueble: datos.tipo_inmueble,
            subtipo_inmueble: datos.subtipo_inmueble,
        });
        onOpenMultiActividad();
    };

    const handleNuevaActualizacion = () => {
        setIsEdit(false);
        setDatosRecibidos(datos);
        onOpenActualizacion();
    };

    return (
        <>
            <Box p={8} border="1px" rounded="md" shadow="dark-lg">
                <Heading textAlign="center" mb={4}>
                    Actividades
                </Heading>
                {pedidoAbierto && (
                    <Flex>
                        <Button rounded="none" w="100%" colorScheme="facebook" onClick={handleNuevaActualizacion}>
                            Llamada de actualización
                        </Button>
                        {actividades &&
                            actividades.some(
                                (actividad) => actividad.tipo_actividad === "Actualizacion" && actividad.estado_actividad === "Realizada"
                            ) && (
                                <Button
                                    rounded="none"
                                    w="100%"
                                    colorScheme="yellow"
                                    onClick={() => navigate(`/cruce/pedido/promocion/${datos.uuid}`)}>
                                    Llamada de promoción
                                </Button>
                            )}

                        {actividades &&
                            actividades.some(
                                (actividad) => actividad.tipo_actividad === "Promocion" && actividad.estado_actividad === "Realizada"
                            ) && (
                                <Button
                                    rounded="none"
                                    w="100%"
                                    colorScheme="green"
                                    onClick={() => navigate(`/cruce/pedido/cita/${datos.uuid}`)}>
                                    Cita de venta/alquiler
                                </Button>
                            )}
                    </Flex>
                )}

                {actividades && actividades.length === 0 && (
                    <Text py={6} fontWeight={"bold"} textAlign={"center"}>
                        No hay actividades para este pedido
                    </Text>
                )}

                {actividades && actividades.length > 0 && (
                    <TableContainer maxH="40rem" overflowY="auto">
                        <Table size="sm">
                            <Thead>
                                <Tr bgColor="facebook.50">
                                    <Th>Asunto</Th>
                                    <Th>Modalidad contacto</Th>
                                    <Th>Estado</Th>
                                    <Th>Entidad relacionada</Th>
                                    <Th>Fecha actividad</Th>
                                    <Th>Vencimiento</Th>
                                    <Th>Responsable</Th>
                                    <Th>Descripción</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {actividades.map((actividad) => {
                                    return (
                                        <Tr key={actividad.uuid} _hover={{ bg: "gray.100", cursor: "pointer" }}>
                                            <Td onClick={() => handleOpenActividad(actividad)}>{actividad.tipo_actividad} </Td>
                                            <Td onClick={() => handleOpenActividad(actividad)}>{actividad.modalidad_contacto}</Td>
                                            <Td onClick={() => handleOpenActividad(actividad)}>{actividad.estado_actividad}</Td>
                                            <Td onClick={() => handleOpenActividad(actividad)}>{actividad.entidad_relacionada}</Td>
                                            <Td onClick={() => handleOpenActividad(actividad)}>
                                                <EasyDatePicker value={actividad.fecha_actividad} />
                                            </Td>
                                            <Td onClick={() => handleOpenActividad(actividad)}>
                                                <EasyDatePicker value={actividad.fecha_vencimiento} />
                                            </Td>
                                            <Td onClick={() => handleOpenActividad(actividad)}>
                                                {actividad.responsable?.nombre} {actividad.responsable?.apellidos}
                                            </Td>
                                            <Td>
                                                <PopoverDescripcion descripcion={actividad.descripcion} />
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
            {isOpenActualizacion && (
                <ActualizacionModal isOpen={isOpenActualizacion} onClose={onCloseActualizacion} datos={datosRecibidos} isEdit={isEdit} />
            )}
            {isOpenMultiActividad && (
                <MultiActividad isOpen={isOpenMultiActividad} onClose={onCloseMultiActividad} datos={datosRecibidos} isEdit={isEdit} />
            )}
        </>
    );
};

export default ListaActividadesPedidos;
