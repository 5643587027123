import { Heading, Input, Select, SimpleGrid, Stack, Text, Textarea } from "@chakra-ui/react";

const DatosContacto = ({ formData, handleChange }) => {

    let otros_datos_contacto;
    if(formData.otros_datos_contacto){
        try {
            otros_datos_contacto = JSON.parse(formData.otros_datos_contacto);
        } catch (error) {
            otros_datos_contacto = JSON.parse(JSON.stringify(formData.otros_datos_contacto));
        }
    }

    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Datos de contacto</Heading>

            <SimpleGrid columns={2}>
                <Text>Correo electrónico</Text>
                <Input
                    name="otros_datos_contacto.email"
                    value={otros_datos_contacto.email}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Email secundario</Text>
                <Input
                    name="otros_datos_contacto.email_secundario"
                    value={otros_datos_contacto.email_secundario}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Teléfono</Text>
                <Input
                    borderLeft="3px solid red"
                    name="telefono"
                    value={formData.telefono}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Teléfono secundario</Text>
                <Input
                    name="otros_datos_contacto.telefono_secundario"
                    value={otros_datos_contacto.telefono_secundario}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Provincia</Text>
                <Select
                    name="otros_datos_contacto.provincia"
                    value={otros_datos_contacto.provincia}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                >
                    <option value="alava">Álava</option>
                    <option value="albacete">Albacete</option>
                    <option value="alicante">Alicante</option>
                    <option value="almeria">Almería</option>
                    <option value="asturias">Asturias</option>
                    <option value="avila">Ávila</option>
                    <option value="badajoz">Badajoz</option>
                    <option value="barcelona">Barcelona</option>
                    <option value="burgos">Burgos</option>
                    <option value="caceres">Cáceres</option>
                    <option value="cadiz">Cádiz</option>
                    <option value="cantabria">Cantabria</option>
                    <option value="castellon">Castellón</option>
                    <option value="ceuta">Ceuta</option>
                    <option value="ciudadreal">Ciudad Real</option>
                    <option value="cordoba">Córdoba</option>
                    <option value="cuenca">Cuenca</option>
                    <option value="girona">Girona</option>
                    <option value="laspalmas">Las Palmas</option>
                    <option value="granada">Granada</option>
                    <option value="guadalajara">Guadalajara</option>
                    <option value="guipuzcoa">Guipúzcoa</option>
                    <option value="huelva">Huelva</option>
                    <option value="huesca">Huesca</option>
                    <option value="illesbalears">Illes Balears</option>
                    <option value="jaen">Jaén</option>
                    <option value="acoruña">A Coruña</option>
                    <option value="larioja">La Rioja</option>
                    <option value="leon">León</option>
                    <option value="lleida">Lleida</option>
                    <option value="lugo">Lugo</option>
                    <option value="madrid">Madrid</option>
                    <option value="malaga">Málaga</option>
                    <option value="melilla">Melilla</option>
                    <option value="murcia">Murcia</option>
                    <option value="navarra">Navarra</option>
                    <option value="ourense">Ourense</option>
                    <option value="palencia">Palencia</option>
                    <option value="pontevedra">Pontevedra</option>
                    <option value="salamanca">Salamanca</option>
                    <option value="segovia">Segovia</option>
                    <option value="sevilla">Sevilla</option>
                    <option value="soria">Soria</option>
                    <option value="tarragona">Tarragona</option>
                    <option value="santacruztenerife">Santa Cruz de Tenerife</option>
                    <option value="teruel">Teruel</option>
                    <option value="toledo">Toledo</option>
                    <option value="valencia">Valencia</option>
                    <option value="valladolid">Valladolid</option>
                    <option value="vizcaya">Vizcaya</option>
                    <option value="zamora">Zamora</option>
                    <option value="zaragoza">Zaragoza</option>
                </Select>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Ciudad</Text>
                <Input
                    name="otros_datos_contacto.ciudad"
                    value={otros_datos_contacto.ciudad}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Código postal</Text>
                <Input
                    name="otros_datos_contacto.codigo_postal"
                    value={otros_datos_contacto.codigo_postal}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Direccion</Text>
                <Input
                    name="otros_datos_contacto.direccion"
                    value={otros_datos_contacto.direccion}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Número</Text>
                <Input
                    name="otros_datos_contacto.numero"
                    value={otros_datos_contacto.numero}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Piso</Text>
                <Input
                    name="otros_datos_contacto.piso"
                    value={otros_datos_contacto.piso}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Letra</Text>
                <Input
                    name="otros_datos_contacto.letra"
                    value={otros_datos_contacto.letra}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Notas</Text>
                <Textarea
                    name="otros_datos_contacto.notas"
                    value={otros_datos_contacto.notas}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                />
            </SimpleGrid>
        </Stack>
    );
};

export default DatosContacto;
