import { Center, Select, Stack, Text, Textarea } from "@chakra-ui/react";

const BoxEncargo = ({ encargo, needInfo = false, handleChange, isDisabled = false }) => {
    const ubicacion = JSON.parse(encargo.inmueble?.ubicacion);
    return needInfo ? (
        <Stack direction="row">
            <Stack direction="column" border="1px solid" rounded="md" p={4} spacing={4}>
                <Stack direction="row" spacing={6}>
                    <Stack direction="column">
                        <Text textAlign="center">Dirección</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {ubicacion.direccion}
                        </Text>
                    </Stack>
                    <Stack direction="column">
                        <Text textAlign="center">Tipología</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {encargo.inmueble?.tipo_inmueble} {encargo.inmueble?.subtipo_inmueble}
                        </Text>
                    </Stack>
                    <Stack direction="column">
                        <Text textAlign="center">Dormitorios</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {encargo.inmueble?.habitaciones}
                        </Text>
                    </Stack>
                    <Stack direction="column">
                        <Text textAlign="center">Baños</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {encargo.inmueble?.bathrooms}
                        </Text>
                    </Stack>
                </Stack>
                <Center gap={4} border="1px solid black" rounded="sm" p={2} bgColor="gray.50">
                    <Text textAlign="center">Precio</Text>
                    <Text textAlign="center" fontWeight="bold">
                        {encargo.precio_final}
                    </Text>
                </Center>
            </Stack>
            <Stack border="1px solid" rounded="md" p={4}>
                <Select placeholder="Interés del cliente/resultado" name="resultado" value={encargo.resultado} onChange={(e) => handleChange(encargo.uuid, e)} isDisabled={isDisabled}>
                    <option value="Interesa ver el inmueble">Interesa ver el inmueble</option>
                    <option value="Visto y gusta">Visto y gusta</option>
                    <option value="No gusta el precio">No gusta el precio</option>
                    <option value="No gusta el inmueble">No gusta el inmueble</option>
                    <option value="El comprador quiere hacer una propuesta">
                        El comprador quiere hacer una propuesta
                    </option>
                </Select>
                <Textarea placeholder="Comentario" name="observaciones" value={encargo.observaciones} onChange={(e) => handleChange(encargo.uuid, e)} isDisabled={isDisabled} />
            </Stack>
        </Stack>
    ) : (
        <Center>
            <Stack direction="row" spacing={6} border="1px solid" rounded="md" p={4}>
                <Stack direction="column">
                    <Text textAlign="center">Dirección</Text>
                    <Text textAlign="center" fontWeight="bold">
                        {ubicacion.direccion}
                    </Text>
                </Stack>
                <Stack direction="column">
                    <Text textAlign="center">Tipología</Text>
                    <Text textAlign="center" fontWeight="bold">
                        {encargo.inmueble?.tipo_inmueble} {encargo.inmueble?.subtipo_inmueble}
                    </Text>
                </Stack>
                <Stack direction="column">
                    <Text textAlign="center">Dormitorios</Text>
                    <Text textAlign="center" fontWeight="bold">
                        {encargo.inmueble?.habitaciones}
                    </Text>
                </Stack>
                <Stack direction="column">
                    <Text textAlign="center">Baños</Text>
                    <Text textAlign="center" fontWeight="bold">
                        {encargo.inmueble?.bathrooms}
                    </Text>
                </Stack>
                <Stack direction="column">
                    <Text textAlign="center">Precio</Text>
                    <Text textAlign="center" fontWeight="bold">
                        {encargo.precio_final}
                    </Text>
                </Stack>
            </Stack>
        </Center>
    );
};

export default BoxEncargo;
