import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

// ----------------- Cookie login -----------------
import axios from "axios";
import { API_ROUTE } from "./data/constants";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = API_ROUTE;
// ------------------------------------------------

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
);
