import { SimpleGrid, Stack, Text, Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCliente } from "../../../services/clientes";

const Cliente = ({ formData, handleChange }) => {
    const [cliente, setCliente] = useState(formData.cliente);
    const [needReload, setNeedReload] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCliente = async () => {
            setNeedReload(false);
            const response = await getCliente(formData.clienteUuid);
            setCliente(response);
        };

        needReload && handleChange !== null && formData.clienteUuid && fetchCliente();
    }, [formData.clienteUuid, handleChange, needReload]);

    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Cliente</Heading>

            <SimpleGrid columns={2}>
                <Text>Click para ver el cliente</Text>
                {cliente && (
                    <Text
                        onClick={() => navigate(`/clientes/ver/${formData.clienteUuid}`)}
                        _hover={{ cursor: "pointer" }}
                        fontWeight="bold"
                        color="green"
                    >
                        {cliente.nombre} {cliente.apellidos}
                    </Text>
                )}
            </SimpleGrid>
        </Stack>
    );
};

export default Cliente;
