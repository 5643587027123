import { Td, Tr } from "@chakra-ui/react";
import EasyDatePicker from "../EasyDatePicker/EasyDatePicker";
import { Link } from "react-router-dom";

const PedidoTableItem = ({ pedido }) => {
    // onClick={() => navigate(`/pedidos/ver/${pedido.uuid}`, { state: { pedido } })}
    return (
        <Tr key={pedido.uuid} _hover={{ cursor: "pointer", bg: "gray.100" }}>
            <TdLink pedido={pedido.uuid}>
                PE - {pedido.tipo_inmueble} {pedido.subtipo_inmueble && pedido.subtipo_inmueble} -{" "}
                {pedido.cliente?.nombre} {pedido.cliente?.apellidos}
            </TdLink>
            <TdLink pedido={pedido.uuid}>{pedido.estado_pedido}</TdLink>
            <TdLink pedido={pedido.uuid}>{pedido.contrato}</TdLink>
            <TdLink pedido={pedido.uuid}>
                {pedido.tipo_inmueble} {pedido.subtipo_inmueble && pedido.subtipo_inmueble}
            </TdLink>
            <TdLink pedido={pedido.uuid}>
                {pedido.precio && new Intl.NumberFormat().format(pedido.precio) + " €"}
            </TdLink>
            <TdLink pedido={pedido.uuid}>
                <EasyDatePicker value={pedido.createdAt} needHour={true} />
            </TdLink>
            <TdLink pedido={pedido.uuid}>
                <EasyDatePicker value={pedido.updatedAt} needHour={true} />
            </TdLink>
        </Tr>
    );
};

const TdLink = ({ children, pedido }) => {
    return (
        <Td>
            <Link to={`/pedidos/ver/${pedido}`}>{children}</Link>
        </Td>
    );
};

export default PedidoTableItem;
