import { Checkbox, Heading, Input, Select, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import EasyDatePickerWithTime from "../../EasyDatePicker/EasyDatePickerWithTime";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAllAgencias } from "../../../services/agencias";

const AccesoSistema = ({ formData, handleChange }) => {
    const { user } = useSelector((state) => state.auth);

    const [needReload, setNeedReload] = useState(true);
    const [agencias, setAgencias] = useState([]);


    useEffect(() => {
        const fetchAgencias = async () => {
            setNeedReload(false);
            const response = await getAllAgencias();
            // Ordenar por nombre
            response.sort((a, b) => a.nombre.localeCompare(b.nombre));
            setAgencias(response);
        };

        needReload && fetchAgencias();
    }, [needReload, user]);

    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md" my={2}>
                Acceso al sistema
            </Heading>
            <SimpleGrid columns={2}>
                <Text>Activo</Text>
                <Checkbox
                    size="lg"
                    name="activo"
                    value={formData.activo}
                    onChange={handleChange}
                    isChecked={formData.activo}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
            {user?.rol === "Gerente" && (
                <SimpleGrid columns={2}>
                    <Text>Rol/Función</Text>
                    <Select
                        borderLeft="3px solid red"
                        placeholder="-- Ninguno --"
                        name="rol"
                        value={formData.rol}
                        onChange={handleChange}
                        isDisabled={!handleChange}>
                        <option value="Asesor">Asesor/a</option>
                        <option value="Responsable">Responsable</option>
                        <option value="Gerente">Gerente</option>
                    </Select>
                </SimpleGrid>
            )}
            <SimpleGrid columns={2}>
                <Text>Agencia</Text>
                <Select
                    borderLeft="3px solid red"
                    placeholder="-- Ninguno --"
                    name="agenciaUuid"
                    value={formData.agenciaUuid}
                    onChange={handleChange}
                    isDisabled={!handleChange || user?.rol !== "Gerente"}>
                    {agencias.map((agencia) => (
                        <option key={agencia.uuid} value={agencia.uuid}>
                            {agencia.nombre}
                        </option>
                    ))}
                </Select>
            </SimpleGrid>

            {formData.fecha_desactivacion && (
                <>
                    <SimpleGrid columns={2}>
                        <Text>Fecha desactivación</Text>
                        <EasyDatePickerWithTime
                            name="fecha_desactivacion"
                            value={formData.fecha_desactivacion}
                            handleChange={handleChange}
                            isDisabled={!handleChange}
                        />
                    </SimpleGrid>

                    <SimpleGrid columns={2}>
                        <Text>Motivo fin</Text>
                        <Input
                            name="motivo_fin"
                            value={formData.motivo_fin}
                            onChange={handleChange}
                            isDisabled={!handleChange}
                        />
                    </SimpleGrid>
                </>
            )}
        </Stack>
    );
};

export default AccesoSistema;
