import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Center,
    Heading,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from "@chakra-ui/react";
import { getActividadesByInmueble } from "../../services/actividades";
import BusquedaModal from "../Modals/Actividades/BusquedaModal";
import EasyDatePicker from "../EasyDatePicker/EasyDatePicker";
import MultiActividad from "../MultiActividad/MultiActividad";
import { useSelector } from "react-redux";
import PopoverDescripcion from "./PopoverDescripcion";

const TablaActividadesInmueble = ({ datos }) => {
    const [actividades, setActividades] = useState([]);
    const [datosRecibidos, setDatosRecibidos] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [needReload, setNeedReload] = useState(true);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenMultiActividad,
        onOpen: onOpenMultiActividad,
        onClose: onCloseMultiActividad,
    } = useDisclosure();

    const { user } = useSelector((state) => state.auth);

    const { uuid: inmueble } = datos;

    useEffect(() => {
        const getActividades = async () => {
            setNeedReload(false);
            let response = await getActividadesByInmueble(inmueble);
            // if (user?.rol !== "Gerente") {
            //     response = response.filter((actividad) => actividad.agenciaUuid === user.agencia?.uuid);
            // }
            setActividades(response);
        };
        needReload && user !== null && getActividades();
    }, [inmueble, user, needReload]);

    const handleOpenActividad = (actividad) => {
        setIsEdit(true);
        setDatosRecibidos({
            ...actividad,
            ubicacion: datos.ubicacion,
            tipo_inmueble: datos.tipo_inmueble,
            subtipo_inmueble: datos.subtipo_inmueble,
            cliente: datos.cliente,
        });
        onOpenMultiActividad();
    };

    const handleNuevaActividad = () => {
        setIsEdit(false);
        setDatosRecibidos({
            inmueble: datos,
            cliente: datos.cliente,
        });
        onOpen();
    };

    return (
        <>
            <Box p={8} border="1px" rounded="md" shadow="dark-lg">
                <Heading textAlign="center" mb={4}>
                    Actividades
                </Heading>
                <Center>
                    <Button rounded="none" colorScheme="facebook" w="100%" onClick={handleNuevaActividad}>
                        Llamada de búsqueda
                    </Button>
                </Center>
                <TableContainer maxH="40rem" overflowY="auto">
                    <Table size='sm'>
                        <Thead>
                            <Tr bgColor="facebook.50">
                                <Th>Tipo de actividad</Th>
                                <Th>Entidad relacionada</Th>
                                <Th>Modalidad contacto</Th>
                                <Th>Estado actividad</Th>
                                <Th>Fecha actividad</Th>
                                <Th>Creación</Th>
                                <Th>Modificación</Th>
                                <Th>Vencimiento</Th>
                                <Th>Descripción</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {actividades && actividades.length === 0 && (
                                <Tr>
                                    <Td colSpan={7} textAlign="center" fontWeight="bolder">
                                        No hay actividades
                                    </Td>
                                </Tr>
                            )}
                            {actividades &&
                                actividades.length > 0 &&
                                actividades.map((actividad) => (
                                    <Tr key={actividad.uuid} _hover={{ cursor: "pointer", bg: "gray.100" }}>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            ACT - {actividad.tipo_actividad}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.entidad_relacionada}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.modalidad_contacto}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.estado_actividad}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            <EasyDatePicker value={actividad.fecha_actividad} />
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            <EasyDatePicker value={actividad.createdAt} needHour={true} />
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            <EasyDatePicker value={actividad.updatedAt} needHour={true} />
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.fecha_vencimiento ? (
                                                <EasyDatePicker value={actividad.fecha_vencimiento} needHour={true} />
                                            ) : (
                                                <EasyDatePicker value={actividad.fecha_finalizacion} needHour={true} />
                                            )}
                                        </Td>
                                        <Td>
                                            <PopoverDescripcion descripcion={actividad.descripcion} />
                                        </Td>
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
            {isOpen && <BusquedaModal isOpen={isOpen} onClose={onClose} datos={datosRecibidos} isEdit={isEdit} />}
            {isOpenMultiActividad && (
                <MultiActividad
                    isOpen={isOpenMultiActividad}
                    onClose={onCloseMultiActividad}
                    datos={datosRecibidos}
                    isEdit={isEdit}
                />
            )}
        </>
    );
};

export default TablaActividadesInmueble;
