export const defaultUsuarioData = {
    uuid: undefined,
    nombre: undefined,
    apellidos: undefined,
    fecha_nacimiento: undefined,
    sexo: undefined,
    tipo_identificador: undefined,
    identificador: undefined,
    telefono_trabajo: undefined,
    telefono_personal: undefined,
    email: undefined,
    nivel_estudios: undefined,
    fecha_inicio: new Date(),
    permiso_informes: undefined,
    permiso_impresiones: undefined,
    permiso_busquedas: undefined,
    direccion: {},
    activo: true,
    rol: undefined,
    fecha_desactivacion: undefined,
    motivo_fin: undefined,
};