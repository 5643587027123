import { Box, Heading, SimpleGrid, Stack } from "@chakra-ui/react";
import InfoBox from "./InfoBox";
import HeaderBox from "./HeaderBox";

const Trabajadores = ({ trabajador }) => {
    const ObjectBox = ({ children }) => (
        <Stack shadow="lg" p={4} rounded="md" _hover={{ shadow: "2xl" }}>
            {children}
        </Stack>
    );

    return (
        <Box>
            <Box p={4} rounded="md" bgColor="blackAlpha.50">
                <Heading p={4} fontSize={"xl"}>
                    Estadísticas personales de: {`${trabajador.nombre} ${trabajador.apellidos}`}
                </Heading>

                <Stack spacing={4}>
                    <ObjectBox>
                        <InfoBox nombre="Pedidos" dato={trabajador.pedidos.total} />

                        {Object.keys(trabajador.pedidos.estados).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Estados" />
                                {Object.keys(trabajador.pedidos.estados).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.pedidos.estados[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(trabajador.pedidos.tipo_pedido).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Tipos de pedido" />
                                {Object.keys(trabajador.pedidos.tipo_pedido).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.pedidos.tipo_pedido[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(trabajador.pedidos.tipo_inmueble).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Tipos de inmueble" />
                                {Object.keys(trabajador.pedidos.tipo_inmueble).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.pedidos.tipo_inmueble[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(trabajador.pedidos.motivo_cierre).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Motivos de cierre" />
                                {Object.keys(trabajador.pedidos.motivo_cierre).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.pedidos.motivo_cierre[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(trabajador.pedidos.contrato).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Contratos" />
                                {Object.keys(trabajador.pedidos.contrato).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.pedidos.contrato[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}
                    </ObjectBox>

                    <ObjectBox>
                        <InfoBox nombre="Encargos" dato={trabajador.encargos?.total} />

                        {Object.keys(trabajador.encargos.estados).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Estados" />
                                {Object.keys(trabajador.encargos.estados).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.encargos.estados[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(trabajador.encargos.contrato).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Contratos" />
                                {Object.keys(trabajador.encargos.contrato).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.encargos.contrato[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}
                    </ObjectBox>

                    <ObjectBox>
                        <InfoBox nombre="Noticias" dato={trabajador.noticias.total} />

                        {Object.keys(trabajador.noticias.estados).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Estados" />
                                {Object.keys(trabajador.noticias.estados).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.noticias.estados[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(trabajador.noticias.motivacion).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Motivaciones" />
                                {Object.keys(trabajador.noticias.motivacion).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.noticias.motivacion[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(trabajador.noticias.procedencia).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Procedencias" />
                                {Object.keys(trabajador.noticias.procedencia).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.noticias.procedencia[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}
                    </ObjectBox>

                    <ObjectBox>
                        <InfoBox nombre="Actividades" dato={trabajador.actividades.total} />

                        {Object.keys(trabajador.actividades.estados).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Estados" />
                                {Object.keys(trabajador.actividades.estados).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.actividades.estados[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(trabajador.actividades.tipo_actividad).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Tipos de actividad" />
                                {Object.keys(trabajador.actividades.tipo_actividad).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.actividades.tipo_actividad[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(trabajador.actividades.entidades).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Entidades" />
                                {Object.keys(trabajador.actividades.entidades).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.actividades.entidades[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(trabajador.actividades.modalidad_contacto).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Modalidades de contacto" />
                                {Object.keys(trabajador.actividades.modalidad_contacto).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.actividades.modalidad_contacto[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}
                    </ObjectBox>

                    <ObjectBox>
                        <InfoBox nombre="Citas" dato={trabajador.citas.total} />

                        {Object.keys(trabajador.citas.estados).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Estados" />
                                {Object.keys(trabajador.citas.estados).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.citas.estados[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}

                        {Object.keys(trabajador.citas.tipos).length > 0 && (
                            <SimpleGrid minChildWidth="80px" spacing={4} mt={6}>
                                <HeaderBox nombre="Tipos de cita" />
                                {Object.keys(trabajador.citas.tipos).map((key) => (
                                    <InfoBox nombre={key} dato={trabajador.citas.tipos[key]} boxStyle={1} />
                                ))}
                            </SimpleGrid>
                        )}
                    </ObjectBox>
                </Stack>
            </Box>
        </Box>
    );
};

export default Trabajadores;
