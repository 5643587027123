import {
    Box,
    Button,
    Center,
    Collapse,
    Flex,
    IconButton,
    Spacer,
    Spinner,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useToast,
} from "@chakra-ui/react";

import ImageGallery from "react-image-gallery";
import Dropzone from "react-dropzone";

import { AddIcon, CloseIcon, RepeatClockIcon } from "@chakra-ui/icons";
import { BsEye } from "react-icons/bs";
import { S3_ROUTE } from "../../../data/constants";
import { useState } from "react";
import { uploadInmuebleFile } from "../../../services/inmuebles";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";
import { useSelector } from "react-redux";

const ImagenesInmueble = ({ imagenes, galleryImages, formData, refreshImagenes, fileLoading }) => {

    const toast = useToast();
    const { user } = useSelector((state) => state.auth);

    const [file, setFile] = useState(null);
    const [mostrarSubirDocumento, setMostrarSubirDocumento] = useState(false);

    const handleFileSelect = (acceptedFiles) => {
        setFile(acceptedFiles[0]);
        try {
            // Leer el archivo
            const reader = new FileReader();
            reader.readAsDataURL(acceptedFiles[0]);
            reader.onload = async () => {
                const datos = reader.result.split(",")[1];
                // Enviar el archivo al backend
                await uploadInmuebleFile(formData.uuid, acceptedFiles[0].name, datos);
            };
            setMostrarSubirDocumento(false);
            okNotification("Documento subido correctamente", toast);
            // Wait 500ms to refresh images
            setTimeout(() => {
                refreshImagenes();
            }, 1500);
        } catch (err) {
            console.error(err);
            errorNotification("Error al subir el documento", toast);
        } finally {
            setFile(null);
        }
    };

    return (
        <Center>
            <Stack direction="row" spacing={4} w="100%">
                {imagenes.length > 0 && (
                    <Box w="55%">
                        <ImageGallery
                            showBullets={true}
                            showIndex={true}
                            showNav={true}
                            infinite={true}
                            showPlayButton={false}
                            lazyLoad={true}
                            items={galleryImages}
                        />
                    </Box>
                )}
                <Box w={imagenes.length > 0 ? "45%" : "100%"}>
                    <Flex gap={4}>
                        <Text fontWeight="bold" fontSize="2xl" color="facebook.800">
                            IMÁGENES DEL INMUEBLE
                        </Text>
                        <Spacer />
                        <Button rounded="none" colorScheme={"facebook"} onClick={refreshImagenes}>
                            <RepeatClockIcon />
                        </Button>
                        <Button
                            rounded="none"
                            isDisabled={fileLoading}
                            colorScheme={mostrarSubirDocumento ? "red" : "facebook"}
                            onClick={() => setMostrarSubirDocumento(!mostrarSubirDocumento)}>
                            {mostrarSubirDocumento ? <CloseIcon /> : <AddIcon />}
                        </Button>
                    </Flex>
                    <Collapse in={mostrarSubirDocumento} animateOpacity>
                        <Center p={4} my={4} border="1px solid black" bgColor="blackAlpha.100" rounded="md">
                            <Dropzone onDrop={handleFileSelect}>
                                {({ getRootProps, getInputProps }) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>
                                            <strong>Arrastra y suelta</strong> un archivo aquí o{" "}
                                            <strong>haz clic para seleccionar uno.</strong>
                                        </p>
                                        {file && <p>{file.name}</p>}
                                    </div>
                                )}
                            </Dropzone>
                        </Center>
                    </Collapse>
                    <Box border="1px solid" rounded="md" p={4} my={4} w="100%" shadow="lg">
                        {fileLoading ? (
                            <Box>
                                <Center>
                                    <Spinner />
                                </Center>
                                <Text textAlign="center">Cargando imágenes...</Text>
                            </Box>
                        ) : (
                            <Box>
                                {imagenes.length > 0 ? (
                                    <TableContainer maxH="40rem" overflowY="auto">
                                        <Table>
                                            <Thead>
                                                <Tr>
                                                    <Th>Nombre</Th>
                                                    <Th>Fecha subida</Th>
                                                    <Th>Acciones</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {imagenes.map((imagen) => {
                                                    // Check if nombre is more than 50 characters
                                                    const esLargo = imagen.nombre.toString().length > 50;
                                                    const nombre = esLargo
                                                        ? imagen.nombre.toString().slice(0, 50) + "..."
                                                        : imagen.nombre.toString();

                                                    return (
                                                        <Tr>
                                                            <Td>{nombre}</Td>
                                                            <Td>{new Date(imagen.fecha).toLocaleDateString()}</Td>
                                                            <Td>
                                                                <Flex gap={2}>
                                                                    {user && user.rol === "Gerente" && (
                                                                        <IconButton icon={<CloseIcon />} rounded="none" colorScheme="red" />
                                                                    )}

                                                                    <IconButton
                                                                        icon={<BsEye />}
                                                                        rounded="none"
                                                                        colorScheme="facebook"
                                                                        onClick={() =>
                                                                            window.open(
                                                                                `${S3_ROUTE}/inmuebles/${formData.uuid}/${imagen.nombre}`,
                                                                                "_blank",
                                                                                "noopener,noreferrer"
                                                                            )
                                                                        }
                                                                    />
                                                                </Flex>
                                                            </Td>
                                                        </Tr>
                                                    );
                                                })}
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Text>No hay imágenes para este inmueble.</Text>
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Stack>
        </Center>
    );
};

export default ImagenesInmueble;