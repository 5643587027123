import {
    Heading,
    Input,
    NumberInput,
    NumberInputField,
    Select,
    SimpleGrid,
    Stack,
    Text,
    Textarea,
} from "@chakra-ui/react";

const DetalleInmueble = ({ formData, handleChange }) => {

    let superficie;

    if (formData.superficie) {
        try {
            superficie = JSON.parse(formData.superficie);
        } catch (error) {
            superficie = JSON.parse(JSON.stringify(formData.superficie));
        }
    }

    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Detalle inmueble</Heading>

            <SimpleGrid columns={2}>
                <Text>Localidad</Text>
                <Input
                    borderLeft="3px solid red"
                    name="localidad"
                    value={formData.localidad}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Provincia</Text>
                <Select
                    borderLeft="3px solid red"
                    name="provincia"
                    value={formData.provincia}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                >
                    <option value="alava">Álava</option>
                    <option value="albacete">Albacete</option>
                    <option value="alicante">Alicante</option>
                    <option value="almeria">Almería</option>
                    <option value="asturias">Asturias</option>
                    <option value="avila">Ávila</option>
                    <option value="badajoz">Badajoz</option>
                    <option value="barcelona">Barcelona</option>
                    <option value="burgos">Burgos</option>
                    <option value="caceres">Cáceres</option>
                    <option value="cadiz">Cádiz</option>
                    <option value="cantabria">Cantabria</option>
                    <option value="castellon">Castellón</option>
                    <option value="ceuta">Ceuta</option>
                    <option value="ciudadreal">Ciudad Real</option>
                    <option value="cordoba">Córdoba</option>
                    <option value="cuenca">Cuenca</option>
                    <option value="girona">Girona</option>
                    <option value="laspalmas">Las Palmas</option>
                    <option value="granada">Granada</option>
                    <option value="guadalajara">Guadalajara</option>
                    <option value="guipuzcoa">Guipúzcoa</option>
                    <option value="huelva">Huelva</option>
                    <option value="huesca">Huesca</option>
                    <option value="illesbalears">Illes Balears</option>
                    <option value="jaen">Jaén</option>
                    <option value="acoruña">A Coruña</option>
                    <option value="larioja">La Rioja</option>
                    <option value="leon">León</option>
                    <option value="lleida">Lleida</option>
                    <option value="lugo">Lugo</option>
                    <option value="madrid">Madrid</option>
                    <option value="malaga">Málaga</option>
                    <option value="melilla">Melilla</option>
                    <option value="murcia">Murcia</option>
                    <option value="navarra">Navarra</option>
                    <option value="ourense">Ourense</option>
                    <option value="palencia">Palencia</option>
                    <option value="pontevedra">Pontevedra</option>
                    <option value="salamanca">Salamanca</option>
                    <option value="segovia">Segovia</option>
                    <option value="sevilla">Sevilla</option>
                    <option value="soria">Soria</option>
                    <option value="tarragona">Tarragona</option>
                    <option value="santacruztenerife">Santa Cruz de Tenerife</option>
                    <option value="teruel">Teruel</option>
                    <option value="toledo">Toledo</option>
                    <option value="valencia">Valencia</option>
                    <option value="valladolid">Valladolid</option>
                    <option value="vizcaya">Vizcaya</option>
                    <option value="zamora">Zamora</option>
                    <option value="zaragoza">Zaragoza</option>
                </Select>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Codigo postal</Text>
                <Input
                    borderLeft="3px solid red"
                    name="codigo_postal"
                    value={formData.codigo_postal}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>M2 útiles</Text>
                <NumberInput value={superficie?.util || undefined} isDisabled={handleChange == null}>
                    <NumberInputField name="superficie.util" onChange={handleChange} />
                </NumberInput>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>M2 construidos</Text>
                <NumberInput value={superficie?.construida || undefined} isDisabled={handleChange == null}>
                    <NumberInputField name="superficie.construida" onChange={handleChange} />
                </NumberInput>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Parcela</Text>
                <NumberInput value={superficie?.parcela || undefined} isDisabled={handleChange == null}>
                    <NumberInputField name="superficie.parcela" onChange={handleChange} />
                </NumberInput>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Cocina</Text>
                <Select
                    name="cocina"
                    value={formData.cocina}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                    placeholder="-- Ninguna --"
                >
                    <option value="Americana">Americana</option>
                    <option value="Independiente">Independiente</option>
                    <option value="Sin cocina">Sin cocina</option>
                </Select>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Habitaciones</Text>
                <NumberInput value={formData.habitaciones || undefined} isDisabled={handleChange == null}>
                    <NumberInputField name="habitaciones" onChange={handleChange} />
                </NumberInput>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Baños</Text>
                <NumberInput value={formData.bathrooms || undefined} isDisabled={handleChange == null}>
                    <NumberInputField name="bathrooms" onChange={handleChange} />
                </NumberInput>
            </SimpleGrid>

            <Text>Notas</Text>
            <Textarea
                name="observaciones_notas"
                value={formData.observaciones_notas}
                onChange={handleChange}
                isDisabled={handleChange == null}
            />
        </Stack>
    );
};

export default DetalleInmueble;
