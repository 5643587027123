import { SimpleGrid, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Clientes = ({ formData }) => {
    const navigate = useNavigate();

    return (
        <SimpleGrid columns={2}>
            <Text>Cliente</Text>
            <Text fontWeight="bold" onClick={() => navigate(`/clientes/ver/${formData.cliente.uuid}`)} _hover={{ cursor: "pointer", textDecoration: "underline" }}>
                {formData.cliente.nombre} {formData.cliente.apellidos}
            </Text>
        </SimpleGrid>
    );
};

export default Clientes;
