import { Heading, Select, SimpleGrid, Stack, Text } from "@chakra-ui/react";

const OtrasInformaciones = ({ formData, handleChange }) => {
    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Otras informaciones</Heading>
            <SimpleGrid columns={2}>
                <Text>Exigencia pedido</Text>
                <Select
                    name="tipo_pedido"
                    value={formData.tipo_pedido}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                    placeholder="-- Ninguno --"
                >
                    <option value="Primera vivienda">Primera vivienda</option>
                    <option value="Inversion">Inversion</option>
                    <option value="Vacacional">Vacacional</option>
                </Select>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Estado del inmueble</Text>
                <Select
                    name="estado_inmueble"
                    value={formData.estado_inmueble}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                    placeholder="-- Ninguno --"
                >
                    <option value="Nuevo">Nuevo</option>
                    <option value="A reformar">A reformar</option>
                    <option value="Reformado">Reformado</option>
                    <option value="Indiferente">Indiferente</option>
                </Select>
            </SimpleGrid>
        </Stack>
    );
};

export default OtrasInformaciones;
