import { memo, useEffect } from "react";

// Components
import Header from "../Navegacion/Header";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMe } from "../../features/auth/authSlice";
import { Box } from "@chakra-ui/react";

const Layout = memo(({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isError } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(getMe());
    }, [dispatch]);

    useEffect(() => {
        isError && navigate("/");
    }, [isError, navigate]);

    return (
        <Box>
            <Header />
            <Box p={8} mt={4}>
                {children}
            </Box>
        </Box>
    );
});

export default Layout;
