import { useEffect, useState } from "react";
import { Box, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { getActividadesByCliente } from "../../services/actividades";
import MultiActividad from "../MultiActividad/MultiActividad";
import EasyDatePicker from "../EasyDatePicker/EasyDatePicker";
import { useSelector } from "react-redux";
import PopoverDescripcion from "./PopoverDescripcion";

const TablaActividadesCliente = ({ cliente }) => {
    const [actividades, setActividades] = useState([]);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [datosRecibidos, setDatosRecibidos] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [needReload, setNeedReload] = useState(true);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        const getActividades = async () => {
            setNeedReload(false);
            const response = await getActividadesByCliente(cliente);
            // if (user?.rol !== "Gerente") {
            //     response = response.filter((actividad) => actividad.agenciaUuid === user.agencia?.uuid);
            // }
            setActividades(response);
        };
        needReload && cliente && user !== null && getActividades();
    }, [needReload, cliente, user]);

    const handleOpenActividad = (actividad) => {
        setIsEdit(true);
        setDatosRecibidos(actividad);
        onOpen();
    };

    return (
        <>
            <Box p={8} border="1px" rounded="md" shadow="dark-lg">
                <Heading textAlign="center" mb={4}>
                    Actividades
                </Heading>
                <TableContainer maxH="40rem" overflowY="auto">
                    <Table size='sm'>
                        <Thead>
                            <Tr bgColor="facebook.50">
                                <Th>Tipo de actividad</Th>
                                <Th>Entidad relacionada</Th>
                                <Th>Modalidad contacto</Th>
                                <Th>Estado actividad</Th>
                                <Th>Fecha actividad</Th>
                                <Th>Creación</Th>
                                <Th>Modificación</Th>
                                <Th>Vencimiento</Th>
                                <Th>Descripción</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {actividades && actividades.length === 0 && (
                                <Tr>
                                    <Td colSpan={7} textAlign="center" fontWeight="bolder">
                                        No hay actividades
                                    </Td>
                                </Tr>
                            )}
                            {actividades &&
                                actividades.length > 0 &&
                                actividades.map((actividad) => (
                                    <Tr key={actividad.uuid} _hover={{ cursor: "pointer", bg: "gray.100" }}>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            ACT - {actividad.tipo_actividad}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.entidad_relacionada}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.modalidad_contacto}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.estado_actividad}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            <EasyDatePicker value={actividad.fecha_actividad} />
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            <EasyDatePicker value={actividad.createdAt} needHour={true} />
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            <EasyDatePicker value={actividad.updatedAt} needHour={true} />
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.fecha_vencimiento ? (
                                                <EasyDatePicker value={actividad.fecha_vencimiento} needHour={true} />
                                            ) : (
                                                <EasyDatePicker value={actividad.fecha_finalizacion} needHour={true} />
                                            )}
                                        </Td>
                                        <Td>
                                            <PopoverDescripcion descripcion={actividad.descripcion} />
                                        </Td>
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
            {isOpen && <MultiActividad isOpen={isOpen} onClose={onClose} datos={datosRecibidos} isEdit={isEdit} />}
        </>
    );
};

export default TablaActividadesCliente;
