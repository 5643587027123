import { Box, Heading } from "@chakra-ui/react";
import BoxPedido from "./BoxPedido";

const PedidosSeleccionados = ({ pedidosSeleccionados, cruces }) => {
    return (
        <Box my={4}>
            <Box bgColor="yellow.100" rounded="md" p={4} mb={2}>
                <Heading textAlign="center" mb={2} fontSize="xl">
                    Seleccionados
                </Heading>
                {pedidosSeleccionados.map((encargo) => {
                    const cruce = cruces.find((cruce) => cruce.uuid === encargo.uuid);
                    return <BoxPedido key={cruce.uuid} pedido={cruce} />;
                })}
            </Box>
        </Box>
    );
};

export default PedidosSeleccionados;
