import axios from "axios";

export const getAllDocumentos = async (id) => {
    try {
        const response = await axios.get(`/documentacion`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.log("🚀 ~ file: documentacion.js:8 ~ getAllDocumentos ~ error:", error);
        throw error.response;
    }
};
