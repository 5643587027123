import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
    Center,
    Text,
} from "@chakra-ui/react";
import { TIPOS_CITAS } from "../../data/constants";
import { useSelector } from "react-redux";

const NuevaCitaGeneral = ({ isOpen, handleCancel, modal, formData, setFormData }) => {
    const { user } = useSelector((state) => state.auth);
    return (
        <Modal isOpen={isOpen} onClose={handleCancel} size={"3xl"} isCentered>
            <ModalOverlay bgColor="blackAlpha.800" />
            <ModalContent>
                <ModalHeader>Nueva cita</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text textAlign="center" fontWeight="bold" my={4}>
                        El resto de citas se tendrán que generar con procesos guiados.
                    </Text>
                    <SimpleGrid columns={[2, null, 3]} spacing={1}>
                        {TIPOS_CITAS.map((tipo, index) => {
                            if (tipo.isHidden) return null;
                            return (
                                <Box
                                    key={index}
                                    _hover={{ cursor: "pointer", border: "4px", borderColor: "blue.500", shadow: "xl" }}
                                    border="1px"
                                    borderColor="blackAlpha.400"
                                    shadow="md"
                                    height="6rem"
                                    rounded="md"
                                    bgColor={tipo.color}
                                    color={tipo.isWhite ? "white" : "black"}
                                    onClick={() => {
                                        modal.onOpen();
                                        handleCancel();
                                        setFormData({
                                            ...formData,
                                            tipo: tipo.tipo,
                                            color: tipo.color,
                                            responsableUuid: formData.responsableUuid || user.uuid,
                                        });
                                    }}>
                                    <Center height="100%">
                                        <Text fontWeight="bold">{tipo.texto}</Text>
                                    </Center>
                                </Box>
                            );
                        })}
                    </SimpleGrid>
                </ModalBody>

                <ModalFooter></ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default NuevaCitaGeneral;
