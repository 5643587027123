import {
    Box,
    NumberInput,
    NumberInputField,
    SimpleGrid,
    Stack,
    Text,
    Textarea,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const InfoEncargo = ({ encargo, handleChange }) => {
    const navigate = useNavigate()

    let ubicacion
    try {
        ubicacion = JSON.parse(encargo.inmueble.ubicacion)
    } catch (error) {
        ubicacion = JSON.parse(JSON.stringify(encargo.inmueble.ubicacion))
    }
    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <SimpleGrid columns={2}>
                <Text>Cliente</Text>
                <Text
                    onClick={() =>
                        navigate(`/clientes/ver/${encargo.cliente.uuid}`)
                    }
                    _hover={{ cursor: 'pointer' }}
                    fontWeight="bold"
                    color="green">
                    {encargo.cliente?.nombre} {encargo.cliente?.apellidos}
                </Text>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Inmueble</Text>
                <Text
                    onClick={() =>
                        navigate(`/inmuebles/ver/${encargo.inmueble.uuid}`)
                    }
                    _hover={{ cursor: 'pointer' }}
                    fontWeight="bold"
                    color="green">
                    {ubicacion.direccion}
                </Text>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Noticia</Text>
                <Text
                    onClick={() =>
                        navigate(`/noticias/ver/${encargo.noticia.uuid}`)
                    }
                    _hover={{ cursor: 'pointer' }}
                    fontWeight="bold"
                    color="green">
                    Click para ver noticia
                </Text>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Motivación</Text>
                <Text fontWeight="bold">{encargo.noticia?.motivacion}</Text>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Tiene hipoteca</Text>
                <Text fontWeight="bold">
                    {encargo.tieneHipoteca ? encargo.hipoteca : 'Sin hipoteca.'}
                </Text>
            </SimpleGrid>
            <SimpleGrid columns={encargo.tieneDeudas ? 1 : 2}>
                <Text>Tiene deudas</Text>
                {encargo.tieneDeudas ? (
                    <Textarea
                        w="100%"
                        border="1px solid black"
                        fontWeight="bold">
                        {encargo.deudas}
                    </Textarea>
                ) : (
                    <Text fontWeight="bold">Sin deudas.</Text>
                )}
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Precio encargo</Text>
                {handleChange == null ? (
                    <Text fontWeight="bold">
                        {new Intl.NumberFormat().format(encargo.precio_final)} €
                    </Text>
                ) : (
                    <NumberInput
                        value={encargo.precio_final}
                        isDisabled={!handleChange}>
                        <NumberInputField
                            borderLeft="3px solid red"
                            name="precio_final"
                            onChange={handleChange}
                        />
                    </NumberInput>
                )}
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Precio coste vivienda</Text>
                {handleChange == null ? (
                    <Text fontWeight="bold">
                        {new Intl.NumberFormat().format(encargo.costeVivienda)}{' '}
                        €
                    </Text>
                ) : (
                    <NumberInput
                        value={encargo.costeVivienda}
                        isDisabled={!handleChange}>
                        <NumberInputField
                            borderLeft="3px solid red"
                            name="costeVivienda"
                            onChange={handleChange}
                        />
                    </NumberInput>
                )}
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Valoración</Text>
                {handleChange == null ? (
                    // Formateo de número con separador de miles
                    <Text fontWeight="bold">
                        {new Intl.NumberFormat().format(
                            encargo.inmueble?.valoracion
                        )}{' '}
                        €
                    </Text>
                ) : (
                    <NumberInput
                        value={encargo.inmueble?.valoracion}
                        isDisabled>
                        <NumberInputField
                            borderLeft="3px solid red"
                            name="valoracion"
                            onChange={handleChange}
                        />
                    </NumberInput>
                )}
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Comisión</Text>
                {handleChange == null ? (
                    <Text fontWeight="bold">
                        {new Intl.NumberFormat().format(encargo.comision)} €
                    </Text>
                ) : (
                    <NumberInput
                        value={encargo.comision}
                        isDisabled={!handleChange}>
                        <NumberInputField
                            borderLeft="3px solid red"
                            name="comision"
                            onChange={handleChange}
                        />
                    </NumberInput>
                )}
            </SimpleGrid>
            <Box mt={8}>
                <Text>
                    Nota privada
                </Text>
                <Textarea
                    name="nota_privada"
                    value={encargo.nota_privada}
                    onChange={handleChange}
                />
            </Box>
        </Stack>
    )
}

export default InfoEncargo
