import { Button, SimpleGrid, Stack, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { updateAgencia } from "../../services/agencias";
import { createAgencia } from "../../services/agencias";
import { errorNotification, okNotification } from "../../utils/Notificaciones";
import InfoGeneral from "./CRUD/InfoGeneral";

const GridInfoAgencia = ({ formData, handleChange, isEdit = false }) => {
    const navigate = useNavigate();
    const toast = useToast();

    const handleSave = async () => {
        let agencia;
        try {
            if (isEdit) {
                agencia = await updateAgencia(formData.uuid, formData);
                okNotification("Agencia actualizada correctamente", toast);
                navigate(`/agencias/ver/${agencia.uuid}`);
            } else {
                agencia = await createAgencia(formData);
                okNotification("Agencia creada correctamente", toast);
                navigate(`/agencias/ver/${agencia.uuid}`);
            }
        } catch (error) {
            console.error("🚀 ~ file: GridInfoAgencia.js:46 ~ handleSave ~ error:", error);
            errorNotification("Error al realizar la operación.", toast);
        }
    };

    return (
        <>
            <Stack>
                <SimpleGrid columns={1} spacing={4}>
                    <InfoGeneral formData={formData} handleChange={handleChange} />
                    <Stack direction="row">
                        {handleChange == null && (
                            <>
                                <Button
                                    w="100%"
                                    colorScheme="facebook"
                                    size="sm"
                                    mt={4}
                                    onClick={() => navigate(`/agencias/editar/${formData.uuid}`)}>
                                    Editar agencia
                                </Button>

                                <Button w="100%" colorScheme="blue" size="sm" mt={4} onClick={() => navigate(-1)}>
                                    Volver
                                </Button>
                            </>
                        )}
                    </Stack>
                    {handleChange != null && (
                        <Stack direction="row" spacing={4} align="center">
                            <Button
                                w="100%"
                                colorScheme="green"
                                size="sm"
                                mt={4}
                                onClick={handleSave}
                                isDisabled={!formData.nombre}>
                                Guardar
                            </Button>
                            <Button w="100%" colorScheme="facebook" size="sm" mt={4} onClick={() => navigate(-1)}>
                                Volver
                            </Button>
                        </Stack>
                    )}
                </SimpleGrid>
            </Stack>
        </>
    );
};

export default GridInfoAgencia;
