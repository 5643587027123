import {
    Button,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    SimpleGrid,
    Stack,
    Text,
    Textarea,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import EasyDatePicker from "../../EasyDatePicker/EasyDatePicker";
import { useEffect, useState } from "react";
import { getAllUsuarios } from "../../../services/usuarios";
import { crearActividad, updateActividad } from "../../../services/actividades";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";
import BusquedaProcesoGuiado from "./BusquedaProcesoGuiado";
import { useSelector } from "react-redux";

const BusquedaModal = ({ isOpen, onClose, datos, isEdit }) => {
    const [responsables, setResponsables] = useState([]);
    const [needReload, setNeedReload] = useState(true);
    const [formData, setFormData] = useState(datos);
    const toast = useToast();

    const [datosEnviados, setDatosEnviados] = useState({});

    const { isOpen: isOpenProcesoGuiado, onOpen: onOpenProcesoGuiado, onClose: onCloseProcesoGuiado } = useDisclosure();
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        const getResponsables = async () => {
            const auxResponsables = await getAllUsuarios();
            setResponsables(auxResponsables);
            if (!isEdit) {
                setFormData({
                    ...formData,
                    responsableUuid: user.uuid,
                    estado_actividad: "Programada",
                    modalidad_contacto: "Llamada",
                });
            }
            setNeedReload(false);
        };

        needReload && user && getResponsables();
    }, [formData, needReload, isEdit, user]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const getDataForSend = () => {
        return {
            entidad_relacionada: "Inmueble",
            tipo_actividad: "Busqueda",
            modalidad_contacto: formData.modalidad_contacto,
            fecha_vencimiento: formData.fecha_vencimiento,
            fecha_actividad: formData.fecha_actividad,
            fecha_finalizacion: formData.estado_actividad === "Realizada" ? new Date() : null,
            estado_actividad: formData.estado_actividad,
            descripcion: formData.descripcion,
            recordatorio: false,
            recordatorio_fecha: null,
            responsableUuid: formData.responsableUuid,
            clienteUuid: datos.cliente.uuid,
            inmuebleUuid: datos.inmueble.uuid,
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const notificationMessage = !isEdit ? "Actividad creada correctamente" : "Actividad actualizada correctamente";
        const errorMessage = "Error al crear la actividad";

        try {
            if (!isEdit) {
                await crearActividad(getDataForSend());
            } else {
                await updateActividad(datos.uuid, {
                    ...formData,
                    fecha_finalizacion: formData.estado_actividad === "Realizada" ? new Date() : undefined,
                });
            }

            okNotification(notificationMessage, toast);
            onClose();
            window.location.reload();
        } catch (error) {
            errorNotification(errorMessage, toast);
            console.error("🚀 ~ file: ActualizacionModal.js:7 ~ ActualizacionModal ~ error", error);
        }
    };

    const handleProcesoGuiado = () => {
        const parseData = getDataForSend();
        setDatosEnviados({
            ...parseData,
            responsables,
            actividadUuid: isEdit ? datos.uuid : null,
        });
        onOpenProcesoGuiado();
    };

    let ubicacion;
    try {
        ubicacion = JSON.parse(datos.inmueble.ubicacion);
    } catch (e) {
        ubicacion = JSON.parse(JSON.stringify(datos.inmueble.ubicacion));
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
                <ModalOverlay bgColor="blackAlpha.800" />
                <ModalContent>
                    <ModalHeader>Llamada de busqueda</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack direction="column" spacing={4}>
                            <SimpleGrid columns={2}>
                                <Text>Cliente</Text>
                                <Input
                                    name="cliente"
                                    value={`${datos.cliente?.nombre} ${datos.cliente?.apellidos}`}
                                    isReadOnly
                                    isDisabled
                                />
                            </SimpleGrid>

                            <SimpleGrid columns={2}>
                                <Text>Entidad relacionada</Text>
                                <Input
                                    name="entidad"
                                    value={`IN - ${datos.inmueble.tipo_inmueble} - ${ubicacion.direccion}`}
                                    isReadOnly
                                    isDisabled
                                />
                            </SimpleGrid>

                            <SimpleGrid columns={2}>
                                <Text>Modalidad contacto</Text>
                                <Select
                                    name="modalidad_contacto"
                                    value={formData.modalidad_contacto}
                                    onChange={handleChange}
                                    isDisabled={datos.fecha_finalizacion}>
                                    <option value="Llamada">Llamada</option>
                                    <option value="Presencial">Presencial</option>
                                </Select>
                            </SimpleGrid>

                            <SimpleGrid columns={2}>
                                <Text>Responsable</Text>
                                <Select
                                    name="responsableUuid"
                                    value={formData.responsableUuid}
                                    onChange={handleChange}
                                    isDisabled={datos.fecha_finalizacion}>
                                    {responsables.map((responsable) => (
                                        <option key={responsable.uuid} value={responsable.uuid}>
                                            {responsable.nombre} {responsable.apellidos}
                                        </option>
                                    ))}
                                </Select>
                            </SimpleGrid>

                            <SimpleGrid columns={2}>
                                <Text>Fecha actividad</Text>
                                <EasyDatePicker
                                    name="fecha_actividad"
                                    value={formData.fecha_actividad || new Date()}
                                    handleChange={datos.fecha_finalizacion ? null : handleChange}
                                />
                            </SimpleGrid>

                            <SimpleGrid columns={2}>
                                <Text>Vencimiento</Text>
                                <EasyDatePicker
                                    name="fecha_vencimiento"
                                    value={formData.fecha_vencimiento || new Date()}
                                    handleChange={datos.fecha_finalizacion ? null : handleChange}
                                />
                            </SimpleGrid>

                            <SimpleGrid columns={2}>
                                <Text>Estado</Text>
                                <Select
                                    name="estado_actividad"
                                    value={formData.estado_actividad}
                                    onChange={handleChange}
                                    isDisabled={datos.fecha_finalizacion}>
                                    <option value="Programada">Programada</option>
                                    <option value="Realizada">Realizada</option>
                                </Select>
                            </SimpleGrid>

                            <Text>Descripción</Text>
                            <Textarea
                                borderLeft="3px solid red"
                                name="descripcion"
                                value={formData.descripcion}
                                onChange={handleChange}
                                isDisabled={datos.fecha_finalizacion}
                            />

                            {datos && !datos.fecha_finalizacion && (
                                <Flex gap={0}>
                                    <Button
                                        rounded="none"
                                        w="100%"
                                        onClick={handleSubmit}
                                        colorScheme="green"
                                        isDisabled={!formData.descripcion}>
                                        {isEdit ? "Editar" : "Crear"}
                                    </Button>
                                    {(formData.estado_actividad === "Realizada" ||
                                        datos.estado_actividad === "Realizada") && (
                                        <Button
                                            w="100%"
                                            rounded="none"
                                            onClick={handleProcesoGuiado}
                                            colorScheme="facebook"
                                            isDisabled={!formData.descripcion}>
                                            Proceso guiado
                                        </Button>
                                    )}
                                </Flex>
                            )}
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
            {isOpenProcesoGuiado && (
                <BusquedaProcesoGuiado
                    isOpen={isOpenProcesoGuiado}
                    onClose={onCloseProcesoGuiado}
                    datos={datosEnviados}
                    onClosePadre={onClose}
                />
            )}
        </>
    );
};

export default BusquedaModal;
