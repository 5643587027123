import { Box, Heading } from "@chakra-ui/react";
import BoxEncargo from "./BoxEncargo";

const EncargosSeleccionados = ({ encargosSeleccionados, cruces }) => {
    return (
        <Box my={4}>
            <Box bgColor="yellow.100" rounded="md" p={4} mb={2}>
                <Heading textAlign="center" mb={2} fontSize="xl">
                    Seleccionados
                </Heading>
                {encargosSeleccionados.map((encargo) => {
                    const cruce = cruces.find((cruce) => cruce.uuid === encargo.uuid);
                    return <BoxEncargo key={cruce.uuid} encargo={cruce} />;
                })}
            </Box>
        </Box>
    );
};

export default EncargosSeleccionados;
