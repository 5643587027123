export const defaultAgenciaData = {
    nombre: undefined,
    direccion: {
        calle: undefined,
        numero: undefined,
        piso: undefined,
        puerta: undefined,
        codigo_postal: undefined,
        localidad: undefined,
        provincia: undefined,
    },
    activada: true,
}
