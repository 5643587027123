import { Box, Heading } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import GridInfoCliente from '../../components/Clientes/GridInfoClientes';
import { getCliente } from '../../services/clientes';
import { defaultClienteData } from '../../data/DefaultData/DefaultClienteData';
import Layout from '../../components/Layout/Layout';

const AddEditCliente = ({ isEdit = false }) => {

    const { id } = useParams();
    const copyDefaultClienteData = _.cloneDeep(defaultClienteData);
    const [cliente, setCliente] = useState(copyDefaultClienteData);
    const [formData, setFormData] = useState(cliente);

    useEffect(() => {
        const getClienteById = async () => {
            const esteCliente = await getCliente(id);
            setCliente(esteCliente);
            setFormData(esteCliente);
        };

        isEdit && getClienteById();
    }, [id, isEdit]);

    const handleChange = event => {
        const { name, value, type, checked } = event.target;
        setFormData(_.set({ ...formData }, name, type === 'checkbox' ? checked : value));
    };

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center">{isEdit ? "Editar" : "Crear"} cliente</Heading>
            </Box>

            {cliente && (
                <GridInfoCliente
                    formData={formData}
                    handleChange={handleChange}
                    isEdit={isEdit}
                />
            )}
        </Layout>
    );
};

export default AddEditCliente;
