export const defaultEventData = {
    uuid: undefined,
    entidad_relacionada: undefined,
    fechaInicio: new Date(),
    // Fecha de fin = fecha de inicio + 30 min
    fechaFin: new Date(new Date().getTime() + 30 * 60 * 1000),
    estado: undefined,
    tipo: undefined,
    confirmacion_cliente: undefined,
    ubicacion: undefined,
    descripcion: undefined,
    color: undefined,
    recordatorio: undefined,
    recordatorio_fecha: undefined,
    responsableUuid: undefined,
    clienteUuid: undefined,
    inmuebleUuid: undefined,
    pedidoUuid: undefined,
};


