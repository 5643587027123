import axios from "axios";

export const getEstadisticas = async (dias) => {
    try {
        const response = await axios.get(`/estadisticas/${dias}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: estadisticas.js:8 ~ getEstadisticas ~ error:", error);
        throw error.response;
    }
};

export const getEstadisticasPorRango = async (data) => {
    console.log("🚀 ~ file: estadisticas.js:14 ~ getEstadisticasPorRango ~ data:", data)
    try {
        const response = await axios.post(`/estadisticas`, data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: estadisticas.js:8 ~ getEstadisticas ~ error:", error);
        throw error.response;
    }
};
