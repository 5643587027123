import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading, Spacer, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getCitasFinancierasByPedido } from "../../services/agenda";
import PopoverDescripcion from "../Actividades/PopoverDescripcion";
import AsesoramientoFinancieroModal from "../Modals/Actividades/AsesoramientoFinancieroModal";

const ListaCistasFinanciacion = ({ datos }) => {
    const [citas, setCitas] = useState([]);
    const [viewDatos, setViewDatos] = useState(datos);
    const [isEdit, setIsEdit] = useState(false);

    const { isOpen: isOpenNuevoAsesoramiento, onOpen: onOpenNuevoAsesoramiento, onClose: onCloseNuevoAsesoramiento } = useDisclosure();

    useEffect(() => {
        const fetchCitas = async () => {
            const request = await getCitasFinancierasByPedido(datos.uuid);
            setCitas(request);
        };
        datos && datos.uuid && fetchCitas();
    }, [datos]);

    const handleNuevoAsesoramiento = () => {
        setViewDatos(datos);
        setIsEdit(false);
        onOpenNuevoAsesoramiento();
    };

    const handleClick = (e) => {
        setViewDatos(e);
        setIsEdit(true);
        onOpenNuevoAsesoramiento();
    };

    return (
        <>
            <Box p={8} border="1px" rounded="md" shadow="dark-lg">
                <Heading textAlign="center" mb={4}>
                    Asesoramientos financieros
                </Heading>
                <Flex mt={-14} mb={4}>
                    <Spacer />
                    <Button rounded="none" colorScheme={"facebook"} onClick={handleNuevoAsesoramiento}>
                        <AddIcon />
                    </Button>
                </Flex>
                {citas && citas.length === 0 && (
                    <Text py={6} fontWeight={"bold"} textAlign={"center"}>
                        No hay asesoramientos financieros para este pedido
                    </Text>
                )}
                {citas && citas.length > 0 && (
                    <TableContainer maxH="40rem" overflowY="auto">
                        <Table size="sm">
                            <Thead>
                                <Tr bgColor="facebook.50">
                                    <Th>Fecha inicio</Th>
                                    <Th>Fecha fin</Th>
                                    <Th>Fecha creación</Th>
                                    <Th>Última actualización</Th>
                                    <Th>Estado</Th>
                                    <Th>Responsable</Th>
                                    <Th>Descripción</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {citas.map((cita) => {
                                    return (
                                        <Tr key={cita.uuid} _hover={{ bgColor: "facebook.50" }}>
                                            <Td onClick={() => handleClick(cita)} cursor="pointer">
                                                {new Date(cita.fechaInicio).toLocaleDateString() +
                                                    " " +
                                                    new Date(cita.fechaInicio).toLocaleTimeString()}
                                            </Td>
                                            <Td onClick={() => handleClick(cita)} cursor="pointer">
                                                {new Date(cita.fechaFin).toLocaleDateString() +
                                                    " " +
                                                    new Date(cita.fechaFin).toLocaleTimeString()}
                                            </Td>
                                            <Td onClick={() => handleClick(cita)} cursor="pointer">
                                                {new Date(cita.createdAt).toLocaleDateString() +
                                                    " " +
                                                    new Date(cita.createdAt).toLocaleTimeString()}
                                            </Td>
                                            <Td onClick={() => handleClick(cita)} cursor="pointer">
                                                {new Date(cita.updatedAt).toLocaleDateString()}{" "}
                                                {new Date(cita.updatedAt).toLocaleTimeString()}
                                            </Td>
                                            <Td onClick={() => handleClick(cita)} cursor="pointer">
                                                {cita.estado}
                                            </Td>
                                            <Td onClick={() => handleClick(cita)} cursor="pointer">
                                                {cita.responsable?.nombre + " " + cita.responsable?.apellidos}
                                            </Td>
                                            <Td>
                                                <PopoverDescripcion descripcion={cita.descripcion} />
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>
                    </TableContainer>
                )}
            </Box>
            {isOpenNuevoAsesoramiento && (
                <AsesoramientoFinancieroModal
                    isOpen={isOpenNuevoAsesoramiento}
                    onClose={onCloseNuevoAsesoramiento}
                    datos={viewDatos}
                    isEdit={isEdit}
                />
            )}
        </>
    );
};

export default ListaCistasFinanciacion;
