import { useState, useEffect } from "react";
import { Button, Center, SimpleGrid, Text, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { diff } from "jsondiffpatch";
import "./selectWithColumns.css";
import { format } from "date-fns";
import { updateCliente } from "../../services/clientes";
import { errorNotification, okNotification } from "../../utils/Notificaciones";

const Reconciliacion = ({ formData, nuevo, antiguo, handleCancel }) => {
    const [diferencias, setDiferencias] = useState([]);
    const mismoIdentificador = nuevo.identificador !== undefined && nuevo.identificador === antiguo.identificador;
    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        const deltas = diff(nuevo, antiguo);
        setDiferencias(deltas);
    }, [nuevo, antiguo]);
    const [valoresSeleccionados, setValoresSeleccionados] = useState([]);

    function actualizarValorSeleccionado(path, valor) {
        setValoresSeleccionados((prevState) => ({
            ...prevState,
            [path]: valor,
        }));
    }

    const handleSave2 = async () => {
        var aux = antiguo;
        Object.entries(valoresSeleccionados).forEach(([key, value]) => {
            if (!key.split(".")[1]) {
                aux[key] = value;
            } else {
                const path0 = key.split(".")[0];
                const path1 = key.split(".")[1];
                aux[path0][path1] = value;
            }
        });

        try {
            await updateCliente(formData.uuid, aux);
            okNotification("Cliente reconciliado correctamente", toast);
            navigate("/clientes/");
        } catch (error) {
            console.error("🚀 ~ file: GridInfoCliente.js:19 ~ handleSaveCliente ~ error", error);
            errorNotification("Error al reconciliar el cliente", toast);
        }
    };

    function Elemento({ path, diferencia, valorSeleccionado }) {

        if (path === "updatedAt" || path === "createdAt" || path === "uuid" || path === "isEdit") {
            return null;
        }

        if (diferencia[0] === undefined && diferencia[1] === undefined) {
            return null;
        }

        if (diferencia[0] === undefined && diferencia[1] === null) {
            return null;
        }

        if (diferencia[0] === null && diferencia[1] === undefined) {
            return null;
        }

        if (diferencia[0] === null && diferencia[1] === null) {
            return null;
        }

        if (diferencia[0] === "" && diferencia[1] === "") {
            return null;
        }

        const isSelected1 = valorSeleccionado[path] === diferencia[0];
        const isSelected2 = valorSeleccionado[path] === diferencia[1];

        return (
            <SimpleGrid>
                {diferencia[0] === undefined ? (
                    Object.keys(diferencia).map((dif, index) => (
                        <Elemento
                            key={path + "." + dif}
                            path={path + "." + dif}
                            diferencia={diferencia[dif]}
                            valorSeleccionado={valoresSeleccionados}
                            index={index}
                        />
                    ))
                ) : (
                    <Center>
                        <SimpleGrid columns={3} gap={4}>
                            <h3>{path}</h3>
                            <Button
                                className={`boton ${isSelected1 ? "seleccionado" : ""}`}
                                onClick={() => actualizarValorSeleccionado(path, diferencia[0])}>
                                {diferencia[0] instanceof Date ? format(diferencia[0], "yyyy-mm-dd") : diferencia[0]}
                            </Button>
                            <Button
                                className={`boton ${isSelected2 ? "seleccionado" : ""}`}
                                onClick={() => actualizarValorSeleccionado(path, diferencia[1])}>
                                {diferencia[1] instanceof Date ? format(diferencia[1], "yyyy-mm-dd") : diferencia[1]}
                            </Button>
                        </SimpleGrid>
                    </Center>
                )}
            </SimpleGrid>
        );
    }

    return (
        <div>
            {mismoIdentificador && (
                <Center>
                    <Text fontWeight="bold" color="red">Tienen el mismo identificador, es OBLIGATORIO reconciliarlos.</Text>
                </Center>
            )}
            {diferencias &&
                Object.keys(diferencias).map((diferencia, index) => (
                    <Elemento
                        key={diferencia}
                        path={diferencia}
                        diferencia={diferencias[diferencia]}
                        valorSeleccionado={valoresSeleccionados}
                        index={index}
                    />
                ))}

            <Center columns={2} gap={4}>
                <Button colorScheme="red" size="lg" mt={4} onClick={handleSave2}>
                    Guardar, unir ambos en un cliente.
                </Button>
                {!mismoIdentificador && (
                    <Button colorScheme="blue" size="lg" mt={4} onClick={handleCancel}>
                        Cancelar, guardar como cliente nuevo.
                    </Button>
                )}
            </Center>
        </div>
    );
};

export default Reconciliacion;
