import { Td, Tr } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const UsuarioTableItem = ({ usuario, setUsuarioSeleccionado, setNeedReload }) => {
    const navigate = useNavigate();

    return (
        <Tr key={usuario.uuid} _hover={{ bg: 'gray.200' }} cursor="pointer" onClick={() => {navigate(`/usuarios/ver/${usuario.uuid}`)}}>
            <Td>{usuario.nombre} {usuario.apellidos}</Td>
            <Td>{usuario.tipo_identificador}</Td>
            <Td>{usuario.identificador}</Td>
            <Td>{usuario.telefono_trabajo}</Td>
            <Td>{usuario.telefono_personal}</Td>
            <Td>{usuario.email}</Td>
            <Td>{usuario.agencia}</Td>
        </Tr>
    );
};

export default UsuarioTableItem;
