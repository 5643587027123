import { Box, Heading } from "@chakra-ui/react";

const InfoBox = ({ nombre, dato, boxStyle = 0 }) => {
    return (
        <Box bg={boxStyle === 0 ? "gray.700" : "facebook.600"} p={4} borderRadius="md">
            <Heading size="xl" textAlign="center" color="white">
                {dato}
            </Heading>
            <Heading size="md" textAlign="center" color="white" textTransform="uppercase">
                {nombre}
            </Heading>
        </Box>
    );
};

export default InfoBox;
