import { Box, Divider } from "@chakra-ui/react";

const Separador = () => {
    return (
        <Box py={8}>
            <Divider />
        </Box>
    );
};

export default Separador;