import { useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { useEffect, useState } from "react";
import { getEncargoById } from "../../services/encargos";
import { Box, Divider, Heading } from "@chakra-ui/react";
import GridInfoEncargo from "../../components/Encargos/CRUD/GridInfoEncargo";
import GridInfoInmuebles from "../../components/Inmuebles/CRUD/GridInfoInmuebles";
import TablaActividadesEncargo from "../../components/Actividades/TablaActividadesEncargo";
import _ from "lodash";

const EditarEncargo = () => {
    const { id } = useParams();
    const [encargo, setEncargo] = useState();

    useEffect(() => {
        const fetchEncargo = async () => {
            const response = await getEncargoById(id);
            setEncargo(response);
        };
        fetchEncargo();
    }, [id]);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setEncargo(_.set({ ...encargo }, name, type === "checkbox" ? checked : value));
    };

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center">Encargo</Heading>
            </Box>
            {encargo && (
                <>
                    <GridInfoEncargo encargo={encargo} handleChange={handleChange} />
                    <Box py={8}>
                        <Divider />
                    </Box>
                    <Heading textAlign="center" mb={2}>
                        Inmueble
                    </Heading>
                    <GridInfoInmuebles formData={encargo.inmueble} hideActividades={true} />
                    <Box py={8}>
                        <Divider />
                    </Box>
                    <Box mt={4}>
                        <TablaActividadesEncargo datos={encargo} />
                    </Box>
                </>
            )}
        </Layout>
    );
};

export default EditarEncargo;
