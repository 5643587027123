import axios from 'axios';

export const crearActividad = async (actividad) => {
    try {
        const response = await axios.post('/actividades', actividad, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: actividades.js:6 ~ crearActividad ~ error:", error)
        throw error.response;
    }
}

export const updateActividad = async (uuid, actividad) => {
    try {
        const response = await axios.patch(`/actividades/${uuid}`, actividad, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: actividades.js:18 ~ updateActividad ~ error:", error)
        throw error.response;
    }
}

export const getActividadesByCliente = async (cliente) => {
    try {
        const response = await axios.get(`/actividades/cliente/${cliente}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: actividades.js:28 ~ getActividadesByCliente ~ error:", error)
        throw error.response;
    }
}

export const getActividadesByInmueble = async (inmueble) => {
    try {
        const response = await axios.get(`/actividades/inmueble/${inmueble}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: actividades.js:38 ~ getActividadesByInmueble ~ error:", error)
        throw error.response;
    }
}

export const getActividadesByNoticia = async (noticia) => {
    try {
        const response = await axios.get(`/actividades/noticia/${noticia}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: actividades.js:48 ~ getActividadesByNoticia ~ error:", error)
        throw error.response;
    }
}

export const getActividadesByEncargo = async (encargo) => {
    try {
        const response = await axios.get(`/actividades/encargo/${encargo}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: actividades.js:58 ~ getActividadesByEncargo ~ error:", error)
        throw error.response;
    }
}