//import { formatDate } from "./formatDate";

/**
 * Crea un evento para ser usado en el handleChange de un componente
 * Utilizado para crear eventos de fecha
 * @param {string} name
 * @param {Date} value
 */
export const createEvent = (name, value) => {
    return {
        target: {
            name: name,
            value: value,
        },
    };
};