import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Center,
    Heading,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from "@chakra-ui/react";
import { getActividadesByNoticia } from "../../services/actividades";
import EasyDatePicker from "../EasyDatePicker/EasyDatePicker";
import ValoracionModal from "../Modals/Actividades/ValoracionModal";
import AdquisicionModal from "../Modals/Actividades/AdquisicionModal";
import MultiActividad from "../MultiActividad/MultiActividad";
import ActualizacionModal from "../Modals/Actividades/ActualizacionModal";
import PopoverDescripcion from "./PopoverDescripcion";

const TablaActividadesNoticia = ({ datos }) => {
    const [actividades, setActividades] = useState([]);
    const [datosRecibidos, setDatosRecibidos] = useState({});
    const [isEdit, setIsEdit] = useState(false);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenAdquisicion, onOpen: onOpenAdquisicion, onClose: onCloseAdquisicion } = useDisclosure();
    const {
        isOpen: isOpenMultiActividad,
        onOpen: onOpenMultiActividad,
        onClose: onCloseMultiActividad,
    } = useDisclosure();
    const { isOpen: isOpenActualizacion, onOpen: onOpenActualizacion, onClose: onCloseActualizacion } = useDisclosure();

    const { uuid: inmueble } = datos;

    useEffect(() => {
        const getActividades = async () => {
            const response = await getActividadesByNoticia(inmueble);
            setActividades(response);
        };
        getActividades();
    }, [inmueble]);

    const handleOpenActividad = (actividad) => {
        setIsEdit(true);
        setDatosRecibidos(actividad);
        onOpenMultiActividad();
    };

    const handleNuevaValoracion = () => {
        setIsEdit(false);
        setDatosRecibidos(datos);
        onOpen();
    };

    const handleNuevaAdquisicion = () => {
        setIsEdit(false);
        setDatosRecibidos(datos);
        onOpenAdquisicion();
    };

    const handleNuevaActualizacion = () => {
        setIsEdit(false);
        setDatosRecibidos(datos);
        onOpenActualizacion();
    };

    return (
        <>
            <Box p={8} border="1px" rounded="md" shadow="dark-lg">
                <Heading textAlign="center" mb={4}>
                    Actividades
                </Heading>
                <Center>
                    <Button rounded="none" colorScheme="facebook" w="100%" onClick={handleNuevaValoracion}>
                        Cita de valoración
                    </Button>
                    <Button rounded="none" colorScheme="green" w="100%" onClick={handleNuevaActualizacion}>
                        Llamada de actualización
                    </Button>
                    {actividades &&
                        actividades.some(
                            (actividad) =>
                                actividad.tipo_actividad === "Valoracion" && actividad.estado_actividad === "Realizada"
                        ) && (
                            <Button rounded="none" colorScheme="facebook" w="100%" onClick={handleNuevaAdquisicion}>
                                Cita de adquisición
                            </Button>
                        )}
                </Center>
                <TableContainer maxH="40rem" overflowY="auto">
                    <Table>
                        <Thead>
                            <Tr bgColor="facebook.50">
                                <Th>Tipo de actividad</Th>
                                <Th>Entidad relacionada</Th>
                                <Th>Modalidad contacto</Th>
                                <Th>Estado actividad</Th>
                                <Th>Fecha actividad</Th>
                                <Th>Creación</Th>
                                <Th>Modificación</Th>
                                <Th>Vencimiento</Th>
                                <Th>Descripción</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {actividades && actividades.length === 0 && (
                                <Tr>
                                    <Td colSpan={7} textAlign="center" fontWeight="bolder">
                                        No hay actividades
                                    </Td>
                                </Tr>
                            )}
                            {actividades &&
                                actividades.length > 0 &&
                                actividades.map((actividad) => (
                                    <Tr key={actividad.uuid} _hover={{ cursor: "pointer", bg: "gray.100" }}>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            ACT - {actividad.tipo_actividad}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.entidad_relacionada}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.modalidad_contacto}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.estado_actividad}
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            <EasyDatePicker value={actividad.fecha_actividad} />
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            <EasyDatePicker value={actividad.createdAt} needHour={true} />
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            <EasyDatePicker value={actividad.updatedAt} needHour={true} />
                                        </Td>
                                        <Td onClick={() => handleOpenActividad(actividad)}>
                                            {actividad.fecha_vencimiento ? (
                                                <EasyDatePicker value={actividad.fecha_vencimiento} needHour={true} />
                                            ) : (
                                                <EasyDatePicker value={actividad.fecha_finalizacion} needHour={true} />
                                            )}
                                        </Td>
                                        <Td>
                                            <PopoverDescripcion descripcion={actividad.descripcion} />
                                        </Td>
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
            {isOpen && (
                <ValoracionModal
                    isOpen={isOpen}
                    onClose={onClose}
                    datos={datosRecibidos}
                    isEdit={isEdit}
                    esNoticia={true}
                />
            )}
            {isOpenAdquisicion && (
                <AdquisicionModal
                    isOpen={isOpenAdquisicion}
                    onClose={onCloseAdquisicion}
                    datos={datosRecibidos}
                    isEdit={isEdit}
                />
            )}
            {isOpenActualizacion && (
                <ActualizacionModal
                    isOpen={isOpenActualizacion}
                    onClose={onCloseActualizacion}
                    datos={datosRecibidos}
                    isEdit={isEdit}
                    esNoticia={true}
                />
            )}
            {isOpenMultiActividad && (
                <MultiActividad
                    isOpen={isOpenMultiActividad}
                    onClose={onCloseMultiActividad}
                    datos={datosRecibidos}
                    isEdit={isEdit}
                    esNoticia={true}
                />
            )}
        </>
    );
};

export default TablaActividadesNoticia;
