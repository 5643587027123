import {
    Button,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    SimpleGrid,
    Stack,
    Text,
    Textarea,
    useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getAllUsuarios } from "../../../services/usuarios";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";
import { useSelector } from "react-redux";
import Fechas from "../../Agenda/Fechas";
import { createEvent, updateEvent } from "../../../services/agenda";

const AsesoramientoFinancieroModal = ({ isOpen, onClose, datos, isEdit }) => {
    const [responsables, setResponsables] = useState([]);
    const [needReload, setNeedReload] = useState(true);
    const [formData, setFormData] = useState(datos);
    const toast = useToast();
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        const getResponsables = async () => {
            setNeedReload(false);
            const auxResponsables = await getAllUsuarios();
            setResponsables(auxResponsables);
            if (!isEdit) {
                setFormData({
                    ...formData,
                    responsableUuid: user.uuid,
                    estado: "Programada",
                });
            }
        };

        needReload && user && getResponsables();
    }, [formData, needReload, isEdit, user]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const getDataForSend = () => {
        return {
            entidad_relacionada: "Pedido",
            fechaInicio: formData.fechaInicio,
            fechaFin: formData.fechaFin,
            estado: formData.estado,
            tipo: "Asesoramiento financiero",
            color: "#573EEE",
            descripcion: formData.descripcion,
            responsableUuid: formData.responsableUuid,
            clienteUuid: datos.cliente.uuid,
            pedidoUuid: datos.pedidoUuid || datos.uuid,
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const notificationMessage = !isEdit ? "Actividad creada correctamente" : "Actividad actualizada correctamente";
        const errorMessage = "Error al crear la actividad";

        try {
            if (!isEdit) {
                await createEvent(getDataForSend());
            } else {
                await updateEvent(datos.uuid, {
                    ...formData,
                    fecha_finalizacion: formData.estado === "Realizada" ? new Date() : undefined,
                });
            }

            okNotification(notificationMessage, toast);
            onClose();
            window.location.reload();
        } catch (error) {
            errorNotification(errorMessage, toast);
            console.error("🚀 ~ file: ActualizacionModal.js:7 ~ ActualizacionModal ~ error", error);
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
                <ModalOverlay bgColor="blackAlpha.800" />
                <ModalContent>
                    <ModalHeader>Asesoramiento financiero</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack direction="column" spacing={4}>
                            <SimpleGrid columns={2}>
                                <Text>Cliente</Text>
                                <Input
                                    name="cliente"
                                    value={`${datos.cliente?.nombre} ${datos.cliente?.apellidos}`}
                                    isReadOnly
                                    isDisabled
                                />
                            </SimpleGrid>

                            <Fechas formData={formData} handleChange={handleChange} />

                            <SimpleGrid columns={2}>
                                <Text>Responsable</Text>
                                <Select
                                    name="responsableUuid"
                                    value={formData.responsableUuid}
                                    onChange={handleChange}
                                    isDisabled={datos.fecha_finalizacion}>
                                    {responsables.map((responsable) => (
                                        <option key={responsable.uuid} value={responsable.uuid}>
                                            {responsable.nombre} {responsable.apellidos}
                                        </option>
                                    ))}
                                </Select>
                            </SimpleGrid>

                            <SimpleGrid columns={2}>
                                <Text>Estado</Text>
                                <Select
                                    name="estado"
                                    value={formData.estado}
                                    onChange={handleChange}
                                    isDisabled={datos.fecha_finalizacion}>
                                    <option value="Programada">Programada</option>
                                    <option value="Realizada">Realizada</option>
                                </Select>
                            </SimpleGrid>

                            <Text>Descripción</Text>
                            <Textarea
                                isDisabled={datos.fecha_finalizacion}
                                borderLeft="3px solid red"
                                name="descripcion"
                                value={formData.descripcion}
                                onChange={handleChange}
                            />

                            {datos && !datos.fecha_finalizacion && (
                                <Flex gap={0}>
                                    <Button
                                        w="100%"
                                        rounded="none"
                                        onClick={handleSubmit}
                                        colorScheme="green"
                                        isDisabled={!formData.descripcion}>
                                        {isEdit ? "EDITAR" : "CREAR"}
                                    </Button>
                                </Flex>
                            )}
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AsesoramientoFinancieroModal;
