import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from "@chakra-ui/react";

const ConfirmationModal = ({ show, cancelRef, handleClose, handleConfirm, title, body }) => {
    return (
        <AlertDialog  isOpen={show} leastDestructiveRef={cancelRef} onClose={handleClose} isCentered>
            <AlertDialogOverlay bgColor="blackAlpha.800">
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {title}
                    </AlertDialogHeader>

                    <AlertDialogBody>{body}</AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="red" onClick={handleConfirm} ml={3}>
                            Confirm
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default ConfirmationModal;
