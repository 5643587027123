import { Heading, Select, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { Subtipo_inmueble, Tipologia_inmueble } from "../../../data/constants";
import { useNavigate } from "react-router-dom";

const InfoInmueble = ({ formData, handleChange, usuarios, clientes }) => {

    const navigate = useNavigate();
    let auxSubtipo = [];

    if (formData.tipo_inmueble) {
        auxSubtipo = Subtipo_inmueble.find(function (e) {
            return e.tipo === formData.tipo_inmueble;
        }).subtipo;
    }

    const esNoticia = formData.noticiaUuid != null;
    const esEncargo = esNoticia && formData.encargoUuid != null;

    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Información general</Heading>

            <SimpleGrid columns={2}>
                <Text>Tipologia</Text>
                <Select
                    borderLeft="3px solid red"
                    name="tipo_inmueble"
                    value={formData.tipo_inmueble}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                    placeholder="-- Ninguno --">
                    {Tipologia_inmueble.map((tipo, index) => {
                        return (
                            <option key={index} value={tipo.tipo}>
                                {tipo.tipo}
                            </option>
                        );
                    })}
                </Select>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Subtipologia</Text>
                <Select
                    borderLeft={(auxSubtipo.length > 0 || !formData.tipo_inmueble) ? "3px solid red" : undefined}
                    name="subtipo_inmueble"
                    value={formData.subtipo_inmueble}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                    placeholder="-- Ninguno --">
                    {auxSubtipo.map((tipo, index) => {
                        return (
                            <option key={index} value={tipo.tipo}>
                                {tipo.tipo}
                            </option>
                        );
                    })}
                </Select>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Ocupado por</Text>
                <Select
                    borderLeft="3px solid red"
                    name="ocupado"
                    value={formData.ocupado}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                    placeholder="-- Ninguno --">
                    <option value="Inquilino">Inquilino</option>
                    <option value="Propietario">Propietario</option>
                    <option value="Vacio">Vacio</option>
                    <option value="No identificado">No identificado</option>
                </Select>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Responsable</Text>
                <Select
                    borderLeft="3px solid red"
                    name="responsableUuid"
                    value={formData.responsableUuid}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                    placeholder="-- Ninguno --">
                    {usuarios &&
                        usuarios.map((usuario) => {
                            const nombreCompleto = usuario.nombre + " " + usuario.apellidos;
                            return (
                                <option key={usuario.uuid} value={usuario.uuid}>
                                    {nombreCompleto}
                                </option>
                            );
                        })}
                </Select>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Propietario</Text>
                {handleChange == null && (
                    <Text
                        onClick={() => navigate(`/clientes/ver/${formData.cliente.uuid}`)}
                        _hover={{ cursor: "pointer" }}
                        fontWeight="bold"
                        color="green">
                        {formData.cliente?.nombre} {formData.cliente?.apellidos}
                    </Text>
                )}
                {handleChange != null && (
                    <Select
                        borderLeft="3px solid red"
                        name="clienteUuid"
                        value={formData.clienteUuid}
                        onChange={handleChange}
                        isDisabled={handleChange == null}
                        placeholder="-- Ninguno --">
                        {clientes &&
                            clientes.map((cliente) => {
                                const nombreCompleto = cliente.nombre + " " + cliente.apellidos;
                                return (
                                    <option key={cliente.uuid} value={cliente.uuid}>
                                        {nombreCompleto}
                                    </option>
                                );
                            })}
                    </Select>
                )}
            </SimpleGrid>
            {handleChange == null && (
                <SimpleGrid columns={2}>
                    <Text>Estado inmueble</Text>
                    {!esNoticia && <Text>Inmueble</Text>}
                    {esNoticia && !esEncargo && (
                        <Text
                            color="green"
                            fontWeight="bold"
                            _hover={{ cursor: "pointer" }}
                            onClick={() => navigate(`/noticias/ver/${formData.noticiaUuid}`)}>
                            Noticia
                        </Text>
                    )}
                    {esEncargo && (
                        <Text
                            color="blue"
                            fontWeight="bold"
                            _hover={{ cursor: "pointer" }}
                            onClick={() => navigate(`/encargos/ver/${formData.encargoUuid}`)}>
                            Encargo
                        </Text>
                    )}
                </SimpleGrid>
            )}
        </Stack>
    );
};

export default InfoInmueble;
