import { Box, Center, Stack, Text } from "@chakra-ui/react";

const InfoPedido = ({ pedido }) => {
    return (
        <Box bgColor="gray.100" rounded="md" p={4}>
            <Text textAlign="center" mb={2}>
                Estás buscando encargos que se ajusten a...
            </Text>
            <Center>
                <Stack direction="row" spacing={6}>
                    <Stack direction="column" border="1px solid" rounded="md" p={2}>
                        <Text textAlign="center">Provincia</Text>
                        <Text textAlign="center" fontWeight="bold" textTransform="capitalize">
                            {pedido.provincia}
                        </Text>
                    </Stack>
                    <Stack direction="column" border="1px solid" rounded="md" p={2}>
                        <Text textAlign="center">Tipología inmueble</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {pedido.tipo_inmueble} {pedido.subtipo_inmueble}
                        </Text>
                    </Stack>
                    <Stack direction="column" border="1px solid" rounded="md" p={2}>
                        <Text textAlign="center">Contrato</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {pedido.contrato}
                        </Text>
                    </Stack>
                    <Stack direction="column" border="1px solid" rounded="md" p={2}>
                        <Text textAlign="center">Dormitorios</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {pedido.habitaciones ? pedido.habitaciones : "Indiferente"}
                        </Text>
                    </Stack>
                    <Stack direction="column" border="1px solid" rounded="md" p={2}>
                        <Text textAlign="center">Baños</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {pedido.bathrooms ? pedido.bathrooms : "Indiferente"}
                        </Text>
                    </Stack>
                    <Stack direction="column" border="1px solid" rounded="md" p={2}>
                        <Text textAlign="center">Precio</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {new Intl.NumberFormat().format(pedido.precio)}{" €"}
                        </Text>
                    </Stack>
                </Stack>
            </Center>
        </Box>
    );
};

export default InfoPedido;
