import { Center, Select, Stack, Text, Textarea } from "@chakra-ui/react";

const BoxPedido = ({ pedido, needInfo = false, handleChange, isDisabled = false }) => {
    return needInfo ? (
        <Stack direction="row" w="100%">
            <Stack direction="column" border="1px solid" rounded="md" p={4} spacing={4} w="100%">
                <Stack direction="row" spacing={6}>
                    <Stack direction="column">
                        <Text textAlign="center">Provincia</Text>
                        <Text textAlign="center" fontWeight="bold" textTransform="capitalize">
                            {pedido.provincia}
                        </Text>
                    </Stack>
                    <Stack direction="column">
                        <Text textAlign="center">Tipología</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {pedido.tipo_inmueble} {pedido.subtipo_inmueble}
                        </Text>
                    </Stack>
                    <Stack direction="column">
                        <Text textAlign="center">Dormitorios</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {pedido.habitaciones ? pedido.habitaciones : "-"}
                        </Text>
                    </Stack>
                    <Stack direction="column">
                        <Text textAlign="center">Baños</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {pedido.bathrooms ? pedido.bathrooms : "-"}
                        </Text>
                    </Stack>
                </Stack>
                <Center gap={4} border="1px solid black" rounded="sm" p={2} bgColor="gray.50">
                    <Text textAlign="center">Precio</Text>
                    <Text textAlign="center" fontWeight="bold">
                        {pedido.precio ? pedido.precio : "-"}
                    </Text>
                </Center>
            </Stack>
            <Stack border="1px solid" rounded="md" p={4} w="100%">
                <Select
                    placeholder="Interés del cliente/resultado"
                    name="resultado"
                    value={pedido.resultado}
                    onChange={(e) => handleChange(pedido.uuid, e)}
                    isDisabled={isDisabled}>
                    <option value="Interesa ver el inmueble">Interesa ver el inmueble</option>
                    <option value="Visto y gusta">Visto y gusta</option>
                    <option value="No gusta el precio">No gusta el precio</option>
                    <option value="No gusta el inmueble">No gusta el inmueble</option>
                    <option value="El comprador quiere hacer una propuesta">
                        El comprador quiere hacer una propuesta
                    </option>
                </Select>
                <Textarea
                    placeholder="Comentario"
                    name="observaciones"
                    value={pedido.observaciones}
                    onChange={(e) => handleChange(pedido.uuid, e)}
                    isDisabled={isDisabled}
                />
            </Stack>
        </Stack>
    ) : (
        <Center>
            <Stack direction="row" spacing={6} border="1px solid" rounded="md" p={4}>
                <Stack direction="column">
                    <Text textAlign="center">Provincia</Text>
                    <Text textAlign="center" fontWeight="bold" textTransform="capitalize">
                        {pedido.provincia}
                    </Text>
                </Stack>
                <Stack direction="column">
                    <Text textAlign="center">Tipología</Text>
                    <Text textAlign="center" fontWeight="bold">
                        {pedido.tipo_inmueble} {pedido.subtipo_inmueble}
                    </Text>
                </Stack>
                <Stack direction="column">
                    <Text textAlign="center">Dormitorios</Text>
                    <Text textAlign="center" fontWeight="bold">
                        {pedido.habitaciones ? pedido.habitaciones : "-"}
                    </Text>
                </Stack>
                <Stack direction="column">
                    <Text textAlign="center">Baños</Text>
                    <Text textAlign="center" fontWeight="bold">
                        {pedido.bathrooms ? pedido.bathrooms : "-"}
                    </Text>
                </Stack>
                <Stack direction="column">
                    <Text textAlign="center">Precio</Text>
                    <Text textAlign="center" fontWeight="bold">
                        {pedido.precio ? pedido.precio : "-"}
                    </Text>
                </Stack>
            </Stack>
        </Center>
    );
};

export default BoxPedido;
