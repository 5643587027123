import { Center, Input, Select, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Subtipo_inmueble, Tipologia_inmueble } from "../../data/constants";
import { removeAccents } from "../../utils/removeAccents";
import { getAllAgencias } from "../../services/agencias";

const FiltroPedidos = ({ datos, setDatosFiltrados }) => {
    const [estado, setEstado] = useState("");
    const [motivo, setMotivo] = useState("");
    const [tipologia, setTipologia] = useState("");
    const [subtipologia, setSubtipologia] = useState("");
    const [orden, setOrden] = useState("");
    const [textoFiltro, setTextoFiltro] = useState("");

    const [agencias, setAgencias] = useState([]);
    const [agenciaSeleccionada, setAgenciaSeleccionada] = useState("");

    const [needReload, setNeedReload] = useState(true);

    useEffect(() => {
        const fetchAgencias = async () => {
            setNeedReload(false);

            try {
                const request = await getAllAgencias();
                setAgencias(request);
            } catch (error) {
                console.error("🚀 ~ fetchAgencias ~ error:", error)
            }
        };
        needReload && fetchAgencias();
    }, [needReload]);

    useEffect(() => {
        const filtrarDatos = () => {
            // Crear una copia de los datos
            let datosFiltrados = [...datos];

            if (orden !== "") {
                datosFiltrados = datosFiltrados.sort((a, b) => {
                    const aDate = new Date(a.createdAt);
                    const bDate = new Date(b.createdAt);
                    if (orden === "asc") {
                        return aDate - bDate;
                    } else {
                        return bDate - aDate;
                    }
                });
            }

            if (estado !== "") {
                datosFiltrados = datosFiltrados.filter((dato) => dato.estado_pedido === estado);
            }

            if (motivo !== "") {
                datosFiltrados = datosFiltrados.filter((dato) => dato.contrato === motivo);
            }

            if (tipologia !== "") {
                datosFiltrados = datosFiltrados.filter((dato) => dato.tipo_inmueble === tipologia);
            }

            if (subtipologia !== "") {
                datosFiltrados = datosFiltrados.filter((dato) => dato.subtipo_inmueble === subtipologia);
            }

            if (agenciaSeleccionada !== "") {
                datosFiltrados = datosFiltrados.filter((dato) => dato.agenciaUuid === agenciaSeleccionada);
            }

            if (textoFiltro !== "") {
                const texto = removeAccents(textoFiltro.toLowerCase());
                datosFiltrados = datosFiltrados.filter((dato) => {
                    const fullName = dato.cliente?.nombre + " " + dato.cliente?.apellidos;
                    const fullNameResponsable = dato.responsable?.nombre + " " + dato.responsable?.apellidos;

                    const searchKeywords = texto.split(" ");
                    return searchKeywords.every((keyword) =>
                        [
                            removeAccents(fullName.toLowerCase()),
                            dato.cliente?.identificador?.toLowerCase(),
                            dato.cliente?.telefono?.toLowerCase(),
                            dato.cliente?.email?.toLowerCase(),
                            dato.subtipo_inmueble?.toLowerCase(),
                            dato.tipo_inmueble?.toLowerCase(),
                            dato.precio?.toString(),
                            removeAccents(fullNameResponsable.toLowerCase()),
                            dato.responsable?.identificador?.toLowerCase(),
                            dato.responsable?.email?.toLowerCase(),
                            dato.responsable?.telefono?.toLowerCase(),
                        ].some((text) => text?.includes(keyword))
                    );
                });
            }

            setDatosFiltrados(datosFiltrados);
        };
        datos.length > 0 && setDatosFiltrados && filtrarDatos();
    }, [datos, setDatosFiltrados, estado, motivo, tipologia, subtipologia, orden, textoFiltro, agenciaSeleccionada]);

    let auxSubtipo = [];

    if (tipologia !== "") {
        auxSubtipo = Subtipo_inmueble.find(function (e) {
            return e.tipo === tipologia;
        }).subtipo;
    }

    return (
        <Center>
            <Stack direction="column" spacing={0} align="center">
                <Stack w="100%">
                    <Input rounded="none" placeholder="Buscar" onChange={(e) => setTextoFiltro(e.target.value)} />
                </Stack>
                <Stack direction="row" spacing={4} align="center">
                    <Stack>
                        <Select size="xs" placeholder="-- Estado --" onChange={(e) => setEstado(e.target.value)}>
                            <option value="En proceso">En proceso</option>
                            <option value="Cerrado">Cerrado</option>
                            <option value="Cancelado">Cancelado</option>
                        </Select>
                    </Stack>
                    <Stack>
                        <Select size="xs" placeholder="-- Motivo --" onChange={(e) => setMotivo(e.target.value)}>
                            <option value="Compra">Compra</option>
                            <option value="Alquiler">Alquilar</option>
                        </Select>
                    </Stack>
                    <Stack>
                        <Select size="xs" placeholder="-- Tipología --" onChange={(e) => setTipologia(e.target.value)}>
                            {Tipologia_inmueble.map((tipo, index) => {
                                return (
                                    <option key={index} value={tipo.tipo}>
                                        {tipo.tipo}
                                    </option>
                                );
                            })}
                        </Select>
                    </Stack>
                    <Stack>
                        <Select size="xs" placeholder="-- Subtipología --" onChange={(e) => setSubtipologia(e.target.value)}>
                            {auxSubtipo.map((tipo, index) => {
                                return (
                                    <option key={index} value={tipo.tipo}>
                                        {tipo.tipo}
                                    </option>
                                );
                            })}
                        </Select>
                    </Stack>
                    <Stack>
                        <Select size="xs" placeholder="-- Creación --" onChange={(e) => setOrden(e.target.value)}>
                            <option value="asc">Antiguas primero</option>
                            <option value="desc">Recientes primero</option>
                        </Select>
                    </Stack>
                    <Stack>
                        <Select size="xs" placeholder="-- Agencia --" onChange={(e) => setAgenciaSeleccionada(e.target.value)}>
                            <option value="">Todas</option>
                            {agencias.map((agencia) => {
                                return (
                                    <option key={agencia.uuid} value={agencia.uuid}>
                                        {agencia.nombre}
                                    </option>
                                );
                            })}
                        </Select>
                    </Stack>
                </Stack>
            </Stack>
        </Center>
    );
};

export default FiltroPedidos;
