import { Box, Heading } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import GridInfoInmuebles from '../../components/Inmuebles/CRUD/GridInfoInmuebles'
import Layout from '../../components/Layout/Layout'
import { getInmuebleById } from '../../services/inmuebles'

const VerInmueble = () => {
    const [inmueble, setInmueble] = useState()
    const { id } = useParams()

    useEffect(() => {
        const getInmueble = async () => {
            const esteInmueble = await getInmuebleById(id)
            setInmueble(esteInmueble)
        }

        getInmueble()
    }, [id])

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center">Inmueble</Heading>
            </Box>

            {inmueble && 
                <GridInfoInmuebles formData={inmueble} />
            }
        </Layout>
    )
}

export default VerInmueble
