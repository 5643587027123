import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { getCrucePedidosEncargos } from "../../services/cruces";
import { Box, Button, Center, Heading, Text, useDisclosure } from "@chakra-ui/react";
import { getPedidoById, getPedidoEncargoById } from "../../services/pedidos";
import InfoPedido from "../../components/Cruce/InfoPedido";
import EncargosSeleccionados from "../../components/Cruce/EncargosSeleccionado";
import ResultadosCruce from "../../components/Cruce/ResultadosCruce";
import PromocionModal from "../../components/Modals/Actividades/PromocionModal";
import VentaAlquilerV2Modal from "../../components/Modals/Actividades/VentaAlquilerV2Modal";

const CrucePedido = ({ esCita = false }) => {
    const [pedido, setPedido] = useState();
    const [pedidoEncargos, setPedidoEncargos] = useState([]);
    const [cruces, setCruces] = useState([]);
    const [encargosSeleccionados, setEncargosSeleccionados] = useState([]);
    const [datosEnviados, setDatosEnviados] = useState(encargosSeleccionados);
    const [needReload, setNeedReload] = useState(true);

    const { id } = useParams();
    const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const fetchData = async () => {
            setNeedReload(false);
            const [auxPedido, auxCruces, auxPedidoEncargo] = await Promise.all([
                getPedidoById(id),
                getCrucePedidosEncargos(id),
                getPedidoEncargoById(id),
            ]);
            setPedido(auxPedido);
            setCruces(auxCruces);
            setPedidoEncargos(auxPedidoEncargo);
            if (esCita) {
                // Buscar los entres los cruces los que tienen el uuid de los pedidos
                const auxPedidosSeleccionados = auxCruces.filter((cruce) =>
                    auxPedidoEncargo.find((pedido) => pedido.encargoUuid === cruce.uuid)
                );

                setEncargosSeleccionados(auxPedidosSeleccionados);
            }
        };
        needReload && fetchData();
    }, [id, needReload, esCita]);

    const handleSelectEncargo = (e) => {
        const { checked, value } = e.target;
        if (checked) {
            const cruce = cruces.find((cruce) => cruce.uuid === value);
            setEncargosSeleccionados([...encargosSeleccionados, cruce]);
        } else {
            setEncargosSeleccionados(encargosSeleccionados.filter((encargo) => encargo.uuid !== value));
        }
    };

    const handleCreateActividad = () => {
        setDatosEnviados({
            encargosSeleccionados,
            pedido: pedido,
            cliente: pedido.cliente,
        });
        onOpen();
    };

    const handleCreateCita = () => {
        setDatosEnviados({
            encargosSeleccionados,
            pedido: pedido,
            cliente: pedido.cliente,
        });
        onOpen();
    };

    return (
        <>
            <Layout>
                <Box mb={4}>
                    <Heading textAlign="center">Cruce demanda-oferta</Heading>
                </Box>
                {cruces.length === 0 && (
                    <Text textAlign="center" mb={4} fontWeight="bold" color="red" fontSize="xl">
                        NO HAY INMUEBLES COMPATIBLES CON EL PEDIDO
                    </Text>
                )}
                <Center gap={4} my={2}>
                    <Button colorScheme="blue" size="sm" mt={2} w="30%" onClick={() => navigate(-1)}>
                        Volver
                    </Button>
                    <Button colorScheme="green" size="sm" mt={2} w="30%" onClick={() => setNeedReload(true)}>
                        Recargar
                    </Button>
                </Center>

                {cruces.length > 0 && pedido && <InfoPedido pedido={pedido} />}

                {encargosSeleccionados.length > 0 && (
                    <>
                        <EncargosSeleccionados
                            encargosSeleccionados={encargosSeleccionados}
                            cruces={cruces}
                            pedidoUuid={id}
                        />
                        {esCita ? (
                            <Center>
                                <Button colorScheme="green" mt={2} w="30%" onClick={handleCreateCita}>
                                    Crear cita de venta/alquiler
                                </Button>
                            </Center>
                        ) : (
                            <Center>
                                <Button colorScheme="green" mt={2} w="30%" onClick={handleCreateActividad}>
                                    Crear actividad (promoción)
                                </Button>
                            </Center>
                        )}
                    </>
                )}

                {pedidoEncargos && cruces.length > 0 && (
                    <ResultadosCruce
                        encargos={pedidoEncargos}
                        encargosSeleccionados={encargosSeleccionados}
                        cruces={cruces}
                        handleSelect={handleSelectEncargo}
                        esCita={esCita}
                    />
                )}
            </Layout>
            {isOpen && !esCita && <PromocionModal isOpen={isOpen} onClose={onClose} datos={datosEnviados} />}
            {isOpen && esCita && <VentaAlquilerV2Modal isOpen={isOpen} onClose={onClose} datos={datosEnviados} />}
        </>
    );
};

export default CrucePedido;
