import { Box, Td, Tr } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import EasyDatePicker from "../../EasyDatePicker/EasyDatePicker";

const InmuebleTableItem = ({ inmueble }) => {
    const navigate = useNavigate();

    const esNoticia = inmueble.noticiaUuid !== null;
    const esEncargo = esNoticia && inmueble.encargoUuid !== null;

    let ubicacion;
    if (inmueble.ubicacion) {
        try {
            ubicacion = JSON.parse(inmueble.ubicacion);
        } catch (error) {
            ubicacion = JSON.parse(JSON.stringify(inmueble.ubicacion));
        }
    }

    return (
        <Tr key={inmueble.uuid} _hover={{ bg: "gray.100" }}>
            <Td onClick={() => navigate(`/inmuebles/ver/${inmueble.uuid}`)} _hover={{ cursor: "pointer" }}>
                {ubicacion.direccion} {ubicacion.numero}
            </Td>
            <Td>
                {!esNoticia && !esEncargo && (
                    <Box
                        color="red.500"
                        fontWeight="bolder"
                        onClick={() => navigate(`/inmuebles/ver/${inmueble.uuid}`)}
                        _hover={{ cursor: "pointer" }}>
                        Inmueble
                    </Box>
                )}
                {esNoticia && !esEncargo && (
                    <Box
                        color="green.500"
                        fontWeight="bolder"
                        onClick={() => navigate(`/noticias/ver/${inmueble.noticiaUuid}`)}
                        _hover={{ cursor: "pointer" }}>
                        Noticia
                    </Box>
                )}
                {esEncargo && (
                    <Box
                        color="blue.500"
                        fontWeight="bolder"
                        onClick={() => navigate(`/encargos/ver/${inmueble.encargoUuid}`)}
                        _hover={{ cursor: "pointer" }}>
                        Encargo
                    </Box>
                )}
            </Td>
            <Td>
                {inmueble.tipo_inmueble} {inmueble.subtipo_inmueble}
            </Td>
            <Td>
                {inmueble.cliente?.nombre} {inmueble.cliente?.apellidos}
            </Td>
            <Td>
                {inmueble.responsable?.nombre} {inmueble.responsable?.apellidos}
            </Td>
            <Td>
                <EasyDatePicker value={inmueble.createdAt} needHour={true} />
            </Td>
            <Td>
                <EasyDatePicker value={inmueble.updatedAt} needHour={true} />
            </Td>
        </Tr>
    );
};

export default InmuebleTableItem;
