import { Button, SimpleGrid, Stack, useDisclosure, useToast } from "@chakra-ui/react";
import InfoEncargo from "./InfoEncargo";
import Fechas from "./Fechas";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { editarEncargo, eliminarEncargo } from "../../../services/encargos";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";
import ResponsableAgencia from "./ResponsableAgencia";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import { useRef } from "react";

const GridInfoEncargo = ({ encargo, handleChange }) => {
    const { user } = useSelector((state) => state.auth);
    const toast = useToast();
    const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();

    const handleSave = async () => {
        try {
            await editarEncargo(encargo.uuid, encargo);
            okNotification("Encargo actualizado correctamente", toast);
            navigate(-1);
        } catch (error) {
            console.error("🚀 ~ file: GridInfoEncargo.js:19 ~ handleSave ~ error:", error);
            errorNotification("Error al actualizar el encargo", toast);
        }
    };

    const handleDelete = async () => {
        try {
            await eliminarEncargo(encargo.uuid);
            okNotification("Encargo eliminado correctamente", toast);
            navigate(-1);
        } catch (error) {
            console.error("🚀 ~ file: GridInfoEncargo.js:29 ~ handleDelete ~ error:", error);
            errorNotification("Error al eliminar el encargo", toast);
        }
    };

    return (
        <>
            <SimpleGrid columns={2} spacing={4}>
                <InfoEncargo encargo={encargo} handleChange={handleChange} />
                <Stack direction="column">
                    <ResponsableAgencia encargo={encargo} handleChange={handleChange} />
                    <Fechas encargo={encargo} handleChange={handleChange} />
                    {handleChange == null && (
                        <Stack spacing={0}>
                            <Stack direction={{ base: "column", md: "row" }} spacing={0} w="100%">
                                {(user.rol === "Gerente" || user.uuid === encargo.responsableUuid) && (
                                    <Button
                                        colorScheme="facebook"
                                        w="100%"
                                        rounded="none"
                                        onClick={() => navigate(`/encargos/editar/${encargo.uuid}`)}>
                                        EDITAR
                                    </Button>
                                )}
                                <Button colorScheme="facebook" rounded="none" w="100%" onClick={() => navigate(-1)}>
                                    VOLVER
                                </Button>
                            </Stack>
                            {user && user.rol === "Gerente" && (
                                <Button colorScheme="red" rounded="none" size="sm" onClick={onOpen}>
                                    ELIMINAR
                                </Button>
                            )}
                        </Stack>
                    )}
                    {handleChange != null && (
                        <>
                            <Stack direction={{ base: "column", md: "row" }} spacing={0} w="100%">
                                <Button colorScheme="green" rounded="none" onClick={handleSave} w="100%">
                                    GUARDAR
                                </Button>
                                <Button colorScheme="facebook" rounded="none" w="100%" onClick={() => navigate(-1)}>
                                    VOLVER
                                </Button>
                            </Stack>
                        </>
                    )}
                </Stack>
            </SimpleGrid>
            {isOpen && (
                <ConfirmationModal
                    show={isOpen}
                    cancelRef={cancelRef}
                    handleClose={onClose}
                    handleConfirm={handleDelete}
                    title="Eliminar encargo"
                    body="¿Está seguro de que desea eliminar el encargo?"
                />
            )}
        </>
    );
};

export default GridInfoEncargo;
