import { SimpleGrid, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Responsables = ({ formData }) => {
    const navigate = useNavigate();
    return (
        <SimpleGrid columns={2}>
            <Text>Representante</Text>
            <Text
                fontWeight="bold"
                onClick={() => navigate(`/usuarios/ver/${formData.responsable.uuid}`)}
                _hover={{ cursor: "pointer", textDecoration: "underline" }}
            >
                {formData.responsable.nombre} {formData.responsable.apellidos}
            </Text>
        </SimpleGrid>
    );
};

export default Responsables;
