import { ChakraProvider } from "@chakra-ui/react";
import AppRoutes from "./routes/AppRoutes";
import "./App.css";

function App() {

    return (
        <ChakraProvider>
            <AppRoutes />
        </ChakraProvider>
    );
}

export default App;
