import { NumberInput, NumberInputField, Select, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getAllUsuarios } from "../../../services/usuarios";

const EstadoNoticia = ({ noticia, handleChange }) => {
    const [colaboradores, setColaboradores] = useState([]);
    const [needReload, setNeedReload] = useState(true);

    useEffect(() => {
        const getColaboradores = async () => {
            setNeedReload(false);
            const colaboradores = await getAllUsuarios();
            setColaboradores(colaboradores);
        };
        needReload && getColaboradores();
    }, [needReload]);

    return (
        <Stack direction="column" p={4} border="1px solid black" rounded="md">
            <SimpleGrid columns={2}>
                <Text>Motivación</Text>
                <Select name="motivacion" value={noticia.motivacion} onChange={handleChange} isDisabled={!handleChange}>
                    <option value="Alquilar">Alquilar</option>
                    <option value="Vender">Vender</option>
                </Select>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Estado</Text>
                <Select name="estado" value={noticia.estado} onChange={handleChange} isDisabled={!handleChange}>
                    <option value="No verificada con propietario">No verificada con propietario</option>
                    <option value="Inmueble en el mercado">Inmueble en el mercado</option>
                    <option value="Cerrada sin exito">Cerrada sin éxito</option>
                    <option value="Cerrada con exito">Cerrada con éxito</option>
                </Select>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Procedencia</Text>
                <Select
                    name="procedencia"
                    value={noticia.procedencia}
                    onChange={handleChange}
                    placeholder="-- No seleccionado --"
                    isDisabled={!handleChange}
                >
                    <option value="Directa">Directa</option>
                    <option value="Publicidad">Publicidad</option>
                    <option value="Informador">Informador</option>
                    <option value="Colaborador">Colaborador</option>
                </Select>
            </SimpleGrid>
            {noticia.procedencia === "Colaborador" && (
                <SimpleGrid columns={2}>
                    <Text>Colaborador</Text>
                    <Select
                        name="procedencia_colaborador"
                        value={noticia.procedencia_colaborador}
                        onChange={handleChange}
                        placeholder="-- No seleccionado --"
                        isDisabled={!handleChange}
                    >
                        {colaboradores.map((colaborador) => (
                            <option key={colaborador.uuid} value={colaborador.uuid}>
                                {colaborador.nombre} {colaborador.apellidos}
                            </option>
                        ))}
                    </Select>
                </SimpleGrid>
            )}
            <SimpleGrid columns={2}>
                <Text>Precio pedido por el cliente</Text>
                <NumberInput value={noticia.precio_cliente || undefined} isDisabled={!handleChange}>
                    <NumberInputField borderLeft="3px solid red" name="precio_cliente" onChange={handleChange} />
                </NumberInput>
            </SimpleGrid>
        </Stack>
    );
};

export default EstadoNoticia;
