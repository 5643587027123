import { Td, Tr } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"

const TableItem  = ({ agencia }) => {
    const navigate = useNavigate()

    const handleVerAgencia = () => {
        navigate(`/agencias/ver/${agencia.uuid}`)
    }   

    return (
        <Tr key={agencia.id} onClick={handleVerAgencia} _hover={{ cursor: "pointer", bg: "gray.100" }}>
            <Td>{agencia.nombre}</Td>
            {agencia.direccion? <Td>
                {agencia.direccion.calle}, {agencia.direccion.numero}. {agencia.direccion.provincia} - {agencia.direccion.localidad}, {agencia.direccion.codigo_postal} </Td>
                : <Td fontSize= "12px" fontStyle="italic">- Sin datos -</Td>}
            <Td>{agencia.activada ? "Sí" : "No"}</Td>
        </Tr>
    )
}

export default TableItem