import { Center, Input } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { removeAccents } from "../../utils/removeAccents";

const FiltrosClientes = ({ datos, setDatosFiltrados }) => {
    const [textoFiltro, setTextoFiltro] = useState("");

    useEffect(() => {
        const filtrarDatos = () => {
            let datosFiltrados = datos;

            if (textoFiltro !== "") {
                const texto = removeAccents(textoFiltro.toLowerCase());
                datosFiltrados = datosFiltrados.filter((dato) => {
                    let datosContacto;

                    try {
                        datosContacto = JSON.parse(dato.otros_datos_contacto);
                    } catch (error) {
                        datosContacto = JSON.parse(JSON.stringify(dato.otros_datos_contacto));
                    }

                    const fullName = dato.nombre + " " + dato.apellidos;
                    const searchKeywords = texto.split(" ");

                    return searchKeywords.every((keyword) =>
                        [
                            removeAccents(fullName.toLowerCase()),
                            dato.identificador?.toLowerCase(),
                            dato.telefono?.toLowerCase(),
                            datosContacto.email?.toLowerCase(),
                            removeAccents(datosContacto?.direccion?.toLowerCase()),
                            datosContacto?.numero?.toLowerCase(),
                            removeAccents(datosContacto?.localidad?.toLowerCase()),
                            removeAccents(datosContacto?.provincia?.toLowerCase()),
                        ].some((value) => value?.includes(keyword))
                    );
                });
            }

            setDatosFiltrados(datosFiltrados);
        };
        datos.length > 0 && setDatosFiltrados && filtrarDatos();
    }, [datos, setDatosFiltrados, textoFiltro]);

    return (
        <Center>
            <Input
                borderColor="facebook.200"
                rounded="none"
                placeholder="Buscar..."
                onChange={(e) => setTextoFiltro(e.target.value)}
            />
        </Center>
    );
};

export default FiltrosClientes;
