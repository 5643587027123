import { Center, Checkbox, Heading, SimpleGrid, Stack, Text } from "@chakra-ui/react";

const Suministros = ({ formData, handleChange }) => {
    let info;
    try {
        info = JSON.parse(formData);
    } catch (error) {
        info = JSON.parse(JSON.stringify(formData));
    }
    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Suministros</Heading>
            <SimpleGrid columns={2} w="100%">
                <Stack direction="column" w="100%">
                    <Text textAlign="center">Suministro de luz</Text>
                    <Center>
                        <Checkbox
                            size="lg"
                            name="otros.suministro_luz"
                            isChecked={info.suministro_luz}
                            onChange={handleChange}
                            isDisabled={!handleChange}
                        />
                    </Center>
                </Stack>

                <Stack direction="column" w="100%">
                    <Text textAlign="center">Suministro de agua</Text>
                    <Center>
                        <Checkbox
                            size="lg"
                            name="otros.suministro_agua"
                            isChecked={info.suministro_agua}
                            onChange={handleChange}
                            isDisabled={!handleChange}
                        />
                    </Center>
                </Stack>
            </SimpleGrid>
        </Stack>
    );
};

export default Suministros;
