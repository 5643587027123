import {
    Box,
    Button,
    Center,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    NumberInput,
    NumberInputField,
    Radio,
    RadioGroup,
    SimpleGrid,
    Stack,
    Text,
    useToast,
} from "@chakra-ui/react";

import { useState } from "react";
import { crearActividad, updateActividad } from "../../../services/actividades";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";
import { editarEncargo } from "../../../services/encargos";
import EasyDatePicker from "../../EasyDatePicker/EasyDatePicker";
import { TIPOS_CITAS } from "../../../data/constants";
import { createEvent } from "../../../services/agenda";

const GestionEncargoProcesoGuiado = ({ isOpen, onClose, onClosePadre, datos }) => {
    const [pedidoRenovado, setPedidoRenovado] = useState("No");
    const [cambioPrecio, setCambioPrecio] = useState("No");
    const [nuevoPrecio, setNuevoPrecio] = useState(datos.precio_final);
    const [nuevaFechaVencimiento, setNuevaFechaVencimiento] = useState(datos.vencimiento_encargo);

    const toast = useToast();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let datosActividad;
            if (datos.actividadUuid) {
                datosActividad = await updateActividad(datos.actividadUuid, datos);
            } else {
                datosActividad = await crearActividad(datos);
            }

            let info = {};

            if (pedidoRenovado === "Si") {
                info = {
                    ...info,
                    fecha_vencimiento: nuevaFechaVencimiento,
                };
            }

            if (cambioPrecio === "Si") {
                // Cambiar precio
                info = {
                    ...info,
                    precio_final: nuevoPrecio,
                };
            }

            await editarEncargo(datos.encargoUuid, info);

            if (datos.modalidad_contacto === "Presencial") {
                await createEvent({
                    entidad_relacionada: "Encargo",
                    fechaInicio: datos.fechaInicio,
                    fechaFin: datos.fechaFin,
                    estado: "Programada",
                    tipo: "Gestion de encargo",
                    descripcion: datos.descripcion,
                    color: TIPOS_CITAS.find((tipo) => tipo.tipo === "Gestion de encargo").color,
                    responsableUuid: datos.responsableUuid,
                    clienteUuid: datos.clienteUuid,
                    inmuebleUuid: datos.inmuebleUuid,
                    actividadeUuid: datosActividad.uuid,
                });
            }

            okNotification("Actividad creada. Encargo actualizado.", toast);
        } catch (error) {
            errorNotification("Error al crear la actividad", toast);
        }
        onClose();
        onClosePadre();
        window.location.reload();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="6xl">
            <ModalOverlay bgColor="blackAlpha.800" />
            <ModalContent>
                <ModalHeader>Proceso guiado</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack direction="column">
                        <SimpleGrid columns={2} spacing={4}>
                            <Stack>
                                <Text textAlign="center">¿Ha renovado el encargo? (+30 DÍAS)</Text>
                                <Center>
                                    <RadioGroup onChange={setPedidoRenovado} value={pedidoRenovado}>
                                        <Stack direction="row">
                                            <Radio value="Si" size="lg">
                                                Si
                                            </Radio>
                                            <Radio value="No" size="lg">
                                                No
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </Center>
                            </Stack>
                            <Stack>
                                <Text textAlign="center">¿Quiere modificar el precio?</Text>
                                <Center>
                                    <RadioGroup onChange={setCambioPrecio} value={cambioPrecio}>
                                        <Stack direction="row">
                                            <Radio value="Si" size="lg">
                                                Si
                                            </Radio>
                                            <Radio value="No" size="lg">
                                                No
                                            </Radio>
                                        </Stack>
                                    </RadioGroup>
                                </Center>
                            </Stack>
                        </SimpleGrid>
                        {pedidoRenovado === "Si" && (
                            <Box my={4} p={4} border="1px solid black" rounded="md">
                                <SimpleGrid columns={2}>
                                    <Text>Nuevo vencimiento</Text>
                                    <EasyDatePicker
                                        name="vencimiento_encargo"
                                        value={nuevaFechaVencimiento}
                                        handleChange={(fecha) => setNuevaFechaVencimiento(fecha.target.value)}
                                    />
                                </SimpleGrid>
                            </Box>
                        )}
                        {cambioPrecio === "Si" && (
                            <Box my={4} p={4} border="1px solid black" rounded="md">
                                <SimpleGrid columns={2}>
                                    <Text>Nuevo precio</Text>
                                    <NumberInput value={nuevoPrecio}>
                                        <NumberInputField
                                            borderLeft="3px solid red"
                                            name="precio_final"
                                            onChange={(e) => setNuevoPrecio(e.target.value)}
                                        />
                                    </NumberInput>
                                </SimpleGrid>
                            </Box>
                        )}
                        <Button
                            colorScheme="blue"
                            size="sm"
                            mt={4}
                            onClick={handleSubmit}
                            isDisabled={cambioPrecio === "Si" && !nuevoPrecio}>
                            Guardar
                        </Button>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default GestionEncargoProcesoGuiado;
