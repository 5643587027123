import { useEffect, useState } from "react";

import { Box, Heading, Table, TableContainer, Tbody, Td, Thead, Tr } from "@chakra-ui/react";
import Layout from "../../components/Layout/Layout";
import { getAllEncargos } from "../../services/encargos";
import { Link } from "react-router-dom";
import EasyDatePicker from "../../components/EasyDatePicker/EasyDatePicker";
import FiltroEncargos from "../../components/Filtros/FiltroEncargos";

const ListarEncargos = () => {
    const [encargos, setEncargos] = useState([]);
    const [encargosFiltrados, setEncargosFiltrados] = useState(encargos);
    const [needReload, setNeedReload] = useState(true);

    useEffect(() => {
        const getInmuebles = async () => {
            setNeedReload(false);
            const auxEncargos = await getAllEncargos();
            setEncargos(auxEncargos);
        };

        needReload && getInmuebles();
    }, [needReload]);

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center" color="facebook.800">
                    ENCARGOS
                </Heading>
            </Box>

            <FiltroEncargos datos={encargos} setDatosFiltrados={setEncargosFiltrados} />

            <Box mt={6}>
                <TableContainer w="100%" border="1px" borderColor="facebook.400" rounded="lg" shadow="md">
                    <Table size="lg" p={4}>
                        <Thead bgColor="facebook.100">
                            <Tr>
                                <Td>Encargo</Td>
                                <Td>Inmueble</Td>
                                <Td>Propietario</Td>
                                <Td>Motivo</Td>
                                <Td>Precio</Td>
                                <Td>Responsable</Td>
                                <Td>Creación</Td>
                                <Td>Modificación</Td>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {encargosFiltrados &&
                                encargosFiltrados.map((encargo) => {
                                    let direccion;
                                    if (encargo && encargo.inmueble && encargo.inmueble.ubicacion) {
                                        try {
                                            direccion = JSON.parse(encargo.inmueble.ubicacion).direccion;
                                        } catch (error) {
                                            direccion = JSON.parse(
                                                JSON.stringify(encargo.inmueble.ubicacion)
                                            ).direccion;
                                        }
                                    }
                                    return (
                                        <Tr
                                            key={encargo.uuid}
                                            h="100%"
                                            _hover={{ cursor: "pointer", bg: "gray.100" }}>
                                            <TdLink encargo={encargo.uuid}>
                                                EN - {encargo.cliente?.nombre} {encargo.cliente?.apellidos} -{" "}
                                                {encargo.estado}
                                            </TdLink>
                                            <TdLink encargo={encargo.uuid}>
                                                <strong>
                                                    {encargo.inmueble?.tipo_inmueble}{" "}
                                                    {encargo.inmueble?.subtipo_inmueble}
                                                </strong>{" "}
                                                en <strong>{direccion}</strong>
                                            </TdLink>
                                            <TdLink encargo={encargo.uuid}>
                                                {encargo.cliente?.nombre} {encargo.cliente?.apellidos}
                                            </TdLink>
                                            <TdLink encargo={encargo.uuid}>{encargo.noticia.motivacion}</TdLink>
                                            <TdLink encargo={encargo.uuid}>{encargo.precio_final}</TdLink>
                                            <TdLink encargo={encargo.uuid}>
                                                {encargo.responsable?.nombre} {encargo.responsable?.apellidos}
                                            </TdLink>
                                            <TdLink encargo={encargo.uuid}>
                                                <EasyDatePicker value={encargo.createdAt} needHour={false} />
                                            </TdLink>
                                            <TdLink encargo={encargo.uuid}>
                                                <EasyDatePicker value={encargo.updatedAt} needHour={false} />
                                            </TdLink>
                                        </Tr>
                                    );
                                })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Layout>
    );
};

const TdLink = ({ children, encargo }) => {
    return (
        <Td>
            <Link to={`/encargos/ver/${encargo}`}>{children}</Link>
        </Td>
    );
};

export default ListarEncargos;
