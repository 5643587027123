import { Button, SimpleGrid, Stack, useDisclosure, useToast } from "@chakra-ui/react";
import InfoGeneral from "./InfoGeneral";
import EstadoNoticia from "./EstadoNoticia";
import { useNavigate } from "react-router-dom";
import Fechas from "./Fechas";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";
import { updateNoticia } from "../../../services/noticias";
import { useSelector } from "react-redux";
import { deletePedido } from "../../../services/pedidos";
import { useRef } from "react";
import ConfirmationModal from "../../Modals/ConfirmationModal";

const GridInfoNoticias = ({ noticia, handleChange }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const { user } = useSelector((state) => state.auth);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();

    const handleSave = async () => {
        try {
            await updateNoticia(noticia.uuid, noticia);
            okNotification("Noticia actualizada correctamente", toast);
            navigate(-1);
        } catch (error) {
            errorNotification("Error al actualizar la noticia", toast);
        }
    };

    const handleDelete = async () => {
        try {
            await deletePedido(noticia.uuid);
            okNotification("Noticia eliminada correctamente", toast);
            navigate(-1);
        } catch (error) {
            console.error("🚀 ~ file: GridInfoNoticias.js:32 ~ handleDelete ~ error:", error);
            errorNotification("Error al eliminar la noticia", toast);
        }
    };

    return (
        <>
            <SimpleGrid columns={3} spacing={4} mb={4}>
                <Stack direction="column">
                    <InfoGeneral noticia={noticia} handleChange={handleChange} />
                </Stack>
                <EstadoNoticia noticia={noticia} handleChange={handleChange} />
                <Stack direction="column">
                    <Fechas noticia={noticia} handleChange={handleChange} />
                    {handleChange == null && (
                        <Stack spacing={0}>
                            <Stack direction={{ base: "column", md: "row" }} spacing={0} w="100%">
                                {(user?.rol === "Gerente" ||
                                    user?.rol === "Responsable" ||
                                    (user?.rol === "Asesor" && user?.uuid === noticia.responsableUuid)) && (
                                    <Button
                                        colorScheme="facebook"
                                        rounded="none"
                                        w="100%"
                                        onClick={() => navigate(`/noticias/editar/${noticia.uuid}`)}>
                                        EDITAR
                                    </Button>
                                )}

                                <Button colorScheme="facebook" rounded="none" w="100%" onClick={() => navigate(-1)}>
                                    VOLVER
                                </Button>
                            </Stack>
                            {user?.rol === "Gerente" && (
                                <Button colorScheme="red" w="100%" rounded="none" size="sm" onClick={onOpen}>
                                    ELIMINAR
                                </Button>
                            )}
                        </Stack>
                    )}
                    {handleChange != null && (
                        <>
                            <Stack direction={{ base: "column", md: "row" }} spacing={0} w="100%">
                                <Button colorScheme="green" w="100%" rounded="none" onClick={handleSave}>
                                    GUARDAR
                                </Button>
                                <Button colorScheme="facebook" w="100%" rounded="none" onClick={() => navigate(-1)}>
                                    VOLVER
                                </Button>
                            </Stack>
                        </>
                    )}
                </Stack>
            </SimpleGrid>

            {isOpen && (
                <ConfirmationModal
                    show={isOpen}
                    cancelRef={cancelRef}
                    handleClose={onClose}
                    handleConfirm={handleDelete}
                    title="Eliminar noticia"
                    body="¿Está seguro que desea eliminar la noticia?"
                />
            )}
        </>
    );
};

export default GridInfoNoticias;
