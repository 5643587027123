import DateTimePicker from "react-datetime-picker";
import styles from "./EasyDatePicker.module.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { Text } from "@chakra-ui/react";

const EasyDatePickerWithTime = ({ name, value, handleChange }) => {
    const fechaPlazo = new Date(value);
    const formattedDate = fechaPlazo.toLocaleDateString("es-ES", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
    });

    return handleChange ? (
        <DateTimePicker
            clearIcon={null}
            dateFormat="dd/MM/yyyy h:mm"
            name={name}
            value={value}
            onChange={handleChange}
            className={styles.datePickerRestyleV2}
            showTimeInput
        />
    ) : (
        <Text fontWeight="bold" border="1px" borderColor="blackAlpha.400" rounded="md" p={2} cursor="not-allowed">
            {formattedDate}
        </Text>
    );
};

export default EasyDatePickerWithTime;
