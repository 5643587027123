import { Box, Heading } from "@chakra-ui/react";
import GridInfoPedidos from "../../components/Pedidos/CRUD/GridInfoPedidos";
import Layout from "../../components/Layout/Layout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getPedidoById } from "../../services/pedidos";

const VerPedido = () => {
    const [pedido, setPedido] = useState();
    const [needReload, setNeedReload] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const getPedido = async () => {
            setNeedReload(false);
            const estePedido = await getPedidoById(id);
            setPedido(estePedido);
        };

        needReload && getPedido();
    }, [needReload, id]);

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center">Pedido</Heading>
            </Box>

            {pedido && <GridInfoPedidos formData={pedido} />}
        </Layout>
    );
};

export default VerPedido;
