import { Box, Center, Heading } from "@chakra-ui/react";

const HeaderBox = ({ nombre }) => {
    return (
        <Box bg={"gray.700"} p={4} borderRadius="md">
            <Center h="100%">
                <Heading size="md" textAlign="center" color="white" textTransform="uppercase">
                    {nombre}
                </Heading>
            </Center>
        </Box>
    );
};

export default HeaderBox;
