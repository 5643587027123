import { Box, Button, Heading, Spacer, Stack, Table, TableContainer, Tbody, Td, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import UsuarioTableItem from "../../components/Usuarios/TablaUsuarios/UsuarioTableItem";
import { getAllUsuarios } from "../../services/usuarios";
import Layout from "../../components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import { AddIcon } from "@chakra-ui/icons";

const ListarUsuarios = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [needReload, setNeedReload] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const getUsuarios = async () => {
            const users = await getAllUsuarios();
            setUsuarios(users);
            setNeedReload(false);
        };

        needReload && getUsuarios();
    }, [needReload]);

    return (
        <Layout>
            <Box w="100%">
                <Heading textAlign="center" color="facebook.800">
                    USUARIOS
                </Heading>
            </Box>
            <Stack direction="row" mt={-10}>
                <Spacer />
                <Button colorScheme="facebook" rounded="none" onClick={() => navigate("/usuarios/crear")}>
                    <AddIcon />
                </Button>
            </Stack>
            <Box mt={6}>
                <TableContainer w="100%" border="1px" borderColor="facebook.400" rounded="lg" shadow="dark-lg">
                    <Table size="lg" p={4}>
                        <Thead bgColor="facebook.100">
                            <Tr>
                                <Td>Nombre y apellidos</Td>
                                <Td>Tipo identificador</Td>
                                <Td>Identificador</Td>
                                <Td>Telefono trabajo</Td>
                                <Td>Telefono personal</Td>
                                <Td>Correo</Td>
                                <Td>Agencia</Td>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {usuarios &&
                                usuarios.map((usuario) => {
                                    return <UsuarioTableItem key={usuario.uuid} usuario={usuario} />;
                                })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Layout>
    );
};

export default ListarUsuarios;
