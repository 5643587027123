import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    SimpleGrid,
    Stack,
    Text,
    Textarea,
    useToast,
} from "@chakra-ui/react";

import { crearActividad, updateActividad } from "../../../services/actividades";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";
import EasyDatePickerWithTime from "../../EasyDatePicker/EasyDatePickerWithTime";
import { useState } from "react";
import { createEvent } from "../../../utils/createEvent";
import { TIPOS_CITAS } from "../../../data/constants";

const PromocionProcesoGuiado = ({ isOpen, onClose, onClosePadre, datos }) => {
    const toast = useToast();
    const [crearCita, setCrearCita] = useState(false);
    const [fechaInicio, setFechaInicio] = useState(new Date());
    // fechaFin = fechaInicio + 1 hora
    const [fechaFin, setFechaFin] = useState(new Date(new Date().getTime() + 30 * 60000));
    const [comentario, setComentario] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (crearCita) {
                let actividad;
                if (datos.actividadUuid) {
                    actividad = await updateActividad(datos.actividadUuid, datos);
                } else {
                    actividad = await crearActividad(datos);
                }

                await createEvent({
                    entidad_relacionada: "Inmueble",
                    fechaInicio: fechaInicio || new Date(),
                    fechaFin: fechaFin || new Date(new Date().getTime() + 30 * 60000),
                    estado: "Programada",
                    tipo: "Promocion",
                    descripcion: datos.descripcion,
                    color: TIPOS_CITAS.find((tipo) => tipo.tipo === "Promocion").color,
                    responsableUuid: datos.responsableUuid,
                    clienteUuid: datos.clienteUuid,
                    inmuebleUuid: datos.inmuebleUuid,
                    actividadeUuid: actividad.uuid,
                });

                okNotification("Actividad creada. Cita creada.", toast);
            } else {
                if (datos.actividadUuid) {
                    await updateActividad(datos.actividadUuid, datos);
                } else {
                    await crearActividad(datos);
                }

                okNotification("Actividad creada.", toast);
            }
        } catch (error) {
            errorNotification("Error al crear la actividad", toast);
        }
        onClose();
        onClosePadre();
        window.location.reload();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="6xl">
            <ModalOverlay bgColor="blackAlpha.800" />
            <ModalContent>
                <ModalHeader>Proceso guiado</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack direction="column">
                        <Text textAlign="center">¿Quieres hacer una cita de venta?</Text>
                        <RadioGroup onChange={(e) => setCrearCita(e)}>
                            <SimpleGrid columns={2} spacing={10}>
                                <Radio value={true}>Si</Radio>
                                <Radio value={false}>No</Radio>
                            </SimpleGrid>
                        </RadioGroup>

                        {crearCita && (
                            <Stack direction="column">
                                <Box>
                                    <Text textAlign="center">Fecha de inicio</Text>
                                    <EasyDatePickerWithTime
                                        name="fecha_inicio"
                                        value={fechaInicio}
                                        handleChange={(e) => setFechaInicio(e)}
                                    />
                                </Box>
                                <Box>
                                    <Text textAlign="center">Fecha de fin</Text>
                                    <EasyDatePickerWithTime
                                        handleChange={(e) => setFechaFin(e)}
                                    />
                                </Box>
                                <Textarea value={comentario} onChange={(e) => setComentario(e.target.value)} />
                            </Stack>
                        )}

                        <Button
                            colorScheme="blue"
                            size="sm"
                            mt={4}
                            onClick={handleSubmit}
                            isDisabled={crearCita && (!fechaInicio || !fechaFin || !comentario)}>
                            Guardar
                        </Button>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default PromocionProcesoGuiado;
