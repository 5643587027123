import {
    Button,
    Center,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Select,
    SimpleGrid,
    Stack,
    Text,
    useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { crearActividad, updateActividad } from "../../../services/actividades";
import { updatePedido } from "../../../services/pedidos";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";

const ActualizacionProcesoGuiado = ({ isOpen, onClose, onClosePadre, datos }) => {
    const [sigueInteresado, setSigueInteresado] = useState("Si");
    const [razon, setRazon] = useState("No busca mas");

    const toast = useToast();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (sigueInteresado === "Si") {
                if (datos.actividadUuid) {
                    await updateActividad(datos.actividadUuid, datos);
                } else {
                    await crearActividad(datos);
                }
                okNotification("Actividad creada correctamente", toast);
            } else if (sigueInteresado === "No") {
                if (datos.actividadUuid) {
                    await updateActividad(datos.actividadUuid, datos);
                } else {
                    await crearActividad(datos);
                }
                await updatePedido(datos.pedidoUuid, { estado_pedido: "Cerrado", motivo_cierre: razon });
                okNotification("Actividad creada. Pedido cerrado", toast);
            } else if (sigueInteresado === "OtraZona") {
                if (datos.actividadUuid) {
                    await updateActividad(datos.actividadUuid, datos);
                } else {
                    await crearActividad(datos);
                }
                okNotification("Actividad creada correctamente", toast);
                //await updatePedido(datos.pedidoUuid, { estado_pedido: "Cerrado" })
            }
        } catch (error) {
            errorNotification("Error al crear la actividad", toast);
        }
        onClose();
        onClosePadre();
        window.location.reload();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay bgColor="blackAlpha.800" />
            <ModalContent>
                <ModalHeader>Proceso guiado</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack direction="column">
                        <Text textAlign="center">¿Sigue interesado?</Text>
                        <Center>
                            <RadioGroup onChange={setSigueInteresado} value={sigueInteresado}>
                                <Stack direction="row">
                                    <Radio value="Si" size="lg">
                                        Si
                                    </Radio>
                                    <Radio value="No" size="lg">
                                        No
                                    </Radio>
                                    <Radio value="OtraZona" size="lg">
                                        Sí, pero no en mi zona.
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </Center>
                        {sigueInteresado === "No" && (
                            <SimpleGrid columns={2}>
                                <Text>¿Por qué?</Text>
                                <Select name="razon" value={razon} onChange={(e) => setRazon(e.target.value)}>
                                    <option value="No busca mas">No busca mas</option>
                                    <option value="Encontro con la competencia">Encontro con la competencia</option>
                                    <option value="Encontro con particular">Encontro con particular</option>
                                </Select>
                            </SimpleGrid>
                        )}
                        <Button colorScheme="blue"  size="sm" mt={4} onClick={handleSubmit}>
                            Guardar
                        </Button>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default ActualizacionProcesoGuiado;
