import {
    Box,
    Button,
    Center,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    SimpleGrid,
    Spinner,
    Stack,
    Text,
    Textarea,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getAllUsuarios } from "../../../services/usuarios";
import { crearActividad, updateActividad } from "../../../services/actividades";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";
import PromocionProcesoGuiado from "./PromocionProcesoGuiado";
import { actualizarPedidoEncargos, getPedidoEncargoByActividad } from "../../../services/pedidos";
import BoxEncargo from "../../Cruce/BoxEncargo";
import EasyDatePickerWithTime from "../../EasyDatePicker/EasyDatePickerWithTime";
import { createEvent } from "../../../services/agenda";
import { TIPOS_CITAS } from "../../../data/constants";
import { actualizarEncargoPedidos, getEncargoPedidoByActividad } from "../../../services/encargos";
import BoxPedido from "../../Cruce/BoxPedido";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EasyDatePicker from "../../EasyDatePicker/EasyDatePicker";

const VentaAlquilerV2Modal = ({ isOpen, onClose, datos, isEdit, esEncargo = false }) => {
    const selectorDatos = esEncargo ? datos.pedidosSeleccionados : datos.encargosSeleccionados;
    const [responsables, setResponsables] = useState([]);
    const [needReload, setNeedReload] = useState(true);
    const [formData, setFormData] = useState(datos);
    const [datosSeleccionados, setDatosSeleccionados] = useState(selectorDatos);

    const navigate = useNavigate();

    esEncargo = esEncargo || datos.entidad_relacionada === "Encargo";
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();

    esEncargo = esEncargo || false;

    const [datosEnviados, setDatosEnviados] = useState({});
    const { user } = useSelector((state) => state.auth);

    const { isOpen: isOpenProcesoGuiado, onOpen: onOpenProcesoGuiado, onClose: onCloseProcesoGuiado } = useDisclosure();

    useEffect(() => {
        const fetchInfo = async () => {
            setNeedReload(false);
            setIsLoading(true);

            const auxResponsables = await getAllUsuarios();
            setResponsables(auxResponsables);

            if (!isEdit) {
                const newDate = new Date();

                setFormData({
                    ...formData,
                    responsable: user.uuid,
                    estado_actividad: "Programada",
                    modalidad_contacto: "Llamada",
                    fechaInicio: newDate,
                    fechaFin: new Date(newDate.getTime() + 30 * 60000),
                });
            } else {
                const promise = esEncargo ? await getEncargoPedidoByActividad(datos.uuid) : await getPedidoEncargoByActividad(datos.uuid);

                setDatosSeleccionados(promise);
            }
            setIsLoading(false);
        };

        needReload && user && fetchInfo();
    }, [formData, needReload, isEdit, datos.uuid, esEncargo, user]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangeDatos = (encargo, e) => {
        let test = datosSeleccionados.find((enc) => enc.uuid === encargo);
        test = { ...test, [e.target.name]: e.target.value };
        setDatosSeleccionados([...datosSeleccionados.filter((enc) => enc.uuid !== encargo), test]);
    };

    const getDataForSend = () => {
        return {
            entidad_relacionada: esEncargo ? "Encargo" : "Pedido",
            tipo_actividad: "Venta o alquiler",
            modalidad_contacto: formData.modalidad_contacto,
            fecha_actividad: formData.fecha_actividad,
            fecha_vencimiento: formData.fecha_vencimiento,
            fecha_finalizacion: formData.estado_actividad === "Realizada" ? new Date() : null,
            estado_actividad: formData.estado_actividad,
            descripcion: formData.descripcion,
            recordatorio: false,
            recordatorio_fecha: null,
            responsableUuid: formData.responsable?.uuid || formData.responsableUuid || user.uuid,
            clienteUuid: datos.cliente.uuid,
            pedidoUuid: datos.pedido?.uuid || datos.pedidoUuid,
            encargoUuid: datos.encargo?.uuid || datos.encargoUuid,
            inmuebleUuid: datosSeleccionados[0]?.inmueble?.uuid || datos.inmuebleUuid || datos.inmueble?.uuid,
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const notificationMessage = !isEdit ? "Actividad creada correctamente" : "Actividad actualizada correctamente";
        const errorMessage = "Error al crear la actividad";

        try {
            const auxEncargoUuid = datos.encargo?.uuid || datos.encargoUuid;
            const auxPedidoUuid = datos.pedido?.uuid || datos.pedidoUuid;

            let actividad;
            if (!isEdit) {
                actividad = await crearActividad(getDataForSend());
                await createEvent({
                    entidad_relacionada: "Encargo",
                    fechaInicio: formData.fechaInicio || new Date(),
                    fechaFin: formData.fechaFin || new Date(new Date().getTime() + 30 * 60000),
                    estado: "Programada",
                    tipo: "Venta o alquiler",
                    ubicacion: formData.ubicacion,
                    descripcion: formData.descripcion,
                    color: TIPOS_CITAS.find((tipo) => tipo.tipo === "Venta o alquiler").color,
                    responsableUuid: formData.responsable?.uuid || formData.responsableUuid || user.uuid,
                    clienteUuid: datos.cliente.uuid,
                    actividadeUuid: actividad.uuid,
                    pedidoUuid: auxPedidoUuid,
                    encargoUuid: auxEncargoUuid,
                    inmuebleUuid: datosSeleccionados[0]?.inmueble?.uuid || datos.inmuebleUuid || datos.inmueble?.uuid,
                });
            } else {
                actividad = await updateActividad(datos.uuid, getDataForSend());
            }

            if (esEncargo) {
                await actualizarEncargoPedidos(auxEncargoUuid, {
                    pedidosSeleccionados: datosSeleccionados,
                    actividadUuid: actividad.uuid,
                });
            } else {
                await actualizarPedidoEncargos(auxPedidoUuid, {
                    encargosSeleccionados: datosSeleccionados,
                    actividadUuid: actividad.uuid,
                });
            }

            okNotification(notificationMessage, toast);
            onClose();
            if (!isEdit) navigate(-1);
            if (isEdit) window.location.reload();
        } catch (error) {
            errorNotification(errorMessage, toast);
            console.error("🚀 ~ file: ActualizacionModal.js:7 ~ ActualizacionModal ~ error", error);
        }
    };

    useEffect(() => {
        const changeFechaInicio = () => {
            try {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    fechaFin: new Date(prevFormData.fechaInicio.getTime() + 30 * 60000),
                }));
            } catch (error) {
                console.error("🚀 ~ file: VentaAlquilerV2Modal.js:178 ~ changeFechaInicio ~ error:", error);
            }
        };

        if (formData?.fechaInicio) {
            changeFechaInicio();
        }
    }, [formData.fechaInicio]);

    const handleProcesoGuiado = () => {
        const parseData = getDataForSend();
        setDatosEnviados({
            ...parseData,
            responsables,
            encargosSeleccionados: datos.datosSeleccionados,
        });
        onOpenProcesoGuiado();
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered>
                <ModalOverlay bgColor="blackAlpha.800" />
                <ModalContent>
                    <ModalHeader>Cita de venta/alquiler</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {!isLoading && (
                            <Stack direction="column" spacing={4}>
                                <SimpleGrid columns={2}>
                                    <Text>Cliente</Text>
                                    <Input
                                        name="cliente"
                                        value={`${datos.cliente?.nombre} ${datos.cliente?.apellidos}`}
                                        isReadOnly
                                        isDisabled
                                    />
                                </SimpleGrid>

                                <SimpleGrid columns={2}>
                                    <Text>Entidad relacionada</Text>
                                    {esEncargo ? (
                                        <Input
                                            name="entidad"
                                            value={`EN - ${datos.encargo?.inmueble?.tipo_inmueble} ${datos.encargo?.inmueble?.subtipo_inmueble} - ${datos.cliente?.nombre} ${datos.cliente?.apellidos}`}
                                            isReadOnly
                                            isDisabled
                                        />
                                    ) : (
                                        <Input
                                            name="entidad"
                                            value={`PE - ${datos.pedido?.tipo_inmueble} ${datos.pedido?.subtipo_inmueble} - ${datos.cliente?.nombre} ${datos.cliente?.apellidos}`}
                                            isReadOnly
                                            isDisabled
                                        />
                                    )}
                                </SimpleGrid>

                                <SimpleGrid columns={2}>
                                    <Text>Modalidad contacto</Text>
                                    <Select
                                        name="modalidad_contacto"
                                        value={formData.modalidad_contacto}
                                        onChange={handleChange}
                                        isDisabled={datos.fecha_finalizacion}>
                                        <option value="Llamada">Llamada</option>
                                        <option value="Presencial">Presencial</option>
                                    </Select>
                                </SimpleGrid>

                                <SimpleGrid columns={2}>
                                    <Text>Responsable</Text>
                                    <Select
                                        name="responsableUuid"
                                        value={formData.responsableUuid}
                                        onChange={handleChange}
                                        isDisabled={datos.fecha_finalizacion}>
                                        {responsables.map((responsable) => (
                                            <option key={responsable.uuid} value={responsable.uuid}>
                                                {responsable.nombre} {responsable.apellidos}
                                            </option>
                                        ))}
                                    </Select>
                                </SimpleGrid>

                                <SimpleGrid columns={2}>
                                    <Text>Fecha actividad</Text>
                                    <EasyDatePicker
                                        name="fecha_actividad"
                                        value={formData.fecha_actividad || new Date()}
                                        handleChange={datos.fecha_finalizacion ? null : handleChange}
                                    />
                                </SimpleGrid>

                                {!isEdit && !datos.fecha_finalizacion && (
                                    <>
                                        <SimpleGrid columns={2}>
                                            <Text>Fecha inicio</Text>
                                            <EasyDatePickerWithTime
                                                name="fechaInicio"
                                                value={formData.fechaInicio}
                                                handleChange={
                                                    datos.fecha_finalizacion
                                                        ? null
                                                        : (fecha) => setFormData({ ...formData, fechaInicio: fecha })
                                                }
                                                isDisabled={datos.fecha_finalizacion}
                                            />
                                        </SimpleGrid>

                                        <SimpleGrid columns={2}>
                                            <Text>Fecha inicio</Text>
                                            <EasyDatePickerWithTime
                                                name="fechaFin"
                                                value={formData.fechaFin}
                                                handleChange={
                                                    datos.fecha_finalizacion
                                                        ? null
                                                        : (fecha) => setFormData({ ...formData, fechaFin: fecha })
                                                }
                                                isDisabled={datos.fecha_finalizacion}
                                            />
                                        </SimpleGrid>
                                    </>
                                )}

                                <SimpleGrid columns={2}>
                                    <Text>Estado</Text>
                                    <Select
                                        name="estado_actividad"
                                        value={formData.estado_actividad}
                                        onChange={handleChange}
                                        isDisabled={datos.fecha_finalizacion}>
                                        <option value="Programada">Programada</option>
                                        <option value="Realizada">Realizada</option>
                                    </Select>
                                </SimpleGrid>

                                <Stack direction="column">
                                    <Text fontWeight="bold" textDecoration="underline">
                                        Encargos seleccionados
                                    </Text>
                                    {datosSeleccionados?.map((encargo) =>
                                        esEncargo ? (
                                            <BoxPedido
                                                key={encargo.uuid}
                                                pedido={encargo}
                                                needInfo={formData.estado_actividad === "Realizada"}
                                                handleChange={handleChangeDatos}
                                                isDisabled={datos.fecha_finalizacion}
                                            />
                                        ) : (
                                            <BoxEncargo
                                                key={encargo.uuid}
                                                encargo={encargo}
                                                needInfo={formData.estado_actividad === "Realizada"}
                                                handleChange={handleChangeDatos}
                                                isDisabled={datos.fecha_finalizacion}
                                            />
                                        )
                                    )}
                                </Stack>

                                <Text>Descripción</Text>
                                <Textarea
                                    borderLeft="3px solid red"
                                    name="descripcion"
                                    value={formData.descripcion}
                                    onChange={handleChange}
                                />

                                {datos && (
                                    <Flex gap={4}>
                                        <Button w="100%" onClick={handleSubmit} colorScheme="green" isDisabled={!formData.descripcion}>
                                            {isEdit ? "Editar" : "Crear"}
                                        </Button>
                                        {!datos.fecha_finalizacion &&
                                            (formData.estado_actividad === "Realizada" ||
                                                (datos.estado_actividad === "Realizada" && (
                                                    <Button
                                                        w="100%"
                                                        onClick={handleProcesoGuiado}
                                                        colorScheme="blue"
                                                        isDisabled={!formData.descripcion}>
                                                        Proceso guiado
                                                    </Button>
                                                )))}
                                    </Flex>
                                )}
                            </Stack>
                        )}
                        {isLoading && (
                            <Box>
                                <Text textAlign="center" my={4} fontWeight="bold">
                                    Cargando...
                                </Text>
                                <Center p={6}>
                                    <Spinner size="lg" />
                                </Center>
                            </Box>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
            {isOpenProcesoGuiado && (
                <PromocionProcesoGuiado
                    isOpen={isOpenProcesoGuiado}
                    onClose={onCloseProcesoGuiado}
                    datos={datosEnviados}
                    onClosePadre={onClose}
                />
            )}
        </>
    );
};

export default VentaAlquilerV2Modal;
