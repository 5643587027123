import { Box, Flex, Heading, IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { CloseIcon, ViewIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const ListaDocumentosEncargos = ({ encargos }) => {
    const navigate = useNavigate();
    return (
        <Box w="100%">
            <Heading textAlign="center" fontSize="2xl" color="facebook.800" my={4}>
                ENCARGOS CON DOCUMENTACIÓN
            </Heading>
            {encargos.length === 0 ? (
                <Heading textAlign="center" fontSize="xl" color="red.800">
                    No hay encargos con documentación
                </Heading>
            ) : (
                <TableContainer maxH="40rem" overflowY="auto">
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Encargo</Th>
                                <Th>Inmueble</Th>
                                <Th>Responsable</Th>
                                <Th>Acciones</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {encargos.map((encargo) => {
                                let direccion;
                                if (encargo && encargo.inmueble && encargo.inmueble.ubicacion) {
                                    try {
                                        direccion = JSON.parse(encargo.inmueble.ubicacion).direccion;
                                    } catch (error) {
                                        direccion = JSON.parse(JSON.stringify(encargo.inmueble.ubicacion)).direccion;
                                    }
                                }
                                return (
                                    <Tr key={encargo.uuid}>
                                        <Td>
                                            EN - {encargo.cliente?.nombre} {encargo.cliente?.apellidos} -{" "}
                                            {encargo.estado}
                                        </Td>
                                        <Td>
                                            <strong>
                                                {encargo.inmueble?.tipo_inmueble} {encargo.inmueble?.subtipo_inmueble}
                                            </strong>{" "}
                                            en <strong>{direccion}</strong>
                                        </Td>
                                        <Td>
                                            {encargo.responsable?.nombre} {encargo.responsable?.apellidos}
                                        </Td>
                                        <Td>
                                            <Flex gap={1}>
                                                <IconButton
                                                    colorScheme="facebook"
                                                    icon={<ViewIcon />}
                                                    onClick={() => navigate(`/encargos/ver/${encargo.uuid}`)}
                                                />
                                                <IconButton isDisabled colorScheme="red" icon={<CloseIcon />} />
                                            </Flex>
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default ListaDocumentosEncargos;
