import { Button, SimpleGrid, Stack, useDisclosure, useToast } from "@chakra-ui/react";
import Catastro from "./Catastro";
import ClasificacionEnergetica from "./ClasificacionEnergetica";
import Descripcion from "./Descripcion";
import InfoInmueble from "./InfoInmueble";
import Otros from "./Otros";
import UbicacionInmueble from "./UbicacionInmueble";
import { useNavigate } from "react-router-dom";
import TablaActividadesInmueble from "../../Actividades/TablaActividadesInmueble";
import Separador from "../../Separador/Separador";
import { deleteInmueble, getAllInmuebleFiles } from "../../../services/inmuebles";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import Suministros from "./Suministros";

import { getAllClientes } from "../../../services/clientes";
import { getAllUsuarios } from "../../../services/usuarios";
import { S3_ROUTE } from "../../../data/constants";
import ImagenesInmueble from "./ImagenesInmueble";

const GridInfoInmuebles = ({ formData, handleChange, handleSave, handleCancel, hideActividades = false }) => {
    const navigate = useNavigate();
    const toast = useToast();
    const { user } = useSelector((state) => state.auth);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();

    const [usuarios, setUsuarios] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [needReload, setNeedReload] = useState(true);
    // const [mismaAgencia, setMismaAgencia] = useState(false);

    const [fileLoading, setFileLoading] = useState(false);

    const [imagenes, setImagenes] = useState([]);
    const [galleryImages, setGalleryImages] = useState([{}]);

    let superficie;
    if (formData.superficie) {
        try {
            superficie = JSON.parse(formData.superficie);
        } catch (error) {
            superficie = JSON.parse(JSON.stringify(formData.superficie));
        }
    }
    console.log("🚀 ~ file: GridInfoInmuebles.js:43 ~ GridInfoInmuebles ~ superficie:", superficie)


    useEffect(() => {
        const getData = async () => {
            try {
                setNeedReload(false);
                setFileLoading(true);
                const promises = [getAllUsuarios(), getAllClientes()];
                const [auxUsuarios, auxClientes] = await Promise.all(promises);

                if (formData.uuid) {
                    const auxImagenes = await getAllInmuebleFiles(formData.uuid);
                    setImagenes(auxImagenes);
                    const auxGalleryImages = auxImagenes.map((imagen) => ({
                        original: `${S3_ROUTE}/inmuebles/${formData.uuid}/${imagen.nombre}`,
                        thumbnail: `${S3_ROUTE}/inmuebles/${formData.uuid}/${imagen.nombre}`,
                    }));
                    setGalleryImages(auxGalleryImages);
                }

                setUsuarios(auxUsuarios);
                setClientes(auxClientes);
                // const findUser = auxUsuarios.find((usuario) => usuario.uuid === formData.responsableUuid);
                // setMismaAgencia(findUser != null);
            } catch (error) {
                console.log("🚀 ~ file: GridInfoInmuebles.js:91 ~ getData ~ error:", error);
            } finally {
                setFileLoading(false);
            }
        };
        needReload && formData && getData();
    }, [needReload, formData, handleChange]);

    const refreshImagenes = async () => {
        setFileLoading(true);
        try {
            const auxImagenes = await getAllInmuebleFiles(formData.uuid);
            setImagenes(auxImagenes);

            let auxGalleryImages = [];

            if (auxImagenes.length > 0) {
                auxGalleryImages = auxImagenes.map((imagen) => ({
                    original: `${S3_ROUTE}/inmuebles/${formData.uuid}/${imagen.nombre}`,
                    thumbnail: `${S3_ROUTE}/inmuebles/${formData.uuid}/${imagen.nombre}`,
                }));
                setGalleryImages(auxGalleryImages);
            }
        } catch (error) {
            console.log("🚀 ~ file: GridInfoInmuebles.js:117 ~ refreshImagenes ~ error:", error);
        } finally {
            setFileLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            await deleteInmueble(formData.uuid);
            okNotification("Inmueble eliminado correctamente", toast);
            navigate(-1);
        } catch (error) {
            console.error("🚀 ~ file: GridInfoInmuebles.js:26 ~ handleDelete ~ error:", error);
            errorNotification("Error al eliminar el inmueble", toast);
        }
    };

    const checkIfNullOrUndefined = (value) => {
        return value == null || value === undefined || value === "";
    };

    return (
        <>
            <SimpleGrid columns={3} spacing={4}>
                <Stack direction="column">
                    <InfoInmueble formData={formData} handleChange={handleChange} usuarios={usuarios} clientes={clientes} />
                    <UbicacionInmueble formData={formData} handleChange={handleChange} />
                </Stack>

                <Stack direction="column">
                    <Suministros formData={formData.otros} handleChange={handleChange} />
                    <Descripcion formData={formData} handleChange={handleChange} />
                </Stack>

                <Stack direction="column">
                    <ClasificacionEnergetica formData={formData.clasificacion_energetica} handleChange={handleChange} />
                    <Otros formData={formData.otros} handleChange={handleChange} />

                    <Catastro formData={formData} handleChange={handleChange} />
                    {handleChange == null ? (
                        <Stack spacing={0}>
                            <Stack direction={{ base: "column", md: "row" }} spacing={0} w="100%">
                                <Button
                                    w="100%"
                                    rounded="none"
                                    colorScheme="facebook"
                                    onClick={() => navigate(`/inmuebles/editar/${formData.uuid}`)}>
                                    EDITAR
                                </Button>
                                <Button colorScheme="facebook" rounded="none" w="100%" onClick={() => navigate(-1)}>
                                    VOLVER
                                </Button>
                            </Stack>
                            {user && user.rol === "Gerente" && (
                                <Button colorScheme="red" rounded="none" size="sm" onClick={onOpen}>
                                    ELIMINAR
                                </Button>
                            )}
                        </Stack>
                    ) : (
                        <Stack direction={{ base: "column", md: "row" }} spacing={0} w="100%">
                            <Button
                                w="100%"
                                colorScheme="green"
                                rounded="none"
                                onClick={handleSave}
                                isDisabled={
                                    checkIfNullOrUndefined(formData.tipo_inmueble) ||
                                    checkIfNullOrUndefined(formData.ocupado) ||
                                    checkIfNullOrUndefined(formData.localidad) ||
                                    checkIfNullOrUndefined(formData.provincia) ||
                                    checkIfNullOrUndefined(formData.codigo_postal) ||
                                    checkIfNullOrUndefined(formData.fecha_construccion) ||
                                    checkIfNullOrUndefined(superficie.util) ||
                                    checkIfNullOrUndefined(superficie.construida) ||
                                    isNaN(formData.habitaciones) ||
                                    checkIfNullOrUndefined(formData.habitaciones) ||
                                    isNaN(formData.bathrooms) ||
                                    checkIfNullOrUndefined(formData.bathrooms) ||
                                    checkIfNullOrUndefined(formData.estado_inmueble)
                                }>
                                GUARDAR
                            </Button>
                            <Button w="100%" colorScheme="facebook" rounded="none" onClick={handleCancel}>
                                VOLVER
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </SimpleGrid>

            <Separador />

            <ImagenesInmueble
                imagenes={imagenes}
                galleryImages={galleryImages}
                formData={formData}
                refreshImagenes={refreshImagenes}
                fileLoading={fileLoading}
            />

            <Separador />

            {handleChange == null && !hideActividades && (
                <Stack direction="column" mt={2}>
                    <TablaActividadesInmueble datos={formData} />
                </Stack>
            )}

            {isOpen && (
                <ConfirmationModal
                    title="Eliminar inmueble"
                    description="¿Estás seguro de que quieres eliminar este inmueble?"
                    cancelRef={cancelRef}
                    show={isOpen}
                    onClose={onClose}
                    onConfirm={handleDelete}
                />
            )}
        </>
    );
};

export default GridInfoInmuebles;
