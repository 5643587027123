import { Box, Center, Stack, Text } from "@chakra-ui/react";

const InfoEncargo = ({ encargo }) => {
    let ubicacion;
    if(encargo && encargo.inmueble && encargo.inmueble.ubicacion) {
        try {
            ubicacion = JSON.parse(encargo.inmueble.ubicacion);
        } catch (error) {
            ubicacion = JSON.parse(JSON.stringify(encargo.inmueble.ubicacion));
        }
    }

    return (
        <Box bgColor="gray.100" rounded="md" p={4}>
            <Text textAlign="center" mb={2}>
                Estás buscando pedidos que se ajusten a...
            </Text>
            <Center>
                <Stack direction="row" spacing={6}>
                    {ubicacion && (
                        <Stack direction="column" border="1px solid" rounded="md" p={2}>
                            <Text textAlign="center">Provincia</Text>
                            <Text textAlign="center" fontWeight="bold">
                                {ubicacion?.direccion}
                            </Text>
                        </Stack>
                    )}
                    <Stack direction="column" border="1px solid" rounded="md" p={2}>
                        <Text textAlign="center">Tipología inmueble</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {encargo.inmueble?.tipo_inmueble} {encargo.inmueble?.subtipo_inmueble}
                        </Text>
                    </Stack>
                    <Stack direction="column" border="1px solid" rounded="md" p={2}>
                        <Text textAlign="center">Contrato</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {encargo.contrato}
                        </Text>
                    </Stack>
                    <Stack direction="column" border="1px solid" rounded="md" p={2}>
                        <Text textAlign="center">Dormitorios</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {encargo.inmueble?.habitaciones}
                        </Text>
                    </Stack>
                    <Stack direction="column" border="1px solid" rounded="md" p={2}>
                        <Text textAlign="center">Baños</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {encargo.inmueble?.bathrooms}
                        </Text>
                    </Stack>
                    <Stack direction="column" border="1px solid" rounded="md" p={2}>
                        <Text textAlign="center">Precio encargo</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {encargo.precio_final}
                        </Text>
                    </Stack>
                    <Stack direction="column" border="1px solid" rounded="md" p={2}>
                        <Text textAlign="center">Valoración inmueble</Text>
                        <Text textAlign="center" fontWeight="bold">
                            {encargo.inmueble?.valoracion}
                        </Text>
                    </Stack>
                </Stack>
            </Center>
        </Box>
    );
};

export default InfoEncargo;
