import { Select, SimpleGrid, Text } from "@chakra-ui/react";

const EstadoCita = ({ formData, handleChange }) => {
    return (
        <SimpleGrid columns={2}>
            <Text>Estado de la cita</Text>
            <Select name="estado" placeholder="--Ninguno--" value={formData.estado} onChange={handleChange} borderLeft="3px solid red">
                <option value="No realizada">No realizada</option>
                <option value="Programada">Programada</option>
                <option value="Realizada">Realizada</option>
            </Select>
        </SimpleGrid>
    );
};

export default EstadoCita;
