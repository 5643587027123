import {
    Box,
    Button,
    Center,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    NumberInput,
    NumberInputField,
    Radio,
    RadioGroup,
    Select,
    SimpleGrid,
    Stack,
    Text,
    Textarea,
    useToast,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { crearActividad, updateActividad } from "../../../services/actividades";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";
import { crearNoticia, getNoticiaByInmueble } from "../../../services/noticias";
import { updateInmueble } from "../../../services/inmuebles";
import { getAllUsuarios } from "../../../services/usuarios";
import { useNavigate } from "react-router-dom";

const BusquedaProcesoGuiado = ({ isOpen, onClose, onClosePadre, datos }) => {
    const [sigueInteresado, setSigueInteresado] = useState("No");
    const [motivacion, setMotivacion] = useState("Alquilar");
    const [responsable, setResponsable] = useState(datos.responsableUuid);
    const [precioCliente, setPrecioCliente] = useState(undefined);
    const [observaciones, setObservaciones] = useState("");
    const [procedencia, setProcedencia] = useState("");
    const [procedenciaColaborador, setProcedenciaColaborador] = useState("");
    const [colaboradores, setColaboradores] = useState([]);
    const [needReload, setNeedReload] = useState(true);

    const toast = useToast();
    const navigate = useNavigate();

    useEffect(() => {
        const getColaboradores = async () => {
            setNeedReload(false);
            const colaboradores = await getAllUsuarios();
            setColaboradores(colaboradores);
        };
        needReload && getColaboradores();
    }, [needReload]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (sigueInteresado === "Si") {
                if (datos.actividadUuid) {
                    await updateActividad(datos.actividadUuid, datos);
                } else {
                    await crearActividad(datos);
                }

                const check = await getNoticiaByInmueble(datos.inmuebleUuid);
                if (!check) {
                    const noticia = await crearNoticia({
                        estado: "Inmueble en el mercado",
                        motivacion: motivacion,
                        precio_cliente: precioCliente,
                        observaciones: observaciones,
                        procedencia: procedencia,
                        procedencia_colaborador: procedenciaColaborador,
                        inmuebleUuid: datos.inmuebleUuid,
                        responsableUuid: responsable,
                        clienteUuid: datos.clienteUuid,
                    });
                    await updateInmueble(datos.inmuebleUuid, {
                        noticiaUuid: noticia.uuid,
                    });
                    okNotification("Actividad creada. Noticia creada.", toast);
                    navigate(`/noticias/ver/${noticia.uuid}`)
                } else {
                    await updateInmueble(datos.inmuebleUuid, {
                        noticiaUuid: check.uuid,
                    });
                    okNotification("Actividad creada. La noticia ya existia, volviendo a vincular.", toast);
                }

                
            } else if (sigueInteresado === "No") {
                if (datos.actividadUuid) {
                    await updateActividad(datos.actividadUuid, datos);
                } else {
                    await crearActividad(datos);
                }

                okNotification("Actividad creada.", toast);
            } else if (sigueInteresado === "Quizas") {
                if (datos.actividadUuid) {
                    await updateActividad(datos.actividadUuid, datos);
                } else {
                    await crearActividad(datos);
                }

                // CREAR NOTICIA AQUI
                okNotification("Actividad creada correctamente", toast);
            }
        } catch (error) {
            errorNotification("Error al crear la actividad", toast);
        }
        onClose();
        onClosePadre();
        window.location.reload();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="6xl">
            <ModalOverlay bgColor="blackAlpha.800" />
            <ModalContent>
                <ModalHeader>Proceso guiado</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack direction="column">
                        <Text textAlign="center">¿Quiere vender o alquilar?</Text>
                        <Center>
                            <RadioGroup onChange={setSigueInteresado} value={sigueInteresado}>
                                <Stack direction="row">
                                    <Radio value="Si" size="lg">
                                        Si
                                    </Radio>
                                    <Radio value="No" size="lg">
                                        No
                                    </Radio>
                                    <Radio value="Quizas" size="lg">
                                        Quizá
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </Center>
                        {(sigueInteresado === "Si" || sigueInteresado === "Quizas") && (
                            <>
                                <Box p={4} border="1px solid black" rounded="md">
                                    <SimpleGrid columns={2} spacing={4}>
                                        <SimpleGrid columns={2}>
                                            <Text>Motivación</Text>
                                            <Select
                                                borderLeft="3px solid red"
                                                name="motivacion"
                                                value={motivacion}
                                                onChange={(e) => setMotivacion(e.target.value)}>
                                                <option value="Alquilar">Alquilar</option>
                                                <option value="Vender">Vender</option>
                                            </Select>
                                        </SimpleGrid>
                                        <SimpleGrid columns={2}>
                                            <Text>Responsable</Text>
                                            <Select
                                                borderLeft="3px solid red"
                                                name="responsable"
                                                value={responsable}
                                                onChange={(e) => setResponsable(e.target.value)}>
                                                {datos.responsables &&
                                                    datos.responsables.map((responsable) => (
                                                        <option key={responsable.uuid} value={responsable.uuid}>
                                                            {responsable.nombre} {responsable.apellidos}
                                                        </option>
                                                    ))}
                                            </Select>
                                        </SimpleGrid>
                                        <SimpleGrid columns={2}>
                                            <Text>Precio pedido por el cliente</Text>
                                            <NumberInput value={precioCliente}>
                                                <NumberInputField
                                                    borderLeft="3px solid red"
                                                    name="habitaciones"
                                                    onChange={(e) => setPrecioCliente(e.target.value)}
                                                />
                                            </NumberInput>
                                        </SimpleGrid>
                                    </SimpleGrid>
                                    <Text textAlign="center" mt={4}>
                                        Nota de la noticia
                                    </Text>
                                    <Textarea
                                        borderLeft="3px solid red"
                                        name="observaciones"
                                        value={observaciones}
                                        onChange={(e) => setObservaciones(e.target.value)}
                                    />
                                </Box>
                                <Box p={4} border="1px solid black" rounded="md" mt={4}>
                                    <Text textAlign="center">Procedencia</Text>
                                    <Select
                                        borderLeft="3px solid red"
                                        name="procedencia"
                                        value={procedencia}
                                        onChange={(e) => setProcedencia(e.target.value)}
                                        placeholder="-- No seleccionado --">
                                        <option value="Directa">Directa</option>
                                        <option value="Publicidad">Publicidad</option>
                                        <option value="Informador">Informador</option>
                                        <option value="Colaborador">Colaborador</option>
                                    </Select>
                                </Box>
                                {procedencia === "Colaborador" && (
                                    <Box p={4} border="1px solid black" rounded="md" mt={4}>
                                        <Text textAlign="center">Colaborador</Text>
                                        <Select
                                            borderLeft="3px solid red"
                                            name="procedencia_colaborador"
                                            value={procedenciaColaborador}
                                            onChange={(e) => setProcedenciaColaborador(e.target.value)}
                                            placeholder="-- No seleccionado --">
                                            {colaboradores &&
                                                colaboradores.map((colaborador) => (
                                                    <option key={colaborador.uuid} value={colaborador.uuid}>
                                                        {colaborador.nombre} {colaborador.apellidos}
                                                    </option>
                                                ))}
                                        </Select>
                                    </Box>
                                )}
                            </>
                        )}
                        <Button
                            colorScheme="blue"
                            size="sm"
                            mt={4}
                            onClick={handleSubmit}
                            isDisabled={(!observaciones || !procedencia || !precioCliente) && sigueInteresado !== "No"}>
                            Guardar
                        </Button>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default BusquedaProcesoGuiado;
