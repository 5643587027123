import { Flex, Select, Stack, Text, Textarea } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllUsuarios } from "../../../services/usuarios";
import { getAllAgencias } from "../../../services/agencias";
import { useSelector } from "react-redux";

const InfoGeneral = ({ noticia, handleChange }) => {
    const navigate = useNavigate();
    const [responsables, setResponsables] = useState([]);
    const [agencias, setAgencias] = useState([]);
    const [needReload, setNeedReload] = useState(true);

    let direccion;
    if(noticia && noticia.inmueble && noticia.inmueble.ubicacion) {
        try {
            direccion = JSON.parse(noticia.inmueble.ubicacion);
        } catch (error) {
            direccion = JSON.parse(JSON.stringify(noticia.inmueble.ubicacion));
        }
    }
    const fullDireccion = direccion && `${direccion.direccion}, ${direccion.sin_numero ? "S/N" : direccion.numero}`;

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        const fetchNoticia = async () => {
            setNeedReload(false);
            const [usuarios, auxAgencias] = await Promise.all([getAllUsuarios(), getAllAgencias()]);
            setResponsables(usuarios);
            setAgencias(auxAgencias);
        };
        needReload && fetchNoticia();
    }, [needReload]);

    return (
        <Stack direction="column" p={4} border="1px solid black" rounded="md">
            <Flex gap={4}>
                <Text minW="7rem">Cliente</Text>
                <Text
                    fontWeight="bold"
                    _hover={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => navigate(`/clientes/ver/${noticia.cliente.uuid}`)}>
                    {noticia.cliente?.nombre} {noticia.cliente?.apellidos}
                </Text>
            </Flex>
            <Flex gap={4}>
                <Text minW="7rem">Inmueble</Text>
                <Text
                    fontWeight="bold"
                    _hover={{ cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => navigate(`/inmuebles/ver/${noticia.inmueble.uuid}`)}>
                    {noticia.inmueble?.tipo_inmueble} {noticia.inmueble?.subtipo_inmueble} - {fullDireccion}
                </Text>
            </Flex>
            <Flex gap={4}>
                <Text minW="7rem">Responsable</Text>
                <Select
                    name="responsableUuid"
                    value={noticia.responsableUuid}
                    onChange={handleChange}
                    isDisabled={!handleChange}>
                    {responsables &&
                        responsables.map((responsable) => (
                            <option key={responsable.uuid} value={responsable.uuid}>
                                {responsable.nombre} {responsable.apellidos}
                            </option>
                        ))}
                </Select>
            </Flex>
            {user?.rol === "Gerente" && (
                <Flex gap={4}>
                    <Text minW="7rem">Agencia</Text>
                    <Select
                        name="agenciaUuid"
                        value={noticia.agenciaUuid}
                        onChange={handleChange}
                        isDisabled={handleChange == null || user?.rol !== "Gerente"}
                        placeholder="-- Ninguna --">
                        {agencias &&
                            agencias.map((agencia) => {
                                return (
                                    <option key={agencia.uuid} value={agencia.uuid}>
                                        {agencia.nombre}
                                    </option>
                                );
                            })}
                    </Select>
                </Flex>
            )}
            <Text mt={4}>Observaciones</Text>
            <Textarea
                name="observaciones"
                value={noticia.observaciones}
                onChange={handleChange}
                isDisabled={!handleChange}
            />
        </Stack>
    );
};

export default InfoGeneral;
