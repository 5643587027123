import { useEffect, useState } from "react";
import { Box, Button, Heading, Spacer, Stack, Table, TableContainer, Tbody, Td, Thead, Tr } from "@chakra-ui/react";
import { getAllClientes } from "../../services/clientes";
import TableItem from "../../components/Clientes/TableItem";
import Layout from "../../components/Layout/Layout";
import { useNavigate } from "react-router-dom";
import FiltrosClientes from "../../components/Filtros/FiltroClientes";
import { AddIcon } from "@chakra-ui/icons";

const BuscarClientes = () => {
    const [clientes, setClientes] = useState([]);
    const [clientesFiltrados, setClientesFiltrados] = useState([]);
    const [needReload, setNeedReload] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const getUsuarios = async () => {
            const users = await getAllClientes();
            // Ordenar por nombre
            users.sort((a, b) => {
                // Ignore upper and lowercase
                const nameA = a.nombre.toUpperCase();
                const nameB = b.nombre.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            });
            setClientes(users);
            setNeedReload(false);
        };

        needReload && getUsuarios();
    }, [needReload]);

    return (
        <Layout>
            <Box w="100%">
                <Heading textAlign="center" color="facebook.800">
                    CLIENTES
                </Heading>
            </Box>
            <Stack direction="row" mt={-10}>
                <Spacer />
                <FiltrosClientes datos={clientes} setDatosFiltrados={setClientesFiltrados} />

                <Button onClick={() => navigate("/clientes/crear")} colorScheme="facebook" rounded="none">
                    <AddIcon />
                </Button>
            </Stack>

            <Box mt={6}>
                <TableContainer w="100%" border="1px" borderColor="facebook.400" rounded="lg" shadow="dark-lg">
                    <Table size="lg" p={4}>
                        <Thead bgColor="facebook.100">
                            <Tr>
                                <Td>Tipo de cliente</Td>
                                <Td>Cliente</Td>
                                <Td>Tipo identificador</Td>
                                <Td>Identificador</Td>
                                <Td>Dirección</Td>
                                <Td>Telefono</Td>
                                <Td>Correo</Td>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {clientesFiltrados &&
                                clientesFiltrados.map((usuario) => {
                                    return (
                                        <TableItem key={usuario.uuid} usuario={usuario} setNeedReload={setNeedReload} />
                                    );
                                })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Layout>
    );
};

export default BuscarClientes;
