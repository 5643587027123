import { useEffect, useState } from "react";

import { Box, Heading, Table, TableContainer, Tbody, Td, Thead, Tr } from "@chakra-ui/react";

import { getAllInmuebles } from "../../services/inmuebles";
import InmuebleTableItem from "../../components/Inmuebles/TablaInmuebles/InmuebleTableItem";
import Layout from "../../components/Layout/Layout";
import FiltroInmuebles from "../../components/Filtros/FiltroInmuebles";

const ListarInmuebles = () => {
    const [inmuebles, setInmuebles] = useState([]);
    const [inmueblesFiltrados, setInmueblesFiltrados] = useState(inmuebles);
    const [needReload, setNeedReload] = useState(true);

    useEffect(() => {
        const getInmuebles = async () => {
            const auxInmuebles = await getAllInmuebles();
            setInmuebles(auxInmuebles);
            setNeedReload(false);
        };

        needReload && getInmuebles();
    }, [needReload]);

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center" color="facebook.800">INMUEBLES</Heading>
            </Box>

            <FiltroInmuebles datos={inmuebles} setDatosFiltrados={setInmueblesFiltrados} />

            <Box mt={6}>
                <TableContainer w="100%" border="1px" borderColor="facebook.400" rounded="lg" shadow="dark-lg">
                    <Table size="lg" p={4}>
                        <Thead bgColor="facebook.100">
                            <Tr>
                                <Td>Inmueble</Td>
                                <Td>Estado</Td>
                                <Td>Tipo</Td>
                                <Td>Propietario</Td>
                                <Td>Responsable</Td>
                                <Td>Creación</Td>
                                <Td>Modificación</Td>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {inmueblesFiltrados &&
                                inmueblesFiltrados.map((inmueble) => {
                                    return <InmuebleTableItem key={inmueble.uuid} inmueble={inmueble} />;
                                })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </Layout>
    );
};

export default ListarInmuebles;
