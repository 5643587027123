export const defaultClienteData = {
    tipo_cliente: undefined,
    tipo_identificador: undefined,
    identificador: undefined,
    nombre: undefined,
    apellidos: undefined,
    sexo: undefined,
    fecha_nacimiento: undefined,
    nacionalidad: undefined,
    estado_civil: undefined,
    nivel_estudios: undefined,
    trabajo: undefined,
    cliente_compartido: 0,
    perfil_wolfin: false,
    telefono: undefined,
    otros_datos_contacto: {
        email: undefined,
        email_secundario: undefined,
        telefono_secundario: undefined,
        provincia: undefined,
        ciudad: undefined,
        codigo_postal: undefined,
        direccion: undefined,
        numero: undefined,
        piso: undefined,
        letra: undefined,
        notas: undefined,
    },
    procedencia: undefined,
    observaciones: undefined,
};
