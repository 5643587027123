import { Heading, Input, SimpleGrid, Stack, Text } from '@chakra-ui/react';

const Password = ({ handleChange }) => {

    return (
        <Stack border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md" my={2}>
                CONTRASEÑA DE ACCESO
            </Heading>
            <SimpleGrid columns={2}>
                <Text>Nueva contraseña</Text>
                <Input borderLeft="3px solid red" type="password" name="password" onChange={handleChange} />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Repite contraseña</Text>
                <Input borderLeft="3px solid red" type="password" name="confirm_password" onChange={handleChange} />
            </SimpleGrid>
        </Stack>
    );
};

export default Password;
