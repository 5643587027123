import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { BsHouseFill } from "react-icons/bs";
import { Box, Center, Checkbox, Input, Stack, Text } from "@chakra-ui/react";

const style = {
    container: {
        width: "100%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
        fontFamily: "inherit",
    },
    fullWidth: {
        width: "100%",
        fontFamily: "inherit",
        boxSizing: "border-box",
    },
    fullLabel: {
        width: "100%",
        boxSizing: "border-box",
        fontFamily: "inherit",
    },
    halfLabel: {
        width: "49%",
        boxSizing: "border-box",
        fontFamily: "inherit",
    },
    button: {
        width: "100%",
        fontFamily: "inherit",
        boxSizing: "border-box",
    },
};

const AutoComplete = ({ inputId, handleChange, formData }) => {
    const [direccionCompleta, setDireccionCompleta] = useState("");
    let ubicacion;
    try {
        ubicacion = JSON.parse(formData.ubicacion);
    } catch (error) {
        ubicacion = JSON.parse(JSON.stringify(formData.ubicacion));
    }
    const [showMap, setShowMap] = useState(false);

    // Temporizardor 1 segundo para mostrar el mapa
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMap(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        let autocomplete;
        const handlePlaceSelect = async () => {
            if (!handleChange) return;
            const place = autocomplete.getPlace();
            const latitud = await place.geometry.location.lat();
            const longitud = await place.geometry.location.lng();
            let info = {
                isCustom: true,
                ubicacion: {
                    direccion: place.formatted_address,
                    latitud: latitud,
                    longitud: longitud,
                },
            };

            for (const component of place.address_components) {
                const type = component.types[0];
                switch (type) {
                    case "street_number":
                        info.ubicacion.numero = component.long_name;
                        info.ubicacion.sin_numero = false;
                        break;
                    case "route":
                        info.ubicacion.calle = component.long_name;
                        break;
                    case "locality":
                        info.localidad = component.long_name;
                        break;
                    case "administrative_area_level_2":
                        info.provincia = component.long_name;
                        break;
                    case "postal_code":
                        info.codigo_postal = component.long_name;
                        break;
                    default:
                        console.log("irrelevant component type");
                        break;
                }
            }
            handleChange(info);
        };

        const renderGoogle = () => {
            if (window.google && window.google.maps && window.google.maps.places && handleChange) {
                autocomplete = new window.google.maps.places.Autocomplete(document.getElementById(inputId), {
                    types: ["address"],
                    componentRestrictions: { country: "es" },
                    fields: ["address_components", "geometry", "formatted_address"],
                });
                autocomplete.addListener("place_changed", handlePlaceSelect);
            } else {
                setTimeout(renderGoogle, 500);
            }
        };

        if (!handleChange) return;
        const script = document.getElementById("placesScript");
        if (!script) {
            const newScript = document.createElement("script");
            newScript.id = "placesScript";
            newScript.src =
                "https://maps.googleapis.com/maps/api/js?key=" +
                "AIzaSyCoxqLUb3-V9x_VzQSGBP0tmsdcdn5jxg8" +
                "&libraries=places";
            newScript.async = true;
            newScript.onload = renderGoogle;
            document.body.appendChild(newScript);
        } else {
            renderGoogle();
        }

        return () => {
            if (autocomplete) autocomplete.unbindAll();
        };
    }, [inputId, handleChange]);

    //listen for mobile screen size
    const [mobile, setMobile] = useState(false);
    useEffect(() => {
        const screenSize = (e) => {
            const w = e.target.innerWidth;
            w < 600 ? setMobile(true) : setMobile(false);
        };
        window.addEventListener("resize", screenSize);
    }, []);

    const IconPointer = () => <BsHouseFill />;

    return (
        <Box style={style.container}>
            {handleChange && (
                <label style={style.fullLabel}>
                    Escribe la dirección
                    <Input
                        border="2px solid green"
                        id={inputId}
                        type="text"
                        value={direccionCompleta}
                        onChange={(e) => setDireccionCompleta(e.target.value)}
                    />
                </label>
            )}
            <label style={style.fullLabel}>
                Dirección completa
                <Input
                    borderLeft="3px solid red"
                    name="ubicacion.direccion"
                    value={ubicacion.direccion}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </label>
            <label style={!mobile ? style.halfLabel : style.fullLabel}>
                Calle
                <Input
                    borderLeft="3px solid red"
                    name="ubicacion.calle"
                    value={ubicacion.calle}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </label>

            <label style={!mobile ? style.halfLabel : style.fullLabel}>
                <Center h="100%">
                    <Stack>
                        <Text fontWeight="bold">SIN NUMERO</Text>
                        <Center>
                            <Box mx={4}>
                                <Checkbox
                                    size="lg"
                                    name="ubicacion.sin_numero"
                                    isChecked={ubicacion.sin_numero}
                                    onChange={handleChange}
                                    isDisabled={!handleChange}
                                />
                            </Box>
                        </Center>
                    </Stack>
                </Center>
            </label>

            <label style={!mobile ? style.halfLabel : style.fullLabel}>
                Número
                <Input
                    name="ubicacion.numero"
                    value={ubicacion.numero}
                    onChange={handleChange}
                    isDisabled={!handleChange || ubicacion.sin_numero}
                />
            </label>

            <label style={!mobile ? style.halfLabel : style.fullLabel}>
                Interior
                <Input
                    name="ubicacion.interior"
                    value={ubicacion.interior}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </label>

            <label style={!mobile ? style.halfLabel : style.fullLabel}>
                Escalera
                <Input
                    name="ubicacion.escalera"
                    value={ubicacion.escalera}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </label>

            <label style={!mobile ? style.halfLabel : style.fullLabel}>
                Numero de planta
                <Input
                    name="ubicacion.num_planta"
                    value={ubicacion.num_planta}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </label>

            <label style={!mobile ? style.halfLabel : style.fullLabel}>
                Localidad
                <Input
                    borderLeft="3px solid red"
                    name="localidad"
                    value={formData.localidad}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </label>

            <label style={!mobile ? style.halfLabel : style.fullLabel}>
                Provincia
                <Input
                    borderLeft="3px solid red"
                    name="provincia"
                    value={formData.provincia}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                    textTransform="capitalize"
                />
            </label>

            <label style={!mobile ? style.halfLabel : style.fullLabel}>
                Codigo postal
                <Input
                    borderLeft="3px solid red"
                    name="codigo_postal"
                    value={formData.codigo_postal}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </label>

            {showMap && ubicacion.latitud && ubicacion.longitud && (
                <Box h="50vh" w="100%" my={4} rounded="lg">
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyBCqAxVb_lWsngMPHHFNpEd8WZNNO6p0wU" }}
                        draggable={true}
                        hoverDistance={20}
                        defaultCenter={{
                            lat: ubicacion.latitud,
                            lng: ubicacion.longitud,
                        }}
                        defaultZoom={17}
                        center={{
                            lat: ubicacion.latitud,
                            lng: ubicacion.longitud,
                        }}>
                        <IconPointer lat={ubicacion.latitud} lng={ubicacion.longitud} />
                    </GoogleMapReact>
                </Box>
            )}
        </Box>
    );
};

export default AutoComplete;
