import { Heading, Input, Select, SimpleGrid, Stack, Text } from "@chakra-ui/react"

const InfoGeneral = ({ formData, handleChange }) => {

    let ubicacion;
    if(formData.direccion){
        try {
            ubicacion = JSON.parse(formData.direccion);
        } catch (error) {
            ubicacion = JSON.parse(JSON.stringify(formData.direccion));
        }
    }

    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Información general</Heading>

            <SimpleGrid columns={2}>
                <Text>Nombre agencia</Text>
                <Input name="nombre" borderLeft="3px solid red" isDisabled={handleChange == null} value={formData.nombre} onChange={handleChange} />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Heading size="md" my={2}>
                    Dirección
                </Heading>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Dirección</Text>
                <Input
                    name="direccion.calle"
                    value={ubicacion.calle}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Número</Text>
                <Input
                    name="direccion.numero"
                    value={ubicacion.numero}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Localidad</Text>
                <Input
                    name="direccion.localidad"
                    value={ubicacion.localidad}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Provincia</Text>
                <Input
                    name="direccion.provincia"
                    value={ubicacion.provincia}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Código postal</Text>
                <Input
                    name="direccion.codigo_postal"
                    value={ubicacion.codigo_postal}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Activa</Text>
                <Select name="activada" isDisabled={handleChange == null} value={formData.activada} onChange={handleChange}>
                    <option value="true">Sí</option>
                    <option value="false">No</option>
                </Select>
            </SimpleGrid>

        </Stack>
    )
}

export default InfoGeneral
            
    
