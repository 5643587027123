import { Checkbox, Heading, Input, Select, SimpleGrid, Stack, Text, Textarea } from "@chakra-ui/react";
import EasyDatePicker from "../../EasyDatePicker/EasyDatePicker";

const InfoGeneral = ({ formData, handleChange }) => {
    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Información general</Heading>

            <SimpleGrid columns={2}>
                <Text>Tipo de cliente</Text>
                <Select
                    name="tipo_cliente"
                    value={formData.tipo_cliente}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                    placeholder="-- Ninguno --">
                    <option value="Fisica">Persona física</option>
                    <option value="Juridica">Persona jurídica</option>
                </Select>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Tipo de documento</Text>
                <Select
                    isDisabled={handleChange == null}
                    name="tipo_identificador"
                    value={formData.tipo_identificador}
                    onChange={handleChange}
                    placeholder="-- Ninguno --">
                    <option value="DNI">DNI/NIF</option>
                    <option value="NIE">NIE</option>
                </Select>
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Nº Identificación</Text>
                <Input
                    name="identificador"
                    isDisabled={handleChange == null}
                    value={formData.identificador}
                    onChange={handleChange}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Nombre</Text>
                <Input
                    name="nombre"
                    borderLeft="3px solid red"
                    isDisabled={handleChange == null}
                    value={formData.nombre}
                    onChange={handleChange}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Apellidos</Text>
                <Input
                    name="apellidos"
                    borderLeft="3px solid red"
                    isDisabled={handleChange == null}
                    value={formData.apellidos}
                    onChange={handleChange}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Sexo</Text>
                <Select
                    name="sexo"
                    value={formData.sexo}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                    placeholder="-- Ninguno --">
                    <option value="Hombre">Hombre</option>
                    <option value="Mujer">Mujer</option>
                </Select>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Fecha de nacimiento</Text>
                <EasyDatePicker name="fecha_nacimiento" value={formData.fecha_nacimiento} handleChange={handleChange} />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Nacionalidad</Text>
                <Input
                    name="nacionalidad"
                    isDisabled={handleChange == null}
                    value={formData.nacionalidad}
                    onChange={handleChange}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Estado civil</Text>
                <Select
                    isDisabled={handleChange == null}
                    name="estado_civil"
                    value={formData.estado_civil}
                    onChange={handleChange}
                    placeholder="-- Ninguno --">
                    <option value="Soltero">Soltero</option>
                    <option value="Casado">Casado</option>
                    <option value="Familia numerosa">Familia numerosa</option>
                </Select>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Nivel de estudios</Text>
                <Select
                    name="nivel_estudios"
                    isDisabled={handleChange == null}
                    value={formData.nivel_estudios}
                    onChange={handleChange}
                    placeholder="-- Ninguno --">
                    <option value="Sin estudios">Sin estudios</option>
                    <option value="Primaria">Primaria</option>
                    <option value="Secundaria">Secundaria</option>
                    <option value="Bachillerato">Bachillerato</option>
                    <option value="FP">Formación profesional</option>
                    <option value="Universidad">Universidad</option>
                    <option value="Doctorado">Doctorado</option>
                </Select>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Trabajo</Text>
                <Select
                    name="trabajo"
                    isDisabled={handleChange == null}
                    value={formData.trabajo}
                    onChange={handleChange}
                    placeholder="-- Ninguno --">
                    <option value="Desempleado">Desempleado</option>
                    <option value="Temporal">Temporal</option>
                    <option value="Autonomo">Autónomo</option>
                    <option value="Fijo discontinuo">Fijo discontinuo</option>
                    <option value="Indefinido">Indefinido</option>
                    <option value="Funcionario">Funcionario</option>
                    <option value="Interino">Interino</option>
                    <option value="Pensionista">Pensionista</option>
                </Select>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Cliente compartido</Text>
                <Checkbox
                    size="lg"
                    isDisabled={handleChange == null}
                    name="cliente_compartido"
                    value={formData.cliente_compartido}
                    onChange={handleChange}
                    isChecked={formData.cliente_compartido}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Wolfin financiera</Text>
                <Checkbox
                    size="lg"
                    isDisabled={handleChange == null}
                    name="perfil_wolfin"
                    value={formData.perfil_wolfin}
                    onChange={handleChange}
                    isChecked={formData.perfil_wolfin}
                />
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Observaciones</Text>
                <Textarea
                    name="observaciones"
                    isDisabled={handleChange == null}
                    value={formData.observaciones}
                    onChange={handleChange}
                />
            </SimpleGrid>
        </Stack>
    );
};

export default InfoGeneral;
