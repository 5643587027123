import { Heading, Input, SimpleGrid, Stack, Text } from '@chakra-ui/react';

const Catastro = ({ formData, handleChange }) => {
    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Catastro</Heading>
            <SimpleGrid columns={2}>
                <Text>Referencia catastral</Text>
                <Input name="id_catastral" value={formData.id_catastral} onChange={handleChange} isDisabled={handleChange == null} />
            </SimpleGrid>
        </Stack>
    );
};

export default Catastro;
