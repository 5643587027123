export const defaultInmuebleData = {
    uuid: undefined,
    estado_inmueble: undefined,
    tipo_inmueble: undefined,
    subtipo_inmueble: undefined,
    planta: undefined,
    habitaciones: 0,
    bathrooms: 0,
    calefaccion: undefined,
    cocina: undefined,
    localidad: undefined,
    provincia: undefined,
    fecha_construccion: 0,
    clasificacion_energetica: {
        certificacion: undefined,
        clase: undefined,
        valor_ipe: undefined,
        ipe: undefined,
        fuentes_renovables: undefined,
        clase_emisiones: undefined,
        valor_emisiones: undefined,
        numero_registro: undefined,
        fecha_vencimiento: undefined
    },
    otros: {
        ascensor: false,
        garaje: false,
        trastero: false,
        piscina: false,
        jardin: false,
        terraza: false,
        patio: false,
        amueblado: false,
        calefaccion: false,
        aire_acondicionado: false,
    },
    ubicacion: {
        direccion: undefined,
        codigo_postal: undefined,
        num_planta: undefined,
        latitud: undefined,
        longitud: undefined
    },
    superficie: {
        construida: 0,
        util: 0,
        parcela: undefined
    },
    id_catastral: undefined,
    valoracion: 0,
    descripcion: undefined,
    fecha_ultima_venta: new Date(),
    ocupado: undefined

}