import {
    Box,
    Flex,
    Text,
    IconButton,
    Stack,
    Collapse,
    Icon,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useDisclosure,
    Button,
    Menu,
    MenuButton,
    MenuList,
    Avatar,
    Center,
    MenuItem,
    MenuDivider,
    Image,
} from "@chakra-ui/react";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import { HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";

import { NAV_ITEMS } from "../../data/NAV_ITEMS";
import { useDispatch, useSelector } from "react-redux";
import { logout, reset } from "../../features/auth/authSlice";

export default function Header() {
    const { isOpen, onToggle } = useDisclosure();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logoColor = useColorModeValue(null, "brightness(0) invert(1)");

    const { user } = useSelector((state) => state.auth);
    const logoutHandle = () => {
        dispatch(logout());
        dispatch(reset());
        navigate("/");
    };

    return (
        <Box shadow="lg">
            <Flex
                bg={useColorModeValue("white", "gray.800")}
                color={useColorModeValue("gray.600", "white")}
                minH={"60px"}
                py={{ base: 2 }}
                px={{ base: 4 }}
                borderBottom={1}
                borderStyle={"solid"}
                borderColor={useColorModeValue("gray.200", "gray.900")}
                align={"center"}>
                <Flex flex={{ base: 1, md: "auto" }} ml={{ base: -2 }} display={{ base: "flex", md: "none" }}>
                    <IconButton
                        onClick={onToggle}
                        icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
                        variant={"ghost"}
                        aria-label={"Toggle Navigation"}
                    />
                </Flex>
                <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
                    <Image
                        src="https://panacloud-data.s3.eu-west-3.amazonaws.com/logo.png"
                        alt="logo"
                        width="80px"
                        style={{ filter: logoColor }}
                    />

                    <Flex display={{ base: "none", md: "flex" }} ml={10}>
                        <DesktopNav />
                    </Flex>
                </Flex>
                <Flex alignItems={"center"}>
                    <Stack direction={"row"} spacing={7}>
                        <Box zIndex={100}>
                            <Menu>
                                <MenuButton as={Button} rounded={"full"} variant={"link"} cursor={"pointer"} minW={0}>
                                    <Avatar size={"sm"} src={"https://api.dicebear.com/7.x/avataaars-neutral/svg?backgroundType=gradientLinear"} />
                                </MenuButton>
                                <MenuList alignItems={"center"}>
                                    <Center>
                                        <Avatar
                                            size={"2xl"}
                                            src={"https://api.dicebear.com/7.x/avataaars-neutral/svg?backgroundType=gradientLinear"}
                                        />
                                    </Center>
                                    <Text p={2} textAlign="center">
                                        <strong>
                                            {user?.nombre} {user?.apellidos}
                                        </strong>
                                        <br />
                                        <Box fontStyle="italic">
                                            {user?.agencia?.uuid ? user.agencia.nombre : "Sin agencia asignada"}
                                        </Box>
                                        {user?.rol}
                                    </Text>
                                    <MenuDivider />
                                    <MenuItem onClick={() => navigate("/perfil")}>Ajustes del perfil</MenuItem>
                                    <MenuItem onClick={logoutHandle}>Salir de la sesión</MenuItem>
                                </MenuList>
                            </Menu>
                        </Box>
                    </Stack>
                </Flex>
            </Flex>

            <Collapse in={isOpen} animateOpacity>
                <MobileNav />
            </Collapse>
        </Box>
    );
}

const DesktopNav = () => {
    const linkColor = useColorModeValue("facebook.800", "gray.200");
    const linkHoverColor = useColorModeValue("facebook.800", "white");
    const popoverContentBgColor = useColorModeValue("white", "gray.800");

    const { user } = useSelector((state) => state.auth);

    return (
        <Stack direction={"row"} spacing={4}>
            {NAV_ITEMS.map((navItem) => {
                if (navItem.rolesPermitidos === undefined || navItem.rolesPermitidos.includes(user?.rol)) {
                    return (
                        <Box key={navItem.label}>
                            <Popover trigger={"hover"} placement={"bottom-start"}>
                                <PopoverTrigger>
                                    <Link
                                        p={2}
                                        as={RouterLink}
                                        to={navItem.href ?? "#"}
                                        fontSize={"sm"}
                                        fontWeight={500}
                                        color={linkColor}
                                        _hover={{
                                            textDecoration: "none",
                                            color: linkHoverColor,
                                            cursor: navItem.isDisabled ? "not-allowed" : "pointer",
                                        }}>
                                        {navItem.label}
                                    </Link>
                                </PopoverTrigger>

                                {navItem.children && !navItem.isDisabled && (
                                    <PopoverContent
                                        border={0}
                                        boxShadow={"xl"}
                                        bg={popoverContentBgColor}
                                        p={4}
                                        rounded={"xl"}
                                        minW={"sm"}>
                                        <Stack>
                                            {navItem.children.map((child) => (
                                                <DesktopSubNav key={child.label} {...child} />
                                            ))}
                                        </Stack>
                                    </PopoverContent>
                                )}
                            </Popover>
                        </Box>
                    );
                } else {
                    return null;
                }
            })}
        </Stack>
    );
};

const DesktopSubNav = ({ label, href, subLabel }) => {
    return (
        <Link
            as={RouterLink}
            to={href}
            role={"group"}
            display={"block"}
            p={2}
            rounded={"md"}
            _hover={{ bg: useColorModeValue("facebook.200", "gray.900") }}>
            <Stack direction={"row"} align={"center"}>
                <Box>
                    <Text transition={"all .3s ease"} _groupHover={{ color: "facebook.800" }} fontWeight={500}>
                        {label}
                    </Text>
                    <Text fontSize={"sm"}>{subLabel}</Text>
                </Box>
                <Flex
                    transition={"all .3s ease"}
                    transform={"translateX(-10px)"}
                    opacity={0}
                    _groupHover={{
                        opacity: "100%",
                        transform: "translateX(0)",
                    }}
                    justify={"flex-end"}
                    align={"center"}
                    flex={1}>
                    <Icon color={"facebook.800"} w={5} h={5} as={ChevronRightIcon} />
                </Flex>
            </Stack>
        </Link>
    );
};

const MobileNav = () => {
    return (
        <Stack bg={useColorModeValue("white", "gray.800")} p={4} display={{ md: "none" }}>
            {NAV_ITEMS.map((navItem) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};

const MobileNavItem = ({ label, children, href }) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? "#"}
                justify={"space-between"}
                align={"center"}
                _hover={{
                    textDecoration: "none",
                }}>
                <Text fontWeight={600} color={useColorModeValue("gray.600", "gray.200")}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ChevronDownIcon}
                        transition={"all .25s ease-in-out"}
                        transform={isOpen ? "rotate(180deg)" : ""}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={"solid"}
                    borderColor={useColorModeValue("gray.200", "gray.700")}
                    align={"start"}>
                    {children &&
                        children.map((child) => (
                            <Link key={child.label} py={2} href={child.href}>
                                {child.label}
                            </Link>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};
