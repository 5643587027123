import axios from 'axios';

export const getAllAgencias = async () => {
    try {
        const response = await axios.get('/agencias', { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: agencias.js:6 ~ getAllAgencias ~ error:", error)
        throw error.response;
    }
}

export const getAgencia = async (id) => {
    try {
        const response = await axios.get(`/agencias/${id}`);
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: agencias.js:16 ~ getAgencia ~ error:", error)
        throw error.response;
    }
}

export const updateAgencia = async (id, data) => {
    try {
        const response = await axios.patch(`/agencias/${id}`, data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: agencias.js:26 ~ updateAgencia ~ error:", error)
        throw error.response;
    }
}

export const createAgencia = async (data) => {
    try {
        const response = await axios.post('/agencias', data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: agencias.js:36 ~ createAgencia ~ error:", error)
        throw error.response;
    }
}
