import axios from 'axios';

export const getAllClientes = async () => {
    try {
        const response = await axios.get('/clientes', { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: clientes.js:19 ~ getAllClientes ~ error", error)
        throw error.data.error;
    }
}

export const getCliente = async (id) => {
    try {
        const response = await axios.get(`/clientes/${id}`);
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: clientes.js:29 ~ getCliente ~ error", error)
        throw error.response;
    }
}

export const getClienteSimilar = async (data) => {
    try {
        const response = await axios.post('/clientes/similar', data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: clientes.js:59 ~ getClienteSimilar ~ error", error)
        throw error.response;
    }
}

export const updateCliente = async (id, data) => {
    try {
        const response = await axios.patch(`/clientes/${id}`, data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: clientes.js:39 ~ updateCliente ~ error", error)
        throw error.response;
    }
}

export const createCliente = async (data) => {
    try {
        const response = await axios.post('/clientes', data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: clientes.js:49 ~ createCliente ~ error", error)
        throw error.response;
    }
}

export const deleteCliente = async (id) => {
    try {
        const response = await axios.delete(`/clientes/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: clientes.js:58 ~ deleteCliente ~ error:", error)
        throw error.response;
    }
}