import { Checkbox, Heading, SimpleGrid, Stack, Text } from "@chakra-ui/react";

const Permisos = ({ formData, handleChange }) => {
    return (
        <Stack border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md" my={2}>
                RGPD - Permisos
            </Heading>
            <SimpleGrid columns={2}>
                <Text>Informes</Text>
                <Checkbox
                    size="lg"
                    name="permiso_informes"
                    value={formData.permiso_informes}
                    isChecked={formData.permiso_informes}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Impresiones</Text>
                <Checkbox
                    size="lg"
                    name="permiso_impresiones"
                    value={formData.permiso_impresiones}
                    isChecked={formData.permiso_impresiones}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Búsquedas</Text>
                <Checkbox
                    size="lg"
                    name="permiso_busquedas"
                    value={formData.permiso_busquedas}
                    isChecked={formData.permiso_busquedas}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
        </Stack>
    );
};

export default Permisos;
