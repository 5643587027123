import {
    Box,
    Button,
    Center,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    NumberInput,
    NumberInputField,
    Radio,
    RadioGroup,
    Select,
    SimpleGrid,
    Stack,
    Text,
    Textarea,
    useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { crearActividad, updateActividad } from "../../../services/actividades";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";
import EasyDatePickerWithTime from "../../EasyDatePicker/EasyDatePickerWithTime";
import { crearEncargo, getEncargoByNoticia } from "../../../services/encargos";
import { updateInmueble } from "../../../services/inmuebles";
import { createEvent } from "../../../services/agenda";
import { TIPOS_CITAS } from "../../../data/constants";
import { useNavigate } from "react-router-dom";
import { updateNoticia } from "../../../services/noticias";

const AdquisicionProcesoGuiado = ({ isOpen, onClose, onClosePadre, datos }) => {
    const [sigueInteresado, setSigueInteresado] = useState("No");
    const [precioEncargo, setPrecioEncargo] = useState(datos.noticia?.precio_cliente);
    const [valoracion, setValoracion] = useState();
    const [comision, setComision] = useState();
    const [fechaVencimiento, setFechaVencimiento] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)));
    const [notaPrivada, setNotaPrivada] = useState("");

    const [viviendaRegistrada, setViviendaRegistrada] = useState();
    const [costeVivienda, setCosteVivienda] = useState();
    const [tieneHipoteca, setTieneHipoteca] = useState();
    const [tieneDeudas, setTieneDeudas] = useState();
    const [comentarioDeudas, setComentarioDeudas] = useState("");
    const [hipoteca, setHipoteca] = useState(0);

    const [tieneLuz, setTieneLuz] = useState("No");
    const [tieneAgua, setTieneAgua] = useState("No");

    const toast = useToast();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!costeVivienda || !precioEncargo || !valoracion || !comision || !notaPrivada) {
            errorNotification("Rellena todos los campos", toast);
            return;
        }
        try {
            if (sigueInteresado === "Si") {
                let datosActividad;
                if (datos.actividadUuid) {
                    datosActividad = await updateActividad(datos.actividadUuid, datos);
                } else {
                    datosActividad = await crearActividad(datos);
                }

                await createEvent({
                    entidad_relacionada: "Noticia",
                    fechaInicio: datos.fechaInicio,
                    fechaFin: datos.fechaFin,
                    estado: "Programada",
                    tipo: "Adquisicion",
                    ubicacion: datos.ubicacion,
                    descripcion: datos.descripcion,
                    color: TIPOS_CITAS.find((tipo) => tipo.tipo === "Adquisicion").color,
                    responsableUuid: datos.responsableUuid,
                    clienteUuid: datos.clienteUuid,
                    inmuebleUuid: datos.inmuebleUuid,
                    actividadeUuid: datosActividad.uuid,
                });

                const check = await getEncargoByNoticia(datos.noticiaUuid);
                let encargo;
                if (!check) {
                    encargo = await crearEncargo({
                        estado: "Activo",
                        precio_final: precioEncargo,
                        fecha_vencimiento: fechaVencimiento,
                        inmuebleUuid: datos.inmuebleUuid,
                        clienteUuid: datos.clienteUuid,
                        responsableUuid: datos.responsableUuid,
                        noticiaUuid: datos.noticiaUuid,
                        comision: comision,
                        contrato: datos.noticia.motivacion,
                        viviendaRegistrada: viviendaRegistrada === "Si" ? true : false,
                        costeVivienda: costeVivienda,
                        tieneHipoteca: tieneHipoteca === "Si" ? true : false,
                        hipoteca: hipoteca,
                        tieneDeudas: tieneDeudas === "Si" ? true : false,
                        comentarioDeudas: comentarioDeudas,
                        nota_privada: notaPrivada,
                    });

                    okNotification("Encargo creado.", toast);
                }

                await updateNoticia(datos.noticiaUuid, {
                    estado: "Cerrada con exito",
                });

                let otros;
                if (datos.noticia && datos.noticia.inmueble && datos.noticia.inmueble.otros) {
                    try {
                        otros = JSON.parse(datos.noticia.inmueble.otros);
                    } catch (error) {
                        otros = JSON.parse(JSON.stringify(datos.noticia.inmueble.otros));
                    }
                }

                const suministro_luz = tieneLuz === "Si" ? true : false;
                const suministro_agua = tieneAgua === "Si" ? true : false;
                const distintaValoracion = datos.noticia.inmueble.valoracion !== valoracion;
                const distintaLuz = otros.suministro_luz !== suministro_luz;
                const distintaAgua = otros.suministro_agua !== suministro_agua;

                otros = {
                    ...otros,
                    suministro_luz: distintaLuz ? suministro_luz : otros.suministro_luz,
                    suministro_agua: distintaAgua ? suministro_agua : otros.suministro_agua,
                };

                await updateInmueble(datos.inmuebleUuid, {
                    valoracion: distintaValoracion ? valoracion : undefined,
                    encargoUuid: encargo ? encargo.uuid : undefined,
                    otros,
                });

                if (distintaAgua || distintaLuz || distintaValoracion) {
                    okNotification("Inmueble actualizado.", toast);
                }

                okNotification("Actividad y cita creadas. Noticia cerrada.", toast);
                if (encargo) navigate(`/encargos/ver/${encargo.uuid}`);
            } else if (sigueInteresado === "No") {
                if (datos.actividadUuid) {
                    await updateActividad(datos.actividadUuid, datos);
                } else {
                    await crearActividad(datos);
                }
                okNotification("Actividad creada.", toast);
            }
        } catch (error) {
            console.error("🚀 ~ file: AdquisicionProcesoGuiado.js:154 ~ handleSubmit ~ error:", error);
            errorNotification("Error al crear la actividad", toast);
        }
        onClose();
        onClosePadre();
        //window.location.reload()
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="6xl">
            <ModalOverlay bgColor="blackAlpha.800" />
            <ModalContent>
                <ModalHeader>Proceso guiado</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Stack direction="column">
                        <Text textAlign="center">¿Has cogido encargo?</Text>
                        <Center>
                            <RadioGroup onChange={setSigueInteresado} value={sigueInteresado}>
                                <Stack direction="row">
                                    <Radio value="Si" size="lg">
                                        Si
                                    </Radio>
                                    <Radio value="No" size="lg">
                                        No
                                    </Radio>
                                </Stack>
                            </RadioGroup>
                        </Center>
                        {sigueInteresado === "Si" && (
                            <>
                                <Box p={4} border="1px solid black" rounded="md">
                                    <SimpleGrid columns={2} spacing={4}>
                                        <Stack direction="column">
                                            <SimpleGrid columns={2}>
                                                <Text>¿Vivienda registrada?</Text>
                                                <Select
                                                    borderLeft="3px solid red"
                                                    placeholder="Selecciona"
                                                    value={viviendaRegistrada}
                                                    onChange={(e) => setViviendaRegistrada(e.target.value)}>
                                                    <option value="Si">Si</option>
                                                    <option value="No">No</option>
                                                </Select>
                                            </SimpleGrid>
                                            <SimpleGrid columns={2}>
                                                <Text>¿Tiene suministro de luz?</Text>
                                                <Select
                                                    borderLeft="3px solid red"
                                                    placeholder="Selecciona"
                                                    value={tieneLuz}
                                                    onChange={(e) => setTieneLuz(e.target.value)}>
                                                    <option value="Si">Si</option>
                                                    <option value="No">No</option>
                                                </Select>
                                            </SimpleGrid>
                                            <SimpleGrid columns={2}>
                                                <Text>¿Tiene suministro de agua?</Text>
                                                <Select
                                                    borderLeft="3px solid red"
                                                    placeholder="Selecciona"
                                                    value={tieneAgua}
                                                    onChange={(e) => setTieneAgua(e.target.value)}>
                                                    <option value="Si">Si</option>
                                                    <option value="No">No</option>
                                                </Select>
                                            </SimpleGrid>
                                            <SimpleGrid columns={2}>
                                                <Text>¿Cuanto le costó?</Text>
                                                <NumberInput value={costeVivienda}>
                                                    <NumberInputField
                                                        borderLeft="3px solid red"
                                                        name="costeVivienda"
                                                        onChange={(e) => setCosteVivienda(e.target.value)}
                                                    />
                                                </NumberInput>
                                            </SimpleGrid>

                                            <SimpleGrid columns={2}>
                                                <Text>¿Deuda o embargo?</Text>
                                                <Select
                                                    borderLeft="3px solid red"
                                                    placeholder="Selecciona"
                                                    value={tieneDeudas}
                                                    onChange={(e) => setTieneDeudas(e.target.value)}>
                                                    <option value="Si">Si</option>
                                                    <option value="No">No</option>
                                                </Select>
                                            </SimpleGrid>
                                            {tieneDeudas === "Si" && (
                                                <SimpleGrid columns={2}>
                                                    <Text>Comentario deudas/embargo</Text>
                                                    <Textarea
                                                        borderLeft="3px solid red"
                                                        name="comentarioDeudas"
                                                        value={comentarioDeudas}
                                                        onChange={(e) => setComentarioDeudas(e.target.value)}
                                                    />
                                                </SimpleGrid>
                                            )}
                                        </Stack>

                                        <Stack direction="column">
                                            <SimpleGrid columns={2}>
                                                <Text>Hipoteca</Text>
                                                <Select
                                                    borderLeft="3px solid red"
                                                    placeholder="Selecciona"
                                                    value={tieneHipoteca}
                                                    onChange={(e) => setTieneHipoteca(e.target.value)}>
                                                    <option value="Si">Si</option>
                                                    <option value="No">No</option>
                                                </Select>
                                            </SimpleGrid>
                                            {tieneHipoteca === "Si" && (
                                                <SimpleGrid columns={2}>
                                                    <Text>¿Cuanta hipoteca le queda?</Text>
                                                    <NumberInput value={hipoteca}>
                                                        <NumberInputField
                                                            borderLeft="3px solid red"
                                                            name="hipoteca"
                                                            onChange={(e) => setHipoteca(e.target.value)}
                                                        />
                                                    </NumberInput>
                                                </SimpleGrid>
                                            )}
                                            <SimpleGrid columns={2}>
                                                <Text>Precio encargo</Text>
                                                <NumberInput value={precioEncargo}>
                                                    <NumberInputField
                                                        borderLeft="3px solid red"
                                                        name="precioEncargo"
                                                        onChange={(e) => setPrecioEncargo(e.target.value)}
                                                    />
                                                </NumberInput>
                                            </SimpleGrid>
                                            <SimpleGrid columns={2}>
                                                <Text>Valoración</Text>
                                                <NumberInput value={valoracion}>
                                                    <NumberInputField
                                                        borderLeft="3px solid red"
                                                        name="valoracion"
                                                        onChange={(e) => setValoracion(e.target.value)}
                                                    />
                                                </NumberInput>
                                            </SimpleGrid>
                                            <SimpleGrid columns={2}>
                                                <Text>Comisión</Text>
                                                <NumberInput value={comision}>
                                                    <NumberInputField
                                                        borderLeft="3px solid red"
                                                        name="habitaciones"
                                                        onChange={(e) => setComision(e.target.value)}
                                                    />
                                                </NumberInput>
                                            </SimpleGrid>
                                            <SimpleGrid columns={2}>
                                                <Text>Vencimiento encargo</Text>
                                                <EasyDatePickerWithTime
                                                    borderLeft="3px solid red"
                                                    name="fechaVencimiento"
                                                    value={fechaVencimiento}
                                                    handleChange={(fecha) => setFechaVencimiento(fecha)}
                                                />
                                            </SimpleGrid>
                                        </Stack>
                                    </SimpleGrid>
                                    <Text textAlign="center" mt={4}>
                                        Nota privada
                                    </Text>
                                    <Textarea
                                        borderLeft="3px solid red"
                                        name="notaPrivada"
                                        value={notaPrivada}
                                        onChange={(e) => setNotaPrivada(e.target.value)}
                                    />
                                </Box>
                            </>
                        )}
                        <Button
                            colorScheme="blue"
                            size="sm"
                            mt={4}
                            onClick={handleSubmit}
                            isDisabled={
                                !tieneDeudas ||
                                !tieneHipoteca ||
                                !viviendaRegistrada ||
                                !costeVivienda ||
                                !precioEncargo ||
                                !valoracion ||
                                !comision ||
                                !notaPrivada ||
                                !fechaVencimiento
                            }>
                            Guardar
                        </Button>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AdquisicionProcesoGuiado;
