import { useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { useEffect, useState } from "react";
import { getEncargoById } from "../../services/encargos";
import { Box, Heading } from "@chakra-ui/react";
import GridInfoEncargo from "../../components/Encargos/CRUD/GridInfoEncargo";
import GridInfoInmuebles from "../../components/Inmuebles/CRUD/GridInfoInmuebles";
import TablaActividadesEncargo from "../../components/Actividades/TablaActividadesEncargo";
import Separador from "../../components/Separador/Separador";
import ListaDocumentosEncargo from "../../components/Documentos/ListaDocumentosEncargo";

const VerEncargo = () => {
    const { id } = useParams();
    const [encargo, setEncargo] = useState();
    const [needReload, setNeedReload] = useState(true);

    useEffect(() => {
        const fetchEncargo = async () => {
            setNeedReload(false);
            const response = await getEncargoById(id);
            setEncargo(response);
        };
        needReload && fetchEncargo();
    }, [id, needReload]);

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center">Encargo</Heading>
            </Box>
            {encargo && (
                <>
                    <GridInfoEncargo encargo={encargo} />
                    <Separador />
                    <Heading textAlign="center" mb={2}>
                        Inmueble
                    </Heading>
                    <GridInfoInmuebles formData={encargo.inmueble} hideActividades={true} />
                    <Box mt={4}>
                        <TablaActividadesEncargo datos={encargo} />
                        <Separador />
                        <ListaDocumentosEncargo encargoUuid={encargo.uuid} />
                    </Box>
                </>
            )}
        </Layout>
    );
};

export default VerEncargo;
