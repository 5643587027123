import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
} from "@chakra-ui/react";

import GridEvent from "../../components/Agenda/Event";

const CitasModal = ({ isOpen, onClose, formData, handleChange, handleSave, handleCancel }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"xl"} isCentered>
            <ModalOverlay bgColor="blackAlpha.800" />
            <ModalContent>
                <ModalHeader>{formData.tipo}</ModalHeader>
                <ModalCloseButton />
                <ModalBody my={2}>
                    <GridEvent
                        formData={formData}
                        handleChange={handleChange}
                        handleSave={handleSave}
                        handleCancel={handleCancel}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CitasModal;