import {
    Box,
    Button,
    Center,
    Collapse,
    Flex,
    Heading,
    Spacer,
    Spinner,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tr,
    useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getAllEncargoFiles, uploadEncargoFile } from "../../services/encargos";
import Dropzone from "react-dropzone";
import { errorNotification, okNotification } from "../../utils/Notificaciones";
import { AddIcon, CloseIcon, RepeatClockIcon } from "@chakra-ui/icons";
import { S3_ROUTE } from "../../data/constants";

const ListaDocumentosEncargo = ({ encargoUuid }) => {
    const [documentos, setDocumentos] = useState([]);
    const [needReload, setNeedReload] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [mostrarSubirDocumento, setMostrarSubirDocumento] = useState(false);
    const toast = useToast();

    const handleFileSelect = (acceptedFiles) => {
        setFile(acceptedFiles[0]);
        try {
            // Leer el archivo
            const reader = new FileReader();
            reader.readAsDataURL(acceptedFiles[0]);
            reader.onload = async () => {
                const datos = reader.result.split(",")[1];
                // Enviar el archivo al backend
                await uploadEncargoFile(encargoUuid, acceptedFiles[0].name, datos);
            };
            setMostrarSubirDocumento(false);
            okNotification("Documento subido correctamente", toast);
        } catch (err) {
            console.error(err);
            errorNotification("Error al subir el documento", toast);
        }
    };

    useEffect(() => {
        const getDocumentos = async () => {
            try {
                setNeedReload(false);
                setIsLoading(true);
                const auxDocumentos = await getAllEncargoFiles(encargoUuid);
                setDocumentos(auxDocumentos);
                setIsLoading(false);
            } catch (err) {
                console.error(err);
                errorNotification("Error al obtener los documentos", toast);
            }
        };

        needReload && encargoUuid && getDocumentos();
    }, [encargoUuid, needReload, toast]);

    /*
    const handleDownload = async (nombre) => {
        try {
            const response = await getEncargoFile(encargoUuid, nombre);
            // Crear una URL para el archivo descargado
            const url = window.URL.createObjectURL(new Blob([response]));
            // Crear un enlace para descargar el archivo
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", nombre);
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "noopener noreferrer");
            document.body.appendChild(link);
            link.click();
        } catch (err) {
            console.error(err);
            errorNotification("Error al descargar el documento", toast);
        }
    };
    */

    return (
        <Box p={8} border="1px" rounded="md" shadow="lg">
            <Heading textAlign="center" mb={4}>
                Documentos
            </Heading>

            {isLoading && (
                <Box>
                    <Text textAlign="center">Cargando...</Text>
                    <Center>
                        <Spinner />
                    </Center>
                </Box>
            )}

            {!needReload && !isLoading && (
                <Flex mt={-14}>
                    <Button rounded="none" colorScheme="facebook" onClick={() => setNeedReload(true)}>
                        <RepeatClockIcon />
                    </Button>
                    <Spacer />
                    <Button
                        rounded="none"
                        colorScheme={mostrarSubirDocumento ? "red" : "facebook"}
                        onClick={() => setMostrarSubirDocumento(!mostrarSubirDocumento)}>
                        {mostrarSubirDocumento ? <CloseIcon /> : <AddIcon />}
                    </Button>
                </Flex>
            )}

            <Collapse in={mostrarSubirDocumento} animateOpacity>
                <Center p={4} my={4} border="1px solid black" bgColor="blackAlpha.100" rounded="md">
                    <Dropzone onDrop={handleFileSelect}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>
                                    <strong>Arrastra y suelta</strong> un archivo aquí o{" "}
                                    <strong>haz clic para seleccionar uno.</strong>
                                </p>
                                {file && <p>{file.name}</p>}
                            </div>
                        )}
                    </Dropzone>
                </Center>
            </Collapse>

            {!needReload && !isLoading && (
                <TableContainer maxH="40rem" overflowY="auto">
                    <Table>
                        <Tbody>
                            {documentos &&
                                documentos.length > 0 &&
                                documentos.map((documento) => (
                                    <Tr _hover={{ bgColor: "gray.100", cursor: "pointer" }} key={documento}>
                                        <Td>
                                            <a
                                                href={`${S3_ROUTE}/encargos/${encargoUuid}/${documento}`}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                {documento}
                                            </a>
                                        </Td>
                                    </Tr>
                                ))}

                            {documentos && documentos.length === 0 && (
                                <Tr>
                                    <Td colSpan={7} textAlign="center" fontWeight="bold">
                                        <Text py={4}> No hay documentos para este encargo</Text>
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default ListaDocumentosEncargo;
