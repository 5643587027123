import { Divider, Heading, NumberInput, NumberInputField, Select, SimpleGrid, Stack, Text, Textarea } from "@chakra-ui/react";

const Descripcion = ({ formData, handleChange }) => {
    let superficie;

    if (formData.superficie) {
        try {
            superficie = JSON.parse(formData.superficie);
        } catch (error) {
            superficie = JSON.parse(JSON.stringify(formData.superficie));
        }
    }

    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md">Descripción</Heading>

            <SimpleGrid columns={2}>
                <Text>Nº habitaciones</Text>
                <NumberInput value={formData.habitaciones} isDisabled={handleChange == null}>
                    <NumberInputField borderLeft="3px solid red" name="habitaciones" onChange={handleChange} />
                </NumberInput>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Nº baños</Text>
                <NumberInput value={formData.bathrooms} isDisabled={handleChange == null}>
                    <NumberInputField borderLeft="3px solid red" name="bathrooms" onChange={handleChange} />
                </NumberInput>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Cocina</Text>
                <Select
                    value={formData.cocina}
                    name="cocina"
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                    placeholder="-- Ninguno --">
                    <option value="Americana">Americana</option>
                    <option value="Independiente">Independiente</option>
                    <option value="Sin cocina">Sin cocina</option>
                </Select>
            </SimpleGrid>

            <Divider />

            <SimpleGrid columns={2}>
                <Text>Estado</Text>
                <Select
                    borderLeft="3px solid red"
                    name="estado_inmueble"
                    value={formData.estado_inmueble}
                    onChange={handleChange}
                    isDisabled={handleChange == null}
                    placeholder="-- Ninguno --">
                    <option value="Nuevo">Nuevo</option>
                    <option value="Buen estado">Buen estado</option>
                    <option value="Reformado">Reformado</option>
                    <option value="A reformar">A reformar</option>
                </Select>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>M2 útiles</Text>
                <NumberInput value={superficie.util || undefined} isDisabled={handleChange == null}>
                    <NumberInputField borderLeft="3px solid red" name="superficie.util" onChange={handleChange} />
                </NumberInput>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>M2 construidos</Text>
                <NumberInput value={superficie.construida || undefined} isDisabled={handleChange == null}>
                    <NumberInputField borderLeft="3px solid red" name="superficie.construida" onChange={handleChange} />
                </NumberInput>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Parcela</Text>
                <NumberInput value={superficie.parcela || undefined} isDisabled={handleChange == null}>
                    <NumberInputField name="superficie.parcela" onChange={handleChange} />
                </NumberInput>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Año construcción</Text>
                <NumberInput value={formData.fecha_construccion || undefined} isDisabled={handleChange == null}>
                    <NumberInputField borderLeft="3px solid red" name="fecha_construccion" onChange={handleChange} />
                </NumberInput>
            </SimpleGrid>

            <SimpleGrid columns={2}>
                <Text>Valoración (€)</Text>
                {!handleChange ? (
                    <Text
                        fontWeight="bold"
                        border={formData.valoracion ? "2px solid green" : "2px solid red"}
                        p={1}
                        rounded="md"
                        cursor="not-allowed">
                        {formData.valoracion ? new Intl.NumberFormat().format(formData.valoracion) + " €" : "Sin valoración"}
                    </Text>
                ) : (
                    <NumberInput value={formData.valoracion || undefined} isDisabled={handleChange == null}>
                        <NumberInputField name="valoracion" onChange={handleChange} />
                    </NumberInput>
                )}
            </SimpleGrid>

            <Text>Descripción</Text>
            <Textarea name="descripcion" value={formData.descripcion} onChange={handleChange} isDisabled={handleChange == null} />
        </Stack>
    );
};

export default Descripcion;
