import { Heading, Select, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getAllUsuarios } from "../../../services/usuarios";
import { getAllAgencias } from "../../../services/agencias";
import { useSelector } from "react-redux";

const ResponsableAgencia = ({ encargo, handleChange }) => {
    const [responsables, setResponsables] = useState([]);
    const [agencias, setAgencias] = useState([]);
    const [needReload, setNeedReload] = useState(true);

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        const fetchNoticia = async () => {
            setNeedReload(false);
            const [usuarios, auxAgencias] = await Promise.all([getAllUsuarios(), getAllAgencias()]);
            setResponsables(usuarios);
            setAgencias(auxAgencias);
        };
        needReload && fetchNoticia();
    }, [needReload]);

    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md" my={2}>
                Información
            </Heading>
            <SimpleGrid columns={2}>
                <Text minW="7rem">Responsable</Text>
                <Select
                    name="responsableUuid"
                    value={encargo.responsableUuid}
                    onChange={handleChange}
                    isDisabled={!handleChange}>
                    {responsables &&
                        responsables.map((responsable) => (
                            <option key={responsable.uuid} value={responsable.uuid}>
                                {responsable.nombre} {responsable.apellidos}
                            </option>
                        ))}
                </Select>
            </SimpleGrid>
            {user.rol === "Gerente" && (
                <SimpleGrid columns={2}>
                    <Text minW="7rem">Agencia</Text>
                    <Select
                        name="agenciaUuid"
                        value={encargo.agenciaUuid}
                        onChange={handleChange}
                        isDisabled={handleChange == null || user?.rol !== "Gerente"}
                        placeholder="-- Ninguna --">
                        {agencias &&
                            agencias.map((agencia) => {
                                return (
                                    <option key={agencia.uuid} value={agencia.uuid}>
                                        {agencia.nombre}
                                    </option>
                                );
                            })}
                    </Select>
                </SimpleGrid>
            )}
        </Stack>
    );
};

export default ResponsableAgencia;
