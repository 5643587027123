import { Box, Heading, Stack } from "@chakra-ui/react";
import Layout from "../../components/Layout/Layout";
import { useEffect, useState } from "react";
import { getAllPedidos } from "../../services/pedidos";
import { getAllEncargos } from "../../services/encargos";
import { getAllDocumentos } from "../../services/documentacion";
import ListaDocumentosEncargos from "./ListaDocumentosEncargos";
import ListaDocumentosPedidos from "./ListaDocumentosPedidos";

const ListaDocumentos = () => {
    const [pedidos, setPedidos] = useState([]);
    const [encargos, setEncargos] = useState([]);
    

    useEffect(() => {
        const fetchPedidosEncargos = async () => {
            const [pedidos, encargos, documentosS3] = await Promise.all([
                getAllPedidos(),
                getAllEncargos(),
                getAllDocumentos(),
            ]);
            // Borrar todos los pedidos que no aparezcan en documentosS3
            const pedidosFiltrados = pedidos.filter((pedido) =>
                documentosS3.pedidos.some((documento) => documento === pedido.uuid)
            );
            const encargosFiltrados = encargos.filter((encargo) =>
                documentosS3.encargos.some((documento) => documento === encargo.uuid)
            );
            setPedidos(pedidosFiltrados);
            setEncargos(encargosFiltrados);
        };
        fetchPedidosEncargos();
    }, []);

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center" color="facebook.800">
                    DOCUMENTACIÓN
                </Heading>
            </Box>

            <Stack direction="row" spacing={4} border="1px" rounded="lg" borderColor="facebook.600" h="100%">
                <ListaDocumentosPedidos pedidos={pedidos} />
                <Box w="2px" minH="100%" border="1px" borderColor="facebook.800" />
                <ListaDocumentosEncargos encargos={encargos} />
            </Stack>

        </Layout>
    );
};

export default ListaDocumentos;
