import { useEffect, useState } from "react";
import { defaultAgenciaData } from "../../data/DefaultData/DefaultAgenciaData";
import { useParams } from "react-router-dom";
import { getAgencia } from "../../services/agencias";
import Layout from "../../components/Layout/Layout";
import { Box, Heading } from "@chakra-ui/react";
import GridInfoAgencia from "../../components/Agencias/GridInfoAgencia.js";

const VerAgencia = () => {
    const [agencia, setAgencia] = useState(defaultAgenciaData)
    const [needReload, setNeedReload] = useState(true)
    const { id } = useParams()

    useEffect(() => { 
        const getAgenciaById = async () => {
            setNeedReload(false)
            const estaAgencia = await getAgencia(id)
            setAgencia(estaAgencia)
        }

        needReload && getAgenciaById()
    }, [id, needReload])

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center">Ver agencia</Heading>
            </Box>

            {agencia && (
                <GridInfoAgencia
                    formData={agencia}
                />
            )}
        </Layout>
    )
}

export default VerAgencia