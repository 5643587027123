import { Button, SimpleGrid, Stack, useDisclosure, useToast } from "@chakra-ui/react";
import AccesoSistema from "./AccesoSistema";
import Direccion from "./Direccion";
import InfoGeneral from "./InfoGeneral";
import Password from "./Password";
import Permisos from "./Permisos";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import { deleteUsuario } from "../../../services/usuarios";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";
import { useSelector } from "react-redux";

const GridInfoUser = ({ formData, handleChange, handleSave, handleCancel }) => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();
    const toast = useToast();

    const { user } = useSelector((state) => state.auth);

    const handleDeleteUser = async () => {
        try {
            const response = await deleteUsuario(formData.uuid);
            if (!response.error) {
                okNotification(response.message, toast);
                onClose();
                navigate("/usuarios");
            } else {
                errorNotification(response.message, toast);
            }
        } catch (error) {
            console.error("🚀 ~ file: GridInfoUser.js:28 ~ handleDeleteUser ~ error:", error);
            errorNotification("Error al eliminar el usuario", toast);
        }
    };

    return (
        <>
            <SimpleGrid columns={3} spacing={4}>
                <InfoGeneral formData={formData} handleChange={handleChange} />

                <Stack direction="column">
                    <Permisos formData={formData} handleChange={handleChange} />

                    <Direccion direccion={formData.direccion} handleChange={handleChange} />
                </Stack>

                <Stack direction="column">
                    <AccesoSistema formData={formData} handleChange={handleChange} />

                    {handleChange && <Password handleChange={handleChange} />}

                    {handleChange == null && (
                        <Stack spacing={0}>
                            <Stack direction={{ base: "column", md: "row" }} spacing={0} w="100%">
                                <Button
                                    colorScheme="facebook"
                                    fontWeight="bold"
                                    rounded="none"
                                    w="100%"
                                    onClick={() => navigate(`/usuarios/editar/${formData.uuid}`)}>
                                    EDITAR
                                </Button>
                                <Button
                                    colorScheme="facebook"
                                    rounded="none"
                                    w="100%"
                                    fontWeight="bold"
                                    onClick={() => navigate(-1)}>
                                    VOLVER
                                </Button>
                            </Stack>
                            {user.rol === "Gerente" && (
                                <Button colorScheme="red" rounded="none" size="sm" fontWeight="bold" onClick={onOpen}>
                                    ELIMINAR
                                </Button>
                            )}
                        </Stack>
                    )}
                    {handleChange != null && (
                        <Stack direction={{ base: "column", md: "row" }} spacing={0} w="100%">
                            <Button colorScheme="green" rounded="none" onClick={handleSave} w="100%">
                                GUARDAR
                            </Button>
                            <Button colorScheme="facebook" rounded="none" onClick={handleCancel} w="100%">
                                VOLVER
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </SimpleGrid>
            {isOpen && (
                <ConfirmationModal
                    show={isOpen}
                    cancelRef={cancelRef}
                    title={"Eliminar usuario"}
                    body={"¿Quieres borrar el usuario?"}
                    handleClose={onClose}
                    handleConfirm={handleDeleteUser}
                />
            )}
        </>
    );
};

export default GridInfoUser;
