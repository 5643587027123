import {
    Box,
    Center,
    Checkbox,
    Heading,
    Input,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { removeAccents } from "../../utils/removeAccents";

const ResultadosCruce = ({
    encargos,
    cruces,
    handleSelect,
    encargosSeleccionados,
    busquedaPorEncargo = false,
    esCita,
}) => {
    const [filteredCruces, setFilteredCruces] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        let filtered = cruces;

        if (search !== "") {
            const lowerCaseSearch = removeAccents(search.toLowerCase());
            let direccion;

            filtered = cruces.filter((cruce) => {
                const { inmueble, cliente } = cruce;

                if (inmueble) {
                    try {
                        direccion = JSON.parse(inmueble.ubicacion);
                    } catch (error) {
                        direccion = JSON.parse(JSON.stringify(inmueble.ubicacion));
                    }
                }

                // Unir nombre y apellidos del cliente
                const fullName = `${cliente?.nombre} ${cliente?.apellidos}`;
                const searchKeywords = lowerCaseSearch.split(" ");

                return searchKeywords.every((keyword) =>
                    [
                        inmueble?.bathrooms?.toString().toLowerCase(),
                        inmueble?.habitaciones?.toString().toLowerCase(),
                        removeAccents(inmueble?.provincia?.toLowerCase()),
                        inmueble?.codigo_postal?.toString().toLowerCase(),
                        removeAccents(direccion?.direccion?.toLowerCase()),
                        direccion?.numero?.toLowerCase(),
                        removeAccents(direccion?.calle?.toLowerCase()),
                        removeAccents(fullName.toLowerCase()),
                        cliente?.identificador?.toString().toLowerCase(),
                        cliente?.telefono?.toString().toLowerCase(),
                    ].some((value) => value?.includes(keyword))
                );
            });
        }

        setFilteredCruces(filtered);
    }, [search, cruces]);

    return (
        <Box my={4}>
            <Heading textAlign="center" mb={4}>
                Resultados
            </Heading>

            <Center>
                <Input maxW="25%" mb={4} placeholder="Buscar" onChange={(e) => setSearch(e.target.value)} />
            </Center>

            <TableContainer
                maxH="40rem"
                overflowY="auto"
                border="1px"
                borderColor="blackAlpha.400"
                rounded="md"
                shadow="lg">
                <Table p={4}>
                    <Thead>
                        <Tr>
                            <Th>Seleccionar</Th>
                            <Th>{busquedaPorEncargo ? "Pedido" : "Encargo"}</Th>
                            <Th>Tipología</Th>
                            <Th>Precio</Th>
                            <Th>M2</Th>
                            <Th>Dormitorios</Th>
                            <Th>Baños</Th>
                            {encargos.length > 0 && (
                                <>
                                    <Th>Interés del cliente/resultado</Th>
                                    <Th>Comentario</Th>
                                </>
                            )}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredCruces &&
                            filteredCruces.length > 0 &&
                            filteredCruces.map((cruce) => {
                                // encargosSeleccionados en un array de objetos, hay que buscar el uuid
                                // Comparar con el uuid del cruce para ver si está seleccionado
                                const estaSeleccionado =
                                    encargosSeleccionados.find((encargo) => encargo.uuid === cruce.uuid) || false;

                                let superficie, ubicacion;
                                if (!busquedaPorEncargo) {
                                    try {
                                        superficie = JSON.parse(cruce.inmueble?.superficie);
                                        ubicacion = JSON.parse(cruce.inmueble?.ubicacion);
                                    } catch (error) {
                                        superficie = JSON.parse(JSON.stringify(cruce.inmueble?.superficie));
                                        ubicacion = JSON.parse(JSON.stringify(cruce.inmueble?.ubicacion));
                                    }
                                }

                                let existe = null;
                                if (encargos && encargos.length > 0) {
                                    if (busquedaPorEncargo) {
                                        existe = encargos.find((encargo) => encargo.pedidoUuid === cruce.uuid);
                                    } else {
                                        existe = encargos.find((encargo) => encargo.encargoUuid === cruce.uuid);
                                    }
                                }

                                return (
                                    <Tr key={cruce.uuid} _hover={{ bgColor: "gray.100" }}>
                                        <Td>
                                            <Checkbox
                                                size="lg"
                                                value={cruce.uuid}
                                                onChange={handleSelect}
                                                isDisabled={!esCita && existe}
                                                isChecked={
                                                    !esCita && (existe || encargosSeleccionados.includes(cruce))
                                                        ? true
                                                        : estaSeleccionado
                                                }
                                            />
                                        </Td>
                                        {!busquedaPorEncargo ? (
                                            <>
                                                <Td>
                                                    IN - {ubicacion.direccion} - N{ubicacion.numero}
                                                </Td>

                                                <Td>
                                                    {cruce.inmueble?.tipo_inmueble} {cruce.inmueble?.subtipo_inmueble}
                                                </Td>

                                                <Td>
                                                    {cruce.precio_final
                                                        ? new Intl.NumberFormat().format(cruce.precio_final) + " €"
                                                        : "Sin datos"}
                                                </Td>
                                                <Td>{superficie.construida}</Td>
                                                <Td>{cruce.inmueble?.habitaciones}</Td>
                                                <Td>{cruce.inmueble?.bathrooms}</Td>
                                            </>
                                        ) : (
                                            <>
                                                <Td>
                                                    PE - {cruce.tipo_inmueble} {cruce.subtipo_inmueble} -{" "}
                                                    {cruce.cliente?.nombre} {cruce.cliente?.apellidos}
                                                </Td>
                                                <Td>
                                                    {cruce.tipo_inmueble} {cruce.subtipo_inmueble}
                                                </Td>
                                                <Td>
                                                    {cruce.precio_final
                                                        ? new Intl.NumberFormat().format(cruce.precio_final) + " €"
                                                        : "Sin datos"}
                                                </Td>
                                                <Td>
                                                    {cruce.superficie?.construida
                                                        ? cruce.superficie?.construida
                                                        : "Sin datos"}
                                                </Td>
                                                <Td>{cruce.habitaciones ? cruce.habitaciones : "Sin datos"}</Td>
                                                <Td>{cruce.bathrooms ? cruce.bathrooms : "Sin datos"}</Td>
                                            </>
                                        )}
                                        {encargos.length > 0 && (
                                            <>
                                                <Td>
                                                    {existe?.resultado}{" "}
                                                    {existe?.resultado &&
                                                        existe?.fecha &&
                                                        `(${new Date(existe.fecha).toLocaleDateString("es-ES")})`}
                                                </Td>
                                                <Td>{existe?.observaciones}</Td>
                                            </>
                                        )}
                                    </Tr>
                                );
                            })}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ResultadosCruce;
