import {
    Button,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Select,
    SimpleGrid,
    Stack,
    Text,
    Textarea,
    useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getAllUsuarios } from "../../../services/usuarios";
import { crearActividad, updateActividad } from "../../../services/actividades";
import { errorNotification, okNotification } from "../../../utils/Notificaciones";
import { createEvent } from "../../../services/agenda";
import EasyDatePickerWithTime from "../../EasyDatePicker/EasyDatePickerWithTime";
import { TIPOS_CITAS } from "../../../data/constants";
import { useSelector } from "react-redux";
import EasyDatePicker from "../../EasyDatePicker/EasyDatePicker";

const ValoracionModal = ({ isOpen, onClose, datos, isEdit, esNoticia = false }) => {
    const [responsables, setResponsables] = useState([]);
    const [needReload, setNeedReload] = useState(true);
    const [formData, setFormData] = useState(datos);
    const toast = useToast();

    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        const getResponsables = async () => {
            const auxResponsables = await getAllUsuarios();
            setResponsables(auxResponsables);
            if (!isEdit) {
                const newDate = new Date();
                setFormData({
                    ...formData,
                    responsableUuid: user.uuid,
                    estado_actividad: "Programada",
                    modalidad_contacto: "Llamada",
                    fechaInicio: newDate,
                    fechaFin: new Date(newDate.getTime() + 30 * 60000),
                });
            }
            setNeedReload(false);
        };

        needReload && getResponsables();
    }, [formData, needReload, isEdit, user.uuid]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const getDataForSend = () => {
        return {
            entidad_relacionada: "Noticia",
            tipo_actividad: "Valoracion",
            modalidad_contacto: formData.modalidad_contacto,
            fecha_vencimiento: formData.fecha_vencimiento,
            fecha_actividad: formData.fecha_actividad,
            fecha_finalizacion: formData.estado_actividad === "Realizada" ? new Date() : null,
            estado_actividad: formData.estado_actividad,
            descripcion: formData.descripcion,
            recordatorio: false,
            recordatorio_fecha: null,
            responsableUuid: formData.responsableUuid,
            clienteUuid: datos.cliente.uuid,
            noticiaUuid: esNoticia ? datos.uuid : undefined,
            encargoUuid: esNoticia ? undefined : datos.uuid,
            inmuebleUuid: datos.inmueble.uuid,
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const notificationMessage = !isEdit
            ? "Actividad creada. Cita creada"
            : "Actividad y cita actualizada correctamente";
        const errorMessage = "Error al crear la actividad";

        try {
            if (!isEdit) {
                const auxActividad = await crearActividad(getDataForSend());
                await createEvent({
                    entidad_relacionada: "Noticia",
                    fechaInicio: formData.fechaInicio || new Date(),
                    fechaFin: formData.fechaFin || new Date(new Date().getTime() + 30 * 60000),
                    estado: "Programada",
                    tipo: "Valoracion",
                    ubicacion: formData.ubicacion,
                    descripcion: formData.descripcion,
                    color: TIPOS_CITAS.find((tipo) => tipo.tipo === "Valoracion").color,
                    responsableUuid: formData.responsableUuid,
                    clienteUuid: datos.cliente.uuid,
                    inmuebleUuid: datos.inmueble.uuid,
                    actividadeUuid: auxActividad.uuid,
                });
            } else {
                await updateActividad(datos.uuid, {
                    ...formData,
                    fecha_finalizacion: formData.estado_actividad === "Realizada" ? new Date() : undefined,
                });
            }

            okNotification(notificationMessage, toast);
            onClose();
            window.location.reload();
        } catch (error) {
            errorNotification(errorMessage, toast);
            console.error("🚀 ~ file: ActualizacionModal.js:7 ~ ActualizacionModal ~ error", error);
        }
    };

    useEffect(() => {
        const changeFechaInicio = () => {
            try {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    fechaFin: new Date(prevFormData.fechaInicio.getTime() + 30 * 60000),
                }));
            } catch (error) {
                console.error("🚀 ~ file: ValoracionModal.js:129 ~ changeFechaInicio ~ error:", error);
            }
        };

        if (formData?.fechaInicio) {
            changeFechaInicio();
        }
    }, [formData.fechaInicio]);

    let direccion;
    if (datos.inmueble && datos.inmueble.ubicacion) {
        try {
            direccion = JSON.parse(datos.inmueble.ubicacion)?.direccion;
        } catch (error) {
            direccion = JSON.parse(JSON.stringify(datos.inmueble.ubicacion))?.direccion;
        }
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
                <ModalOverlay bgColor="blackAlpha.800" />
                <ModalContent>
                    <ModalHeader>Cita de valoración</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack direction="column" spacing={4}>
                            <SimpleGrid columns={2}>
                                <Text>Cliente</Text>
                                <Input
                                    name="cliente"
                                    value={`${datos.cliente?.nombre} ${datos.cliente?.apellidos}`}
                                    isReadOnly
                                    isDisabled
                                />
                            </SimpleGrid>

                            <SimpleGrid columns={2}>
                                <Text>Entidad relacionada</Text>
                                <Input
                                    name="entidad"
                                    value={`NT - ${datos.inmueble?.tipo_inmueble} ${
                                        datos.inmueble?.subtipo_inmueble ? datos.inmueble?.subtipo_inmueble : ""
                                    } - ${direccion}`}
                                    isReadOnly
                                    isDisabled
                                />
                            </SimpleGrid>

                            <SimpleGrid columns={2}>
                                <Text>Modalidad contacto</Text>
                                <Select
                                    name="modalidad_contacto"
                                    value={formData.modalidad_contacto}
                                    onChange={handleChange}
                                    isDisabled={datos.fecha_finalizacion}>
                                    <option value="Llamada">Llamada</option>
                                    <option value="Presencial">Presencial</option>
                                </Select>
                            </SimpleGrid>

                            <SimpleGrid columns={2}>
                                <Text>Responsable</Text>
                                <Select
                                    name="responsableUuid"
                                    value={formData.responsableUuid}
                                    onChange={handleChange}
                                    isDisabled={datos.fecha_finalizacion}>
                                    {responsables.map((responsable) => (
                                        <option key={responsable.uuid} value={responsable.uuid}>
                                            {responsable.nombre} {responsable.apellidos}
                                        </option>
                                    ))}
                                </Select>
                            </SimpleGrid>

                            <SimpleGrid columns={2}>
                                <Text>Fecha actividad</Text>
                                <EasyDatePicker
                                    name="fecha_actividad"
                                    value={formData.fecha_actividad || new Date()}
                                    handleChange={datos.fecha_finalizacion ? null : handleChange}
                                />
                            </SimpleGrid>

                            {(!datos.fecha_finalizacion && !isEdit) && (
                                <>
                                    <SimpleGrid columns={2}>
                                        <Text>Fecha inicio</Text>
                                        <EasyDatePickerWithTime
                                            name="fechaInicio"
                                            value={formData.fechaInicio}
                                            handleChange={(e) => setFormData({ ...formData, fechaInicio: e })}
                                        />
                                    </SimpleGrid>

                                    <SimpleGrid columns={2}>
                                        <Text>Fecha fin</Text>
                                        <EasyDatePickerWithTime
                                            name="fechaFin"
                                            value={formData.fechaFin}
                                            handleChange={(e) => setFormData({ ...formData, fechaFin: e })}
                                        />
                                    </SimpleGrid>
                                </>
                            )}

                            <SimpleGrid columns={2}>
                                <Text>Estado</Text>
                                <Select
                                    name="estado_actividad"
                                    value={formData.estado_actividad}
                                    onChange={handleChange}
                                    isDisabled={datos.fecha_finalizacion}>
                                    <option value="Programada">Programada</option>
                                    <option value="Realizada">Realizada</option>
                                </Select>
                            </SimpleGrid>

                            <Text>Descripción</Text>
                            <Textarea
                                borderLeft="3px solid red"
                                name="descripcion"
                                value={formData.descripcion}
                                onChange={handleChange}
                                isDisabled={datos.fecha_finalizacion}
                            />

                            {datos && !datos.fecha_finalizacion && (
                                <Flex gap={4}>
                                    <Button w="100%" onClick={handleSubmit} colorScheme="green">
                                        {isEdit ? "Editar" : "Crear"}
                                    </Button>
                                </Flex>
                            )}
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ValoracionModal;
