import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { createEvent } from "../../utils/createEvent";
import EasyDatePickerWithTime from "../EasyDatePicker/EasyDatePickerWithTime";

const Fechas = ({ formData, handleChange }) => {
    const defaultDateInicio = formData.fechaInicio ? new Date(formData.fechaInicio) : new Date();
    const defaultDateFin = formData.fechaFin ? new Date(formData.fechaFin) : new Date();
    const [fechaInicio, setFechaInicio] = useState(defaultDateInicio);
    const [fechaFin, setFechaFin] = useState(defaultDateFin);

    const [ultimaActualizacion, setUltimaActualizacion] = useState({
        fechaInicio: "",
        fechaFin: "",
    });

    useEffect(() => {
        const generateEvent = (name, value) => {
            const event = createEvent(name, value);
            handleChange(event);
        };

        if (fechaInicio !== ultimaActualizacion.fechaInicio) {
            generateEvent("fechaInicio", fechaInicio);
            setUltimaActualizacion({ ...ultimaActualizacion, fechaInicio: fechaInicio });
        }

        if (fechaFin !== ultimaActualizacion.fechaFin) {
            generateEvent("fechaFin", fechaFin);
            setUltimaActualizacion({ ...ultimaActualizacion, fechaFin: fechaFin });
        }
    }, [handleChange, ultimaActualizacion, fechaFin, fechaInicio]);

    return (
        <SimpleGrid columns={2}>
            <Box>
                <Text paddingBottom={2}>Fecha inicio</Text>
                <EasyDatePickerWithTime name="fechaInicio" value={fechaInicio} handleChange={(date) => setFechaInicio(date)} />
            </Box>
            <Box>
                <Text paddingBottom={2}>Fecha fin</Text>
                <EasyDatePickerWithTime name="fechaFin" value={fechaFin} handleChange={(date) => setFechaFin(date)} />
            </Box>
        </SimpleGrid>
    );
};

export default Fechas;
