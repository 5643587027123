import { useEffect, useState } from "react";
import { getPedidosByCliente } from "../../services/pedidos";
import { Box, Button, Flex, Heading, Spacer, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import PedidoTableItem from "./PedidoTableItem";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AddIcon } from "@chakra-ui/icons";

const TablaPedidosCliente = ({ cliente }) => {
    const [pedidos, setPedidos] = useState([]);
    const navigate = useNavigate();
    const [needReload, setNeedReload] = useState(true);
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        const getPedidos = async () => {
            setNeedReload(false);
            const auxPedidos = await getPedidosByCliente(cliente);
            setPedidos(auxPedidos);
        };
        needReload && cliente && user !== null && getPedidos();
    }, [cliente, needReload, user]);

    return (
        <Box p={8} border="1px" rounded="md" shadow="dark-lg">
            <Heading textAlign="center" mb={4}>
                Pedidos
            </Heading>
            <Flex mt={-14}>
                <Spacer />
                <Button rounded="none" colorScheme="facebook" onClick={() => navigate(`/pedidos/crear/${cliente}`)}>
                    <AddIcon />
                </Button>
            </Flex>
            <TableContainer maxH="40rem" overflowY="auto">
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Pedido</Th>
                            <Th>Estado</Th>
                            <Th>Motivación</Th>
                            <Th>Tipología inmueble</Th>
                            <Th>Precio</Th>
                            <Th>Fecha insercción</Th>
                            <Th>Fecha actualización</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {pedidos && pedidos.length === 0 && (
                            <Tr>
                                <Td colSpan={7} textAlign="center" fontWeight="bolder">
                                    No hay pedidos
                                </Td>
                            </Tr>
                        )}
                        {pedidos &&
                            pedidos.length > 0 &&
                            pedidos.map((pedido) => <PedidoTableItem key={pedido.uuid} pedido={pedido} />)}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default TablaPedidosCliente;
