import { Box, Center, Heading, NumberInput, NumberInputField, Select, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getEstadisticas } from "../../services/estadisticas";
import Layout from "../../components/Layout/Layout";

import Generales from "./Generales";
import Agencias from "./Agencias";
import Trabajadores from "./Trabajadores";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
// import { DateRange } from "react-date-range";

// import "react-date-range/dist/styles.css"; // main style file
// import "react-date-range/dist/theme/default.css"; // theme css file

const Estadisticas = () => {
    const { user } = useSelector((state) => state.auth);
    const [estadisticas, setEstadisticas] = useState([]);
    const [dias, setDias] = useState(30);
    const debouncedDias = useDebounce(dias, 500);
    const [tipoEstadisticas, setTipoEstadisticas] = useState();
    const [selectedAgencia, setSelectedAgencia] = useState();
    const [selectedTrabajador, setSelectedTrabajador] = useState();

    const [needReload, setNeedReload] = useState(true);

    // const [dateRange, setDateRange] = useState([
    //     {
    //         startDate: new Date(),
    //         endDate: null,
    //         key: "dateRange",
    //     },
    // ]);

    useEffect(() => {
        const fetchEstadisticas = async () => {
            try {
                const estadisticas = await getEstadisticas(debouncedDias);
                console.log("🚀 ~ file: Estadisticas.js:36 ~ fetchEstadisticas ~ estadisticas:", estadisticas);
                // Order agencias by name
                estadisticas.agencias?.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
                // Order trabajadores by name
                estadisticas.trabajadores?.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));
                setEstadisticas(estadisticas);
                setSelectedAgencia(estadisticas.agencias[0]);

                if (user.rol !== "Gerente" && user.rol !== "Responsable") {
                    const findMyUser = estadisticas.trabajadores.find((trabajador) => trabajador.uuid === user.uuid);
                    setSelectedTrabajador(findMyUser);
                } else {
                    setSelectedTrabajador(estadisticas.trabajadores[0]);
                }
            } catch (error) {
                console.log("🚀 ~ file: Estadisticas.js:24 ~ fetchEstadisticas ~ error:", error);
            } finally {
                setNeedReload(false);
            }
        };
        user && fetchEstadisticas();
    }, [user, debouncedDias]);

    const handleSelectAgencia = (e) => {
        const agencia = estadisticas.agencias.find((agencia) => agencia.uuid === e.target.value);
        setSelectedAgencia(agencia);
    };

    const handleSelectTrabajador = (e) => {
        const trabajador = estadisticas.trabajadores.find((trabajador) => trabajador.uuid === e.target.value);
        setSelectedTrabajador(trabajador);
    };

    useEffect(() => {
        if (estadisticas?.agencias && user?.rol === "Responsable") {
            const agencia = estadisticas.agencias.find((agencia) => agencia.uuid === user.agencia.uuid);
            setSelectedAgencia(agencia);
        }
    }, [user, estadisticas]);

    return (
        <Layout>
            <Box mb={4}>
                <Heading>Informes estadísticos sobre PanaCloud</Heading>

                {needReload && <Loader />}
                {!needReload &&
                    (user?.rol === "Gerente" || user?.rol === "Responsable" ? (
                        <Stack spacing={6} my={4} direction={{ base: "column", md: "row" }}>
                            <Stack>
                                <Text fontSize="md">Selecciona tipo</Text>
                                <Center>
                                    <Select
                                        value={tipoEstadisticas}
                                        w="100%"
                                        placeholder="Selecciona una opción"
                                        onChange={(e) => setTipoEstadisticas(e.target.value)}>
                                        {user?.rol === "Gerente" && <option value="General">General</option>}
                                        <option value="Agencias">Agencias</option>
                                        <option value="Trabajadores">Trabajadores</option>
                                    </Select>
                                </Center>
                                {tipoEstadisticas === "Agencias" && user?.rol === "Gerente" && (
                                    <Stack>
                                        <Text fontSize="md">¿De qué agencia ver las estadísticas?</Text>
                                        <Center>
                                            <Select value={selectedAgencia?.uuid} w="100%" onChange={handleSelectAgencia}>
                                                {estadisticas.agencias?.map((agencia) => (
                                                    <option value={agencia.uuid}>{agencia.nombre}</option>
                                                ))}
                                            </Select>
                                        </Center>
                                    </Stack>
                                )}
                                {tipoEstadisticas === "Trabajadores" && (
                                    <Stack>
                                        <Text fontSize="md">¿De qué trabajador?</Text>
                                        <Center>
                                            <Select value={selectedTrabajador?.uuid} w="100%" onChange={handleSelectTrabajador}>
                                                {estadisticas.trabajadores?.map((trabajador) => (
                                                    <option value={trabajador.uuid}>
                                                        {trabajador.nombre} {trabajador.apellidos}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Center>
                                    </Stack>
                                )}
                                <Stack>
                                    <Text fontSize="md">De los últimos... (días)</Text>
                                    <Center>
                                        <NumberInput defaultValue={dias} min={1} max={365} onChange={(value) => setDias(value)} w="100%">
                                            <NumberInputField />
                                        </NumberInput>
                                    </Center>
                                </Stack>
                            </Stack>
                            <Box w="100%">
                                {tipoEstadisticas === "General" && <Generales estadisticas={estadisticas?.generales} />}
                                {tipoEstadisticas === "Agencias" && <Agencias agencia={selectedAgencia} />}
                                {tipoEstadisticas === "Trabajadores" && <Trabajadores trabajador={selectedTrabajador} />}
                                {!tipoEstadisticas && (
                                    <Box w="100%" fontWeight={"bold"}>
                                        Selecciona una opción del menú lateral para empezar.
                                    </Box>
                                )}
                            </Box>
                        </Stack>
                    ) : (
                        <Box w="100%">
                            <Stack>
                                <Text fontSize="xl" textAlign="center">
                                    De los últimos... (días)
                                </Text>
                                <Center>
                                    <NumberInput
                                        defaultValue={dias}
                                        min={1}
                                        max={365}
                                        onChange={(value) => setDias(value)}
                                        bgColor={"facebook.50"}
                                        rounded="lg">
                                        <NumberInputField />
                                    </NumberInput>
                                </Center>
                            </Stack>
                            {selectedTrabajador && <Trabajadores trabajador={selectedTrabajador} />}
                        </Box>
                    ))}
            </Box>
        </Layout>
    );
};

export default Estadisticas;

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);

    return debouncedValue;
}
