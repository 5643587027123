import { Heading, Input, Select, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import EasyDatePicker from "../../EasyDatePicker/EasyDatePicker";

const InfoGeneral = ({ formData, handleChange, editUser = false }) => {
    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md" my={2}>
                Información general
            </Heading>
            <SimpleGrid columns={2}>
                <Text>Nombre</Text>
                <Input
                    name="nombre"
                    borderLeft="3px solid red"
                    value={formData.nombre}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Apellidos</Text>
                <Input
                    name="apellidos"
                    borderLeft="3px solid red"
                    value={formData.apellidos}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Fecha de nacimiento</Text>
                <EasyDatePicker name="fecha_nacimiento" value={formData.fecha_nacimiento} handleChange={handleChange} />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Sexo</Text>
                <Select
                    name="sexo"
                    value={formData.sexo}
                    onChange={handleChange}
                    placeholder="-- Ninguno --"
                    isDisabled={!handleChange}>
                    <option value="hombre">Hombre</option>
                    <option value="mujer">Mujer</option>
                </Select>
            </SimpleGrid>
            {!editUser && (
                <>
                    <SimpleGrid columns={2}>
                        <Text>Tipo de documento</Text>
                        <Select
                            borderLeft="3px solid red"
                            isDisabled={!handleChange}
                            name="tipo_identificador"
                            value={formData.tipo_identificador}
                            onChange={handleChange}
                            placeholder="-- Ninguno --">
                            <option value="dni">DNI/NIF</option>
                            <option value="nie">NIE</option>
                        </Select>
                    </SimpleGrid>
                    <SimpleGrid columns={2}>
                        <Text>ID. Personal</Text>
                        <Input
                            borderLeft="3px solid red"
                            name="identificador"
                            isDisabled={!handleChange}
                            value={formData.identificador}
                            onChange={handleChange}
                        />
                    </SimpleGrid>
                </>
            )}
            <SimpleGrid columns={2}>
                <Text>Teléfono trabajo</Text>
                <Input
                    borderLeft="3px solid red"
                    name="telefono_trabajo"
                    value={formData.telefono_trabajo}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Teléfono personal</Text>
                <Input
                    name="telefono_personal"
                    value={formData.telefono_personal}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Correo electrónico</Text>
                <Input
                    name="email"
                    borderLeft="3px solid red"
                    value={formData.email}
                    onChange={handleChange}
                    isDisabled={!handleChange}
                />
            </SimpleGrid>
            <SimpleGrid columns={2}>
                <Text>Nivel de estudios</Text>
                <Select
                    name="nivel_estudios"
                    value={formData.nivel_estudios}
                    onChange={handleChange}
                    placeholder="-- Ninguno --"
                    isDisabled={!handleChange}>
                    <option value="Sin estudios">Sin estudios</option>
                    <option value="Primaria">Primaria</option>
                    <option value="Secundaria">Secundaria</option>
                    <option value="Bachillerato">Bachillerato</option>
                    <option value="FP">Formación profesional</option>
                    <option value="Universidad">Universidad</option>
                    <option value="Doctorado">Doctorado</option>
                </Select>
            </SimpleGrid>
            {!editUser && (
                <SimpleGrid columns={2}>
                    <Text>Fecha inicio</Text>
                    <EasyDatePicker
                        borderLeft="3px solid red"
                        name="fecha_inicio"
                        value={formData.fecha_inicio}
                        handleChange={handleChange}
                        isDisabled={!handleChange}
                    />
                </SimpleGrid>
            )}
        </Stack>
    );
};

export default InfoGeneral;
