import { Box, Center, Input, Select, Stack } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { removeAccents } from "../../utils/removeAccents";

const FiltroNoticias = ({ datos, setDatosFiltrados }) => {
    const [estado, setEstado] = useState("");
    const [orden, setOrden] = useState("");
    const [tipo, setTipo] = useState("Personales"); // ["Personales", "Generales"]
    const [textoBuscador, setTextoBuscador] = useState("");
    //const [datosHash, setDatosHash] = useState();

    const { user } = useSelector((state) => state.auth);

    const filtrarDatos = useCallback(() => {
        // Crear una copia de los datos
        let datosFiltrados = [...datos];

        if (tipo !== "") {
            if (tipo === "Generales") {
                datosFiltrados = datos;
            } else if (tipo === "Personales") {
                datosFiltrados = datosFiltrados.filter(
                    (inmueble) => inmueble.responsableUuid?.trim() === user?.uuid?.trim()
                );
            }
        }

        if (orden !== "") {
            datosFiltrados = datosFiltrados.sort((a, b) => {
                const aDate = new Date(a.createdAt);
                const bDate = new Date(b.createdAt);
                if (orden === "asc") {
                    return aDate - bDate;
                } else {
                    return bDate - aDate;
                }
            });
        }

        if (estado !== "") {
            datosFiltrados =
                estado === "Cerradas"
                    ? datosFiltrados.filter(
                          // Solo cerradas con exito o sin exito, si no, todas las contrarias
                          (dato) => dato.estado === "Cerrada con exito" || dato.estado === "Cerrada sin exito"
                      )
                    : datosFiltrados.filter(
                          (dato) => dato.estado !== "Cerrada con exito" && dato.estado !== "Cerrada sin exito"
                      );
        }

        if (textoBuscador !== "") {
            datosFiltrados = datosFiltrados.filter((dato) => {
                let ubicacionInmueble = "";
                try {
                    ubicacionInmueble = JSON.parse(dato.inmueble.ubicacion);
                } catch (error) {
                    ubicacionInmueble = JSON.parse(JSON.stringify(dato.inmueble.ubicacion));
                }

                const fullName = dato.cliente?.nombre + " " + dato.cliente?.apellidos;
                const fullNameResp = dato.responsable?.nombre + " " + dato.responsable?.apellidos;

                const lowerCase = removeAccents(textoBuscador.toLowerCase());
                const searchKeywords = lowerCase.split(" ");

                return searchKeywords.every((keyword) =>
                    [
                        removeAccents(fullName.toLowerCase()),
                        dato.cliente?.identificador?.toLowerCase(),
                        dato.cliente?.telefono?.toLowerCase(),
                        removeAccents(ubicacionInmueble.direccion?.toLowerCase()),
                        dato.responsable?.identificador?.toLowerCase(),
                        dato.responsable?.telefono_trabajo?.toLowerCase(),
                        removeAccents(fullNameResp.toLowerCase()),
                    ].some((text) => text?.includes(keyword))
                );
            });
        }

        return datosFiltrados;
    }, [datos, estado, orden, tipo, user?.uuid, textoBuscador]);

    useEffect(() => {
        const filtro = filtrarDatos();
        setDatosFiltrados(filtro);
    }, [filtrarDatos, setDatosFiltrados]);

    return (
        <Center>
            <Stack direction="column" spacing={0} align="center">
                <Box w="100%">
                    <Input rounded="none" placeholder="Buscar..." onChange={(e) => setTextoBuscador(e.target.value)} />
                </Box>
                <Stack direction="row" spacing={4} align="center" mb={4}>
                    <Stack>
                        <Select size="xs" onChange={(e) => setTipo(e.target.value)}>
                            <option value="Personales">Personales</option>
                            <option value="Generales">Generales</option>
                        </Select>
                    </Stack>
                    <Stack>
                        <Select
                            size="xs"
                            placeholder="-- Abiertas / Cerradas --"
                            onChange={(e) => setEstado(e.target.value)}>
                            <option value="Abiertas">Abiertas</option>
                            <option value="Cerradas">Cerradas</option>
                        </Select>
                    </Stack>
                    <Stack>
                        <Select
                            size="xs"
                            placeholder="-- Orden de creación --"
                            onChange={(e) => setOrden(e.target.value)}>
                            <option value="asc">Antiguas primero</option>
                            <option value="desc">Recientes primero</option>
                        </Select>
                    </Stack>
                </Stack>
            </Stack>
        </Center>
    );
};

export default FiltroNoticias;
