import { Center, Input, Select, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Subtipo_inmueble, Tipologia_inmueble } from "../../data/constants";
import { removeAccents } from "../../utils/removeAccents";

const FiltroEncargos = ({ datos, setDatosFiltrados }) => {
    const [estado, setEstado] = useState("");
    const [textoBuscador, setTextoBuscador] = useState("");
    //const [responsable, setResponsable] = useState("");
    const [tipologia, setTipologia] = useState("");
    const [subtipologia, setSubtipologia] = useState("");
    const [orden, setOrden] = useState("");

    useEffect(() => {
        const filtrarDatos = () => {
            // Crear una copia de los datos
            let datosFiltrados = [...datos];

            if (orden !== "") {
                datosFiltrados = datosFiltrados.sort((a, b) => {
                    const aDate = new Date(a.createdAt);
                    const bDate = new Date(b.createdAt);
                    if (orden === "asc") {
                        return aDate - bDate;
                    } else {
                        return bDate - aDate;
                    }
                });
            }

            if (estado !== "") {
                datosFiltrados = datosFiltrados.filter((dato) => dato.contrato === estado);
            }

            if (tipologia !== "") {
                datosFiltrados = datosFiltrados.filter((dato) => dato.inmueble?.tipo_inmueble === tipologia);
            }

            if (subtipologia !== "") {
                datosFiltrados = datosFiltrados.filter((dato) => dato.inmueble?.subtipo_inmueble === subtipologia);
            }

            if (textoBuscador !== "") {
                const texto = removeAccents(textoBuscador.toLowerCase());
                datosFiltrados = datosFiltrados.filter((dato) => {
                    let ubicacionInmueble = "";
                    try {
                        ubicacionInmueble = JSON.parse(dato.inmueble.ubicacion);
                    } catch (error) {
                        ubicacionInmueble = JSON.parse(JSON.stringify(dato.inmueble.ubicacion));
                    }

                    const fullName = dato.cliente?.nombre + " " + dato.cliente?.apellidos;
                    const fullNameResp = dato.responsable?.nombre + " " + dato.responsable?.apellidos;
                    const searchKeywords = texto.split(" ");

                    return searchKeywords.every((keyword) =>
                        [
                            removeAccents(fullName.toLowerCase()),
                            dato.cliente?.identificador?.toLowerCase(),
                            dato.cliente?.telefono?.toLowerCase(),
                            dato.inmueble?.tipo_inmueble?.toLowerCase(),
                            dato.inmueble?.subtipo_inmueble?.toLowerCase(),
                            removeAccents(ubicacionInmueble?.direccion?.toLowerCase()),
                            removeAccents(ubicacionInmueble?.localidad?.toLowerCase()),
                            removeAccents(ubicacionInmueble?.provincia?.toLowerCase()),
                            ubicacionInmueble?.codigo_postal?.toLowerCase(),
                            removeAccents(fullNameResp.toLowerCase()),
                            dato.responsable?.identificador?.toLowerCase(),
                            dato.responsable?.telefono_trabajo?.toLowerCase(),
                        ].some((text) => text?.includes(keyword))
                    );
                });
            }

            setDatosFiltrados(datosFiltrados);
        };
        datos.length > 0 && setDatosFiltrados && filtrarDatos();
    }, [datos, estado, textoBuscador, tipologia, subtipologia, orden, setDatosFiltrados]);

    let auxSubtipo = [];

    if (tipologia !== "") {
        auxSubtipo = Subtipo_inmueble.find(function (e) {
            return e.tipo === tipologia;
        }).subtipo;
    }

    return (
        <Center>
            <Stack direction="column" spacing={0} align="center">
                <Input
                    w="100%"
                    rounded="none"
                    placeholder="Buscar"
                    onChange={(e) => setTextoBuscador(e.target.value)}
                />
                <Stack direction="row" spacing={2} align="center" mt={4}>
                    <Stack>
                        <Select size="xs" placeholder="-- Motivo --" onChange={(e) => setEstado(e.target.value)}>
                            <option value="Alquilar">Alquilar</option>
                            <option value="Vender">Vender</option>
                        </Select>
                    </Stack>
                    <Stack>
                        <Select size="xs" placeholder="-- Tipología --" onChange={(e) => setTipologia(e.target.value)}>
                            {Tipologia_inmueble.map((tipo, index) => {
                                return (
                                    <option key={index} value={tipo.tipo}>
                                        {tipo.tipo}
                                    </option>
                                );
                            })}
                        </Select>
                    </Stack>
                    <Stack>
                        <Select
                            size="xs"
                            placeholder="-- Subtipología --"
                            onChange={(e) => setSubtipologia(e.target.value)}>
                            {auxSubtipo.map((tipo, index) => {
                                return (
                                    <option key={index} value={tipo.tipo}>
                                        {tipo.tipo}
                                    </option>
                                );
                            })}
                        </Select>
                    </Stack>
                    <Stack>
                        <Select size="xs" placeholder="-- Creación --" onChange={(e) => setOrden(e.target.value)}>
                            <option value="asc">Antiguas primero</option>
                            <option value="desc">Recientes primero</option>
                        </Select>
                    </Stack>
                </Stack>
            </Stack>
        </Center>
    );
};

export default FiltroEncargos;
