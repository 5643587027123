import { Box, Heading, useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { errorNotification, okNotification } from "../../utils/Notificaciones";
import { crearInmueble, getInmuebleById, updateInmueble } from "../../services/inmuebles";
import GridInfoInmuebles from "../../components/Inmuebles/CRUD/GridInfoInmuebles";
import Layout from "../../components/Layout/Layout";
import { defaultInmuebleData } from "../../data/DefaultData/DefaultInmuebleData";
import { useSelector } from "react-redux";

const AddEditInmueble = ({ isEdit = false }) => {
    const copyDefaultInmuebleData = _.cloneDeep(defaultInmuebleData);
    const [formData, setFormData] = useState(copyDefaultInmuebleData);
    const [dataLoaded, setDataLoaded] = useState(false);
    const { id } = useParams();
    const { user } = useSelector((state) => state.auth);

    const navigate = useNavigate();
    const toast = useToast();

    const fetchInmueble = useCallback(async () => {
        try {
            const esteInmueble = await getInmuebleById(id);
            setFormData(esteInmueble);
        } catch (error) {
            console.error(error);
        }
    }, [id, setFormData]);

    const initialFormData = useMemo(() => {
        return isEdit ? {} : { ...copyDefaultInmuebleData, responsableUuid: user?.uuid, clienteUuid: id };
    }, [isEdit, copyDefaultInmuebleData, id, user]);

    useEffect(() => {
        const check = async () => {
            if (isEdit) {
                await fetchInmueble();
            } else {
                setFormData(initialFormData);
            }
            setDataLoaded(true);
        };
        !dataLoaded && user && check();
    }, [isEdit, initialFormData, fetchInmueble, setFormData, dataLoaded, user]);

    const handleSave = async () => {
        if (isEdit) {
            try {
                await updateInmueble(formData.uuid, formData);
                okNotification("Inmueble actualizado correctamente", toast);
                navigate(-1);
            } catch (error) {
                errorNotification("Error al actualizar el inmueble", toast);
            }
        } else {
            try {
                await crearInmueble({
                    ...formData,
                    clienteUuid: id,
                });
                okNotification("Inmueble creado correctamente", toast);
                navigate(-1);
            } catch (error) {
                errorNotification("Error al crear el inmueble", toast);
            }
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    const handleChange = (event) => {
        if (event.isCustom) {
            setFormData({ ...formData, ...event });
        } else {
            const { name, value, type, checked } = event.target;
            setFormData(_.set({ ...formData }, name, type === "checkbox" ? checked : value));
        }
    };

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center">{isEdit ? "Editar" : "Crear"} inmueble</Heading>
            </Box>

            {formData && (
                <GridInfoInmuebles
                    formData={formData}
                    handleChange={handleChange}
                    handleCancel={handleCancel}
                    handleSave={handleSave}
                    isEdit={isEdit}
                />
            )}
        </Layout>
    );
};

export default AddEditInmueble;
