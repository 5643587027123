import { memo } from "react";
import { Route, Routes } from "react-router-dom";

// Components
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import BuscarClientes from "../pages/Clientes/BuscarClientes";
import VerCliente from "../pages/Clientes/VerCliente";
import AddEditCliente from "../pages/Clientes/AddEditCliente";
import CrearReconciliacion from "../pages/Reconciliacion/Reconciliacion";
import ListarPedidos from "../pages/Pedidos/ListarPedidos";
import VerPedido from "../pages/Pedidos/VerPedido";
import AddEditPedidos from "../pages/Pedidos/AddEditPedidos";
import ListarInmuebles from "../pages/Inmuebles/ListarInmuebles";
import VerInmueble from "../pages/Inmuebles/VerInmueble";
import AddEditInmueble from "../pages/Inmuebles/AddEditInmueble";
import VerNoticia from "../pages/Noticias/VerNoticia";
import ListarNoticias from "../pages/Noticias/ListarNoticias";
import ListarEncargos from "../pages/Encargos/ListarEncargos";
import VerEncargo from "../pages/Encargos/VerEncargo";
import EditarNoticia from "../pages/Noticias/EditarNoticia";
import EditarEncargo from "../pages/Encargos/EditarEncargo";
import CrucePedido from "../pages/CruceDemanda/CrucePedido";
import ListarUsuarios from "../pages/Usuarios/ListarUsuarios";
import VerUsuario from "../pages/Usuarios/VerUsuario";
import AddEditUsuarios from "../pages/Usuarios/AddEditUsuarios";
import CruceEncargo from "../pages/CruceDemanda/CruceEncargo";
import Perfil from "../pages/Perfil/Perfil";
import VerAgencia from "../pages/Agencias/VerAgencia";
import BuscarAgencias from "../pages/Agencias/BuscarAgencias";
import AddEditAgencia from "../pages/Agencias/AddEditAgencia";
import Estadisticas from "../pages/Estadisticas/Estadisticas";
import ListaDocumentos from "../pages/Documentacion/ListaDocumentos";

const AppRoutes = memo(() => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/inicio" element={<Dashboard />} />

            <Route path="/usuarios" element={<ListarUsuarios />} />
            <Route path="/usuarios/ver/:id" element={<VerUsuario />} />
            <Route path="/usuarios/crear" element={<AddEditUsuarios />} />
            <Route path="/usuarios/editar/:id" element={<AddEditUsuarios isEdit={true} />} />
            <Route path="/perfil" element={<Perfil />} />

            <Route path="/clientes" element={<BuscarClientes />} />
            <Route path="/clientes/ver/:id" element={<VerCliente />} />
            <Route path="/clientes/crear" element={<AddEditCliente />} />
            <Route path="/clientes/editar/:id" element={<AddEditCliente isEdit={true} />} />

            <Route path="/agencias" element={<BuscarAgencias />} />
            <Route path="/agencias/ver/:id" element={<VerAgencia />} />
            <Route path="/agencias/crear" element={<AddEditAgencia />} />
            <Route path="/agencias/editar/:id" element={<AddEditAgencia isEdit={true} />} />

            <Route path="/reconciliacion" element={<CrearReconciliacion />} />

            <Route path="/pedidos" element={<ListarPedidos />} />
            <Route path="/pedidos/ver/:id" element={<VerPedido />} />
            <Route path="/pedidos/crear/:id" element={<AddEditPedidos />} />
            <Route path="/pedidos/editar/:id" element={<AddEditPedidos isEdit={true} />} />

            <Route path="/inmuebles" element={<ListarInmuebles />} />
            <Route path="/inmuebles/ver/:id" element={<VerInmueble />} />
            <Route path="/inmuebles/crear/:id" element={<AddEditInmueble />} />
            <Route path="/inmuebles/editar/:id" element={<AddEditInmueble isEdit={true} />} />

            <Route path="/noticias" element={<ListarNoticias />} />
            <Route path="/noticias/ver/:id" element={<VerNoticia />} />
            <Route path="/noticias/editar/:id" element={<EditarNoticia />} />

            <Route path="/encargos" element={<ListarEncargos />} />
            <Route path="/encargos/ver/:id" element={<VerEncargo />} />
            <Route path="/encargos/editar/:id" element={<EditarEncargo />} />

            <Route path="/cruce/pedido/promocion/:id" element={<CrucePedido />} />
            <Route path="/cruce/pedido/cita/:id" element={<CrucePedido esCita={true} />} />

            <Route path="/cruce/encargo/promocion/:id" element={<CruceEncargo />} />
            <Route path="/cruce/encargo/cita/:id" element={<CruceEncargo esCita={true} />} />

            <Route path="/estadisticas" element={<Estadisticas />} />

            <Route path="/documentacion" element={<ListaDocumentos />} />
        </Routes>
    );
});

export default AppRoutes;
