import { Heading, Select, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getAllUsuarios } from "../../../services/usuarios";

const Procedencia = ({ formData, handleChange }) => {
    const [colaboradores, setColaboradores] = useState([]);
    const [needReload, setNeedReload] = useState(true);

    useEffect(() => {
        const getColaboradores = async () => {
            setNeedReload(false);
            const colaboradores = await getAllUsuarios();
            setColaboradores(colaboradores);
        };
        needReload && getColaboradores();
    }, [needReload]);

    return (
        <Stack direction="column" border="1px" p={4} rounded="md" shadow="lg">
            <Heading size="md" my={2}>
                Procedencia
            </Heading>
            <SimpleGrid columns={2}>
                <Text>Tipo de origen</Text>
                <Select
                    name="procedencia"
                    value={formData.procedencia}
                    isDisabled={handleChange == null}
                    onChange={handleChange}
                >
                    <option value="Directa">Directa</option>
                    <option value="Agencia Panacea">Agencia Panacea</option>
                    <option value="Wolfin">Wolfin</option>
                    <option value="Colaborador">Colaborador interno</option>
                </Select>
            </SimpleGrid>

            {formData.procedencia === "Colaborador" && (
                <SimpleGrid columns={2}>
                    <Text>Colaborador</Text>
                    <Select
                        name="procendencia_colaborador"
                        value={formData.procendencia_colaborador}
                        isDisabled={handleChange == null}
                        onChange={handleChange}
                    >
                        {colaboradores.map(colaborador => (
                            <option key={colaborador.uuid} value={colaborador.uuid}>
                                {colaborador.nombre} {colaborador.apellidos}
                            </option>
                        ))}
                    </Select>
                </SimpleGrid>
            )}
        </Stack>
    );
};

export default Procedencia;
