import axios from 'axios';

export const getCrucePedidosEncargos = async (id) => {
    try {
        const response = await axios.get(`/cruce/pedido/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: cruces.js:8 ~ getCrucePedidosEncargos ~ error:", error)
        throw error.response;
    }
}

export const getCruceEncargosPedidos = async (id) => {
    try {
        const response = await axios.get(`/cruce/encargo/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: cruces.js:18 ~ getCruceEncargosPedidos ~ error:", error)
        throw error.response;
    }
}