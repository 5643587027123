import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Center, Heading, SimpleGrid, Stack, useToast } from "@chakra-ui/react";

// Services
import _ from "lodash";
import { updateUsuario } from "../../services/usuarios";
import { errorNotification, okNotification } from "../../utils/Notificaciones";

// Components
import InfoGeneral from "../../components/Usuarios/CRUD/InfoGeneral";
import Password from "../../components/Usuarios/CRUD/Password";
import Direccion from "../../components/Usuarios/CRUD/Direccion";
import { useSelector } from "react-redux";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";

const Perfil = () => {
    const toast = useToast();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.auth);
    const [needReload, setNeedReload] = useState(true);
    const [formData, setFormData] = useState(user);

    useEffect(() => {
        if (needReload && user !== null) {
            setFormData(user);
            setNeedReload(false);
        }
    }, [needReload, user]);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(_.set({ ...formData }, name, type === "checkbox" ? checked : value));
    };

    const handleSave = async () => {
        try {
            await updateUsuario(formData.uuid, formData);
            okNotification("Perfil actualizado correctamente", toast);
            navigate("/inicio");
        } catch (error) {
            console.error("🚀 ~ file: EditarUsuario.js:21 ~ handleSave ~ error", error);
            errorNotification("Error al actualizar usuario", toast);
        }
    };

    return (
        <Layout>
            <Heading textAlign="center">Perfil</Heading>
            {formData === null ? (
                <Loader />
            ) : (
                <Center>
                    <Stack direction="row" spacing={4} mt={4}>
                        <InfoGeneral formData={formData} handleChange={handleChange} editUser={true} />
                        <Stack direction="column">
                            <Direccion direccion={formData.direccion} handleChange={handleChange} />
                            <Password handleChange={handleChange} />
                            <SimpleGrid columns={2} gap={4}>
                                <Button colorScheme="blue" size="lg" mt={4} onClick={handleSave}>
                                    Guardar
                                </Button>
                                <Button colorScheme="red" size="lg" mt={4} onClick={() => navigate("/home")}>
                                    Cancelar
                                </Button>
                            </SimpleGrid>
                        </Stack>
                    </Stack>
                </Center>
            )}
        </Layout>
    );
};

export default Perfil;
