import { useEffect, useState } from "react";
import { Box, Button, Center, Heading, useDisclosure } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";

import { getCruceEncargosPedidos } from "../../services/cruces";
import { getEncargoById, getEncargoPedidoById } from "../../services/encargos";

import Layout from "../../components/Layout/Layout";
import ResultadosCruce from "../../components/Cruce/ResultadosCruce";
import PromocionModal from "../../components/Modals/Actividades/PromocionModal";
import VentaAlquilerV2Modal from "../../components/Modals/Actividades/VentaAlquilerV2Modal";
import InfoEncargo from "../../components/Cruce/InfoEncargo";
import PedidosSeleccionados from "../../components/Cruce/PedidosSeleccionados";

const CruceEncargo = ({ esCita = false }) => {
    const [encargo, setEncargo] = useState({});
    const [pedidoEncargos, setPedidoEncargos] = useState([]);
    const [cruces, setCruces] = useState([]);
    const [pedidosSeleccionados, setPedidosSeleccionados] = useState([]);
    const [datosEnviados, setDatosEnviados] = useState(pedidosSeleccionados);
    const [needReload, setNeedReload] = useState(true);

    const { id } = useParams();
    const navigate = useNavigate();

    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const fetchData = async () => {
            setNeedReload(false);
            const [auxEncargo, auxCruces, auxPedidoEncargo] = await Promise.all([
                getEncargoById(id),
                getCruceEncargosPedidos(id),
                getEncargoPedidoById(id),
            ]);
            setEncargo(auxEncargo);
            setCruces(auxCruces);
            setPedidoEncargos(auxPedidoEncargo);
            if (esCita) {
                // Buscar los entres los cruces los que tienen el uuid de los pedidos
                const auxPedidosSeleccionados = auxCruces.filter((cruce) =>
                    auxPedidoEncargo.find((pedido) => pedido.pedidoUuid === cruce.uuid)
                );
                
                setPedidosSeleccionados(auxPedidosSeleccionados);
            }
        };
        needReload && fetchData();
    }, [id, needReload, esCita]);

    const handleSelectPedido = (e) => {
        const { checked, value } = e.target;
        if (checked) {
            const cruce = cruces.find((cruce) => cruce.uuid === value);
            setPedidosSeleccionados([...pedidosSeleccionados, cruce]);
        } else {
            setPedidosSeleccionados(pedidosSeleccionados.filter((pedido) => pedido.uuid !== value));
        }
    };

    const handleCreateActividad = () => {
        setDatosEnviados({
            pedidosSeleccionados,
            encargo: encargo,
            cliente: encargo.cliente,
        });
        onOpen();
    };

    const handleCreateCita = () => {
        setDatosEnviados({
            pedidosSeleccionados,
            encargo: encargo,
            cliente: encargo.cliente,
        });
        onOpen();
    };

    return (
        <>
            <Layout>
                <Box mb={4}>
                    <Heading textAlign="center">Cruce demanda-oferta</Heading>
                </Box>
                <Center gap={4} my={2}>
                    <Button colorScheme="blue" size="sm" mt={2} w="30%" onClick={() => navigate(-1)}>
                        Volver
                    </Button>
                    <Button colorScheme="green" size="sm" mt={2} w="30%" onClick={() => setNeedReload(true)}>
                        Recargar
                    </Button>
                </Center>

                {encargo && <InfoEncargo encargo={encargo} />}

                {pedidosSeleccionados.length > 0 && (
                    <>
                        <PedidosSeleccionados
                            pedidosSeleccionados={pedidosSeleccionados}
                            cruces={cruces}
                            pedidoUuid={id}
                        />
                        {esCita ? (
                            <Center>
                                <Button colorScheme="green" mt={2} w="30%" onClick={handleCreateCita}>
                                    Crear cita de venta/alquiler
                                </Button>
                            </Center>
                        ) : (
                            <Center>
                                <Button colorScheme="green" mt={2} w="30%" onClick={handleCreateActividad}>
                                    Crear actividad (promoción)
                                </Button>
                            </Center>
                        )}
                    </>
                )}

                {pedidoEncargos && cruces.length > 0 && (
                    <ResultadosCruce
                        encargos={pedidoEncargos}
                        encargosSeleccionados={pedidosSeleccionados}
                        cruces={cruces}
                        handleSelect={handleSelectPedido}
                        busquedaPorEncargo={true}
                        esCita={esCita}
                    />
                )}
            </Layout>
            {isOpen && !esCita && (
                <PromocionModal isOpen={isOpen} onClose={onClose} datos={datosEnviados} esEncargo={true} />
            )}
            {isOpen && esCita && (
                <VentaAlquilerV2Modal isOpen={isOpen} onClose={onClose} datos={datosEnviados} esEncargo={true} />
            )}
        </>
    );
};

export default CruceEncargo;
