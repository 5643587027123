import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Box, Heading, useToast } from "@chakra-ui/react";

// Services
import _ from "lodash";
import { createCliente, updateCliente } from "../../services/clientes";
import { errorNotification, okNotification } from "../../utils/Notificaciones";

// Components
import Reconciliacion from "../../components/Reconciliacion/Reconciliacion";
import Layout from "../../components/Layout/Layout";
import { defaultClienteData } from "../../data/DefaultData/DefaultClienteData";

const CrearReconciliacion = () => {
    const defaultClienteDataCopy = _.cloneDeep(defaultClienteData);
    const [formData, setFormData] = useState(defaultClienteDataCopy);
    const [nuevo, setNuevo] = useState([]);
    const [antiguo, setAntiguo] = useState([]);
    const toast = useToast();
    const navigate = useNavigate();

    const { state } = useLocation();

    useEffect(() => {
        try {
            const { clienteNuevo, clienteSimilar } = state;
            setNuevo(clienteNuevo);
            setAntiguo(clienteSimilar);
            setFormData(clienteSimilar);
        } catch {
            //console.error("ERROR");
        }
    }, [state]);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData(_.set({ ...formData }, name, type === "checkbox" ? checked : value));
    };

    const handleCancel = async () => {
        try {
            await createCliente(nuevo);
            okNotification("Cliente creado correctamente", toast);
            navigate("/clientes/");
        } catch (error) {
            console.error("🚀 ~ file: EditarCliente.js:21 ~ handleSave ~ error", error);
            errorNotification("Error al crear el cliente", toast);
        }
    };

    const handleSaveCliente = async () => {
        try {
            await updateCliente(formData.id, formData);
            okNotification("Cliente creado correctamente", toast);
            setFormData(defaultClienteData);
            navigate("/clientes/buscar");
        } catch (error) {
            console.error("🚀 ~ file: GridInfoCliente.js:19 ~ handleSaveCliente ~ error", error);
            errorNotification("Error al crear cliente", toast);
        }
    };

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center">Reconciliacion</Heading>
                <Reconciliacion
                    formData={formData}
                    antiguo={antiguo}
                    nuevo={nuevo}
                    handleChange={handleChange}
                    handleSave={handleSaveCliente}
                    handleCancel={handleCancel}
                    setFormData={setFormData}
                />
            </Box>
        </Layout>
    );
};

export default CrearReconciliacion;
