import {
    Box,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Text,
} from "@chakra-ui/react";
import { ChatIcon } from "@chakra-ui/icons";

const PopoverDescripcion = ({ descripcion }) => {
    const mensaje = descripcion ? descripcion : "Sin información";
    return (
        <Popover placement="left-start" isLazy>
            <PopoverTrigger>
                <IconButton icon={<ChatIcon color="facebook.800" />} variant="ghost" w="100%" />
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader fontWeight="bold">Descripción</PopoverHeader>
                <PopoverBody overflow="auto" maxH="6rem">
                    <Box>
                        <Text
                            style={{
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                            }}
                            w="100%">
                            {mensaje}
                        </Text>
                    </Box>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export default PopoverDescripcion;
