import { Center, Input, Select, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Subtipo_inmueble, Tipologia_inmueble } from "../../data/constants";
import { removeAccents } from "../../utils/removeAccents";

const FiltroInmuebles = ({ datos, setDatosFiltrados }) => {
    const [estado, setEstado] = useState("");
    const [tipologia, setTipologia] = useState("");
    const [subtipologia, setSubtipologia] = useState("");
    const [orden, setOrden] = useState("");

    const [search, setSearch] = useState("");

    useEffect(() => {
        const filtrarDatos = () => {
            // Crear una copia de los datos
            let datosFiltrados = [...datos];

            if (search !== "") {
                const lowerCase = removeAccents(search.toLowerCase());

                datosFiltrados = datosFiltrados.filter((dato) => {

                    let direccion;
                    if (dato.ubicacion) {
                        try {
                            direccion = JSON.parse(dato.ubicacion);
                        } catch (error) {
                            direccion = JSON.parse(JSON.stringify(dato.ubicacion));
                        }
                    }

                    const clienteNombreCompleto = `${dato.cliente?.nombre} ${dato.cliente?.apellidos}`.toLowerCase();

                    const responsableCompleto =
                        `${dato.responsable?.nombre} ${dato.responsable?.apellidos}`.toLowerCase();

                    const searchKeywords = lowerCase.split(" ");

                    return searchKeywords.every((keyword) =>
                        [
                            dato.inmueble?.bathrooms?.toString(),
                            dato.inmueble?.habitaciones?.toString(),
                            removeAccents(dato.inmueble?.localidad?.toLowerCase()),
                            dato.inmueble?.codigo_postal?.toLowerCase(),
                            removeAccents(direccion?.direccion?.toLowerCase()),
                            removeAccents(direccion?.localidad?.toLowerCase()),
                            direccion?.codigo_postal?.toLowerCase(),
                            direccion?.numero?.toLowerCase(),
                            removeAccents(dato.localidad?.toLowerCase()),
                            dato.codigo_postal?.toLowerCase(),
                            dato.cliente?.identificador?.toLowerCase(),
                            dato.cliente?.telefono?.toLowerCase(),
                            dato.responsable?.identificador?.toLowerCase(),
                            dato.responsable?.telefono_trabajo?.toLowerCase(),
                            removeAccents(clienteNombreCompleto),
                            removeAccents(responsableCompleto)
                        ].some((value) => value?.includes(keyword))
                    );
                });
            }

            if (orden !== "") {
                datosFiltrados = datosFiltrados.sort((a, b) => {
                    const aDate = new Date(a.createdAt);
                    const bDate = new Date(b.createdAt);
                    if (orden === "asc") {
                        return aDate - bDate;
                    } else {
                        return bDate - aDate;
                    }
                });
            }

            if (estado !== "") {
                datosFiltrados = datosFiltrados.filter((dato) => {
                    const esNoticia = dato.noticiaUuid !== null;
                    const esEncargo = esNoticia && dato.encargoUuid !== null;
                    if (estado === "Inmueble") {
                        return !esNoticia;
                    } else if (estado === "Noticia") {
                        return esNoticia && !esEncargo;
                    }
                    return esEncargo;
                });
            }

            if (tipologia !== "") {
                datosFiltrados = datosFiltrados.filter((dato) => dato.tipo_inmueble === tipologia);
            }

            if (subtipologia !== "") {
                datosFiltrados = datosFiltrados.filter((dato) => dato.subtipo_inmueble === subtipologia);
            }
            setDatosFiltrados(datosFiltrados);
        };
        datos.length > 0 && setDatosFiltrados && filtrarDatos();
    }, [datos, setDatosFiltrados, estado, tipologia, subtipologia, orden, search]);

    let auxSubtipo = [];

    if (tipologia !== "") {
        auxSubtipo = Subtipo_inmueble.find(function (e) {
            return e.tipo === tipologia;
        }).subtipo;
    }

    return (
        <Center>
            <Stack direction="column" spacing={0} align="center">
                <Input rounded="none" placeholder="Buscar" onChange={(e) => setSearch(e.target.value)} />

                <Stack direction="row" spacing={4} align="center">
                    <Stack>
                        <Select size="xs" placeholder="-- Estado --" onChange={(e) => setEstado(e.target.value)}>
                            <option value="Inmueble">Inmueble</option>
                            <option value="Noticia">Noticia</option>
                            <option value="Encargo">Encargo</option>
                        </Select>
                    </Stack>
                    <Stack>
                        <Select size="xs" placeholder="-- Tipología --" onChange={(e) => setTipologia(e.target.value)}>
                            {Tipologia_inmueble.map((tipo, index) => {
                                return (
                                    <option key={index} value={tipo.tipo}>
                                        {tipo.tipo}
                                    </option>
                                );
                            })}
                        </Select>
                    </Stack>
                    <Stack>
                        <Select
                            size="xs"
                            placeholder="-- Subtipología --"
                            onChange={(e) => setSubtipologia(e.target.value)}>
                            {auxSubtipo.map((tipo, index) => {
                                return (
                                    <option key={index} value={tipo.tipo}>
                                        {tipo.tipo}
                                    </option>
                                );
                            })}
                        </Select>
                    </Stack>
                    <Stack>
                        <Select size="xs" placeholder="-- Creación --" onChange={(e) => setOrden(e.target.value)}>
                            <option value="asc">Antiguas primero</option>
                            <option value="desc">Recientes primero</option>
                        </Select>
                    </Stack>
                </Stack>
            </Stack>
        </Center>
    );
};

export default FiltroInmuebles;
