import axios from "axios";

export const crearInmueble = async (data) => {
    try {
        const response = await axios.post("/inmuebles", data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: inmuebles.js:8 ~ crearInmueble ~ error", error);
        throw error.response;
    }
};

export const getAllInmuebles = async () => {
    try {
        const response = await axios.get("/inmuebles", { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: inmuebles.js:18 ~ getAllInmuebles ~ error", error);
        throw error.response;
    }
};

export const getInmuebleById = async (id) => {
    try {
        const response = await axios.get(`/inmuebles/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: inmuebles.js:28 ~ getInmuebleById ~ error", error);
        throw error.response;
    }
};

export const updateInmueble = async (id, data) => {
    try {
        const response = await axios.patch(`/inmuebles/${id}`, data, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: inmuebles.js:38 ~ updateInmueble ~ error", error);
        throw error.response;
    }
};

export const deleteInmueble = async (id) => {
    try {
        const response = await axios.delete(`/inmuebles/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: inmuebles.js:48 ~ deleteInmueble ~ error", error);
        throw error.response;
    }
};

export const getInmueblesByCliente = async (id) => {
    try {
        const response = await axios.get(`/inmuebles/cliente/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.error("🚀 ~ file: inmuebles.js:28 ~ getInmuebleById ~ error", error);
        throw error.response;
    }
};

export const uploadInmuebleFile = async (id, filename, data) => {
    try {
        const response = await axios.post(`/inmuebles/imagenes/${id}`, {
            filename: filename,
            data: data,
        });

        return response.data.data;
    } catch (error) {
        console.log("🚀 ~ file: inmuebles.js:72 ~ uploadInmuebleFile ~ error:", error);
        throw error.response;
    }
};

export const getAllInmuebleFiles = async (id) => {
    try {
        const response = await axios.get(`/inmuebles/imagenes/${id}`, { retry: 3, retryDelay: 1000 });
        return response.data.data;
    } catch (error) {
        console.log("🚀 ~ file: inmuebles.js:82 ~ getAllInmuebleFiles ~ error:", error);
        throw error.response;
    }
};
