
import { useEffect, useState } from "react";
import { Box, Heading } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import GridInfoCliente from "../../components/Clientes/GridInfoClientes";
import { getCliente } from "../../services/clientes";
import { defaultClienteData } from "../../data/DefaultData/DefaultClienteData";
import Layout from "../../components/Layout/Layout";

const VerCliente = () => {
    const [cliente, setCliente] = useState(defaultClienteData);
    const [needReload, setNeedReload] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        const getClienteById = async () => {
            setNeedReload(false);
            const esteCliente = await getCliente(id);
            setCliente(esteCliente);
        };

        needReload && getClienteById();
    }, [id, needReload]);

    return (
        <Layout>
            <Box mb={4}>
                <Heading textAlign="center">Ver cliente</Heading>
            </Box>

            {cliente && (
                <GridInfoCliente
                    formData={cliente}
                />
            )}
        </Layout>
    );
};

export default VerCliente;
